import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { SessionContext } from '../contexts/SessionContextProvider';

export const IS_NEW_BROWSER_SESSION = 'isNewBrowserSession';

export const isAuthenticated = userId => {
  return userId && Cookies.get(IS_NEW_BROWSER_SESSION);
};

const AuthenticatedComponent = ({ children }) => {
  const { userId, ready } = useContext(SessionContext);
  return ready && isAuthenticated(userId) ? <Fragment>{children}</Fragment> : null;
};

AuthenticatedComponent.propTypes = {
  children: PropTypes.node,
};

export default AuthenticatedComponent;
