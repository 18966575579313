import { last } from 'lodash';
import appendGapSegments from './appendGapSegments';
import appendTherapySegments from './appendTherapySegments';
import buildSegment from './buildSegment';
import { nextYear, startOfYear } from './dateUtils';
import getTherapyDateRanges from './getTherapyDateRanges';
import SegmentType from './SegmentType';

function getFirstTherapyDate(therapyFills) {
  // TODO: Need to fix this. This implementation doesn't actually sort the array, it either returns
  // the reverse version of the array or the orignal array depending on which browser your using. The V8
  // engine would return a reversed list but firefox would return the original array.
  // Need to verify whether we want to return the earliest therapy fill date or the latest therapy fill date.
  const sorted = therapyFills.map(f => f.dispense_date.valueOf()).sort(d => -1 * d);

  return sorted[0];
}

export default function getPDCSegments(therapyFills, fromDate, untilDate) {
  /* eslint-disable no-loop-func, no-param-reassign */
  if (!fromDate) fromDate = startOfYear();
  if (!untilDate) untilDate = nextYear(fromDate);

  const therapyDateRanges = getTherapyDateRanges({ therapyFills, limit: { fromDate, untilDate } });
  if (therapyDateRanges.length < 1) {
    return [
      buildSegment({
        fromDate,
        type: SegmentType.Untracked,
        untilDate,
      }),
    ];
  }

  const segments = [];

  const firstClaimDate = getFirstTherapyDate(therapyFills);
  appendTherapySegments(segments, { firstClaimDate, fromDate, therapyDateRanges });

  // Fill in the gap at the end
  const final = last(segments);

  if (final.untilDate < untilDate) {
    appendGapSegments(segments, {
      firstClaimDate,
      fromDate: final.untilDate,
      untilDate,
    });
  }

  return segments;
}
