import gql from 'graphql-tag';

const GET_COHORT_STATUS = gql`
  query getCohortStatus($cohort_parents_id: Int!) {
    getCohortStatus(cohort_parents_id: $cohort_parents_id) {
      total
    }
  }
`;

export default GET_COHORT_STATUS;
