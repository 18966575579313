import {
  colors,
  DropdownFooter,
  font,
  LinkButton,
  padding,
  space,
  Row,
  Column,
  FlexItem,
} from '@zipdrug/ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { find } from 'lodash';
import CallButtonContainer from 'ui/CallButtonContainer';
import formatPhoneNumber from '../../../dashboard/components/PharmacyList/utils/FormatPhone.utils';

const sx = StyleSheet.create({
  container: {
    position: 'relative',
    ...padding.build(space.half, space.half, font.size.small),
    color: colors.gray62,
    fontSize: font.size.xmedium,
    lineHeight: font.lineHeight.looser,
    overflow: 'hidden',
    width: '29rem',
  },
  content: {
    paddingRight: space.half,
    width: '100%',
    position: 'relative',
  },
  label: {
    marginBottom: '0.2rem',
  },
});

export const GuardianDropdownLabel = () => (
  <div className={css(sx.label)}>
    <strong>Guardian</strong>
  </div>
);

const GuardianMenu = ({ patientInfo, onToggleEdit }) => {
  const { phones, guardian_name, guardian_phone_id } = patientInfo;
  const guardian_phone = find(phones, { id: guardian_phone_id });

  const [isGuardianAvailable, setIsGuardianAvailable] = useState(false);

  useEffect(() => {
    if ((guardian_name && guardian_name !== '') || guardian_phone_id) {
      setIsGuardianAvailable(true);
    }
  }, [guardian_name, guardian_phone_id]);

  return (
    <Column>
      <Row align="start" justify="space-between" styles={sx.container}>
        <Column styles={sx.content}>
          <GuardianDropdownLabel />
          <div>
            {patientInfo && isGuardianAvailable ? (
              <>
                <div>{guardian_name}</div>
                <div>{formatPhoneNumber(guardian_phone?.number, true)}</div>
              </>
            ) : (
              <div>No guardian name or phone number added</div>
            )}
          </div>
        </Column>
        {guardian_phone && (
          <FlexItem flex="none">
            <CallButtonContainer phone={guardian_phone} title={`Call ${guardian_name}`} />
          </FlexItem>
        )}
      </Row>
      <DropdownFooter>
        <LinkButton id="e2e-guardianAddButton" onClick={onToggleEdit} tabIndex="0">
          {patientInfo && isGuardianAvailable ? 'Edit' : 'Add Guardian'}
        </LinkButton>
      </DropdownFooter>
    </Column>
  );
};

GuardianMenu.propTypes = {
  patientInfo: PropTypes.object,
  onToggleEdit: PropTypes.func,
};

export default GuardianMenu;
