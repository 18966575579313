import { StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { border, colors, Div, Dot, padding, Row, space } from '@zipdrug/ui';

const sx = StyleSheet.create({
  container: {
    ...border.build('bottom', { color: colors.gray62 }),
    ...padding.build(space.quarter, 0),
  },
  date: {
    textAlign: 'right',
    width: '7rem',
  },
  fillNumber: {
    minWidth: '3rem',
  },
  secondaryText: {
    color: colors.gray62,
  },
  section: {
    flex: 'none',
  },
});

const RxItemFillListItem = ({
  dispense_date,
  fill_number,
  isLatest,
  isOriginal,
  member_pay_amount,
}) => {
  const dispenseDate = moment.utc(dispense_date);
  const hasLabel = isLatest || isOriginal;

  return (
    <Row align="center" justify="space-between" styles={sx.container}>
      <Row align="center" styles={sx.section}>
        <Div>
          <Div styles={sx.fillNumber}>{`Fill ${fill_number}`}</Div>
        </Div>
        <Dot />
        <Div styles={sx.secondaryText}>{`$${member_pay_amount}`}</Div>
      </Row>
      <Row align="center" styles={sx.section}>
        {hasLabel && <Div styles={sx.secondaryText}>{isLatest ? 'Latest' : 'Original'}</Div>}
        <Dot style={{ opacity: hasLabel ? 1 : 0 }} />
        <Div styles={sx.date} title={dispenseDate.format('MMMM Do, YYYY')}>
          {dispenseDate.format('M/DD/YYYY')}
        </Div>
      </Row>
    </Row>
  );
};

RxItemFillListItem.propTypes = {
  dispense_date: PropTypes.number,
  fill_number: PropTypes.number,
  isLatest: PropTypes.bool,
  isOriginal: PropTypes.bool,
  member_pay_amount: PropTypes.number,
};

export default RxItemFillListItem;
