import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { colors, font, space } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 0,
  },
  header: {
    color: colors.orange,
  },
});

const EnrollmentActivityItem = ({ activity }) => {
  const { created_by_user } = get(activity, 'model_update');
  return (
    <SystemEventActivityItem
      heading={'Pharmacy manually reassigned.'}
      createdAt={activity.created_at}
      footerName={created_by_user || 'Zipdrug Admin'}
      footerStyles={sx.footer}
      headerStyles={sx.header}
      icon="person"
    />
  );
};

EnrollmentActivityItem.propTypes = {
  activity: PropTypes.object,
};

export default EnrollmentActivityItem;
