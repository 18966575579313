import { claims as claimsSelection } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { compose, withHandlers } from 'recompose';
import { graphql } from 'react-apollo';
import { get, uniq } from 'lodash';
import gql from 'graphql-tag';
import fetchOnceOptions from 'hoc/utils/fetchOnceOptions';
import RxItem from './RxItem';

export default compose(
  graphql(claimsSelection.mutateUpdateClaims, { name: 'mutateUpdateClaims' }),
  graphql(
    gql`
      query postalCodeCoverageByPharmacies($ids: [Int]!) {
        postalCodeCoverageByPharmacies(ids: $ids) {
          data {
            id
            hasPostalCodes
          }
        }
      }
    `,
    {
      options: ({ allClaims }) => {
        const pharmacyArray = uniq(allClaims?.map(c => c.pharmacy.id));

        return fetchOnceOptions('postalCodeCoverageByPharmacies', {
          variables: {
            ids: pharmacyArray,
          },
        });
      },
      props: ({ data: { postalCodeCoverageByPharmacies: result } }) => ({
        postalCodesByPharmacies: get(result, 'data'),
      }),
    },
  ),
  withHandlers({
    onToggleActiveStatus: ({ isActive, mutateUpdateClaims, rxClaims }) => () => {
      if (!rxClaims) return;
      const { ndc_code, patient_id } = rxClaims[0];

      alertOnError(
        mutateUpdateClaims({
          variables: {
            modifier: {
              is_active: !isActive,
            },
            ndc_code: Number.parseFloat(ndc_code),
            patient_id,
          },
        }),
      );
    },
  }),
)(RxItem);
