import { alerts } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import withRefreshPatients from './withRefreshPatients';

export default Component =>
  compose(
    graphql(alerts.mutateUpdateAlert, { name: 'mutateUpdateAlert' }),
    withRefreshPatients,
    withHandlers({
      mutateUpdateAlert: ({ mutateUpdateAlert, onRefetchPatients }) => variables =>
        alertOnError(mutateUpdateAlert({ variables }))
          // refresh the alert counts
          .then(onRefetchPatients),
    }),
  )(Component);
