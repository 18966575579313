import React from 'react';
import PropTypes from 'prop-types';
import { Button, AutoComplete, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite';

const sx = StyleSheet.create({
  autoComplete: { width: '188px', marginBottom: '8px', display: 'block' },
  searchContainer: {
    padding: '8px',
  },
  searchButton: { width: '90px' },
});

const SearchBox = ({
  placeholder,
  onSearch,
  onReset,
  onFilterSelection,
  options,
  value,
  defaultValue,
}) => {
  return (
    <div className={css(sx.searchContainer)}>
      <AutoComplete
        className={css(sx.autoComplete)}
        placeholder={placeholder}
        value={value}
        onSearch={value => onSearch(value)}
        onSelect={(value, option) => {
          onFilterSelection(value, option);
        }}
        options={options}
        defaultValue={defaultValue}
      />
      <Space>
        <Button
          type="primary"
          onClick={onFilterSelection}
          icon={<SearchOutlined />}
          size="small"
          className={css(sx.searchButton)}
          disabled={value?.length === 0}
        >
          Search
        </Button>
        <Button onClick={onReset} size="small" className={css(sx.searchButton)}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  onFilterSelection: PropTypes.func,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default SearchBox;
