import {
  animation,
  Button,
  colors,
  Column,
  Div,
  FlexCentered,
  font,
  FormButtonGroupField,
  FormCheckboxField,
  FormExtensionField,
  FormPhoneField,
  FormTextField,
  Icon,
  Label,
  margin,
  padding,
  Row,
  space,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import DropdownHeader from '../DropdownHeader';

const CONTAINER_ANIMATION = {
  from: { transform: `translateX(${space.half})` },
  to: { transform: 'translateX(0)' },
};

const CUSTOM_LABEL_FIELD_ANIMATION = {
  '0%': { transform: 'translateY(2px)' },
  '100%': { transform: 'translateY(0)' },
};

const sx = StyleSheet.create({
  button: {
    flex: 1,
    margin: '0 auto',
    textAlign: 'center',
    ':first-of-type': {
      marginRight: space.half,
    },
  },
  buttonGroupLabel: {
    display: 'none',
  },
  checkboxLabel: {
    lineHeight: font.lineHeight.tight,
    marginLeft: '0.9rem',
  },
  checkboxRow: margin.build(space.default, 0),
  container: {
    ...animation.build({ name: [CONTAINER_ANIMATION, animation.fadeIn] }),
    ...padding.build(font.size.small),
    overflowX: 'hidden',
    width: '28rem',
  },
  customLabelField: {
    ...animation.build({
      delay: [150, 100],
      duration: [200, 150],
      ease: 'ease-in',
      name: [CUSTOM_LABEL_FIELD_ANIMATION, animation.fadeIn],
    }),
    opacity: 0,
    willChange: 'opacity, transform',
  },
  deleteButton: {
    backgroundColor: colors.white,
    border: 'none',
    color: colors.red,
    flex: 1,
    marginTop: space.half,
    ':hover': {
      backgroundColor: colors.gray97,
      color: colors.red,
    },
    ':active': {
      color: colors.darkRed,
    },
  },
  deleteButtonIcon: {
    marginRight: space.quarter,
  },
  extensionField: {
    marginLeft: space.half,
    maxWidth: '9rem',
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
  },
  phoneField: {
    marginBottom: 0,
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const ContactForm = ({
  animateCustomLabel,
  handleSubmit,
  initialValues,
  labelOptions,
  onClickCancel,
  onClickDelete,
  showCustomLabel,
  styles,
  submitting,
}) => (
  <Column
    style={{ paddingBottom: initialValues.number ? space.half : font.size.small }}
    styles={[sx.container, styles]}
  >
    <DropdownHeader
      onClickCancel={onClickCancel}
      title={`${initialValues.number ? 'Edit' : 'Add'} number`}
    />
    <Column>
      <FormButtonGroupField
        {...FORMFIELD_PROPS}
        items={labelOptions}
        label="Label"
        labelStyles={sx.buttonGroupLabel}
        name="label"
        type="primary"
      />
      {showCustomLabel && (
        <FormTextField
          {...FORMFIELD_PROPS}
          label="Custom label"
          name="customLabel"
          placeholder="Other"
          styles={[sx.formField, animateCustomLabel ? sx.customLabelField : null]}
        />
      )}
    </Column>
    <Row align="center" justify="space-between">
      <FormPhoneField
        {...FORMFIELD_PROPS}
        autoFocus
        className="fs-hide"
        id="e2e-contactFormNumberInput"
        label="Number"
        name="number"
        placeholder="(000) 000-0000"
        styles={[sx.formField, sx.phoneField]}
      />
      <FormExtensionField
        {...FORMFIELD_PROPS}
        className="fs-hide"
        id="e2e-contactFormExtensionInput"
        label="Ext"
        name="extension"
        optional
        placeholder="123"
        styles={sx.extensionField}
      />
    </Row>
    <Row align="center" component={Label} styles={sx.checkboxRow}>
      <FormCheckboxField name="can_sms" preserveDimensions={false} />
      <Div styles={sx.checkboxLabel}>Authorized for SMS</Div>
    </Row>
    <Row>
      <Button
        focusable
        id="e2e-contactFormSaveButton"
        loading={submitting}
        onClick={handleSubmit}
        size="large"
        styles={sx.button}
        text="Save"
        type="primary"
      />
      <Button
        focusable
        onClick={onClickCancel}
        outline
        size="large"
        styles={sx.button}
        text="Cancel"
        type="secondary"
      />
    </Row>
    {!!initialValues.number && (
      <Row>
        <Button focusable onClick={onClickDelete} size="large" styles={sx.deleteButton}>
          <FlexCentered style={{ width: '100%' }}>
            <Icon name="trash" styles={sx.deleteButtonIcon} />
            <Div>Delete</Div>
          </FlexCentered>
        </Button>
      </Row>
    )}
  </Column>
);

ContactForm.propTypes = {
  animateCustomLabel: PropTypes.bool,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  labelOptions: PropTypes.arrayOf(PropTypes.string),
  onClickCancel: PropTypes.func,
  onClickDelete: PropTypes.func,
  showCustomLabel: PropTypes.bool,
  styles: aphroditePropType,
  submitting: PropTypes.bool,
};

export default ContactForm;
