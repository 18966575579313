import gql from 'graphql-tag';

export const GET_PHARMACY_NETWORKS = gql`
  query pharmacyNetworks($skip: Int, $limit: Int) {
    pharmacyNetworks(skip: $skip, limit: $limit) {
      data {
        id
        name
        lob
        lob_order
        order_tie_breaker_rule
        pharmacy_network_type_order
        payer {
          name
          id
        }
      }
      total
    }
  }
`;

export const GET_PHARM_NETWORK_REPORT_FILE = gql`
  query getPharmacyNetworksReportFile {
    getPharmacyNetworksReportFile {
      skip
      data {
        file_url
      }
    }
  }
`;
