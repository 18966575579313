import { ActivityItem, AudioPlayer, colors } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import CallOutcomeActivityItem from '../activity-item/CallOutcomeActivityItem';

const sx = StyleSheet.create({
  header: {
    color: colors.green,
  },
});

const CallActivityItem = ({ activity = {}, isPlaying, onPlayAudio, ...props }) => {
  const { call } = activity;
  const { direction, to_user, from_user } = call;

  return (
    <>
      <ActivityItem
        {...props}
        createdAt={activity.created_at}
        externalUser={direction === 'outbound' || direction === null ? from_user : to_user}
        headerStyles={sx.header}
        icon="phone"
        title="Call"
      >
        <AudioPlayer
          audioFile={get(activity, 'call.file_url')}
          duration={get(activity, 'call.recording_duration', 0)}
          isPlaying={isPlaying}
          onPlayAudio={onPlayAudio}
        />
      </ActivityItem>
      {activity.callOutcome && (
        <CallOutcomeActivityItem
          activity={activity}
          className="e2e-activityListItem"
          key={activity.id}
        />
      )}
    </>
  );
};

CallActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
  isPlaying: PropTypes.bool,
  onPlayAudio: PropTypes.func,
};

export default CallActivityItem;
