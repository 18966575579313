import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { ExclamationPoint } from './svg';

const sx = StyleSheet.create({
  onCallElsewhereContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    backgroundColor: '#F6F6F6',
    flex: 1,
  },
  text: {
    marginLeft: '10px',
  },
  message: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#696969',
    border: '1px solid #696969',
    padding: '13px',
    borderRadius: '6px',
    height: '40px',
  },
  hangupBtn: {
    backgroundColor: '#E42E2E',
    margin: '0px 15px',
    height: '40px',
    width: '40px',
    border: '1px',
    borderRadius: '6px',
    color: 'white',
    cursor: 'pointer',
  },
});

const OnCallElsewhere = () => {
  return (
    <div className={css(sx.onCallElsewhereContainer)}>
      <div className={css(sx.message)}>
        <ExclamationPoint />
        <div className={css(sx.text)}>You are currently on call with another patient.</div>
      </div>
    </div>
  );
};

export default OnCallElsewhere;
