import { A, Icon, Nbsp, Row, Span } from '@zipdrug/ui';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import SystemEventActivityItem from '../SystemEventActivityItem';

export const DeliveryActivityLink = ({ short_id }) => (
  <Row
    align="center"
    component={A}
    href="https://onfleet.com/dashboard"
    inline
    target="_blank"
    rel="noopener"
    title="Open Onfleet dashboard"
  >
    <Span>{`#${short_id}`}</Span>
    <Nbsp />
    <Icon name="externalLink" />
  </Row>
);

DeliveryActivityLink.propTypes = {
  short_id: PropTypes.string,
};

const DeliveryActivityItem = ({
  color,
  content,
  contentHeading,
  createdAt,
  footerName,
  heading,
  id,
  short_id,
}) => (
  <SystemEventActivityItem
    color={color}
    content={content}
    contentHeading={contentHeading}
    createdAt={createdAt}
    footerName={footerName}
    heading={
      <Fragment>
        <Span>Delivery</Span>
        <Span>
          <Nbsp />
          <DeliveryActivityLink short_id={short_id} />
          <Nbsp />
        </Span>
        {heading}
      </Fragment>
    }
    icon="shoppingCart"
    id={id}
    showLeftAvatar
  />
);

DeliveryActivityItem.propTypes = {
  color: PropTypes.string,
  content: PropTypes.node,
  contentHeading: PropTypes.node,
  createdAt: PropTypes.number,
  footerName: PropTypes.object,
  heading: PropTypes.node,
  id: PropTypes.string,
  short_id: PropTypes.string,
};

export default DeliveryActivityItem;
