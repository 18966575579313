import { get, upperFirst } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Bold, colors, Span } from '@zipdrug/ui';
import DeliveryActivityItem from './DeliveryActivityItem';

const FailedDeliveryActivityItem = ({
  activity: {
    created_at,
    delivery: { completed_at, destination_address, failure_reason, short_id },
  },
  ...props
}) => {
  const addressLabel = get(destination_address, 'label', 'destination');
  const dateTime = moment(completed_at).format('MMMM D, YYYY [at] h:mma');
  const statusText = <Bold style={{ color: colors.red }}>was attempted but failed</Bold>;

  return (
    <DeliveryActivityItem
      {...props}
      color={colors.red}
      content={failure_reason ? upperFirst(failure_reason.toLowerCase()) : null}
      contentHeading={failure_reason ? 'Failure reason' : null}
      createdAt={created_at}
      footerName={{ first_name: 'Pharmacy', last_name: 'Courier' }}
      heading={
        <Span>
          to {addressLabel} {statusText} on {dateTime}.
        </Span>
      }
      short_id={short_id}
    />
  );
};

FailedDeliveryActivityItem.propTypes = {
  activity: PropTypes.object,
};

export default FailedDeliveryActivityItem;
