import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router';
import { reduxForm } from 'redux-form';
import { users } from '@zipdrug/react-api-sdk';
import { alertOnError, emailField, validateForm } from '@zipdrug/ui';
import ForgotPasswordForm from './ForgotPasswordForm';

const ERROR_MESSAGES = {
  invalidEmail: 'is invalid',
  required: 'is required.',
};

const FORGOT_PASSWORD_SCHEMA = {
  email: {
    ...emailField,
    required: true,
  },
};

const validate = values => validateForm(values, FORGOT_PASSWORD_SCHEMA, ERROR_MESSAGES);

const ReduxForgotPasswordForm = reduxForm({
  form: 'forgotPassword',
  validate,
})(ForgotPasswordForm);

class ForgotPasswordFormContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
    requestPasswordReset: PropTypes.func.isRequired,
  };

  handleBackButtonClick = () => this.props.history.push('/signin');

  handleSubmit = async ({ email }) =>
    alertOnError(
      this.props.requestPasswordReset({
        variables: { email },
      }),
    );

  render = () => (
    <ReduxForgotPasswordForm
      intialValues={{ email: null }}
      onBackButtonClick={this.handleBackButtonClick}
      onSubmit={this.handleSubmit}
    />
  );
}

export default graphql(users.mutateRequestPasswordReset, { name: 'requestPasswordReset' })(
  withRouter(ForgotPasswordFormContainer),
);
