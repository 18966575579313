import { colors, Div, font, padding, position, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import PageHeading from '../../ui/PageHeading';
import PageSectionTitle from '../../ui/PageSectionTitle';
import SettingsPageTemplate from '../SettingsPageTemplate';
import CopyToClipboardButton from './CopyToClipboardButton';
import { version } from '../../../package.json';

const sx = StyleSheet.create({
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  boldDescription: {
    fontWeight: 'bold',
  },
  page: {
    ...position.size('100%'),
    maxWidth: 'initial',
    paddingBottom: 0,
  },
  profileFormContainer: padding.build(space.get(1.5), space.get(1.5), space.three),
});

const HelpPage = () => (
  <SettingsPageTemplate styles={sx.page}>
    <PageHeading title="Help" />
    <PageSectionTitle>Version</PageSectionTitle>
    <Div styles={sx.profileFormContainer}>
      <Div styles={sx.description}>{version}</Div>
    </Div>
    <PageSectionTitle>Get in Touch</PageSectionTitle>
    <Div styles={sx.profileFormContainer}>
      <Div styles={[sx.description, sx.boldDescription]}>
        To report a technical issue, please email us at support@zipdrug.com
      </Div>
      <br />
      <Div styles={sx.description}>Please describe your issue in detail.</Div>
      <CopyToClipboardButton email={'support@zipdrug.com'} />
    </Div>
  </SettingsPageTemplate>
);

export default HelpPage;
