import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';

const SET_CURRENTLY_PLAYING_CALL_ID = 'care-site/audio/SET_CURRENTLY_PLAYING_CALL_ID';

const initialState = {
  id: null,
};

export default handleActions(
  {
    [SET_CURRENTLY_PLAYING_CALL_ID]: (state, { payload: { id } }) =>
      update(state, {
        id: { $set: id },
      }),
  },
  initialState,
);

// eslint-disable-next-line max-len
export const setCurrentlyPlayingCallId = createAction(SET_CURRENTLY_PLAYING_CALL_ID, ({ id }) => ({
  id,
}));
