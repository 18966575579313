import { withSession } from '@zipdrug/react-api-sdk';
import { queryPharmacyServices } from 'graphql/pharmacyServices';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps } from 'recompose';
import fetchOnceOptions from './utils/fetchOnceOptions';

const withPharmacyServices = Component =>
  graphql(queryPharmacyServices, {
    skip: ({ pharmacyIdsForServices }) =>
      !pharmacyIdsForServices || pharmacyIdsForServices?.length === 0,
    options: ({ pharmacyIdsForServices }) =>
      fetchOnceOptions('pharmacyServices', {
        variables: {
          ids: pharmacyIdsForServices,
        },
      }),
    props: ({ data: { pharmacyServices: result } }) => ({
      pharmacyServices: get(result, 'data'),
    }),
  })(Component);

export default Component =>
  compose(
    withSession,
    withPharmacyServices,
    mapProps(({ pharmacyServices, ...props }) => ({
      pharmacyServices,
      ...props,
    })),
  )(Component);
