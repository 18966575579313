import { useState, useEffect } from 'react';
import { DISABLED_FEATURES } from '../constants/featureFlags';

const useFeatureFlags = feature => {
  const [featureFlag, setFeatureFlag] = useState(false); // Default value
  const [featureFlagsFromProvider, setFeatureFlagsFromProvider] = useState(null);

  const fetchFeatureFlags = async () => {
    try {
      const response = DISABLED_FEATURES;
      const data = response;
      return data;
    } catch (error) {
      throw new Error('Failed to fetch feature flags');
    }
  };

  useEffect(() => {
    if (!featureFlagsFromProvider) {
      // Fetch feature flags from API
      fetchFeatureFlags()
        .then(data => {
          setFeatureFlagsFromProvider(data);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Error fetching feature flags:', error);
        });
    }
  }, []);

  useEffect(() => {
    if (featureFlagsFromProvider && feature) {
      setFeatureFlag(featureFlagsFromProvider?.includes(feature));
    }
  }, [feature, featureFlagsFromProvider]);
  return featureFlag;
};

export default useFeatureFlags;
