import gql from 'graphql-tag';

const GET_FINAL_COHORT_COUNT = gql`
  query getFinalCohortCount($cohort_parents_id: Int!) {
    getFinalCohortCount(cohort_parents_id: $cohort_parents_id) {
      total
    }
  }
`;

export default GET_FINAL_COHORT_COUNT;
