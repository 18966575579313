import gql from 'graphql-tag';

const GET_PHARMACY = gql`
  query getUniquePharmacyLists($query: JSON) {
    getUniquePharmacyLists(query: $query) {
      data {
        potential_pharmacies {
          id
          name
        }
      }
    }
  }
`;

export default GET_PHARMACY;
