import gql from 'graphql-tag';

const SEND_SITE_BYPASS = gql`
  mutation sendSiteBypass($passcode: String!) {
    sendSiteBypass(passcode: $passcode) {
      data
      errors
    }
  }
`;

export default SEND_SITE_BYPASS;
