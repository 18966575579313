import {
  colors,
  Column,
  FlexCentered,
  font,
  ms,
  padding,
  position,
  space,
  Spinner,
  transition,
} from '@zipdrug/ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import Waypoint from 'react-waypoint';
import ActivityListItem from './ActivityListItem';

const sx = StyleSheet.create({
  container: {
    ...padding.build(0, space.default),
    flex: 1,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    height: '1000px',
  },
  emptyState: {
    ...position.cover,
    color: colors.gray45,
    fontSize: font.size.medium,
  },
  listContainer: transition.build({ property: 'opacity' }),
  spinner: {
    color: colors.gray45,
  },
});

const ActivityListEmptyState = () => (
  <FlexCentered styles={sx.emptyState}>No member activity yet</FlexCentered>
);

const buildListContainerInlineStyle = shouldAnimate => ({
  opacity: shouldAnimate ? 1 : 0,
  transitionDuration: shouldAnimate ? ms(150) : ms(0),
});

const ActivityList = ({
  activities,
  currentPharmacy,
  handleListRef,
  isLoading,
  loadMore,
  shouldAnimateList,
}) => {
  return (
    <div className={css([sx.container])} ref={handleListRef}>
      {isLoading && <Spinner delay={200} loading styles={sx.spinner} />}

      {activities?.length === 0 && !isLoading && <ActivityListEmptyState />}

      {!isLoading && <Waypoint onEnter={loadMore} />}

      {activities?.length > 0 && !isLoading && (
        <Column style={buildListContainerInlineStyle(shouldAnimateList)} styles={sx.listContainer}>
          {activities.map(activity => (
            <ActivityListItem
              activity={activity}
              currentPharmacy={currentPharmacy}
              key={activity instanceof Date ? activity.getTime() : activity.id}
            />
          ))}
        </Column>
      )}
    </div>
  );
};

ActivityList.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  currentPharmacy: PropTypes.object,
  handleListRef: PropTypes.func,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  shouldAnimateList: PropTypes.bool,
};

export default ActivityList;
