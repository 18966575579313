/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Tabs } from 'antd';
import { colors, Div, padding, space } from '@zipdrug/ui';
import { withRouter } from 'react-router';
import withRoles from 'hoc/withRoles';
import VoicemailPage from 'settings/voicemail/VoicemailPage';
import SupervisorPage from 'settings/supervisor/SupervisorPage';
import MembersPage from 'dashboard/components/MemberList/MembersPage';
import PharmacyList from 'dashboard/components/PharmacyList/PharmacyList';
import NotesList from 'dashboard/components/NotesList/NotesList';
import CohortList from 'dashboard/components/CohortList/CohortList';
import LeadListTable from 'dashboard/components/LeadList/components/LeadListTable/LeadListTable';
import TeamPage from 'settings/team/TeamPage';
import LeadList from 'dashboard/components/LeadList/LeadList';

const sx = StyleSheet.create({
  inputBox: { marginRight: '16px' },
  tabs: {
    color: colors.bluePurple,
  },
  largeText: {
    fontSize: '16px',
  },
  ModifyPageContainer: padding.build(space.get(0), space.get(1.5), space.three),
});

const { TabPane } = Tabs;

const Homepage = ({ hasRole, isBdTeam, isAscTeam, isPharmacistTeam }) => {
  // const [leadTabActive, setLeadTabActive] = useState('1');
  const [activeTab, setActiveTab] = useState('1');

  const defaultActiveTab = () => {
    if (hasRole.systemAdmin || hasRole.pbsLead || hasRole.pbsAdmin) {
      setActiveTab('1');
      return '1';
    }
    setActiveTab('4');
    return '4';
  };

  useEffect(() => {
    defaultActiveTab();
  }, []);

  return (
    <Div className={css(sx.ModifyPageContainer)}>
      <Tabs
        defaultActiveKey={defaultActiveTab}
        size="large"
        className={css(sx.tabs)}
        activeKey={activeTab}
        onTabClick={key => setActiveTab(key)}
      >
        {(hasRole.pbsAdmin || hasRole.pbsLead) && (
          <>
            <TabPane tab="Lead List" key={1}>
              <LeadListTable />
            </TabPane>
            <TabPane tab="Voicemails & Missed Call" key="2">
              <VoicemailPage />
            </TabPane>
            <TabPane tab="Supervise Calls" key="3">
              <SupervisorPage />
            </TabPane>
          </>
        )}
        {(isBdTeam || isAscTeam) && (
          <>
            <TabPane tab="Pharmacies" key="4">
              <PharmacyList />
            </TabPane>
            <TabPane tab="Notes" key="5">
              <NotesList />
            </TabPane>
          </>
        )}
      </Tabs>
      {isPharmacistTeam && <MembersPage />}
      {hasRole.projectManager && <CohortList />}
      {hasRole.systemAdmin && <TeamPage />}
      {hasRole.pbsUser && <LeadList />}
    </Div>
  );
};

Homepage.propTypes = {
  isBdTeam: PropTypes.bool,
  isAscTeam: PropTypes.bool,
  isPharmacistTeam: PropTypes.bool,
};

export default withRouter(withRoles(Homepage));
