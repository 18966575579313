import { isEmpty, xorWith, isEqual, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ActivityList from './ActivityList';

function getDatePart(date) {
  const datePart = new Date(date);
  datePart.setHours(0, 0, 0, 0);
  return datePart;
}
export default class ActivityListContainer extends Component {
  static propTypes = {
    activities: PropTypes.arrayOf(PropTypes.object),
    currentPharmacy: PropTypes.object,
    loadMoreRows: PropTypes.func,
    patientId: PropTypes.number,
    isLoading: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    subscribeToNewActivities: PropTypes.func,
    totalActivities: PropTypes.number,
    scrollToBottom: PropTypes.bool,
    setScrollToBottom: PropTypes.func,
  };

  state = { shouldAnimateList: false, activities: [], activitiesWithHeaders: [] };

  componentWillReceiveProps({ patientId, subscribeToNewActivities, activities, scrollToBottom }) {
    const isNewPatientIdValue = patientId !== this.props.patientId;

    if (!this.unsubscribe || (isNewPatientIdValue && Number.isInteger(patientId))) {
      if (this.unsubscribe) this.unsubscribe();

      if (subscribeToNewActivities) {
        this.unsubscribe = subscribeToNewActivities({ patient_id: patientId });
      }
    }

    // jump to bottom when switching to a new patient or when creating a new note.
    // NOTE: this will also run if when getting activities we only get 1 more activity
    // TODO: remove this function and lift scrolling logic up
    if (isNewPatientIdValue || activities.length === this.props.activities.length + 1) {
      // Whenever the patient changes jump to the bottom
      this.jumpToBottom = true;
      this.previousScrollDistanceToBottom = null;
      this.setState({ shouldAnimateList: false });
    }

    if (scrollToBottom) {
      this.scrollTo(0);
      this.props.setScrollToBottom(false);
    }
  }

  getActivities = () => {
    let activitiesWithHeaders = [];
    if (!this.isArrayEqual(this.props.activities, this.state.activities)) {
      let { activities } = this.props;

      this.setState({ activities });

      if (activities.length < 1) {
        this.setState({ activitiesWithHeaders: [] });
        this.resetScroll();
        return activities;
      }

      // Flip the activities so the first result is at the bottom
      activities = activities.slice(0).reverse();

      // Filter for unique id
      activities = uniqBy(activities, 'id');

      // Insert a date header for every new date
      let lastDateHeader = getDatePart(activities[0].created_at);
      activitiesWithHeaders = [lastDateHeader];

      activities.forEach(activity => {
        const activityDatePart = getDatePart(activity.created_at);

        if (activityDatePart > lastDateHeader) {
          activitiesWithHeaders.push(activityDatePart);
          lastDateHeader = activityDatePart;
        }

        activitiesWithHeaders.push(activity);
      });

      this.setState({ activitiesWithHeaders });
      this.resetScroll();
    }
  };

  jumpToBottom = true;
  listRef = null;

  isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual));

  handleRef = ref => {
    this.listRef = ref;
  };

  loadMore = () => {
    const { activities, loadMoreRows, totalActivities } = this.props;

    if (!loadMoreRows || activities.length >= totalActivities) {
      return;
    }

    const distanceToBottom = this.listRef.scrollHeight - this.listRef.scrollTop;
    loadMoreRows().then(() => {
      this.previousScrollDistanceToBottom = distanceToBottom;
    });
  };

  resetScroll = () => {
    if (this.jumpToBottom) {
      this.scrollTo(0);
      this.jumpToBottom = false;
      return;
    }

    if (this.previousScrollDistanceToBottom) {
      // After data loads reset to the previous scroll position
      this.scrollTo(this.previousScrollDistanceToBottom);
      this.previousScrollDistanceToBottom = null;
    }
  };

  scrollTo = distanceToBottom =>
    setTimeout(() => {
      if (this.listRef) {
        const scrollTo = this.listRef.scrollHeight - distanceToBottom;
        this.listRef.scrollTop = scrollTo;
        this.setState({ shouldAnimateList: true });
      }
    }, 0);

  render() {
    const { currentPharmacy, isLoading, patientId } = this.props;

    this.getActivities();

    return (
      <ActivityList
        currentPharmacy={currentPharmacy}
        isLoading={isLoading}
        patientId={patientId}
        activities={this.state.activitiesWithHeaders}
        handleListRef={this.handleRef}
        loadMore={this.loadMore}
        shouldAnimateList={this.state.shouldAnimateList}
      />
    );
  }
}
