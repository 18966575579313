const statuses = [
  {
    text: 'Inactive',
    value: 'inactive',
  },
  {
    text: 'Active',
    value: 'active',
  },
];

export default statuses;
