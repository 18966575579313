import { colors } from '@zipdrug/ui';
import React, { useState, useEffect, useContext } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import * as yup from 'yup';
import { isEmpty, omit, find } from 'lodash';
import { withRouter } from 'react-router';
import { Space, Select, Typography, message, Tag, Button, Card, Checkbox, Alert } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import withRoles from 'hoc/withRoles';
import { isSystemAdmin } from 'contexts/utils/models';
import cloneDeep from 'lodash/cloneDeep';
import OmitDeep from '../../utils/OmitDeep';
import PharmacyInput from './PharmacyInput/PharmacyInput';
import AddressAutoComplete from './AddressAutoComplete/AddressAutoComplete';
import states from '../../constants/states';
import statusTypes from '../../constants/status';
import ADD_PHARMACY from '../../graphql/addPharmacy';
import UPDATE_PHARMACY_INFO from '../../graphql/updatePharmacyInfo';
import languageTypes from '../../constants/languages';
import GET_PHARM_NETWORK_TYPES from '../../graphql/getPharmacyNetworkTypes';
import GET_SERVICE_TYPES from '../../graphql/getServiceTypes';
import ShowMore from './ShowMore';
import RedirectModal from './RedirectModal';
import EditASCModal from './EditASCModal';
import formatPhoneNumber from '../../utils/FormatPhone.utils';
import GeneralInformationReadOnly from './GeneralInformationReadOnly';
import ADD_CHAIN_PHARMACY from '../../graphql/addChainPharmacy';
import UPDATE_CHILDREN_ASC from '../../graphql/UpdateChildrenAsc';
import SavePharmacyModal from './SavePharmacyModal';
import GET_PHARMACY_ASCS from '../../graphql/getPharmacyAscs';

const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '100%',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  statusSelect: {
    minWidth: '215px',
  },
  ascSelect: {
    width: '300px',
    marginLeft: '16px',
  },
  selected: {
    fontSize: '12px',
    marginTop: '5px',
  },
  typeOne: {
    border: '1px solid #D9D9D9',
    color: '#231E33',
  },
  serviceTag: {
    margin: '5px',
    color: '#F5F5F5',
  },
  linkText: {
    size: '16px',
    color: colors.bluePurple,
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
    marginRight: '10px',
    marginTop: '10px',
  },
  addServiceButton: {
    marginTop: '22px',
  },
  openModal: {
    width: '61px',
    height: '19px',
    padding: '0px',
  },
  marginBottom10: {
    marginBottom: '10px',
    gap: '16px',
  },
  marginTop10: {
    marginTop: '10px',
    gap: '16px',
  },
  stateSelect: {
    minWidth: '185px',
  },
  split: {
    display: 'flex',
    gap: '10px',
  },
  serviceSelect: {
    width: '530px',
    marginRight: '8px',
  },
  optionGroup: {
    ':nth-child(1n) .ant-select-item-group': {
      color: '#212121',
      fontSize: '12px',
      fontWeight: 'bold',
    },
  },
  serviceType: {
    margin: '0',
    fontSize: '12px',
    color: '#212121',
  },
  labelBold: {
    fontWeight: 'bold',
  },
  labelTransform: {
    textTransform: 'uppercase',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
  section: {
    marginTop: '10px',
  },
  coverage: {
    width: '100%',
    minWidth: 400,
  },
  hubTag: {
    display: 'inline-block',
    marginRight: '10px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
});

const { Text, Title } = Typography;
const { Option, OptGroup } = Select;

const phoneRegex = RegExp(/^([+][1]){0,1}\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);
const npiRegex = RegExp(/^[0-9]{1,10}$/);
const zipRegex = RegExp(/^\d{5}(?:[- ]?\d{4})?$/);
const skipRegex = RegExp(/^[0-9]{1,10}$/);

const GeneralPharmacyInfoTab = ({
  isEdit,
  readOnly,
  pharmacyInfo,
  history,
  setIsHubPharmacy,
  isHubPharmacy,
  refetchPharmacyInfo,
  setPharmacyInfo,
  setIsEdit,
  zipcodeCoverage,
  potentialZipcodeCoverage,
  parentNpi,
  isBdTeam,
  isAscTeam,
  isChild,
}) => {
  const { setPharmacyStatus } = useContext(CallCenterContext);
  const pharmacySchema = isEdit
    ? yup.object().shape(
        {
          name: yup.string().required('Name is a required field'),
          phone: yup.object().shape({
            number: yup
              .string()
              .matches(phoneRegex, 'Please enter a valid Phone Number')
              .required('Phone Number is a required field.'),
          }),
          address: yup.object().shape({
            line1: yup.string().required('Street Address is a required field'),
            city: yup.string().required('City is a required field'),
            state: yup.string().required('State is a required field'),
            postal_code: yup
              .string()
              .matches(zipRegex, 'Please enter a valid zipcode')
              .required('Zipcode is a required field'),
          }),
          pharmacy_networks: yup.array().of(yup.number()),
          language: yup.array().of(yup.string()),
          skip_number: yup.string().when('skip_number', val => {
            if (val?.length > 0) {
              return yup
                .string()
                .matches(skipRegex, 'Please enter a valid Skip Number')
                .length(1, 'Skip number should be of single digit.');
            }
            return yup
              .string()
              .notRequired()
              .nullable();
          }),
        },
        [['skip_number', 'skip_number']],
      )
    : yup.object().shape({
        name: yup.string().required('Name is a required field'),
        phoneNumber: yup
          .string()
          .matches(phoneRegex, 'Please enter a valid Phone Number')
          .required('Phone Number is a required field.'),
        npi: yup
          .string()
          .length(10, 'NPI should be of 10 digits')
          .matches(npiRegex, 'Please enter a valid NPI number')
          .required('Npi is a required field.'),
        address: yup.object().shape({
          line1: yup.string().required('Street Address is a required field'),
          city: yup.string().required('City is a required field'),
          state: yup.string().required('State is a required field'),
          postal_code: yup
            .string()
            .matches(zipRegex, 'Please enter a valid zipcode')
            .required('Zipcode is a required field'),
        }),
        pharmacy_networks: yup.array().of(yup.number()),
        language: yup.array().of(yup.string()),
        skip_number: yup
          .string()
          .length(1, 'Skip number should be of single digit.')
          .matches(skipRegex, 'Please enter a valid Skip Number'),
      });
  const [editedPharmacyInfo, setEditedPharmacyInfo] = useState(cloneDeep(pharmacyInfo));
  const [ascCoordinators, setAscCoordinators] = useState([]);
  const [errors, setErrors] = useState({});
  const [pharmNetworkTypes, setPharmNetworkTypes] = useState([]);
  const [services, setServices] = useState([]);

  const [selectedZipCodes, setSelectedZipCodes] = useState('');
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);

  const [potentialCoverage, setPotentialCoverage] = useState([]);
  const [zipCodeCoverage, setzipCodeCoverage] = useState([]);

  const [isPcGreaterThanFive, setIsPcGreaterThanFive] = useState(false);
  const [isZcGreaterThanFive, setIsZcGreaterThanFive] = useState(false);

  const [zipCodesLoaded, setZipCodesLoaded] = useState(false);
  const [networksLoaded, setNetworksLoaded] = useState(false);

  const [potentialZipCodeCount, setPotentialZipCodeCount] = useState(null);
  const [zipcodeCount, setZipcodeCount] = useState(null);

  const [currentServices, setCurrentServices] = useState([]);
  const [currentServicesNames, setCurrentServicesNames] = useState([]);

  const [groupedServices, setGroupedServices] = useState([]);
  const [unGroupedServices, setUnGroupedServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [isOther, setIsOther] = useState(false);
  const [other, setOther] = useState('');
  const [otherServices, setOtherServices] = useState([]);

  const [EnteredServicesIds, setEnteredServicesIds] = useState([]);

  const serviceSchema = yup.mixed().oneOf([...currentServicesNames]);

  const [viewData, setViewData] = useState({});

  const [isParentPharmacy, setIsParentPharmacy] = useState(false);

  const [overrideModalVisible, setOverrideModalVisible] = useState(false);

  const [childrenNpiArr, setchildrenNpiArr] = useState([]);

  const [parentAscId, setParentAscId] = useState(null);

  const [showSavePharmacyModal, setShowSavePharmacyModal] = useState(false);

  const [statusChildNpiArr, setStatusChildNpiArr] = useState([]);

  // query to add a Pharamcy.
  const [addPharmacy, { loading: isAddingPharmacy }] = useMutation(ADD_PHARMACY, {
    onError: error => {
      if (error?.message.includes('is not a valid US phone number')) {
        setErrors({ 'phone.number': 'PLEASE ENTER A VALID PHONE NUMBER' });
      } else if (error?.message.includes('This pharmacy already exists in the db..')) {
        setErrors({ npi: 'NPI SHOULD BE UNIQUE' });
      } else {
        message.error(`Error Adding Pharmacy Info: ${error.message}`);
      }
    },
    onCompleted: ({ createPharmacy }) => {
      message.success('Successfully added Pharmacy');
      if (createPharmacy?.data) {
        refetchPharmacyInfo({
          variables: {
            query: {
              id: createPharmacy?.data?.id,
            },
          },
        });
        setPharmacyInfo(createPharmacy.data);
        setIsEdit(true);
        if (parentNpi) {
          const floatChainNpi = parseInt(createPharmacy?.data.npi, 10);
          const floatParentNpi = parseInt(parentNpi, 10);
          addChainPharmacy({
            variables: {
              npi: floatChainNpi,
              parentNpi: floatParentNpi,
            },
          });
        }
      }
    },
  });

  // query to update Pharamcy.
  const [updatePharmacy, { loading: isUpdatingPharmacyInfo }] = useMutation(UPDATE_PHARMACY_INFO, {
    onError: error => {
      if (error?.message.includes('is not a valid US phone number')) {
        setErrors({ 'phone.number': 'PLEASE ENTER A VALID PHONE NUMBER' });
      } else {
        message.error(`Error Updating Pharmacy Info: ${error.message}`);
      }
    },
    onCompleted: ({ updatePharmacyInfo }) => {
      if (
        editedPharmacyInfo === pharmacyInfo &&
        isHubPharmacy &&
        pharmacyInfo?.child_pharmacies?.length === 0
      ) {
        message.error('No changes have been made. Please add a Child.');
      } else {
        message.success('Successfully Updated Pharmacy Info');
      }
      refetchPharmacyInfo();

      if (updatePharmacyInfo?.data) {
        setPharmacyStatus(updatePharmacyInfo?.data?.status);
        setPharmacyInfo(updatePharmacyInfo?.data);
      }
    },
  });

  // bulk update the children of a hub pharmacy for the asc assignment only.

  const [bulkUpdateAsc, { loading: isBulkUpdatingChildren }] = useMutation(UPDATE_CHILDREN_ASC, {
    onError: error => {
      message.error(`Error Updating Pharmacy Info: ${error.message}`);
    },
  });

  // query to connect child pharmacy to parent pharmacy.

  const [addChainPharmacy, { loading: isAddingChildPharmacy }] = useMutation(ADD_CHAIN_PHARMACY, {
    onError: error => {
      message.error(`Error Updating Chain Link Info: ${error.message}
      `);
    },
  });

  // query to get pharmacy asc admins.
  const [getAscCoordinators] = useLazyQuery(GET_PHARMACY_ASCS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ getPharmacyAscs }) => {
      setAscCoordinators(getPharmacyAscs?.data);
    },
    onError: error => {
      message.error(`Error fetching Pharmacy ASC Admins list: ${error.message}`);
    },
  });

  // query to get network types.
  const [getPharmacyNetworkTypes] = useLazyQuery(GET_PHARM_NETWORK_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error fetching pharmacy network types: ${error.message}`);
    },
    onCompleted: ({ pharmacyNetworksGroupedByLob }) => {
      setPharmNetworkTypes(pharmacyNetworksGroupedByLob?.data);
      setNetworksLoaded(true);
    },
  });

  const formatServices = data => {
    const servicesData = data.reduce((acc, val) => {
      if (val.pharmacy_service_type === val.pharmacy_service_sub_type) {
        acc.push({
          id: val.id,
          name: val.pharmacy_service_type,
        });
        return acc;
      }
      const itemIndex = acc.findIndex(service => service.name === val.pharmacy_service_type);
      if (itemIndex > -1 && acc[itemIndex].subTypes) {
        const current = acc[itemIndex];
        current.subTypes = [
          ...current.subTypes,
          { id: val.id, name: val.pharmacy_service_sub_type },
        ];
        acc[itemIndex] = current;
        return acc;
      }
      acc.push({
        name: val.pharmacy_service_type,
        subTypes: [{ id: val.id, name: val.pharmacy_service_sub_type }],
      });
      return acc;
    }, []);
    setServices(servicesData);
  };

  // query to get service types.
  const [getServiceTypes] = useLazyQuery(GET_SERVICE_TYPES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error fetching service types: ${error.message}`);
    },
    onCompleted: ({ pharmacyServiceType }) => {
      const allServices = pharmacyServiceType?.data;
      setCurrentServices(allServices);
      formatServices(pharmacyServiceType?.data);
    },
  });

  useEffect(() => {
    if (editedPharmacyInfo?.pharmacy_services && services.length) {
      updateSelectedServices(
        editedPharmacyInfo.pharmacy_services.map(service => {
          return { type: 'selected', value: service.pharmacy_service_type_id };
        }),
      );
    }
  }, [services]);

  useEffect(() => {
    if (currentServices.length > 0) {
      const names = currentServices.map(s => s.pharmacy_service_sub_type.toLowerCase());
      setCurrentServicesNames(names);
      formatServices(currentServices);
    }
  }, [currentServices]);

  const [getZipcodeCount] = useLazyQuery(
    gql`
      query postalCodesCount($pharmacy_id: Int!) {
        postalCodesCount(pharmacy_id: $pharmacy_id)
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error fetching zipcode count: ${error.message}`);
      },
      onCompleted: ({ postalCodesCount }) => {
        setZipcodeCount(postalCodesCount);
      },
    },
  );

  const [getPotentialZipcodeCount] = useLazyQuery(
    gql`
      query potentialPostalCodesCount($pharmacy_id: Int!) {
        potentialPostalCodesCount(pharmacy_id: $pharmacy_id)
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error fetching potential zipcode count: ${error.message}`);
      },
      onCompleted: ({ potentialPostalCodesCount }) => {
        setPotentialZipCodeCount(potentialPostalCodesCount);
      },
    },
  );

  // function to set limited zipcodes for showing on general info page
  const inferFromCodes = (arr, type) => {
    const newArr = [...arr.slice(0, 5)];
    if (type === 'potential coverage') {
      setPotentialCoverage(newArr);
      setIsPcGreaterThanFive(true);
    }
    if (type === 'zipcode coverage') {
      setzipCodeCoverage(() => newArr);
      setIsZcGreaterThanFive(true);
    }
    setZipCodesLoaded(true);
  };

  const displayCheckbox = data => {
    if (pharmacyInfo?.name === '' && !isChild) {
      return true;
    }
    return !isChild && isEdit && data?.child_pharmacies?.length === 0;
  };

  const submitButtonText = () => {
    if (isEdit) {
      return 'Save';
    }
    if (!isEdit && isChild) {
      return 'Add Child Pharmacy';
    }
    return 'Add Pharmacy';
  };

  useEffect(() => {
    if (!isEmpty(pharmacyInfo)) {
      if (pharmacyInfo?.child_pharmacies?.length > 0) {
        setIsParentPharmacy(true);
      }
      getAscCoordinators();
      getPharmacyNetworkTypes();
      getServiceTypes();
      if (!isHubPharmacy) {
        setHubPharmacyOnLoad(pharmacyInfo);
      }
      setEditedPharmacyInfo(pharmacyInfo);
      if (isEdit) {
        getZipcodeCount({ variables: { pharmacy_id: pharmacyInfo.id } });
        getPotentialZipcodeCount({ variables: { pharmacy_id: pharmacyInfo.id } });
      }

      if (pharmacyInfo.child_pharmacies) {
        const childrenNpis = pharmacyInfo?.child_pharmacies?.map(child => child.npi);
        setchildrenNpiArr(childrenNpis);
      }
    }
  }, [pharmacyInfo]);

  useEffect(() => {
    if (potentialZipcodeCoverage?.length > 5) {
      inferFromCodes(potentialZipcodeCoverage, 'potential coverage');
    } else {
      setPotentialCoverage(potentialZipcodeCoverage);
      setZipCodesLoaded(true);
    }
  }, [potentialZipcodeCoverage]);

  // check if zipcodes coverage are more than 5
  useEffect(() => {
    if (zipcodeCoverage?.length > 5) {
      inferFromCodes(zipcodeCoverage, 'zipcode coverage');
    } else {
      setzipCodeCoverage(zipcodeCoverage);
      setZipCodesLoaded(true);
    }
  }, [zipcodeCoverage]);

  const openShowMoreModal = selectedType => {
    setSelectedZipCodes(selectedType);
    setShowMoreModal(true);
  };
  const setHubPharmacyOnLoad = pharmacyInfo => {
    setIsHubPharmacy(pharmacyInfo?.child_pharmacies?.length > 0);
  };

  const showPotentialAndAssignedZipcodes = () => {
    return (
      <Space className={css(sx.marginBottom10)} align="baseline">
        <div className={css(sx.coverage)}>
          <Text className={css(sx.label)}>Potential Coverage</Text>
          <br />
          {isEdit &&
            zipCodesLoaded &&
            potentialCoverage?.length > 0 &&
            potentialCoverage.map(zipCode => {
              return (
                <Tag className={css(sx.typeOne)} color="#F5F5F5" key={zipCode}>
                  {zipCode}
                </Tag>
              );
            })}
          {isEdit && isPcGreaterThanFive && (
            <Button
              className={css(sx.openModal)}
              type="link"
              onClick={() => openShowMoreModal('Potential Coverage')}
            >
              <Text type="primary" className={css(sx.linkText)} underline>
                View All
              </Text>
            </Button>
          )}
          {(!isEdit || !potentialCoverage?.length) && <Text className={css(sx.label)}>-</Text>}
        </div>
        <div className={css(sx.coverage)}>
          <Text className={css(sx.label)}>Zip Code Coverage</Text>
          <br />
          {isEdit &&
            zipCodesLoaded &&
            zipCodeCoverage?.length > 0 &&
            zipCodeCoverage.map(zipCode => {
              return (
                <Tag key={zipCode} bordered={false} className={css(sx.typeTwo)} color="#494949">
                  {zipCode}
                </Tag>
              );
            })}
          {isEdit && isZcGreaterThanFive && (
            <Button
              className={css(sx.openModal)}
              type="link"
              onClick={() => openShowMoreModal('Zip Code Coverage')}
            >
              <Text className={css(sx.linkText)} underline>
                View All
              </Text>
            </Button>
          )}
          {(!isEdit || !zipCodeCoverage?.length) && <Text className={css(sx.label)}>-</Text>}
        </div>
      </Space>
    );
  };

  const showSelectedServices = () => {
    return (
      <>
        {!readOnly && (
          <Space align="center">
            <div className={css(sx.selected)}>Selected: {selectedServices?.length || 0}</div>
            <Button
              className={css(sx.openModal)}
              type="link"
              onClick={() => clearSelectedServices()}
            >
              <Text type="primary" className={css(sx.linkText)} underline>
                Clear All
              </Text>
            </Button>
          </Space>
        )}
        {readOnly && !selectedServices.length && <Text className={css(sx.label)}>-</Text>}
        <div className={css(sx.serviceSelect)}>
          {unGroupedServices.length > 0 &&
            unGroupedServices.map(service => {
              return (
                <Tag
                  className={css([sx.serviceTag, sx.typeOne])}
                  key={service.id}
                  bordered="false"
                  closable={!readOnly}
                  onClose={() => updateSelectedServicesFromSelect('unselected', service.id)}
                >
                  {service.name}
                </Tag>
              );
            })}
        </div>
        <div className={css(sx.serviceSelect)}>
          {groupedServices.length > 0 &&
            groupedServices.map(service => {
              const { label, values } = service;
              return (
                values.length > 0 && (
                  <React.Fragment key={label}>
                    <p
                      className={css([sx.serviceType, readOnly ? sx.labelTransform : sx.labelBold])}
                    >
                      {label}
                    </p>
                    {values.map(service => {
                      return (
                        <Tag
                          className={css([sx.serviceTag, sx.typeOne])}
                          bordered="false"
                          key={service.id}
                          closable={!readOnly}
                          onClose={() => updateSelectedServicesFromSelect('unselected', service.id)}
                        >
                          {service.name}
                        </Tag>
                      );
                    })}
                  </React.Fragment>
                )
              );
            })}
        </div>
      </>
    );
  };

  const formView = () => {
    return (
      <>
        {((isEdit && editedPharmacyInfo) || !isEdit) && (
          <>
            {isHubPharmacy && !isParentPharmacy && (
              <>
                <Alert
                  message="Child Pharmacy Required"
                  description="Your new Parent Pharmacy requires a child.
                    After saving, please proceed to the Hub Tab and add a Child Pharmacy Account before navigating away from this page."
                  type="warning"
                />
              </>
            )}
            <div className={css(sx.section)}>
              <Space>
                <Select
                  className={css(sx.statusSelect)}
                  placeholder="Status: <Select>"
                  value={`Status: ${statusTypes.mapping[editedPharmacyInfo?.status]}`}
                  onChange={value => {
                    updatePharmacyInfo('status', value);
                    if (pharmacyInfo?.child_pharmacies?.length !== 0) {
                      setShowSavePharmacyModal(true);
                    }
                  }}
                  disabled={!isEdit}
                >
                  {statusTypes?.types?.map(status => (
                    <Option key={status.key} value={status.value}>
                      {status.name}
                    </Option>
                  ))}
                </Select>
              </Space>
              <Space>
                <Select
                  className={css(sx.ascSelect)}
                  placeholder="Account Service Coordinator: <Select>"
                  disabled={isChild ? true : readOnly}
                  onChange={value => {
                    return value !== undefined && value !== null
                      ? updatePharmacyInfo('associate_service_coordinator', { id: value })
                      : updatePharmacyInfo('associate_service_coordinator', value);
                  }}
                  value={editedPharmacyInfo?.associate_service_coordinator?.id}
                >
                  <Option value={null}>&lt;Select&gt;</Option>
                  {ascCoordinators.length > 0 &&
                    ascCoordinators.map(admin => (
                      <Option
                        value={admin.id}
                        key={admin.id}
                      >{`${admin.first_name} ${admin.last_name}`}</Option>
                    ))}
                </Select>
              </Space>
            </div>
            <br />
            <Title level={4} className={css(sx.hubTag)}>
              {'General'}
              {isHubPharmacy && <Tag className={css(sx.marginLeft10)}>Hub Pharmacy</Tag>}
              {isChild && <Tag className={css(sx.marginLeft10)}>Child Pharmacy</Tag>}
            </Title>
            <div>{'All the following fields are required unless marked optional'}</div>
            <br />
            <>
              <Space className={css(sx.marginBottom10)} align="baseline">
                <PharmacyInput
                  field="name"
                  disabled={readOnly}
                  label="name"
                  width="337px"
                  height="45px"
                  value={editedPharmacyInfo?.name || ''}
                  onChange={updatePharmacyInfo}
                  error={errors?.name}
                />
                <PharmacyInput
                  field="npi"
                  label="npi"
                  width="337px"
                  disabled={readOnly || isEdit}
                  height="45px"
                  value={editedPharmacyInfo?.npi || ''}
                  onChange={updatePharmacyInfo}
                  error={errors?.npi}
                />
                <PharmacyInput
                  field="phoneNumber"
                  label="Phone Number"
                  disabled={readOnly}
                  width="313px"
                  height="45px"
                  value={
                    (isEdit
                      ? editedPharmacyInfo?.phone?.number
                      : editedPharmacyInfo?.phoneNumber) || ''
                  }
                  onChange={isEdit ? updatePhoneInfo : updatePharmacyInfo}
                  error={errors?.['phone.number'] || errors?.phoneNumber}
                />
                {!isChild && (
                  <PharmacyInput
                    field="skip_number"
                    label="Skip Number (Optional)"
                    disabled={readOnly}
                    width="155px"
                    height="45px"
                    textFieldWidth="55px"
                    value={
                      (isEdit
                        ? editedPharmacyInfo?.skip_number
                        : editedPharmacyInfo?.skip_number) || ''
                    }
                    onChange={updatePharmacyInfo}
                    error={errors?.skip_number}
                  />
                )}
              </Space>
              <br />
              <Space className={css(sx.marginBottom10)} align="baseline">
                <AddressAutoComplete
                  field="line1"
                  disabled={readOnly}
                  label="Street Address"
                  value={editedPharmacyInfo?.address?.line1}
                  defaultValue={editedPharmacyInfo?.address?.line1}
                  onChange={updatePharmacyInfo}
                  updateAddressInfo={updateAddressInfo}
                  error={errors?.['address.line1']}
                />
                <PharmacyInput
                  field="line2"
                  disabled={readOnly}
                  label="Apt/Suite"
                  defaultValue={editedPharmacyInfo?.address?.line2}
                  value={editedPharmacyInfo?.address?.line2 || ''}
                  onChange={updateAddressInfo}
                />
                <PharmacyInput
                  field="city"
                  disabled={readOnly}
                  label="City"
                  defaultValue={editedPharmacyInfo?.address?.city}
                  value={editedPharmacyInfo?.address?.city || ''}
                  onChange={updateAddressInfo}
                  error={errors?.['address.city']}
                  width="200px"
                />
                <div>
                  <Text className={css(sx.label)}>State</Text>
                  <br />
                  <Select
                    disabled={readOnly}
                    defaultValue={editedPharmacyInfo?.address?.state}
                    className={css(sx.stateSelect)}
                    value={editedPharmacyInfo?.address?.state || ''}
                    onChange={value => {
                      updatePharmacyInfo('address', value, 'state');
                    }}
                    error={errors?.['address.state']}
                  >
                    {states.map(state => (
                      <Option value={state.value} key={state.value}>
                        {state.text} ({state.name})
                      </Option>
                    ))}
                  </Select>
                  <br />
                  {errors?.['address.state'] && (
                    <Text className={css(sx.error)}>{errors?.['address.state']}</Text>
                  )}
                </div>
                <PharmacyInput
                  field="postal_code"
                  label="Zip Code"
                  disabled={readOnly}
                  defaultValue={editedPharmacyInfo?.address?.postal_code}
                  value={editedPharmacyInfo?.address?.postal_code || ''}
                  onChange={updateAddressInfo}
                  error={errors?.['address.postal_code']}
                />
              </Space>
              <br />
              {showPotentialAndAssignedZipcodes()}
              <br />
              <div className={css(sx.section)}>
                <Title level={5}>{'Network & Languages'}</Title>
                <Space align="baseline" className={css(sx.marginBottom10)}>
                  <div>
                    <Text className={css(sx.label)}>Networks</Text>
                    <br />
                    <Select
                      disabled={readOnly}
                      mode="multiple"
                      allowClear
                      showArrow
                      style={{ width: '500px' }}
                      maxTagCount="responsive"
                      onChange={value => {
                        updatePharmacyInfo('pharmacy_networks', value);
                      }}
                      filterOption={(value, option) => {
                        const { children } = option.props;

                        if (option.type?.isSelectOptGroup) {
                          return children.includes(
                            child =>
                              child.props.children.toLowerCase().indexOf(value.toLowerCase()) >= 0,
                          );
                        }
                        return children?.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                      }}
                      defaultValue={
                        !isEdit
                          ? editedPharmacyInfo?.pharmacy_networks?.map(
                              network => network?.network_type?.id,
                            )
                          : editedPharmacyInfo?.pharmacy_networks
                      }
                      dropdownClassName={css(sx.networkContainer)}
                    >
                      {pharmNetworkTypes
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(network => {
                          return (
                            <OptGroup key={network.name} label={network.name.toUpperCase()}>
                              {network.groups
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(group => {
                                  return (
                                    <Option
                                      className={css(sx.pharmGroupOption)}
                                      key={`${group.lob}${group.id}`}
                                      value={group.id}
                                    >
                                      {group.name}
                                    </Option>
                                  );
                                })}
                            </OptGroup>
                          );
                        })}
                    </Select>
                    <div className={css(sx.selected)}>
                      Selected: {editedPharmacyInfo?.pharmacy_networks?.length || 0}
                    </div>
                  </div>
                </Space>
                <Space align="baseline" className={css(sx.marginBottom10)}>
                  <div>
                    <Text className={css(sx.label)} style={{ marginLeft: '16px' }}>
                      Languages
                    </Text>
                    <br />
                    <Select
                      mode="multiple"
                      disabled={readOnly}
                      allowClear
                      showArrow
                      onChange={value => {
                        updateLanguageInfo(value);
                      }}
                      style={{ width: '100%', minWidth: 250, marginLeft: '16px' }}
                      maxTagCount="responsive"
                      defaultValue={
                        !isEdit
                          ? editedPharmacyInfo?.languages?.map(
                              language => languageTypes.key === language,
                            )
                          : editedPharmacyInfo?.languages
                      }
                      dropdownClassName={css(sx.networkContainer)}
                    >
                      {languageTypes.map(language => {
                        return (
                          <Option
                            className={css(sx.pharmGroupOption)}
                            key={`${language.key}`}
                            value={language.key}
                          >
                            {language.name}
                          </Option>
                        );
                      })}
                    </Select>
                    <div className={css(sx.selected)} style={{ marginLeft: '16px' }}>
                      Selected: {editedPharmacyInfo?.languages?.length || 0}
                    </div>
                  </div>
                </Space>
              </div>
              <div className={css(sx.section)}>
                <Title level={5}>{'Services'}</Title>
                <div className={css(sx.split)}>
                  <div>
                    <Text className={css(sx.label)}>Pharmacy Services</Text>
                    <br />
                    <Select
                      mode="multiple"
                      value={selectedServices.map(s => s.id)}
                      showSearch={false}
                      showArrow
                      className={css(sx.serviceSelect)}
                      maxTagCount="responsive"
                      onSelect={value => {
                        updateSelectedServicesFromSelect('selected', value);
                      }}
                      onDeselect={value => {
                        updateSelectedServicesFromSelect('unselected', value);
                      }}
                      dropdownClassName={css(sx.optionGroup)}
                    >
                      <Option value={'other'}>
                        <Text className={css(sx.linkText)} underline>
                          Add Other
                        </Text>
                      </Option>
                      {services.length > 0 &&
                        services.map(service =>
                          service?.subTypes?.length > 0 ? (
                            <OptGroup key={service.name} label={service.name.toUpperCase()}>
                              {service?.subTypes?.map(type => {
                                return (
                                  <Option key={type.id} value={type.id}>
                                    {type.name}
                                  </Option>
                                );
                              })}
                            </OptGroup>
                          ) : (
                            <Option key={service.id} value={service.id}>
                              {service.name}
                            </Option>
                          ),
                        )}
                    </Select>
                    <div>{showSelectedServices()}</div>
                  </div>
                  {isOther && (
                    <div className={css(sx.split)}>
                      <PharmacyInput
                        field="other"
                        label="Other"
                        width="337px"
                        height="45px"
                        value={other}
                        onChange={onChangeOfOther}
                        error={errors?.service}
                      />
                      <Button
                        type="primary"
                        disabled={readOnly || other.trim() === ''}
                        className={css(sx.addServiceButton)}
                        onClick={() => addOtherService()}
                      >
                        Add Service
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <br />
              {(isBdTeam || isAscTeam || isSystemAdmin) && displayCheckbox(pharmacyInfo) && (
                <Space>
                  <div className={css(sx.section)}>
                    <Title level={5}>{'Hub Pharmacy'}</Title>
                    <Checkbox
                      disabled={readOnly}
                      checked={isHubPharmacy}
                      onChange={e => setIsHubPharmacy(e?.target?.checked)}
                    >
                      This is a Hub Pharmacy
                    </Checkbox>
                  </div>
                </Space>
              )}
              <div>
                <Button
                  type="primary"
                  disabled={readOnly}
                  onClick={submitPharmacyInfo}
                  loading={
                    isAddingPharmacy ||
                    isAddingChildPharmacy ||
                    isUpdatingPharmacyInfo ||
                    isBulkUpdatingChildren
                  }
                  className={css(sx.button)}
                >
                  {submitButtonText()}
                </Button>
                <Button onClick={() => handleCancelAction()} className={css(sx.button)}>
                  Cancel
                </Button>
              </div>
            </>
          </>
        )}
      </>
    );
  };

  const getDataForView = () => {
    const readOnlyData = {};
    if (
      editedPharmacyInfo &&
      Object.keys(editedPharmacyInfo?.associate_service_coordinator).length
    ) {
      const { first_name, last_name } = editedPharmacyInfo?.associate_service_coordinator;
      readOnlyData.asc = `${first_name} ${last_name}`;
    } else {
      readOnlyData.asc = 'None';
    }

    if (editedPharmacyInfo?.phone) {
      const formatedPhoneNumber = formatPhoneNumber(editedPharmacyInfo?.phone?.number, true);
      readOnlyData.phone = formatedPhoneNumber;
    }

    if (editedPharmacyInfo?.address.state) {
      const stateObject = states.find(s => s.value === editedPharmacyInfo?.address.state);
      readOnlyData.state = stateObject;
    }

    if (editedPharmacyInfo?.pharmacy_networks?.length) {
      const assignedNetworks = pharmNetworkTypes.reduce((acc, value) => {
        const filteredGroups = value.groups.filter(g => {
          return editedPharmacyInfo.pharmacy_networks.includes(g.id);
        });
        if (filteredGroups.length) {
          const assignedNetworkNames = filteredGroups.map(g => g.name);
          acc.push(...assignedNetworkNames);
        }
        return acc;
      }, []);
      readOnlyData.networks = assignedNetworks;
    } else {
      readOnlyData.networks = [];
    }

    if (editedPharmacyInfo?.languages) {
      const assignedLanguages = editedPharmacyInfo?.languages.reduce((acc, value) => {
        const { name } = languageTypes.find(l => l.key === value);
        acc.push(name);
        return acc;
      }, []);
      readOnlyData.languages = assignedLanguages;
    } else {
      readOnlyData.languages = [];
    }
    setViewData(readOnlyData);
  };

  useEffect(() => {
    if (readOnly && editedPharmacyInfo !== null && networksLoaded) {
      getDataForView();
    }
  }, [editedPharmacyInfo, networksLoaded]);

  const handleCancelAction = () => {
    if (isEdit || editedPharmacyInfo === pharmacyInfo) {
      history.push({
        pathname: '/settings/pharmacylist',
      });
    } else {
      setShowRedirectModal(true);
    }
  };

  const updatePharmacyInfo = (field, value, subfield) => {
    const payload = { ...editedPharmacyInfo };

    if (subfield) {
      payload[field][subfield] = value;
    } else if (
      field === 'associate_service_coordinator' &&
      pharmacyInfo?.child_pharmacies?.length > 0
    ) {
      setOverrideModalVisible(true);
      payload[field] = value;
    } else {
      payload[field] = value;
    }
    setEditedPharmacyInfo(payload);
  };

  const updateLanguageInfo = value => {
    updatePharmacyInfo('languages', value);
  };

  const updateAddressInfo = (field, value) => {
    updatePharmacyInfo('address', value, field);
  };

  const updatePhoneInfo = (field, value) => {
    updatePharmacyInfo('phone', value, 'number');
  };

  const transformServices = () => {
    // get new array with just values
    const valueArr = cloneDeep(unGroupedServices);
    groupedServices.forEach(s => {
      valueArr.push(...s.values);
    });
    setSelectedServices(valueArr);
  };

  // useEffect to update the selected services
  useEffect(() => {
    transformServices();
  }, [groupedServices, unGroupedServices]);

  useEffect(() => {
    if (EnteredServicesIds.length > 0) {
      const other = groupedServices.find(s => s.label === 'Other');
      const filteredOther = other?.values
        .filter(s => EnteredServicesIds.includes(s.id))
        .map(s => s.name);
      setOtherServices(filteredOther);
    }
  }, [groupedServices]);

  const updateSelectedServicesFromSelect = (type, value) => {
    if (value === 'other') {
      setIsOther(true);
    } else {
      clearOtherField();
      setOther('');
      setIsOther(false);
      updateSelectedServices([{ type, value }]);
    }
  };

  // Getting the entire object form 'services' using the id of selected/current service and
  // adding it to the grouped or ungrouped arrays if it does'nt exist
  const updateSelectedServices = newServices => {
    const clonedGroupedData = cloneDeep(groupedServices);
    let clonedUngroupedData = cloneDeep(unGroupedServices);
    newServices.forEach(service => {
      const currentService = services.find(s => s.id === service.value);
      if (currentService) {
        if (service.type === 'selected') {
          const isPresent = clonedUngroupedData.find(item => item.id === currentService.id);
          if (!isPresent) clonedUngroupedData.push(currentService);
        } else {
          clonedUngroupedData = clonedUngroupedData.filter(data => data.id !== currentService.id);
        }
      } else {
        const { name, subTypes } = find(services, { subTypes: [{ id: service.value }] });
        const current = subTypes.find(s => s.id === service.value);
        const itemIndex = clonedGroupedData.findIndex(data => data.label === name);
        if (service.type === 'selected') {
          if (itemIndex > -1) {
            const isPresent = find(clonedGroupedData, {
              values: [{ name: current.name }],
            });
            const { values } = find(clonedGroupedData, { label: name });
            const duplicateIndex = values.findIndex(s => s.name === current.name);
            if (duplicateIndex > -1 && values[duplicateIndex].id !== current.id) {
              clonedGroupedData[itemIndex].values[duplicateIndex].id = current.id;
            }
            if (!isPresent && duplicateIndex <= -1)
              clonedGroupedData[itemIndex].values = [
                ...clonedGroupedData[itemIndex].values,
                current,
              ];
          } else {
            clonedGroupedData.push({ label: name, values: [current] });
          }
        } else {
          const filteredValues = clonedGroupedData[itemIndex].values.filter(
            service => service.name !== current.name,
          );
          clonedGroupedData[itemIndex].values = filteredValues;
        }
      }
    });
    setGroupedServices(clonedGroupedData);
    setUnGroupedServices(clonedUngroupedData);
  };

  const clearSelectedServices = () => {
    setSelectedServices([]);
    setGroupedServices([]);
    setUnGroupedServices([]);
    setOtherServices([]);
  };

  const onChangeOfOther = (field, value) => {
    setOther(value);
  };

  const clearOtherField = () => {
    const clonedErrorData = { ...errors };
    delete clonedErrorData.service;
    setErrors(clonedErrorData);
  };

  const addOtherService = async () => {
    const otherService = other.trim();
    const isServicePresent = await serviceSchema.isValid(otherService.toLowerCase());
    const clonedErrorData = { ...errors };
    if (isServicePresent) {
      clonedErrorData.service = 'This service has already been added. Please refer to service list';
      setErrors(clonedErrorData);
    } else {
      clearOtherField();
      const maxId = Math.max(...[...currentServices]?.map(s => s.id));
      const clonedEnteredServicesIds = cloneDeep(EnteredServicesIds);
      clonedEnteredServicesIds.push(maxId + 1);
      setEnteredServicesIds(clonedEnteredServicesIds);

      const clonedServicesFromInfo = cloneDeep(editedPharmacyInfo.pharmacy_services);
      clonedServicesFromInfo.push({ pharmacy_service_type_id: maxId + 1 });
      updatePharmacyInfo('pharmacy_services', clonedServicesFromInfo);

      const clonedCurrentServices = cloneDeep(currentServices);
      clonedCurrentServices.push({
        id: maxId + 1,
        pharmacy_service_type: 'Other',
        pharmacy_service_sub_type: otherService,
      });
      setCurrentServices(clonedCurrentServices);
      setOther('');
    }
  };

  const validateForm = async () => {
    let errorPayload = {};

    await pharmacySchema
      .validate({ ...editedPharmacyInfo }, { abortEarly: false })
      .then(() => {
        setErrors({});
      })
      .catch(error => {
        errorPayload = error?.inner?.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        setErrors(errorPayload);
      });

    return errorPayload;
  };

  const submitGeneralFormInfo = async childNpis => {
    const pharmacyPayload = OmitDeep(
      {
        ...editedPharmacyInfo,
        associate_service_coordinator: editedPharmacyInfo.associate_service_coordinator
          ? {
              id: editedPharmacyInfo.associate_service_coordinator.id,
            }
          : null,
        pharmacy_services: selectedServices
          .filter(s => !EnteredServicesIds.includes(s.id))
          .map(s => s.id),
        otherOptions: otherServices,
        childNpis,
      },
      ['__typename', 'pharmacyId', 'zipcode_coverage', 'potential_zipcodes', 'child_pharmacies'],
    );
    delete pharmacyPayload.id;
    delete pharmacyPayload.hours;
    delete pharmacyPayload.champion;
    updatePharmacy({
      variables: {
        pharmacyId: editedPharmacyInfo.id,
        modifiers: pharmacyPayload,
      },
    });
    setEnteredServicesIds([]);
    setOtherServices([]);
    if (showSavePharmacyModal) {
      setShowSavePharmacyModal(false);
    }
  };

  const onSaveCancel = async modal => {
    if (modal === 'asc') {
      setOverrideModalVisible(false);
      const payload = { ...editedPharmacyInfo };
      if (
        editedPharmacyInfo.associate_service_coordinator !==
        pharmacyInfo?.associate_service_coordinator
      ) {
        payload.associate_service_coordinator = pharmacyInfo?.associate_service_coordinator;
        setPharmacyInfo(payload);
      }
    } else if (modal === 'status') {
      setShowSavePharmacyModal(false);
      const payload = { ...editedPharmacyInfo };
      if (editedPharmacyInfo?.status !== pharmacyInfo?.status) {
        payload.status = pharmacyInfo?.status;
        setPharmacyInfo(payload);
      }
    }
  };

  const onSaveOk = async (modal, npiArr) => {
    if (modal === 'asc') {
      setOverrideModalVisible(false);
      const saveReturn =
        editedPharmacyInfo?.associate_service_coordinator !== null
          ? setParentAscId(editedPharmacyInfo?.associate_service_coordinator.id)
          : setParentAscId(null);
      return saveReturn;
    } else if (modal === 'status') {
      setStatusChildNpiArr(npiArr);
      setShowSavePharmacyModal(false);
    }
  };

  const submitPharmacyInfo = async () => {
    // Perform validations before submitting.
    const errorPayload = await validateForm();
    if (Object.keys(errorPayload).length === 0) {
      if (isEdit) {
        if (
          (pharmacyInfo?.child_pharmacies?.length > 0 && statusChildNpiArr?.length > 0) ||
          (editedPharmacyInfo?.status !== pharmacyInfo?.status && statusChildNpiArr?.length > 0)
        ) {
          submitGeneralFormInfo(statusChildNpiArr);
        } else {
          submitGeneralFormInfo([]);
        }
      } else {
        const payload = omit(
          {
            ...editedPharmacyInfo,
            npi: parseFloat(editedPharmacyInfo?.npi),
            phone: {
              number: editedPharmacyInfo?.phoneNumber,
            },
            address: {
              ...editedPharmacyInfo?.address,
            },
            pharmacy_services: selectedServices
              .filter(s => !otherServices.includes(s.name))
              .map(s => s.id),
            otherOptions: otherServices,
            associate_service_coordinator: editedPharmacyInfo?.associate_service_coordinator
              ? {
                  id: editedPharmacyInfo?.associate_service_coordinator?.id,
                }
              : null,
          },
          ['phoneNumber', 'status'],
        );
        addPharmacy({
          variables: {
            modifiers: OmitDeep(payload, ['zipcode_coverage', 'potential_zipcodes']),
          },
        });
        setEnteredServicesIds([]);
        setOtherServices([]);
      }
    }
    if (
      pharmacyInfo.child_pharmacies > 0 ||
      editedPharmacyInfo.associate_service_coordinator !==
        pharmacyInfo.associate_service_coordinator
    ) {
      bulkUpdateAsc({
        variables: {
          pharmacyNpis: childrenNpiArr,
          pharmacy_asc_id: parentAscId,
        },
      });
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      validateForm();
    }
  }, [editedPharmacyInfo]);

  return (
    <>
      <Card
        onCancel={() => handleCancelAction()}
        className={css(sx.modalContainer)}
        getContainer={false}
      >
        {!readOnly ? (
          <div>{formView()}</div>
        ) : (
          Object.keys(viewData).length > 0 && (
            <GeneralInformationReadOnly
              viewData={viewData}
              editedPharmacyInfo={editedPharmacyInfo}
              showPotentialAndAssignedZipcodes={showPotentialAndAssignedZipcodes}
              showSelectedServices={showSelectedServices}
              isHubPharmacy={isHubPharmacy}
              isChild={isChild}
            />
          )
        )}
      </Card>
      {pharmacyInfo && pharmacyInfo?.id && showMoreModal && (
        <ShowMore
          title={selectedZipCodes}
          visible={showMoreModal}
          onModifyCancel={() => setShowMoreModal(false)}
          codes={
            selectedZipCodes === 'Potential Coverage' ? potentialZipcodeCoverage : zipcodeCoverage
          }
          zipCodesCount={
            selectedZipCodes === 'Potential Coverage' ? potentialZipCodeCount : zipcodeCount
          }
          isPotentialZipcodes={selectedZipCodes === 'Potential Coverage'}
          pharmacyId={pharmacyInfo.id}
        />
      )}
      {showRedirectModal && (
        <RedirectModal
          visible={showRedirectModal}
          onPharmacyInfoModalCancel={() => setShowRedirectModal(false)}
          pharmNetworkTypes={pharmNetworkTypes}
        />
      )}
      {pharmacyInfo && showSavePharmacyModal && (
        <SavePharmacyModal
          pharmacyInfo={pharmacyInfo}
          savePharmacyCancel={onSaveCancel}
          savePharmacyInfo={onSaveOk}
          visible={showSavePharmacyModal}
        />
      )}
      <EditASCModal
        onSaveCancel={onSaveCancel}
        onSaveOk={onSaveOk}
        visible={overrideModalVisible}
      />
    </>
  );
};

GeneralPharmacyInfoTab.propTypes = {
  pharmacyInfo: PropTypes.object,
  isEdit: PropTypes.bool,
  readOnly: PropTypes.bool,
  history: PropTypes.object,
  isHubPharmacy: PropTypes.bool,
  refetchPharmacyInfo: PropTypes.func,
  setIsHubPharmacy: PropTypes.func,
  setPharmacyInfo: PropTypes.func,
  setIsEdit: PropTypes.func,
  zipcodeCoverage: PropTypes.array,
  potentialZipcodeCoverage: PropTypes.array,
  parentNpi: PropTypes.number,
  isBdTeam: PropTypes.bool,
  isAscTeam: PropTypes.bool,
  isChild: PropTypes.bool,
};

export default withRouter(withRoles(GeneralPharmacyInfoTab));
