/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { SearchOutlined } from '@ant-design/icons';
import SearchBox from './SearchBox';

const sx = StyleSheet.create({
  searchButton: { color: '#4A20C8' },
});

const FilterSearchBox = (
  dataIndex,
  placeholder,
  defaultFilterValue,
  tableActions,
  setTableActions,
) => {
  const onSearch = useCallback((searchValue, setSelectedKeys) => {
    setSelectedKeys(searchValue);
  });

  const onFilterSelection = (confirm, option, setSelectedKeys) => {
    if (option?.value) {
      setSelectedKeys(option.value);
    }
    confirm();
  };

  const onReset = (clearFilters, setSelectedKeys) => {
    setSelectedKeys('');
    clearFilters();
    setTableActions({
      ...tableActions,
      filters: {
        ...tableActions?.filters,
        [dataIndex]: null,
      },
    });
  };

  return {
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <SearchBox
          dataIndex={dataIndex}
          placeholder={placeholder}
          onSearch={searchValue => onSearch(searchValue, setSelectedKeys)}
          onFilterSelection={(value, option) => {
            onFilterSelection(confirm, option, setSelectedKeys);
          }}
          onReset={() => onReset(clearFilters, setSelectedKeys)}
          value={selectedKeys?.length ? selectedKeys : ''}
          defaultValue={defaultFilterValue || ''}
        />
      );
    },
    filterIcon: filtered => {
      return <SearchOutlined className={filtered ? css(sx.searchButton) : undefined} />;
    },
  };
};

SearchBox.propTypes = {
  dataIndex: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FilterSearchBox;
