import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Icon, formatPhone } from '@zipdrug/ui';
import SettingsAccordion from '../SettingsAccordion';
import SettingsAccordionListItemHeader from '../SettingsAccordionListItemHeader';
import PhoneListItemActionsContainer from './PhoneListItemActionsContainer';
import PhoneListItemFormContainer from './PhoneListItemFormContainer';

const PhoneAvatar = props => (
  <Avatar {...props} circle>
    <Icon name="telephone" filled />
  </Avatar>
);

const PhoneListItem = ({ item, workstationPhoneId }) => {
  if (!workstationPhoneId) return null;

  const actionsRenderer = (
    <PhoneListItemActionsContainer item={item} workstationPhoneId={workstationPhoneId} />
  );

  const editFormRenderer = <PhoneListItemFormContainer isEditing item={item} />;

  const headerRenderer = (
    <SettingsAccordionListItemHeader
      avatarRenderer={<PhoneAvatar />}
      editingMetaText={`Update the ${item.label} by editing the information below.`}
      item={item}
      metaItems={[formatPhone(item)]}
      tag={item.id === workstationPhoneId ? 'Assigned to this station' : null}
      title={item.label}
    />
  );

  return (
    <SettingsAccordion
      actionsRenderer={actionsRenderer}
      editFormRenderer={editFormRenderer}
      headerRenderer={headerRenderer}
    />
  );
};

PhoneListItem.propTypes = {
  item: PropTypes.object,
  workstationPhoneId: PropTypes.number.isRequired,
};

export default PhoneListItem;
