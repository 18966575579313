const STATUS_BUTTON_MAP = {
  active: [
    {
      value: 'outreached',
      zipdrugOnly: true,
    },
    {
      value: 'not_interested',
    },
  ],
  churned: [
    {
      value: 'not_interested',
    },
  ],
  interested: [
    {
      value: 'outreached',
      zipdrugOnly: true,
    },
    {
      value: 'not_interested',
    },
  ],
  not_interested: [
    {
      value: 'outreached',
    },
    {
      value: 'interested',
    },
  ],
  outreached: [
    {
      value: 'interested',
    },
    {
      value: 'not_interested',
      zipdrugOnly: true,
    },
  ],
};

export default STATUS_BUTTON_MAP;
