import { Button } from 'antd';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { colors, Icon, space, TruncateText, Flex } from '@zipdrug/ui';
import { OutcomesContext } from 'contexts/OutcomesProvider';
import EnrollStatusModal from './EnrollStatusModal';

const sx = StyleSheet.create({
  enrollmentIndicator: {
    backgroundColor: colors.red,
    cursor: 'inherit',
    marginLeft: 0,
    marginRight: space.quarter,
  },
  menuStyles: {
    left: 0,
    right: 'initial',
  },
  caret: {
    color: colors.gray62,
  },
  enrollStyles: {
    color: '#5009B5',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  buttonStyles: {
    backgroundColor: 'rgba(30, 30, 52, 0.07)',
  },
});

const EnrollDropdown = ({
  patient,
  onModelToggled,
  hasRole: { pharmacistUser, pharmacistAdmin },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [enrollmentStatus, setEnrollmentStatus] = useState(null);

  const {
    isEnrollStatusUpdatedContext,
    setIsEnrollStatusUpdatedContext,
    setUpdatedPharmacyContext,
  } = useContext(OutcomesContext);

  useEffect(() => {
    if (patient?.enroll_status && patient?.enroll_status !== enrollmentStatus) {
      setEnrollmentStatus(patient?.enroll_status);
      setIsEnrollStatusUpdatedContext(patient?.enroll_status);
    }
    // reset updatedPharmacyFlag
    if (patient) {
      setUpdatedPharmacyContext(patient.assigned_pharmacy_id);
    }
  }, [patient]);

  useEffect(() => {
    if (isEnrollStatusUpdatedContext && isEnrollStatusUpdatedContext !== enrollmentStatus) {
      setEnrollmentStatus(isEnrollStatusUpdatedContext);
    }
  }, [isEnrollStatusUpdatedContext]);

  const capitalizeFirstLetter = string => {
    return (string.charAt(0).toUpperCase() + string.slice(1)).replace('_', ' ');
  };

  const transformEnrolledStatus = enrollStatus => {
    return enrollStatus === 'interested' ? 'enrolled' : enrollStatus;
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    onModelToggled(!isOpen);
  };

  return (
    <>
      <EnrollStatusModal
        patient={patient}
        enrollmentStatus={enrollmentStatus}
        visible={isOpen}
        onCloseModal={() => toggleModal()}
        isZipdrugUser={!pharmacistUser && !pharmacistAdmin}
        transformEnrolledStatus={transformEnrolledStatus}
      />
      {patient && patient?.assigned_pharmacy && enrollmentStatus && (
        <Button onClick={() => toggleModal()} type="link" styles={sx.buttonStyles}>
          <Flex align="center">
            <Icon name="member" />
            <TruncateText styles={sx.enrollStyles}>
              {capitalizeFirstLetter(transformEnrolledStatus(enrollmentStatus))}
            </TruncateText>
            <Icon direction="down" name="caret" styles={sx.caret} />
          </Flex>
        </Button>
      )}
    </>
  );
};

EnrollDropdown.propTypes = {
  patient: PropTypes.object,
  onModelToggled: PropTypes.func,
  hasRole: PropTypes.object,
};

export default EnrollDropdown;
