import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';
import { getUrlParams, passwordField, validateForm } from '@zipdrug/ui';
import ResetPasswordForm from './ResetPasswordForm';
import validatePwToUsername from '../hoc/utils/validatePwToUsername';
import withUpdateUserAndLogin from '../hoc/withUpdateUserAndLogin';

const PASSWORD_MINIMUM_LENGTH = 15;

const ERROR_MESSAGES = {
  invalidPassword: `must be at least ${PASSWORD_MINIMUM_LENGTH} characters, including
  at least one capital letter, one lowercase letter, one number, and one special character ($ ! @ * ? |). It cannot contain
  spaces or 3 consecutive characters or numbers.`,
  passwordMatchesUsername: "cannot match three consecutive characters of user's email.",
  required: 'is required.',
  passwordMatchesOldPasswords: 'matches previous passwords used by user.',
};

const resetPasswordField = {
  ...passwordField,
  required: true,
};

const RESET_PASSWORD_SCHEMA = {
  confirmPassword: resetPasswordField,
  password: resetPasswordField,
  // currentPassword: resetPasswordField,
};

const validate = values => {
  const { confirmPassword, password } = values;
  const errors = validateForm(values, RESET_PASSWORD_SCHEMA, ERROR_MESSAGES);

  // Both inputs have value, but do not match, show error
  if (password && confirmPassword && password !== confirmPassword) {
    errors.confirmPassword = 'does not match';
    errors._error = 'Passwords must match exactly';
  }

  return errors;
};

const asyncValidate = values => {
  return validatePwToUsername(values).then(errorMessage => {
    if (errorMessage) {
      // eslint-disable-next-line no-throw-literal
      throw { password: ERROR_MESSAGES[errorMessage] };
    }
  });
};

const ReduxResetPasswordForm = reduxForm({
  form: 'resetPassword',
  validate,
  asyncValidate,
  asyncBlurFields: ['password'],
})(ResetPasswordForm);

class ResetPasswordFormContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    updateUserAndLogin: PropTypes.func.isRequired,
    isModal: PropTypes.bool,
    closeModal: PropTypes.func,
  };

  state = { showDashboardButton: false, submitSucceeded: false, resetting: false };

  componentDidMount() {
    const params = getUrlParams();
    const showDashboardButton = params.redirect === 'care';
    this.setState({ showDashboardButton });
  }

  handleDashboardButtonClick = () => this.props.history.replace('/signin');

  handleSubmit = ({ password, currentPassword }) => {
    this.setState({ resetting: true });
    return this.props
      .updateUserAndLogin({ password, currentPassword })
      .catch(e => {
        let _error = 'No connection';
        if (e.graphQLErrors && e.graphQLErrors.length) {
          if (e.graphQLErrors[0].type === 'AccountLocked') {
            const accountLockDate = new Date(e.graphQLErrors[0].message);
            const timeOptions = { hour: '2-digit', minute: '2-digit', timeZoneName: 'short' };
            _error = `Account locked. Please try again after ${accountLockDate.toLocaleDateString(
              'en-US',
            )} ${accountLockDate.toLocaleTimeString('en-US', timeOptions)}.`;
          } else {
            _error = e.graphQLErrors[0].message;
          }
        }
        _error = e.graphQLErrors[0].message;
        this.setState({ submitSucceeded: false, resetting: false });
        throw new SubmissionError({ _error });
      })
      .then(res => {
        if (res.user) {
          this.setState({ submitSucceeded: true, resetting: false });
        } else if (res[0].message) {
          throw new SubmissionError({ _error: res[0].message });
        }
      });
  };

  render = () => (
    <ReduxResetPasswordForm
      intialValues={{
        password: null,
        confirmPassword: null,
        currentPassword: null,
      }}
      onDashboardButtonClick={this.handleDashboardButtonClick}
      onSubmit={this.handleSubmit}
      showDashboardButton={this.state.showDashboardButton}
      isModal={this.props.isModal}
      closeModal={this.props.closeModal}
      submitSucceeded={this.state.submitSucceeded}
      resetting={this.state.resetting}
    />
  );
}

export default withUpdateUserAndLogin(ResetPasswordFormContainer);
