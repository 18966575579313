import gql from 'graphql-tag';

const FIND_CAMPAIGN = gql`
  query findCampaign($id: Int!) {
    findCampaign(id: $id) {
      data {
        id
        payer_ids
        name
        description
        status
        file_name
        file_upload_message
        file_upload_status
        priority
        pharmacy_names
        pharmacy_ids
        plan_ids
        special_notes
        assigned_enrollers
        campaign_pharmacies {
          id
          name
        }
      }
    }
  }
`;

export default FIND_CAMPAIGN;
