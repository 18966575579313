import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { activities } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import ConfirmDeleteActivityItemModal from './ConfirmDeleteActivityItemModal';

class ConfirmDeleteActivityItemModalContainer extends Component {
  static propTypes = {
    activity: PropTypes.object,
    mutateDeleteNote: PropTypes.func,
    onClose: PropTypes.func,
  };

  handleSubmit = async () => {
    const { activity, mutateDeleteNote, onClose } = this.props;
    return alertOnError(
      mutateDeleteNote({
        variables: {
          id: activity.note.id,
        },
      }),
    ).then(onClose);
  };

  render = () => <ConfirmDeleteActivityItemModal {...this.props} onSubmit={this.handleSubmit} />;
}

export default graphql(activities.mutateDeleteNote, {
  name: 'mutateDeleteNote',
})(ConfirmDeleteActivityItemModalContainer);
