import React from 'react';

const CloseIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8.5" cy="8.5" r="8" fill="white" stroke="#5009B5" />
    <line
      x1="11.8846"
      y1="11.3652"
      x2="5.83293"
      y2="5.31355"
      stroke="#5009B5"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="5.83203"
      y1="11.8846"
      x2="12.4178"
      y2="5.29883"
      stroke="#5009B5"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default CloseIcon;
