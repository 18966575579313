import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const OutcomesContext = React.createContext();

const OutcomesProvider = ({ children }) => {
  const [isOutcomesUpdated, setOutcomesIsUpdated] = useState({});
  const [isEnrollStatusUpdated, setIsEnrollStatusUpdated] = useState('');
  const [isOutcomesUpdatedInCall, setOutcomesUpdatedInCall] = useState({});
  const [updatedPharmacy, setUpdatedPharmacy] = useState(null);

  return (
    <OutcomesContext.Provider
      value={{
        outcomesUpdatedContext: isOutcomesUpdated,
        setOutcomesContext: setOutcomesIsUpdated,
        isEnrollStatusUpdatedContext: isEnrollStatusUpdated,
        setIsEnrollStatusUpdatedContext: setIsEnrollStatusUpdated,
        isOutcomesUpdatedInCallContext: isOutcomesUpdatedInCall,
        setOutcomesUpdatedInCallContext: setOutcomesUpdatedInCall,
        updatedPharmacyContext: updatedPharmacy,
        setUpdatedPharmacyContext: setUpdatedPharmacy,
      }}
    >
      {children}
    </OutcomesContext.Provider>
  );
};

OutcomesProvider.propTypes = {
  children: PropTypes.node,
};

export default React.memo(OutcomesProvider);
