const queryFetchTimes = {};

const fetchOnceOptions = (queryName, options = {}) => {
  const { skip, variables = {}, networkOnly = null } = options;
  if (skip) return options;

  const key = `${queryName}_${JSON.stringify(variables)}`;
  const times = queryFetchTimes[key] || 1;

  let fetchPolicy;
  if (networkOnly) {
    fetchPolicy = 'network-only';
  } else {
    fetchPolicy = times > 1 ? 'cache' : 'cache-and-network';
  }
  queryFetchTimes[key] = times + 1;

  return {
    fetchPolicy,
    skip,
    variables,
  };
};

export default fetchOnceOptions;
