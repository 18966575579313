import React from 'react';

const InsuranceIcon = () => (
  <svg width="19" height="25" viewBox="0 0 19 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.84721C3.73219 4.40972 6.5677 3.87819 8.93968 2.35888L9.5 1.99998L10.0603 2.35888C12.4323 3.87819 15.2678 4.40972 18 3.84721V13.4216C18 16.2791 16.5307 18.918 14.1488 20.3387L9.68681 23L4.97648 20.3114C2.52431 18.9117 1 16.2322 1 13.3212V3.84721Z"
      stroke="#2424C2"
      strokeWidth="2"
    />
    <line x1="10" y1="7" x2="10" y2="17" stroke="#2424C2" strokeWidth="2" />
    <line x1="15" y1="12" x2="5" y2="12" stroke="#2424C2" strokeWidth="2" />
  </svg>
);

export default InsuranceIcon;
