import { dateUtils } from '@zipdrug/ui';
import { daysRemainingInYear, startOfYear, startOfDay } from './dateUtils';
import getPDCSegments from './getPDCSegments';
import SegmentType from './SegmentType';

function sumSegmentDays(segments) {
  return segments.map(({ daysInRange }) => daysInRange).reduce((x, y) => x + y, 0);
}

export default function calculatePDC(therapyFills, additionalDays = 0) {
  const fromDate = startOfYear();
  const untilDate = dateUtils.addDays(startOfDay(), additionalDays);

  const segments = getPDCSegments(therapyFills, fromDate, untilDate);

  const therapies = segments.filter(
    segment => segment.type === SegmentType.AlternativeFill || segment.type === SegmentType.Fill,
  );

  const holes = segments.filter(segment => segment.type === SegmentType.Hole);

  const daysAdherent = sumSegmentDays(therapies);
  const daysNonAdherent = sumSegmentDays(holes);

  const totalDays = daysAdherent + daysNonAdherent;
  if (totalDays < 1) return 0;

  const PDC = (daysAdherent + additionalDays) / (totalDays + additionalDays);
  return Math.round(PDC * 100);
}

export function calculateBestOutcomePDC(therapyFills) {
  const additionalDays = daysRemainingInYear();
  return calculatePDC(therapyFills, additionalDays);
}
