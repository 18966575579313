import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withPharmacyId from './withPharmacyId';
import withSelectedPatientId from './withSelectedPatientId';
import { queryPatientAndPharmacy } from '../graphql/patients';

const withSelectedPatientInfo = Component =>
  graphql(queryPatientAndPharmacy, {
    skip: ({ selectedPatientId, userId }) => !selectedPatientId || !userId,
    options: ({ selectedPatientId }) => ({
      fetchPolicy: 'no-cache',
      variables: {
        id: selectedPatientId,
      },
    }),
    props: ({
      data: {
        loading: isLoading,
        patients: result,
        refetch: refetchSelectedPatientInfo,
        subscribeToMore,
      },
    }) => ({
      isLoading,
      selectedPatient: get(result, 'data[0]'),
      refetchSelectedPatientInfo,
      subscribeToMore,
    }),
  })(Component);

export default Component =>
  compose(withPharmacyId, withSelectedPatientId, withSelectedPatientInfo)(Component);
