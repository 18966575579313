import { ActivityItem, AudioPlayer, colors, font, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import ReturnCallButtonContainer from './ReturnCallButtonContainer';
import CallOutcomeActivityItem from '../activity-item/CallOutcomeActivityItem';

const sx = StyleSheet.create({
  content: {
    paddingTop: font.size.medium,
  },
  header: {
    color: colors.green,
    paddingTop: space.half,
  },
});

const VoicemailActivityItem = ({ activity, isPlaying, onPlayAudio, onSeen, ...props }) => (
  <>
    <ActivityItem
      {...props}
      activity={activity}
      createdAt={activity.created_at}
      contentStyles={sx.content}
      externalUser={activity.patient}
      headerButton={<ReturnCallButtonContainer call={activity.call} />}
      headerStyles={sx.header}
      icon="voicemail"
      title="Voicemail"
    >
      <AudioPlayer
        audioFile={activity.call.file_url}
        duration={activity.call.recording_duration}
        isPlaying={isPlaying}
        onPlayAudio={onPlayAudio}
      />
    </ActivityItem>

    {activity.callOutcome && (
      <CallOutcomeActivityItem
        activity={activity}
        className="e2e-activityListItem"
        key={activity.id}
      />
    )}
  </>
);

VoicemailActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
  isPlaying: PropTypes.bool,
  onPlayAudio: PropTypes.func,
  onSeen: PropTypes.func,
};

export default VoicemailActivityItem;
