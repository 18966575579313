import {
  A,
  Div,
  FlexCentered,
  Icon,
  ImageLoader,
  position,
  Row,
  space,
  transition,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const signatureHoverTransition = transition.build({
  duration: 100,
  ease: 'ease-in',
  property: 'opacity',
});

const sx = StyleSheet.create({
  container: {
    cursor: 'pointer',
    marginTop: space.quarter,
    maxWidth: '20rem',
    minHeight: '4.4rem',
    position: 'relative',
  },
  containerEmpty: {
    cursor: 'default',
    minHeight: space.default,
  },
  loadingStatusText: position.cover,
  downloadButton: {
    ...position.cover,
    ...signatureHoverTransition,
    width: '100%',
    willChange: 'opacity',
  },
  downloadButtonIcon: {
    marginRight: space.quarter,
  },
  signatureImage: {
    ...signatureHoverTransition,
    width: '100%',
    willChange: 'opacity',
  },
});

export default class SignatureDownloadWidget extends Component {
  static propTypes = {
    signatureURL: PropTypes.string,
  };

  state = {
    isError: false,
    isHover: false,
    isLoading: true,
  };

  handleImageError = () => this.setState({ isError: false });
  handleImageLoad = () => this.setState({ isLoading: false });
  handleMouseEnter = () => this.setState({ isHover: true });
  handleMouseLeave = () => this.setState({ isHover: false });

  calcImageOpacity = () => {
    const { isError, isHover, isLoading } = this.state;

    let opacity = 1;
    if (isLoading || isError) opacity = 0;
    else if (isHover) opacity = 0.1;

    return { opacity };
  };

  renderLoadingStatus = () => {
    const { isError, isLoading } = this.state;

    return isError || isLoading ? (
      <Row align="center" styles={sx.loadingStatusText}>
        {`${isError ? 'Error loading' : 'Loading'} signature`}
      </Row>
    ) : null;
  };

  render = () =>
    this.props.signatureURL ? (
      <Row
        align="center"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        styles={sx.container}
      >
        {this.renderLoadingStatus()}
        <ImageLoader
          className="fs-hide"
          onError={this.handleImageError}
          onLoad={this.handleImageLoad}
          src={this.props.signatureURL}
          style={this.calcImageOpacity()}
          styles={sx.signatureImage}
        />
        {!this.state.isLoading && (
          <FlexCentered
            component={A}
            download
            href={this.props.signatureURL}
            style={{ opacity: this.state.isHover ? 1 : 0 }}
            styles={sx.downloadButton}
            title="Download signature"
          >
            <Icon name="download" styles={sx.downloadButtonIcon} />
            <Div>Download</Div>
          </FlexCentered>
        )}
      </Row>
    ) : (
      <Div styles={[sx.container, sx.containerEmpty]}>No signature collected.</Div>
    );
}
