import { withSession } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps } from 'recompose';
import fetchOnceOptions from './utils/fetchOnceOptions';
import withPharmacyId from './withPharmacyId';
import withSelectedPatientId from './withSelectedPatientId';
import GET_PATIENT_CLAIMS from '../patients/rx/grapql/patientClaimsWithZipdrugActive';

const withSelectedPatientClaims = Component =>
  graphql(GET_PATIENT_CLAIMS, {
    skip: ({ selectedPatientId, userId }) => !userId || !selectedPatientId,
    options: ({ selectedPatientId }) =>
      fetchOnceOptions('selectedPatientClaims', {
        variables: {
          id: selectedPatientId,
        },
        networkOnly: true,
      }),
    props: ({ data: { error, loading: isLoading, patients: result } }) => ({
      error,
      isLoading,
      selectedPatientClaims: get(result, 'data[0].claims'),
    }),
  })(Component);

export default Component =>
  compose(
    withSession,
    withPharmacyId,
    withSelectedPatientId,
    withSelectedPatientClaims,
    mapProps(({ selectedPatientId, ...props }) => ({
      selectedPatientId,
      ...props,
    })),
  )(Component);
