import gql from 'graphql-tag';

const UPDATE_PHARMACY_INFO = gql`
  mutation updatePharmacyInfo($pharmacyId: Int!, $modifiers: PharmacyModifiers) {
    updatePharmacyInfo(pharmacyId: $pharmacyId, modifiers: $modifiers) {
      data {
        id
        address {
          id
          line1
          line2
          city
          state
          postal_code
        }
        name
        hours
        status
        skip_number
        associate_service_coordinator {
          id
          first_name
          last_name
        }
        languages
        zipdrug_active_comment
        phones {
          id
          number
          label
        }
        is_commercial_only
        npi
        pharmacy_networks {
          id
          pharmacy_id
          network_type {
            id
            name
            lob
            lob_order
            pharmacy_network_type_order
            order_tie_breaker_rule
          }
        }
        pharmacy_services {
          id
          pharmacy_service_type_id
          pharmacy_id
        }
      }
    }
  }
`;

export default UPDATE_PHARMACY_INFO;
