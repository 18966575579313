const getPlacePredictions = async addressQuery => {
  const autocomplete = new window.google.maps.places.AutocompleteService();
  const OK = window.google.maps.places.PlacesServiceStatus.OK;

  return new Promise((resolve, reject) => {
    autocomplete.getPlacePredictions(
      {
        input: addressQuery,
        componentRestrictions: { country: ['us'] },
        types: ['address'],
      },
      (predictions, status) => {
        if (status !== OK) {
          reject(status);
        }
        resolve(predictions);
      },
    );
  });
};

const getPlaceDetails = async placeId => {
  const geocoder = new window.google.maps.Geocoder();
  const OK = window.google.maps.GeocoderStatus.OK;

  return new Promise((resolve, reject) => {
    geocoder.geocode({ placeId }, (results, status) => {
      if (status !== OK) {
        reject(status);
      }
      resolve(results);
    });
  });
};

const cleanAddress = addressComponents => {
  return addressComponents.reduce(
    (acc, curr) => {
      const componentType = curr.types[0];

      // eslint-disable-next-line default-case
      switch (componentType) {
        case 'street_number': {
          acc.line1 = `${curr.long_name} ${acc.line1 || ''}`;
          break;
        }
        case 'route': {
          acc.line1 = `${acc?.line1 || ''}${curr.short_name}`;
          break;
        }
        case 'postal_code': {
          acc.postal_code = `${curr.long_name}${acc.postal_code || ''}`;
          break;
        }
        case 'postal_code_suffix': {
          acc.postal_code = `${acc.postal_code}-${curr.long_name}`;
          break;
        }
        case 'locality':
          acc.city = curr.long_name;
          break;

        case 'administrative_area_level_1': {
          acc.state = curr.short_name;
          break;
        }
        // case 'country':
        //   acc.country = curr.long_name;
        //   break;
      }

      return acc;
    },
    { line2: '' },
  );
};

export { getPlacePredictions, getPlaceDetails, cleanAddress };
