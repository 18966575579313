import { phoneField, requiredField, validateForm } from '@zipdrug/ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import withPhoneMutation from '../../hoc/withPhoneMutation';
import withPharmacyId from '../../hoc/withPharmacyId';
import PhoneListItemForm from './PhoneListItemForm';

const ERROR_MESSAGES = {
  invalidPhone: 'is invalid.',
  required: 'is required.',
};

const PHONE_FORM_SCHEMA = {
  label: requiredField,
  number: {
    ...phoneField,
    required: true,
  },
};

const validate = values => validateForm(values, PHONE_FORM_SCHEMA, ERROR_MESSAGES);
const ReduxPhoneListItemForm = reduxForm({
  validate,
})(PhoneListItemForm);

class PhoneListItemFormContainer extends Component {
  static propTypes = {
    isEditing: PropTypes.bool,
    item: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    mutateCreatePhone: PropTypes.func,
    mutateUpdatePhone: PropTypes.func,
    onClickCancel: PropTypes.func.isRequired,
  };

  handleCreatePhone = async values =>
    this.props.mutateCreatePhone(values).then(this.props.onClickCancel);

  handleSubmit = values =>
    this.props.item ? this.handleUpdatePhone({ ...values }) : this.handleCreatePhone({ ...values });

  handleUpdatePhone = async ({ extension, label, number }) =>
    this.props
      .mutateUpdatePhone({
        id: this.props.item.id,
        modifier: { extension, label, number },
      })
      .then(this.props.onClickCancel);

  render = () => (
    <ReduxPhoneListItemForm
      form={`phone[${get(this.props, 'item.id', 'new')}]`}
      initialValues={this.props.item}
      isEditing={this.props.isEditing}
      onClickCancel={this.props.onClickCancel}
      onSubmit={this.handleSubmit}
    />
  );
}

export default compose(withPhoneMutation, withPharmacyId)(PhoneListItemFormContainer);
