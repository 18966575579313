import { connect } from 'react-redux';
import { compose } from 'recompose';
import withRoles from './withRoles';

const mapStateToProps = (state, ownProps) => ({
  pharmacy_id:
    !ownProps.hasRole.pharmacistUser && !ownProps.hasRole.pharmacistAdmin
      ? null
      : ownProps.currentUser.roles[0]?.pharmacy_id,
});

export default Component => compose(withRoles, connect(mapStateToProps))(Component);
