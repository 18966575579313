import gql from 'graphql-tag';

const addressFields = `
city
id
label
lat
line1
line2
lon
note
order
postal_code
state`;

const phoneFields = `
extension
can_sms
id
label
number
order`;

const pharmacyFields = `
address {
  ${addressFields}
}
id
name
pharmacy_services {
  pharmacy_service_type {
    pharmacy_service_type
  }
}
npi
status
skip_number
created_at
phones {
  ${phoneFields}
}`;

const patientQueryParametersDefinitions = `
  $alert_type: [String],
  $assigned_pharmacy_id: [Int],
  $enroll_status: [String],
  $id: [Int],
  $language: [String],
  $limit: Int,
  $no_snoozed: Boolean,
  $only_snoozed: Boolean,
  $pdc: [PDCSelector],
  $plan_id: [Int],
  $search: String,
  $skip: Int,
  $sort_by: String,
`;

const patientQueryParametersToVariables = `
  alert_type: $alert_type,
  assigned_pharmacy_id: $assigned_pharmacy_id,
  enroll_status: $enroll_status,
  id: $id,
  language: $language,
  limit: $limit,
  no_snoozed: $no_snoozed,
  only_snoozed: $only_snoozed,
  pdc: $pdc,
  plan_id: $plan_id,
  search: $search,
  skip: $skip,
  sort_by: $sort_by,
`;

const buildPatientQuery = responseFields => gql`
  query patients(${patientQueryParametersDefinitions}) {
    patients(${patientQueryParametersToVariables}) {
      data {
       ${responseFields}
      }
      limit
      skip
      total
    }
  }
`;

export const addNewEmail = gql`
  mutation addEmail($patientId: Int!, $modifiers: EmailModifiers!) {
    addEmail(patientId: $patientId, modifiers: $modifiers) {
      data {
        id
        email
      }
    }
  }
`;

export const updateNewEmail = gql`
  mutation updateEmail($id: Int!, $modifiers: EmailModifiers!) {
    updateEmail(id: $id, modifiers: $modifiers) {
      data {
        id
        email
      }
    }
  }
`;

export const deleteEmail = gql`
  mutation deleteEmail($id: Int!) {
    deleteEmail(id: $id) {
      data
    }
  }
`;

export const queryPatientsBasic = buildPatientQuery(`
  active_at
  birthday
  enroll_status
  first_name
  id
  is_dnc
  language
  last_name
  latest_alert_at
  snoozed_until
  event_source
  special_notes
  dismiss_requires_followup
`);

// eslint-disable-next-line import/prefer-default-export
export const queryPatientAndPharmacy = buildPatientQuery(`
  active_at
  addresses {
    ${addressFields}
  }
  anthem_mcid
  assigned_pharmacy {
    ${pharmacyFields}
  }
  assigned_pharmacy_id
  birthday
  call_status
  first_name
  emails {
    id
    email
  }
  enroll_status
  enroll_status_reasons
  event_source
  id
  is_dnc
  language
  last_name
  maintenance_count
  memberships {
    member_id
    plan_id
    created_at
  }
  payer_id
  payer {
    id
    name
  }
  phones {
    ${phoneFields}
  }
  plans {
    id
    bin
    description
    group_id
    is_primary
    member_id
    plan_type
    name
    phones {
      ${phoneFields}
    }
    pcn
  }
  preferred_followup
  preferred_phone_id
  preferred_email_id
  program_eligibility
  special_notes
  dismiss_requires_followup
  sex
  snoozed_until
  guardian_name
  guardian_phone_id
  lead_churn {
    id
    assigned_user_id
    churned_outreach_date
  }
`);

export const checkPatientZDEEligibility = gql`
  query checkPatientZDEEligibility($patientState: String!) {
    checkPatientZDEEligibility(patientState: $patientState) {
      data
    }
  }
`;

export const checkPatientMinorEligibility = gql`
  query checkPatientMinorEligibility($patient_id: Int!, $plan_type: String, $state: String) {
    checkPatientMinorEligibility(patient_id: $patient_id, plan_type: $plan_type, state: $state) {
      data
    }
  }
`;
