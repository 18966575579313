import { withSession } from '@zipdrug/react-api-sdk';
import { get, uniq } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps, withState } from 'recompose';
import pharmacyTypes from 'dashboard/components/LeadList/constants/pharmacyServiceTypes';
import { GET_PHARMACY_DATA } from 'dashboard/components/PharmacyList/graphql/getPharmacyList';
import withRoles from './withRoles';
import withSelectedPatientClaims from './withSelectedPatientClaims';
import withSelectedPatientInfo from './withSelectedPatientInfo';
import withPharmacyServiceTypes from './withPharmacyServiceTypes';
import withPharmacyServices from './withPharmacyServices';
import withZdeEligibility from './withZdeEligibility';
import fetchOnceOptions from './utils/fetchOnceOptions';

const getPharmaciesById = (
  pharmsFromClaims,
  pharmsFromCoverage,
  pharmacyServices,
  ids,
  defaultMatch,
) => {
  if (pharmsFromClaims) {
    const pharmsFromClaimsById = pharmsFromClaims?.filter(p => {
      return ids.every(s => {
        const pharmTypeIds = p.pharmacy_services?.map(ph => ph.pharmacy_service_type_id);
        return pharmTypeIds?.includes(s);
      });
    });
    if (pharmsFromClaimsById?.length > 0) {
      return {
        baseQuery: false,
        pharmacies: pharmsFromClaimsById,
      };
    }
  }
  if (pharmsFromCoverage) {
    const pharmsFromCoverageById = pharmsFromCoverage?.filter(p => {
      const filteredPharmServices = pharmacyServices?.filter(s => s.pharmacy_id === p.id);
      const filteredPharmServiceTypes = filteredPharmServices?.map(s => s.pharmacy_service_type_id);
      return ids.every(s => filteredPharmServiceTypes?.includes(s));
    });
    if (pharmsFromCoverageById?.length > 0) {
      return {
        baseQuery: false,
        pharmacies: pharmsFromCoverageById,
      };
    }
  }
  return {
    baseQuery: true,
    pharmacies: defaultMatch,
  };
};

const withZipdrugEnhancedMatching = Component =>
  graphql(GET_PHARMACY_DATA, {
    skip: ({ userId, patientNeedsZDEMatching, hasRole: { pharmacistUser, pharmacistAdmin } }) =>
      !userId || (pharmacistUser && pharmacistAdmin) || !patientNeedsZDEMatching,
    options: ({ pharmIdsFromClaims }) =>
      fetchOnceOptions('postalCodes', {
        variables: {
          query: {
            id: pharmIdsFromClaims,
            status: 'active',
          },
        },
        networkOnly: true,
      }),
    props: ({ data: { pharmacies: result } }) => ({
      pharmsFromClaims: get(result, 'data', []),
    }),
  })(Component);

export default Component =>
  compose(
    withSession,
    withRoles,
    withSelectedPatientInfo,
    withSelectedPatientClaims,
    withZdeEligibility,
    withState('selectedPatientClaimsData', 'setSelectedPatientClaimsData', null),
    mapProps(props => {
      const {
        selectedPatientClaims,
        pharmaciesForPostalCodes,
        isPatientEligibleForZDE,
        setSelectedPatientClaimsData,
        selectedPatientClaimsData,
      } = props;

      if (
        selectedPatientClaims?.length > 0 &&
        selectedPatientClaims?.length !== selectedPatientClaimsData?.length
      ) {
        setSelectedPatientClaimsData(selectedPatientClaims);
      }

      const isMedicaidPatient = props.pharmacyNetworkTypes?.lob?.includes('MCAID');
      const anthemPatient = props.payer_id === 2;

      let pharmIdsFromClaims = [];
      let pharmacyIdsForServices = [];
      const patientNeedsZDEMatching = isPatientEligibleForZDE && isMedicaidPatient && anthemPatient;

      if (patientNeedsZDEMatching) {
        pharmIdsFromClaims = uniq(selectedPatientClaimsData?.map(s => s.pharmacy?.id));
        pharmacyIdsForServices = uniq(pharmaciesForPostalCodes?.map(p => p.id));
      }

      return {
        ...props,
        pharmIdsFromClaims,
        patientNeedsZDEMatching,
        pharmacyIdsForServices,
      };
    }),
    withSelectedPatientInfo,
    withZipdrugEnhancedMatching,
    mapProps(({ pharmsFromClaims, ...props }) => {
      return {
        ...props,
        pharmsFromClaims,
      };
    }),
    withPharmacyServiceTypes,
    withPharmacyServices,
    mapProps(
      ({
        pharmaciesForPostalCodes,
        pharmsFromClaims,
        serviceTypes,
        patientNeedsZDEMatching,
        pharmacyServices,
        selectedPatient,
        ...props
      }) => {
        let matchedPharmacies = pharmaciesForPostalCodes;
        if (patientNeedsZDEMatching && serviceTypes && (pharmsFromClaims || pharmacyServices)) {
          const serviceTypeIds = serviceTypes?.map(s => s.id);
          const cpesnTypeId = serviceTypes
            ?.filter(s => s.pharmacy_service_type === pharmacyTypes.cpesn)
            .map(s => s.id);
          const zdTypeID = serviceTypes
            ?.filter(s => s.pharmacy_service_type === pharmacyTypes.zipdrug)
            .map(s => s.id);
          const { baseQuery, pharmacies: zdePharmsFromClaims } = getPharmaciesById(
            pharmsFromClaims,
            pharmaciesForPostalCodes,
            pharmacyServices,
            serviceTypeIds,
            matchedPharmacies,
          );

          if (!baseQuery) {
            matchedPharmacies = zdePharmsFromClaims;
          } else {
            let isBaseQuery = true;
            if (
              selectedPatient.program_eligibility === 'cpesn_eligible' ||
              selectedPatient.program_eligibility === 'zipdrug_and_cpesn_eligible'
            ) {
              const { baseQuery, pharmacies: cpesnPharmacies } = getPharmaciesById(
                pharmsFromClaims,
                pharmaciesForPostalCodes,
                pharmacyServices,
                cpesnTypeId,
                matchedPharmacies,
              );

              matchedPharmacies = cpesnPharmacies;
              isBaseQuery = baseQuery;

              if (isBaseQuery && selectedPatient.program_eligibility === 'cpesn_eligible') {
                matchedPharmacies = [];
              }
            }

            if (
              isBaseQuery &&
              (selectedPatient.program_eligibility === 'zipdrug_eligible' ||
                selectedPatient.program_eligibility === 'zipdrug_and_cpesn_eligible')
            ) {
              const { pharmacies: zdPharmacies } = getPharmaciesById(
                pharmsFromClaims,
                pharmaciesForPostalCodes,
                pharmacyServices,
                zdTypeID,
                matchedPharmacies,
              );

              matchedPharmacies = zdPharmacies;
            }
          }
        }

        return {
          matchedPharmacies,
          pharmaciesForPostalCodes,
          pharmsFromClaims,
          serviceTypes,
          patientNeedsZDEMatching,
          pharmacyServices,
          selectedPatient,
          ...props,
        };
      },
    ),
  )(Component);
