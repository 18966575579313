import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_CAREGAPS = gql`
  query getCareGaps($id: Int!, $limit: Int, $skip: Int) {
    getCareGaps(patient_id: $id, limit: $limit, skip: $skip) {
      data {
        id
        message_id
        rule_id
        provider_message_headline
        provider_message
        care_gap_outcome {
          id
          care_gap_id
        }
      }
    }
  }
`;

export const RESOLVE_CAREGAP = gql`
  mutation resolveCareGap(
    $careGapId: Int!
    $messageId: String!
    $outcomeMsgRuleId: String!
    $patientId: Int!
    $modifiers: CareGapOutcomeModifier
  ) {
    resolveCareGap(
      careGapId: $careGapId
      messageId: $messageId
      outcomeMsgRuleId: $outcomeMsgRuleId
      patientId: $patientId
      modifiers: $modifiers
    ) {
      data {
        id
      }
    }
  }
`;
