import gql from 'graphql-tag';

const UPDATE_ENROLLER = gql`
  mutation updateEnroller(
    $id: Int!
    $language: [LanguageEnum!]
    $status: StatusEnum
    $line_of_business: [LineOfBusinessEnum]
    $team_lead_user_id: Int
    $state: [StateEnum!]
    $payer_id: [Int!]
  ) {
    updateEnroller(
      id: $id
      language: $language
      status: $status
      line_of_business: $line_of_business
      team_lead_user_id: $team_lead_user_id
      state: $state
      payer_id: $payer_id
    ) {
      data {
        id
        line_of_business
        status
        payer_id
        state
        user {
          id
          first_name
          last_name
          title
          user_languages {
            language
          }
        }
        team_lead_user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export default UPDATE_ENROLLER;
