import moment from 'moment';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { font, space, Span } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 10,
  },
});

export default class LetterActivityItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
  };

  renderHeading = () => <Span> A new letter was sent.</Span>;

  render = () => {
    const { activity, ...props } = this.props;
    return (
      <SystemEventActivityItem
        {...props}
        content={`Enrollment letter was sent with an expected delivery date of ${moment(
          activity.letter.expected_delivery_date,
        ).format('dddd, MMMM Do YYYY, h:mm A')}`}
        contentStyles={sx.content}
        createdAt={activity.created_at}
        footerName={activity.letter.created_by_user}
        footerStyles={sx.footer}
        headerStyles={sx.header}
        heading={this.renderHeading()}
        icon="paperPlane"
      />
    );
  };
}
