import gql from 'graphql-tag';

const GET_ENROLLERS_LIST = gql`
  query enrollers($limit: Int, $order_by: EnrollerOrderByInput, $query: EnrollerQuery, $skip: Int) {
    enrollers(limit: $limit, order_by: $order_by, query: $query, skip: $skip) {
      data {
        id
        status
        line_of_business
        state
        payer_id
        user {
          first_name
          last_name
          id
          title
          user_languages {
            language
          }
        }
        team_lead_user {
          first_name
          last_name
          id
          enroller_id
        }
      }
      total
      limit
      skip
    }
  }
`;

export default GET_ENROLLERS_LIST;
