import { get, orderBy } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withSelectedPatientInfo from './withSelectedPatientInfo';
import { checkPatientZDEEligibility } from '../graphql/patients';

const withZdeEligibility = Component =>
  graphql(checkPatientZDEEligibility, {
    skip: ({ selectedPatient }) => !selectedPatient,
    options: ({ selectedPatient }) => {
      const primaryAddress = orderBy(selectedPatient?.addresses, ['order'], ['asc'])[0];
      return {
        fetchPolicy: 'no-cache',
        variables: {
          patientState: primaryAddress?.state,
        },
      };
    },
    props: ({ data: { checkPatientZDEEligibility: result } }) => ({
      isPatientEligibleForZDE: get(result, 'data'),
    }),
  })(Component);

export default Component => compose(withSelectedPatientInfo, withZdeEligibility)(Component);
