import {
  A,
  ArrowButton,
  breakpoint,
  colors,
  Column,
  Div,
  Flex,
  font,
  FormCheckboxField,
  FormPasswordField,
  FormTextField,
  Label,
  margin,
  media,
  padding,
  Row,
  space,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const TERMS_OF_USE_URL = '//www.zipdrug.com/pdfs/Terms_of_Use_Oct_2016.pdf';

const sx = StyleSheet.create({
  arrowButton: {
    marginTop: space.half,
  },
  checkboxLabel: {
    marginLeft: font.size.small,
  },
  checkboxRow: margin.build(space.get(1.5), 0, space.default),
  container: padding.build(space.default, 0, space.two),
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  errorMessage: {
    color: colors.red,
    marginBottom: space.half,
  },
  formField: {
    flex: 1,
    marginBottom: space.default,

    ':only-of-type': {
      marginBottom: space.half,
    },

    [media.desktop.small]: {
      marginRight: font.size.xSmall,
      ':last-child': { marginRight: 0 },
      ':only-of-type': { marginRight: 0 },
    },
  },
  link: {
    textDecoration: 'underline',
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const isMobile = () => window.innerWidth > breakpoint.desktop.small;

const RegisterForm = ({ anyTouched, error, handleSubmit, invalid }) => (
  <Div styles={sx.container}>
    <Flex direction={isMobile() ? 'row' : 'column'}>
      <FormTextField
        {...FORMFIELD_PROPS}
        autoComplete="given-name"
        label="First name"
        name="first_name"
        placeholder="Jane"
      />
      <FormTextField
        {...FORMFIELD_PROPS}
        autoComplete="family-name"
        label="Last name"
        name="last_name"
        placeholder="Smith"
      />
    </Flex>
    <Column>
      <FormPasswordField
        {...FORMFIELD_PROPS}
        autoComplete="new-password"
        label="Password"
        name="password"
        placeholder="••••••••"
      />
      <Div styles={sx.description}>
        Your password must be at least 15 characters long, including at least one capital letter,
        one number, one lowercase letter, and one special character ($ ! @ * ? |). It cannot contain
        spaces or 3 consecutive characters or numbers.
      </Div>
    </Column>
    <Row align="center" component={Label} styles={sx.checkboxRow}>
      <FormCheckboxField name="agreeToTerms" preserveDimensions={false} />
      <Div styles={sx.checkboxLabel}>
        I agree to the Zipdrug&nbsp;
        <A href={TERMS_OF_USE_URL} styles={sx.link} target="_blank" rel="noopener">
          Terms of Use
        </A>
      </Div>
    </Row>
    {anyTouched && error && invalid && <Row styles={sx.errorMessage}>{error}</Row>}
    <Row>
      <ArrowButton onClick={handleSubmit} styles={sx.arrowButton} text="Go to my dashboard" />
    </Row>
  </Div>
);

RegisterForm.propTypes = {
  anyTouched: PropTypes.bool,
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  invalid: PropTypes.bool,
};

export default RegisterForm;
