import {
  animation,
  Button,
  colors,
  Column,
  Div,
  FlexCentered,
  font,
  FormAddressField,
  FormButtonGroupField,
  FormTextareaField,
  FormTextField,
  Icon,
  ms,
  padding,
  Row,
  space,
  CheckboxItem,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DropdownHeader from '../../../ui/DropdownHeader';

const CONTAINER_ANIMATION = {
  from: { transform: `translateX(${space.half})` },
  to: { transform: 'translateX(0)' },
};

const CUSTOM_LABEL_FIELD_ANIMATION = {
  '0%': { transform: 'translateY(2px)' },
  '100%': { transform: 'translateY(0)' },
};

const sx = StyleSheet.create({
  button: {
    flex: 1,
    margin: '0 auto',
    textAlign: 'center',
    ':first-of-type': {
      marginRight: space.half,
    },
  },
  buttonGroupLabel: {
    display: 'none',
  },
  container: {
    ...padding.build(font.size.small),
    animationDuration: ms(200),
    animationFillMode: 'forwards',
    animationName: [CONTAINER_ANIMATION, animation.fadeIn],
    overflowX: 'hidden',
    width: '28rem',
  },
  customLabelField: {
    animationDelay: [ms(150), ms(100)],
    animationDuration: [ms(200), ms(150)],
    animationFillMode: 'forwards',
    animationName: [CUSTOM_LABEL_FIELD_ANIMATION, animation.fadeIn],
    animationTimingFunction: 'ease-in',
    opacity: 0,
    willChange: 'opacity, transform',
  },
  deleteButton: {
    backgroundColor: colors.white,
    border: 'none',
    color: colors.red,
    flex: 1,
    marginTop: space.half,
    ':hover': {
      backgroundColor: colors.gray97,
      color: colors.red,
    },
    ':active': {
      color: colors.darkRed,
    },
  },
  deleteButtonIcon: {
    marginRight: space.quarter,
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
  },
  textareaField: {
    maxHeight: '20rem',
  },
  textareaInput: {
    lineHeight: font.lineHeight.loose,
  },
});

const FORMFIELD_PROPS = {
  inputStyles: sx.formFieldInput,
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const AddressForm = ({
  address,
  animateCustomLabel,
  handleSubmit,
  labelOptions,
  onClickCancel,
  onClickDelete,
  onDefaultAddress,
  onPlaceSelected,
  showCustomLabel,
  styles,
}) => {
  const [isDefaultAddress, setIsDefaultAddress] = useState(address.order === 0);

  const ToogleClickDefaultAddress = () => {
    onDefaultAddress(!isDefaultAddress);
    setIsDefaultAddress(!isDefaultAddress);
  };

  return (
    <Column
      id="e2e-addressForm"
      style={{ paddingBottom: Object.keys(address).length ? space.half : font.size.small }}
      styles={[sx.container, styles]}
    >
      <DropdownHeader
        onClickCancel={onClickCancel}
        title={`${Object.keys(address).length ? 'Edit' : 'Add'} address`}
      />
      <Column>
        <FormButtonGroupField
          {...FORMFIELD_PROPS}
          items={labelOptions}
          label="Label"
          labelStyles={sx.buttonGroupLabel}
          name="label"
          type="primary"
        />
        <CheckboxItem
          {...FORMFIELD_PROPS}
          onChange={() => ToogleClickDefaultAddress()}
          label={'Set as a default address'}
          name="defaultCheck"
          checked={isDefaultAddress}
        />
        {showCustomLabel && (
          <FormTextField
            autoFocus
            {...FORMFIELD_PROPS}
            label="Custom label"
            name="customLabel"
            placeholder="Other"
            styles={[sx.formField, animateCustomLabel ? sx.customLabelField : null]}
          />
        )}
        <FormAddressField
          {...FORMFIELD_PROPS}
          autoFocus={!showCustomLabel}
          className="fs-hide"
          id="e2e-addressFormStreetAddressForm"
          label="Street address"
          name="address"
          onPlaceSelected={onPlaceSelected}
          placeholder="123 ABC Street"
        />
        <FormTextField
          {...FORMFIELD_PROPS}
          className="fs-hide"
          id="e2e-addressFormAptForm"
          label="Apt/Suite"
          name="line2"
          optional
          placeholder="2B"
        />
        <FormTextareaField
          {...FORMFIELD_PROPS}
          id="e2e-addressFormDeliveryForm"
          inputStyles={sx.textareaInput}
          label="Delivery notes"
          maxRows={12}
          minRows={6}
          name="note"
          optional
          placeholder="Leave with doorman"
          resize="vertical"
          styles={[sx.formField, sx.textareaField]}
        />
      </Column>
      <Row>
        <Button
          focusable
          onClick={handleSubmit}
          size="large"
          styles={sx.button}
          text="Save"
          type="primary"
        />
        <Button
          focusable
          onClick={onClickCancel}
          outline
          size="large"
          styles={sx.button}
          text="Cancel"
          type="secondary"
        />
      </Row>
      {!!Object.keys(address).length && (
        <Row>
          <Button
            focusable
            id="e2e-addressFormDeleteButton"
            onClick={onClickDelete}
            size="large"
            styles={sx.deleteButton}
          >
            <FlexCentered style={{ width: '100%' }}>
              <Icon name="trash" styles={sx.deleteButtonIcon} />
              <Div>Delete</Div>
            </FlexCentered>
          </Button>
        </Row>
      )}
    </Column>
  );
};

AddressForm.propTypes = {
  address: PropTypes.object,
  animateCustomLabel: PropTypes.bool,
  handleSubmit: PropTypes.func,
  labelOptions: PropTypes.arrayOf(PropTypes.string),
  onClickCancel: PropTypes.func,
  onClickDelete: PropTypes.func,
  onPlaceSelected: PropTypes.func,
  showCustomLabel: PropTypes.bool,
  onDefaultAddress: PropTypes.func,
  styles: aphroditePropType,
};

export default AddressForm;
