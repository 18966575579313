import { StyleSheet } from 'aphrodite/no-important';
import { groupBy, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Column,
  Div,
  FlexItem,
  Row,
  border,
  colors,
  font,
  margin,
  padding,
  position,
  space,
  transition,
} from '@zipdrug/ui';
import InsuranceCard from './InsuranceCard';

const sx = StyleSheet.create({
  container: {
    ...padding.build(space.default, font.size.small),
    width: '29rem',
  },
  section: {
    marginBottom: space.default,
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  sectionTitle: {
    cursor: 'default',
    fontWeight: font.weight.bold,
    marginBottom: space.half,
    userSelect: 'none',
  },
  metaItem: {
    ...margin.build(space.quarter, 0),
    ':first-of-type': { marginTop: 0 },
    ':last-of-type': { marginBottom: 0 },
  },
  metaTitle: {
    cursor: 'default',
    fontWeight: font.weight.bold,
    marginRight: space.half,
    userSelect: 'none',
    width: '6.5rem',
  },
  meta: {
    fontSize: font.size.xSmall,
    marginTop: space.half,
  },
  mcidContainer: {
    ...border.build(),
    ...padding.build(font.size.small),
    borderRadius: space.half,
    boxShadow: `0 0 2px ${colors.alpha(colors.black, 0.08)}`,
    marginBottom: font.size.small,
    ':before': {
      ...position.cover,
      ...transition.build(),
      borderRadius: space.half,
      boxShadow: `0 0 6px ${colors.alpha(colors.black, 0.1)}`,
      content: '" "',
      opacity: 0,
    },
    ':hover': {
      ':before': {
        opacity: 1,
      },
    },
    ':last-of-type': {
      marginBottom: 0,
    },
  },
});

const filterByPrimary = ({ is_primary }) => (is_primary ? 'primary' : 'secondary');

const AnthemMcidCard = ({ label, value }) => (
  <Row styles={sx.mcidContainer}>
    <Column component={FlexItem}>
      <Div styles={sx.meta}>
        <Row align="center" styles={sx.metaItem}>
          <Div styles={sx.metaTitle}>{label}</Div>
          <Div className="fs-hide">{value}</Div>
        </Row>
      </Div>
    </Column>
  </Row>
);

AnthemMcidCard.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default class InsuranceMenu extends Component {
  static propTypes = {
    patient: PropTypes.object,
  };

  componentWillMount = () => this.buildPlanList();
  componentWillReceiveProps = ({ patient: { plans } }) => this.buildPlanList(plans);

  sortedPlans = {};

  buildPlanList = plans => {
    this.sortedPlans = groupBy(plans || this.props.patient?.plans, filterByPrimary);
  };

  renderInsuranceCards = planType =>
    this.sortedPlans[planType].map(plan => <InsuranceCard {...plan} key={plan.description} />);

  renderSections = () =>
    Object.keys(this.sortedPlans).map(planType => (
      <Column key={planType} styles={sx.section}>
        <Div styles={sx.sectionTitle}>{upperFirst(planType)}</Div>
        {this.renderInsuranceCards(planType)}
      </Column>
    ));

  render = () => {
    return (
      <>
        <Div styles={sx.container}>{this.renderSections()}</Div>
        {this.props.patient?.anthem_mcid && (
          <Div styles={sx.container}>
            <Column key="anthem-mcid" styles={sx.section}>
              <Div styles={sx.sectionTitle}>Anthem MCID</Div>
              <AnthemMcidCard label="MCID" value={this.props.patient?.anthem_mcid} />
            </Column>
          </Div>
        )}
      </>
    );
  };
}
