import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

const sx = StyleSheet.create({
  errorContainer: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '24px',
  },
  errorMessage: {
    cursor: 'pointer',
    color: '#0077FF',
    paddingLeft: '5px',
    outline: 'none',
  },
});

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    if (window.FS) {
      window.FS.log('error', `${error}: ${errorInfo.componentStack}`);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={css(sx.errorContainer)}>
          Whoops, something went wrong.
          <span
            role="button"
            tabIndex={0}
            className={css(sx.errorMessage)}
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload the page
          </span>
          .
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
