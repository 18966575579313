import gql from 'graphql-tag';

const REASSIGN_DSA = gql`
  mutation updateLeads($ids: [Int]!, $modifiers: LeadListModifiers!) {
    updateLeads(ids: $ids, modifiers: $modifiers) {
      data {
        member_id
        assigned_dsa_user_id
        id
      }
    }
  }
`;

export default REASSIGN_DSA;
