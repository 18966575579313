import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Input, message, Modal, Row, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { useMutation } from '@apollo/react-hooks';
import { addNewEmail, deleteEmail, updateNewEmail } from '../../../graphql/patients';
import DeleteConfirmationModal from './DeleteConfirmationModal';

// CSS styles
const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '583px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
});

const { Title } = Typography;
const AddEmailDropdownModal = ({
  onCloseModal, // use this to close the model and refresh the states.
  patient,
  visible,
  refetchSelectedPatientInfo,
}) => {
  const [form] = Form.useForm();
  const [isDeleteVisible, setIsDeleteModalVisible] = useState(false);
  const [emailInput, setEmailInput] = useState('');

  const [addEmail] = useMutation(addNewEmail, {
    onError: error => {
      message.error(`Error Adding Email Address: ${error.message}`);
    },
    onCompleted: ({ addEmail }) => {
      message.success(`Email has be successfully added`);
      patient.emails.push(addEmail.data);
      form.setFieldsValue({ email: addEmail.data.email });
      onCloseModal();
    },
  });

  const [updateEmail] = useMutation(updateNewEmail, {
    onError: error => {
      message.error(`Error Updating Email Address: ${error.message}`);
    },
    onCompleted: ({ updateEmail }) => {
      message.success(`Email has been successfully updated`);
      patient.emails[0] = updateEmail.data;
      form.setFieldsValue({ email: updateEmail.data.email });
      onCloseModal();
    },
  });

  const [deleteCurrentEmail] = useMutation(deleteEmail, {
    onError: error => {
      message.error(`Error Deleting Email Address: ${error.message}`);
    },
    onCompleted: () => {
      message.success(`Email has been successfully deleted`);
      patient.emails = [];
      form.resetFields();
      onCloseModal();
      refetchSelectedPatientInfo();
    },
  });

  const onCancel = () => {
    form.resetFields();
    onCloseModal();
  };

  const toggleDeleteEmail = () => {
    onCloseModal();
    setIsDeleteModalVisible(!isDeleteVisible);
  };

  const closeDeleteModal = () => {
    deleteCurrentEmail({
      variables: {
        id: patient?.emails[0]?.id,
      },
    });
    patient.emails[0].email = '';
    onCloseModal();
  };

  const submitForm = () => {
    const newEmail = form.getFieldValue('email');
    const oldEmail = patient?.emails[0]?.email;
    if (emailInput === oldEmail) {
      onCloseModal();
    } else if (patient?.emails[0]?.id) {
      form
        .validateFields()
        .then(() => {
          updateEmail({
            variables: {
              id: patient.emails[0].id,
              modifiers: {
                email: newEmail,
              },
            },
          });
        })
        .catch(info => {
          return info;
        });
    } else {
      form
        .validateFields()
        .then(() => {
          addEmail({
            variables: {
              patientId: patient.id,
              modifiers: {
                email: newEmail,
              },
            },
          });
        })
        .catch(info => {
          return info;
        });
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        onCloseModal={toggleDeleteEmail}
        patient={patient}
        closeDeleteModal={closeDeleteModal}
        visible={isDeleteVisible}
      />
      <Modal
        className={css(sx.modalContainer)}
        footer={[
          <Button key="cancel" onClick={onCancel} className={css(sx.button)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => submitForm()}
            className={css(sx.button)}
          >
            {patient?.emails[0]?.email ? 'Save' : 'Add Email'}
          </Button>,
        ]}
        getContainer={false}
        okText="Save"
        onOk={submitForm}
        onCancel={onCancel}
        open={visible}
      >
        <Title className="modelHeaderText" level={3}>
          {patient?.emails[0]?.email ? 'Edit Email' : 'Add Email'}
        </Title>
        <Form name="modify-form-ref" form={form} layout="vertical" validateTrigger="submitForm">
          <div className={css(sx.flexContainer)}>
            <div className={css(sx.inputBox)}>
              <p className={css(sx.label)}>EMAIL</p>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: 'email',
                    message: 'PLEASE ENTER A VALID EMAIL ADDRESS',
                  },
                  {
                    required: true,
                    message: 'EMAIL ADDRESS IS A REQUIRED FIELD',
                  },
                ]}
              >
                <Row>
                  <Col span={18}>
                    <Input
                      onChange={e => setEmailInput(e.target.value)}
                      initialValue={patient?.emails[0]?.email}
                      defaultValue={patient?.emails[0]?.email}
                    />
                  </Col>
                  {patient?.emails[0]?.email ? (
                    <Col span={6}>
                      <Button
                        className={css(sx.button)}
                        key="submit"
                        type="link"
                        onClick={() => toggleDeleteEmail()}
                      >
                        Delete Email
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

AddEmailDropdownModal.propTypes = {
  onCloseModal: PropTypes.func,
  patient: PropTypes.object,
  visible: PropTypes.bool,
  refetchSelectedPatientInfo: PropTypes.func,
};

export default AddEmailDropdownModal;
