import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Bold, colors, Span } from '@zipdrug/ui';
import DeliveryActivityItem from './DeliveryActivityItem';
import SignatureDownloadWidget from './SignatureDownloadWidget';

const buildSignatureURL = signature_upload_id =>
  signature_upload_id
    ? `https://d15p8tr8p0vffz.cloudfront.net/${signature_upload_id}/282x.png`
    : null;

const CompletedDeliveryActivityItem = ({
  activity: {
    created_at,
    delivery: { completed_at, destination_address, short_id, signature_upload_id },
  },
  ...props
}) => {
  const addressLabel = get(destination_address, 'label', 'destination');
  const dateTime = moment(completed_at).format('MMMM D, YYYY [at] h:mma');
  const statusText = <Bold style={{ color: colors.green }}>completed</Bold>;

  return (
    <DeliveryActivityItem
      {...props}
      color={colors.green}
      content={<SignatureDownloadWidget signatureURL={buildSignatureURL(signature_upload_id)} />}
      contentHeading="Signature"
      createdAt={created_at}
      footerName={{ first_name: 'Pharmacy', last_name: 'Courier' }}
      heading={
        <Span>
          was {statusText} to {addressLabel} on {dateTime}.
        </Span>
      }
      short_id={short_id}
    />
  );
};

CompletedDeliveryActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default CompletedDeliveryActivityItem;
