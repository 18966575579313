import { ArrowButton, ErrorMessage, FormPasswordField, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { compose, withHandlers } from 'recompose';

const sx = StyleSheet.create({
  arrowButton: {
    flex: 'none',
    marginTop: space.half,
    width: '100%',
  },
  formField: {
    marginBottom: space.default,
  },
  secondaryActionRow: {
    marginTop: space.get(1.25),
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const SiteDownForm = ({ error, handleSubmit, onFormFieldKeyUp, submitFailed }) => (
  <Fragment>
    <FormPasswordField
      {...FORMFIELD_PROPS}
      id="e2e-SiteDownFormPasswordField"
      label="Passcode"
      name="passcode"
      onKeyUp={onFormFieldKeyUp}
    />
    {error && submitFailed && <ErrorMessage styles={sx.formField}>{error}</ErrorMessage>}
    <ArrowButton
      id="e2e-SiteDownFormSubmitButton"
      onClick={handleSubmit}
      styles={sx.arrowButton}
      text="Bypass"
    />
  </Fragment>
);

SiteDownForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onFormFieldKeyUp: PropTypes.func,
  submitFailed: PropTypes.bool,
};

export default compose(
  withHandlers({
    onFormFieldKeyUp: ({ handleSubmit }) => event => {
      if (event.which === 13) {
        // Submit form if user presses "Enter" key
        event.preventDefault();
        handleSubmit();
      }
    },
  }),
)(SiteDownForm);
