import {
  colors,
  Column,
  Div,
  DropdownFooter,
  font,
  FormDateField,
  FormTextareaField,
  FormTimeSliderField,
  LinkButton,
  padding,
  space,
  Span,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DropdownHeader from '../../../ui/DropdownHeader';

const DropdownPadding = font.size.small;

const sx = StyleSheet.create({
  content: padding.build(DropdownPadding),
  error: {
    color: colors.red,
    lineHeight: font.lineHeight.looser,
    marginTop: DropdownPadding,
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
    ':last-of-type': { marginBottom: 0 },
  },
  headerAccent: {
    color: colors.gray62,
  },
  textareaField: {
    maxHeight: '20rem',
  },
  textareaInput: {
    lineHeight: font.lineHeight.loose,
  },
});

const FORMFIELD_PROPS = {
  inputStyles: sx.formFieldInput,
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const DeliveryForm = ({
  error,
  handleSubmit,
  initialValues,
  onClickCancel,
  submitFailed,
  submitting,
}) => (
  <Fragment>
    <Column styles={sx.content}>
      <DropdownHeader
        showBackButton={false}
        title={
          <Span>
            <Span>Create order&nbsp;</Span>
            <Span styles={sx.headerAccent}>{`to ${initialValues.label}`}</Span>
          </Span>
        }
      />
      <Column>
        <FormDateField
          {...FORMFIELD_PROPS}
          autoFocus
          id="e2e-orderFormDeliveryDateField"
          label="Delivery date"
          name="date"
        />
        <FormTimeSliderField
          {...FORMFIELD_PROPS}
          label="Delivery time"
          max="10:00pm"
          min="8:00am"
          name="time"
        />
        <FormTextareaField
          {...FORMFIELD_PROPS}
          inputStyles={sx.textareaInput}
          id="e2e-orderFormDeliveryNotesField"
          label="Delivery notes"
          maxRows={12}
          minRows={6}
          name="delivery_note"
          optional
          placeholder="Enter instructions..."
          resize="vertical"
          styles={[sx.formField, sx.textareaField]}
        />
      </Column>
      {error && submitFailed && (
        <Div styles={sx.error}>{`Error occurred: ${error}. Please try again later`}</Div>
      )}
    </Column>
    <DropdownFooter>
      <LinkButton onClick={onClickCancel} title="Cancel">
        Cancel
      </LinkButton>
      <LinkButton
        id="e2e-orderFormCreateButton"
        isLoading={submitting}
        onClick={handleSubmit}
        title="Create delivery order"
      >
        Schedule delivery
      </LinkButton>
    </DropdownFooter>
  </Fragment>
);

DeliveryForm.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClickCancel: PropTypes.func,
  submitFailed: PropTypes.bool,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default DeliveryForm;
