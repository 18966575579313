import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import { ActivityPrompt, Div, padding, space } from '@zipdrug/ui';

const sx = StyleSheet.create({
  container: {
    ...padding.build(0, space.default, space.default, space.default),
    flex: 'none',
    // height: '100%'
    // bottom: '100px',
    // marginBottom: '30px'
  },
});

const ActivityPromptSection = ({ handleSubmit, styles, ...props }) => (
  <Div styles={[sx.container, styles]}>
    <ActivityPrompt {...props} handleSubmit={handleSubmit} id="e2e-activityPromptTextField" />
  </Div>
);

ActivityPromptSection.propTypes = {
  handleSubmit: PropTypes.func,
  styles: aphroditePropType,
};

export default ActivityPromptSection;
