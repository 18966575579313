import gql from 'graphql-tag';

const UPDATE_CAMPAIGN_WEIGHTS = gql`
  mutation updateCampaignWeight($campaignWeightModifiers: [CampaignWeightModifier]!) {
    updateCampaignWeight(campaignWeightModifiers: $campaignWeightModifiers) {
      data {
        weight
        percent_allocation
      }
    }
  }
`;

export default UPDATE_CAMPAIGN_WEIGHTS;
