import gql from 'graphql-tag';

const basicFields = `
  email
  first_name
  id
  joined_at
  last_name
  inbound_eligible
  call_center_user_status
  roles {
    id
    pharmacy_id
    type
  }
  feature_flags
  title
`;

export const mutateUpdateUserAndLogin = gql`
  mutation updateUserAndLogin($modifier: UserModifier, $token: String) {
    updateUserAndLogin(modifier: $modifier, token: $token) {
      data {
        user {
          ${basicFields}
        }
        token
        redirect
      }
      errors
    }
  }
`;

// eslint-disable-next-line
export const queryCurrentUser = gql`
  query currentUser {
    currentUser {
      data {
        ${basicFields}
      }
    }
  }
`;

export const queryUsers = gql`
  query users($query: JSON) {
    users(query: $query) {
      data {
        ${basicFields}
      }
      limit
      skip
      total
    }
  }
`;

export const mutateCreateUser = gql`
  mutation createUser($email: String, $title: String, $user_type: String, $pharmacy_id: Int, $pharmacy_name: String, , $ticket_number: String) {
    createUser(email: $email, title: $title, user_type: $user_type, pharmacy_id: $pharmacy_id, pharmacy_name: $pharmacy_name, ticket_number: $ticket_number) {
      data {
        ${basicFields}
      }
      errors
    }
  }
`;

export const mutateUpdateUser = gql`
  mutation updateUser($id: Int, $modifier: UserModifier, $pharmacy_id: Int, $token: String) {
    updateUser(id: $id, modifier: $modifier, pharmacy_id: $pharmacy_id, token: $token) {
      data {
        ${basicFields}
      }
      errors
    }
  }
`;

export const mutateLogin = gql`
  mutation login($email: String!, $password: String!) {
    loginRefresh(email: $email, password: $password) {
      data {
        user {
          ${basicFields}
        }
        refreshToken
        accessToken
        expirationToken
        daysUntilPasswordExpires
        redirect
      }
      errors
    }
  }
`;
