import gql from 'graphql-tag';

const UPDATE_CHILDREN_ASC = gql`
  mutation bulkUpdateAsc($pharmacyNpis: [Float!], $pharmacy_asc_id: Int) {
    bulkUpdateAsc(pharmacyNpis: $pharmacyNpis, pharmacy_asc_id: $pharmacy_asc_id) {
      data {
        id
        name
        pharmacy_asc_id
      }
    }
  }
`;

export default UPDATE_CHILDREN_ASC;
