import gql from 'graphql-tag';

const GET_NOTES = gql`
  query getNotes($limit: Int, $query: JSON, $skip: Int) {
    getNotes(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        text
        created_at
        dismissed_at
        created_by_user {
          id
          first_name
          last_name
        }
        activity {
          id
          patient {
            id
            first_name
            last_name
            memberships {
              member_id
            }
            plans {
              id
              name
              description
            }
            assigned_pharmacy {
              id
              name
              npi
              address {
                id
                state
              }
            }
          }
        }
      }
      total
    }
  }
`;

export default GET_NOTES;
