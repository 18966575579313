import gql from 'graphql-tag';

const getEnrollStatusOutcomes = gql`
  query getEnrollStatusOutcomes($patientId: Int!) {
    getEnrollStatusOutcomes(patientId: $patientId) {
      data {
        id
        notes
        user_id
        patient_id
        pharmacy_id
        created_at
        updated_at
        deleted_at
        enroll_status_outcome_reason {
          id
          outcome_reason_type_id
          outcome_type {
            id
            enroll_status
            outcome
            outcome_reason
          }
        }
      }
      total
    }
  }
`;

export default getEnrollStatusOutcomes;
