import { border, colors, Header, padding, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React from 'react';

export const HEADER_HEIGHT = '6rem';

const sx = StyleSheet.create({
  default: {
    ...border.build('bottom', { color: colors.gray92 }),
    ...padding.build(space.default),
    backgroundColor: colors.white,
    flex: 'none',
    height: HEADER_HEIGHT,
    width: '100%',
  },
});

const HeaderComponent = ({ styles, ...props }) => (
  <Row {...props} component={Header} styles={[sx.default, styles]} />
);

HeaderComponent.displayName = 'Header';

HeaderComponent.height = HEADER_HEIGHT;

HeaderComponent.propTypes = {
  styles: aphroditePropType,
};

export default HeaderComponent;
