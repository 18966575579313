import gql from 'graphql-tag';

export const GET_PHARMACY_NOTES = gql`
  query pharmacyNotes($limit: Int, $query: JSON, $skip: Int) {
    pharmacyNotes(limit: $limit, skip: $skip, query: $query) {
      data {
        id
        text
        created_at
        created_by_user {
          first_name
          last_name
        }
        file_name
        file_upload_message
        file_upload_status
        affected_postal_codes
      }
    }
  }
`;

export const ADD_PHARMACY_NOTE = gql`
  mutation addPharmacyNote($text: String!, $pharmacy_id: Int!, $file: Upload) {
    addPharmacyNote(text: $text, pharmacy_id: $pharmacy_id, file: $file) {
      data {
        id
        text
        created_at
        created_by_user {
          first_name
          last_name
        }
        file_name
        file_upload_message
        file_upload_status
      }
    }
  }
`;

export const UPDATE_PHARMACY_NOTE = gql`
  mutation updatePharmacyNote($id: Int!, $text: String, $file: Upload) {
    updatePharmacyNote(id: $id, text: $text, file: $file) {
      data {
        id
        text
        created_at
        created_by_user {
          first_name
          last_name
        }
        file_name
        file_upload_message
        file_upload_status
      }
    }
  }
`;

export const DELETE_PHARMCY_NOTE = gql`
  mutation deletePharmacyNote($id: Int!) {
    deletePharmacyNote(id: $id) {
      errors
    }
  }
`;

export const GET_FILE_ATTACHMENT_URL = gql`
  query getPharmacyNoteFile($id: Int!) {
    getPharmacyNoteFile(id: $id) {
      data {
        file_url
      }
    }
  }
`;
