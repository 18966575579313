import gql from 'graphql-tag';

const mutateLogout = gql`
  mutation {
    logout {
      data
      errors
    }
  }
`;

export default mutateLogout;
