import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { font, space, Span } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 10,
  },
});

const DNC_LABELS = {
  true: 'Do not call',
  false: 'Callable',
};

export default class LetterActivityItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
  };

  renderHeading = () => <Span> Patient status updated.</Span>;

  render() {
    const { activity, ...props } = this.props;
    const name = activity.model_update.created_by_user || 'Zipdrug Admin';

    const content = `${DNC_LABELS[activity.model_update.new_fields.is_dnc]}`;
    return (
      <SystemEventActivityItem
        {...props}
        content={content}
        contentStyles={sx.content}
        createdAt={activity.created_at}
        footerName={{ name }}
        footerStyles={sx.footer}
        headerStyles={sx.header}
        heading={this.renderHeading()}
        icon="person"
      />
    );
  }
}
