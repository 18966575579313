import PropTypes from 'prop-types';
import { Component, createElement } from 'react';

const ACTIVE_CALL_STATE_DURATION = 15000;

const withActiveCallAnimation = ComponentToWrap =>
  class ActiveCallAnimationWrapper extends Component {
    static propTypes = {
      onCallPhone: PropTypes.func,
    };

    state = { isCalling: false };

    componentDidUpdate = () => {
      if (!this.state.isCalling && this.isCallingInterval) {
        clearTimeout(this.isCallingInterval);
      }
    };

    isCallingInterval = null;

    handleCallPhone = phoneId => {
      if (!this.state.isCalling) {
        this.setState({ isCalling: true });
        this.props.onCallPhone(phoneId);
        this.handleAnimationTimeout();
      }
    };

    handleAnimationTimeout = () => {
      this.isCallingInterval = setTimeout(() => {
        this.setState({ isCalling: false });
      }, ACTIVE_CALL_STATE_DURATION);
    };

    render = () =>
      createElement(ComponentToWrap, {
        ...this.props,
        isCalling: this.state.isCalling,
        onCallPhone: this.handleCallPhone,
      });
  };

export default withActiveCallAnimation;
