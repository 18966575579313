import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography } from 'antd';

const sx = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  title: {
    fontWeight: '500',
  },
});

const { Title, Text } = Typography;

const DownloadReportConfirmationModal = ({
  downloadPharmacyNetworkReport,
  downloadPharmacyNetworkReportCancel,
  visible,
}) => {
  return (
    <Modal
      onOk={downloadPharmacyNetworkReport}
      onCancel={downloadPharmacyNetworkReportCancel}
      open={visible}
      okText="Yes"
      cancelText="No"
    >
      <Title className={css(sx.title)} level={3}>
        Download Report
      </Title>
      <div className={css(sx.flexContainer)}>
        <div>
          <Text className={css(sx.cellText)}>
            Would you like to download the current pharmacy network coverage?
          </Text>
        </div>
      </div>
    </Modal>
  );
};

DownloadReportConfirmationModal.propTypes = {
  downloadPharmacyNetworkReport: PropTypes.func,
  downloadPharmacyNetworkReportCancel: PropTypes.func,
  visible: PropTypes.bool,
};

export default DownloadReportConfirmationModal;
