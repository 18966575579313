import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

export const WorkstationPhoneContext = React.createContext();

const WorkstationPhoneContextProvider = ({ children, workstationPhoneId }) => {
  return (
    <WorkstationPhoneContext.Provider
      value={{
        workstationPhoneId,
      }}
    >
      {children}
    </WorkstationPhoneContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    workstationPhoneId: get(state, 'query.all.workstationPhoneId'),
  };
};

WorkstationPhoneContextProvider.propTypes = {
  workstationPhoneId: PropTypes.number,
  children: PropTypes.node,
};

export default connect(mapStateToProps)(React.memo(WorkstationPhoneContextProvider));
