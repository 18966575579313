import moment from 'moment';

const dateConverter = (string, dateFormat) => {
  const dateString = new Date(string);
  const dateObj = new Date(dateString);
  const momentObj = moment(dateObj);
  const momentString = momentObj.format(dateFormat); // 2016-07-15
  return momentString;
};

export default dateConverter;
