import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Button } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';
import { PoweroffOutlined } from '@ant-design/icons';

const sx = StyleSheet.create({
  makeActive: {
    padding: '0px 20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '60px',
    background: '#F5F5F5',
  },
  btnPrimary: {
    fontWeight: 'bold',
    backgroundColor: '#5009B5',
  },
  pl5: {
    paddingLeft: '5px',
  },
});

const MakeActive = ({ getTwilioTokenHandler, loadingTwilioToken }) => {
  return (
    <div className={css(sx.makeActive)}>
      <Tooltip
        placement="bottom"
        title="Mark yourself as Availible on Call Center to place and receive calls."
      >
        <Button
          onClick={getTwilioTokenHandler}
          loading={loadingTwilioToken}
          type="primary"
          className={css(sx.btnPrimary)}
        >
          <PoweroffOutlined />
          <span className={css(sx.pl5)}>Make Myself Available</span>
        </Button>
      </Tooltip>
    </div>
  );
};

MakeActive.propTypes = {
  getTwilioTokenHandler: PropTypes.func,
  loadingTwilioToken: PropTypes.bool,
};

export default MakeActive;
