/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { colors, Div, font, padding, position, space } from '@zipdrug/ui';
import SettingsPageTemplate from 'settings/SettingsPageTemplate';
import ChurnMemberListTable from './components/ChurnMemberListTable/ChurnMemberListTable';
import PageHeading from '../../../ui/PageHeading';
import withCurrentUser from '../../../hoc/withCurrentUser';

const sx = StyleSheet.create({
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  boldDescription: {
    fontWeight: 'bold',
  },
  page: {
    ...position.size('100%'),
    maxWidth: 'initial',
    paddingBottom: 0,
  },
  churnMemberListContainer: padding.build(space.get(0), space.get(1.5), space.three),
});

const ChurnMemberList = ({ currentUser }) => {
  return (
    <SettingsPageTemplate styles={sx.page}>
      <PageHeading title="Churn Members" />
      <Div styles={sx.churnMemberListContainer}>
        <ChurnMemberListTable user={currentUser} />
      </Div>
    </SettingsPageTemplate>
  );
};

export default withCurrentUser(ChurnMemberList);
