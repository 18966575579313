import React from 'react';

const DialIcon = () => (
  <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.76705 4.53313C3.7427 4.53313 4.53362 3.74221 4.53362 2.76656C4.53362 1.79092 3.7427 1 2.76705 1C1.79141 1 1.00049 1.79092 1.00049 2.76656C1.00049 3.74221 1.79141 4.53313 2.76705 4.53313Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M9.29977 4.53313C10.2754 4.53313 11.0663 3.74221 11.0663 2.76656C11.0663 1.79092 10.2754 1 9.29977 1C8.32412 1 7.5332 1.79092 7.5332 2.76656C7.5332 3.74221 8.32412 4.53313 9.29977 4.53313Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M2.76656 11.0663C3.74221 11.0663 4.53313 10.2754 4.53313 9.29977C4.53313 8.32412 3.74221 7.5332 2.76656 7.5332C1.79092 7.5332 1 8.32412 1 9.29977C1 10.2754 1.79092 11.0663 2.76656 11.0663Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M9.29977 11.0663C10.2754 11.0663 11.0663 10.2754 11.0663 9.29977C11.0663 8.32412 10.2754 7.5332 9.29977 7.5332C8.32412 7.5332 7.5332 8.32412 7.5332 9.29977C7.5332 10.2754 8.32412 11.0663 9.29977 11.0663Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M2.76656 17.5995C3.74221 17.5995 4.53313 16.8086 4.53313 15.833C4.53313 14.8573 3.74221 14.0664 2.76656 14.0664C1.79092 14.0664 1 14.8573 1 15.833C1 16.8086 1.79092 17.5995 2.76656 17.5995Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M9.29977 17.5995C10.2754 17.5995 11.0663 16.8086 11.0663 15.833C11.0663 14.8573 10.2754 14.0664 9.29977 14.0664C8.32412 14.0664 7.5332 14.8573 7.5332 15.833C7.5332 16.8086 8.32412 17.5995 9.29977 17.5995Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M9.29977 24.1327C10.2754 24.1327 11.0663 23.3418 11.0663 22.3662C11.0663 21.3905 10.2754 20.5996 9.29977 20.5996C8.32412 20.5996 7.5332 21.3905 7.5332 22.3662C7.5332 23.3418 8.32412 24.1327 9.29977 24.1327Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M15.833 17.5995C16.8086 17.5995 17.5995 16.8086 17.5995 15.833C17.5995 14.8573 16.8086 14.0664 15.833 14.0664C14.8573 14.0664 14.0664 14.8573 14.0664 15.833C14.0664 16.8086 14.8573 17.5995 15.833 17.5995Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M15.833 4.53313C16.8086 4.53313 17.5995 3.74221 17.5995 2.76656C17.5995 1.79092 16.8086 1 15.833 1C14.8573 1 14.0664 1.79092 14.0664 2.76656C14.0664 3.74221 14.8573 4.53313 15.833 4.53313Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
    <path
      d="M15.833 11.0663C16.8086 11.0663 17.5995 10.2754 17.5995 9.29977C17.5995 8.32412 16.8086 7.5332 15.833 7.5332C14.8573 7.5332 14.0664 8.32412 14.0664 9.29977C14.0664 10.2754 14.8573 11.0663 15.833 11.0663Z"
      fill="white"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);

export default DialIcon;
