import gql from 'graphql-tag';

export const PATIENT_UPDATE_SUBSCRIPTION = gql`
  subscription getPatientSubscription($patientId: String!) {
    getPatientSubscription(patientId: $patientId) {
      id
      assigned_pharmacy_id
    }
  }
`;

export default PATIENT_UPDATE_SUBSCRIPTION;
