import { ArrowButton, colors, Div, font, Row, space, Spinner } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';

const sx = StyleSheet.create({
  actionRow: {
    marginTop: space.get(1.5),
  },
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  spinnerContainer: {
    height: space.six,
    position: 'relative',
  },
});

export default class EmailVerificationPage extends Component {
  static propTypes = {
    isVerified: PropTypes.bool,
    loading: PropTypes.bool,
    onButtonClick: PropTypes.func,
  };

  generatePageTitle = () => {
    switch (this.props.isVerified) {
      case true:
        return "You're verified!";
      case false:
        return 'Invalid credentials';
      default:
        return '';
    }
  };

  renderContent = () => {
    const { isVerified, loading, onButtonClick } = this.props;

    if (loading) {
      return (
        <Div styles={sx.spinnerContainer}>
          <Spinner loading />
        </Div>
      );
    } else if (isVerified) {
      return (
        <Div>
          <Div styles={sx.description}>
            Your email address has been verified. Please continue to login.
          </Div>
          <Row styles={sx.actionRow}>
            <ArrowButton onClick={onButtonClick} text="Login" />
          </Row>
        </Div>
      );
    }

    return null;
  };

  render = () => (
    <StandaloneFormPageTemplate title={this.generatePageTitle()}>
      {this.renderContent()}
    </StandaloneFormPageTemplate>
  );
}
