/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { Button, Card, message, Table } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import moment from 'moment';
import AddPharmacyNotesModal from './AddPharmacyNotesModal';
import EditPharmacyNotesModal from './EditPharmacyNotesModal';
import DeletePharmacyNotesModal from './DeletePharmacyNotesModal';
import { GET_PHARMACY_NOTES, GET_FILE_ATTACHMENT_URL } from '../../graphql/pharmacyNotes';
import withCurrentUser from '../../../../../hoc/withCurrentUser';
import withRoles from '../../../../../hoc/withRoles';
import ZipcodesModal from './ZipcodesModal';

const sx = StyleSheet.create({
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
  underline: {
    textDecoration: 'underline',
  },
});

const PharmacyNotesTab = ({ currentUser, isPharmacyTeam, isSystemAdmin, pharmacy }) => {
  const [data, setData] = useState([]);
  const [showAddNotesModal, setShowAddNotesModal] = useState(false);
  const [showEditNotesModal, setShowEditNotesModal] = useState(false);
  const [showDeleteNotesModal, setShowDeleteNotesModal] = useState(false);
  const [zipcodesType, setZipcodesType] = useState('');
  const [zipcodes, setZipcodes] = useState([]);
  const [ShowZipcodesModal, setShowZipcodesModal] = useState(false);

  const [pharmId, setPharmacyId] = useState(null);
  const [notesInfo, setNotesInfo] = useState({});
  const [notesId, setNotesId] = useState(null);

  const [fetchNotes, { loading: isFetchingNotes, refetch: refretchPharmacyNotes }] = useLazyQuery(
    GET_PHARMACY_NOTES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error fetching notes: ${error.message}`);
      },
      onCompleted: ({ pharmacyNotes }) => {
        setData(pharmacyNotes.data);
      },
    },
  );

  const [getAttachmentUrl] = useLazyQuery(GET_FILE_ATTACHMENT_URL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching attachment: ${error.message}`);
    },
    onCompleted: ({ getPharmacyNoteFile }) => {
      const url = getPharmacyNoteFile.data?.file_url;
      window.open(url, '_blank');
    },
  });

  const openAttachment = noteId => {
    getAttachmentUrl({
      variables: {
        id: noteId,
      },
    });
  };

  const handleViewZipcodes = (text, zipcodes) => {
    const title = `${text.includes('Assigned') ? 'Assigned' : 'Potential'} Zip Codes`;
    setZipcodesType(title);
    setZipcodes(zipcodes);
    setShowZipcodesModal(true);
  };

  const generateColumns = () => {
    const currentUserFullname = currentUser.first_name + currentUser.last_name;
    const headers = [
      {
        title: 'Date',
        width: '10%',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: {
          compare: (a, b) => a.created_at - b.created_at,
        },
        render: text => {
          return moment(text).format('MM/DD/YYYY');
        },
      },
      {
        title: 'From',
        dataIndex: 'created_by_user',
        width: '20%',
        key: 'created_by_user',
        sorter: {
          compare: (a, b) =>
            a.created_by_user?.first_name.localeCompare(b.created_by_user?.first_name),
        },
        render: text => {
          return `${text?.first_name} ${text?.last_name}`;
        },
      },
      {
        title: 'Notes',
        dataIndex: 'text',
        width: '50%',
        key: 'note',
        render: (text, record) => {
          const { file_name, affected_postal_codes } = record;
          if (file_name) {
            return (
              <>
                {text}
                <Button type="link" onClick={() => openAttachment(record.id)}>
                  <span className={css(sx.underline)}>View Attachment</span>
                </Button>
              </>
            );
          }
          if (affected_postal_codes) {
            return (
              <>
                {text}
                <Button type="link" onClick={() => handleViewZipcodes(text, affected_postal_codes)}>
                  <span className={css(sx.underline)}>View Zip codes</span>
                </Button>
              </>
            );
          }
          return text;
        },
      },
    ];

    // only pharmacy team can edit pharmacies
    headers.push({
      dataIndex: 'created_by_user',
      title: 'Action',
      width: '20%',
      key: 'action',
      render: (_, record) => {
        const createdBy = record.created_by_user?.first_name + record.created_by_user?.last_name;
        if (currentUserFullname === createdBy && !record?.affected_postal_codes) {
          return (
            <>
              <Button type="link" onClick={() => handleEditNote(record)}>
                <span className={css(sx.underline)}>Edit</span>
              </Button>
              <span />
              <Button type="link" onClick={() => handleDeleteNote(record)}>
                <span className={css(sx.underline)}>Delete</span>
              </Button>
            </>
          );
        }
      },
    });
    return headers;
  };

  const handleAddNote = () => {
    setShowAddNotesModal(true);
  };

  const handleEditNote = value => {
    setNotesInfo(value);
    setShowEditNotesModal(true);
  };

  const handleDeleteNote = value => {
    setNotesId(value.id);
    setShowDeleteNotesModal(true);
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data,
      loading: isFetchingNotes,
      pagination: false,
      rowKey: 'id',
      size: 'middle',
    };

    return tableOptions;
  };

  const fetchPharmacyNotes = () => {
    const pharmacyId = pharmacy?.id;
    fetchNotes({
      variables: {
        query: {
          pharmacy_id: pharmacyId,
        },
      },
    });
  };

  useEffect(() => {
    if (pharmacy?.id) {
      setPharmacyId(pharmacy?.id);
      fetchPharmacyNotes(false);
    }
  }, [pharmacy]);

  return (
    <Card>
      <div className={css(sx.tableOptionsContainer)}>
        {(isPharmacyTeam || isSystemAdmin) && (
          <Button
            type="secondary"
            className={css(sx.tableOptionsButtonMargin)}
            onClick={() => handleAddNote(false)}
          >
            Add Note
          </Button>
        )}
      </div>

      <Table {...getTableOptions()} />

      <AddPharmacyNotesModal
        updateNotes={() =>
          refretchPharmacyNotes({
            variables: {
              query: {
                pharmacy_id: pharmId,
              },
            },
          })
        }
        onCloseModal={() => setShowAddNotesModal(false)}
        pharmacyId={pharmId}
        visible={showAddNotesModal}
      />

      <EditPharmacyNotesModal
        notesInfo={notesInfo}
        onCloseModal={() => setShowEditNotesModal(false)}
        pharmacyId={pharmacy?.id}
        updateNotes={() =>
          refretchPharmacyNotes({
            variables: {
              query: {
                pharmacy_id: pharmId,
              },
            },
          })
        }
        visible={showEditNotesModal}
      />

      <DeletePharmacyNotesModal
        onCloseModal={() => setShowDeleteNotesModal(false)}
        notesId={notesId}
        updateNotes={() =>
          refretchPharmacyNotes({
            variables: {
              query: {
                pharmacy_id: pharmId,
              },
            },
          })
        }
        visible={showDeleteNotesModal}
      />

      {ShowZipcodesModal && zipcodes.length > 0 && zipcodesType !== '' && (
        <ZipcodesModal
          visible={ShowZipcodesModal}
          onCloseModal={() => setShowZipcodesModal(false)}
          title={zipcodesType}
          zipcodes={zipcodes}
        />
      )}
    </Card>
  );
};

export default withCurrentUser(withRoles(PharmacyNotesTab));
