import { actionTypes } from '@zipdrug/react-api-sdk';
import { reducer as formReducer } from 'redux-form';
import recycleState from 'redux-recycle';
import audio from './audio';
import filterPanel from './filterPanel';
import modal from './modal';
import queryReducer from './queryReducer';

const initialStateAction = { type: 'FORCE_INITIAL_STATE' };

export default {
  audio: recycleState(audio, [actionTypes.RESET], audio(undefined, initialStateAction)),
  form: recycleState(formReducer, [actionTypes.RESET], formReducer(undefined, initialStateAction)),
  filterPanel: recycleState(
    filterPanel,
    [actionTypes.RESET],
    filterPanel(undefined, initialStateAction),
  ),
  modal: recycleState(modal, [actionTypes.RESET], modal(undefined, initialStateAction)),
  query: queryReducer,
};
