import { get } from 'lodash';

export default function observe(store, path, onNext) {
  let val = get(store.getState(), path, null);

  const dispose = store.subscribe(() => {
    const nextVal = get(store.getState(), path, null);
    if (val !== nextVal) {
      val = nextVal;
      onNext(val);
    }
  });
  return { dispose };
}
