import React, { useState } from 'react';
import { Button, Col, Divider, Form, message, Row, Select, Space, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { ADD_PHONE, UPDATE_PHONE } from '../../../ui/CallCenter/queries';

// CSS styles
const sx = StyleSheet.create({
  underline: {
    textDecoration: 'underline',
  },
  marginTop25: {
    marginTop: 25,
  },
  label: {
    margin: 0,
    fontSize: 12,
    color: 'black',
  },
  inputStyles: {
    border: '1px solid #EAEAEA',
    borderRadius: '6px',
    padding: '1.3rem',
    fontSize: '1.5rem',
    outline: 'none',
    color: '#1E1E34',
  },
  width100: {
    width: '100%',
  },
});

const { Option: SelectOption } = Select;
const PhonePreferredFollowup = ({
  patient,
  phones,
  setError,
  setChangesSaved,
  setPhoneId,
  phoneId,
  preferredPhone,
  updatePhoneArr,
  setIsEditing,
}) => {
  const [form] = Form.useForm();

  const [addPhoneNumber, setAddPhoneNumber] = useState(false);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);

  const [addPhone] = useMutation(ADD_PHONE, {
    onError: error => {
      if (error?.message) {
        const isGraphqlError = error?.message.slice(0, 13);
        if (isGraphqlError) {
          setError('PLEASE ENTER A VALID PHONE NUMBER');
        }
      }
    },
    onCompleted: ({ createPhone }) => {
      if (createPhone?.data?.id) {
        updatePhoneArr(false, createPhone?.data, null);
        phones.push(createPhone?.data);
        form.setFieldsValue({ phone: formatPhoneNumber(createPhone?.data?.number) });
        setEditPhoneNumber(false);
        setAddPhoneNumber(false);
        setIsEditing(false);
        setError(null);
      }
      setChangesSaved(true);
      message.success('Successfully added the phone number. Continue to save preferred follow-up.');
    },
  });

  const [updatePhone] = useMutation(UPDATE_PHONE, {
    onCompleted: data => {
      const newPhone = data?.updatePhone?.data;
      if (data?.updatePhone?.data?.id) {
        message.success(
          'Successfully edited the phone number. Continue to save preferred follow-up.',
        );
        phones.push(data?.updatePhone?.data);
        phones.find(x => x.id === data?.updatePhone?.data?.id).number = newPhone.number;
        updatePhoneArr(true, data?.updatePhone?.data, newPhone.number);
        setEditPhoneNumber(false);
        setAddPhoneNumber(false);
        setIsEditing(false);
        setError(null);
      }
      setChangesSaved(true);
    },
    onError(error) {
      if (error?.message) {
        const isGraphqlError = error?.message.slice(0, 13);
        if (isGraphqlError) {
          setError('PLEASE ENTER A VALID PHONE NUMBER');
        }
      }
    },
  });

  const changeInputViews = () => {
    if (phoneId) {
      const number = form.getFieldValue('phone');
      const phoneNumber = phones.find(p => p.id === phoneId)
        ? formatPhoneNumber(phones.find(p => p.id === phoneId).number)
        : number;
      form.setFieldsValue({ phone: phoneNumber });
      setEditPhoneNumber(true);
      setIsEditing(true);
      setChangesSaved(false);
      setError(null);
    } else {
      form.setFieldsValue({ phone: '' });
      setAddPhoneNumber(true);
      setIsEditing(true);
      setError(null);
    }
  };

  const handleCancel = () => {
    setEditPhoneNumber(false);
    setAddPhoneNumber(false);
    setIsEditing(false);
    form.resetFields();
    setPhoneId(patient.preferred_phone_id || null);
    setError(null);
  };

  const handleSave = () => {
    const number = form.getFieldValue('phone');
    const cleanedNumber = `+1${number.replace(/\D/g, '')}`;
    if (number === '') {
      setError('PHONE NUMBER IS A REQUIRED FIELD');
    } else if (editPhoneNumber) {
      updatePhone({
        variables: {
          id: phoneId,
          modifier: {
            number: cleanedNumber,
          },
        },
      });
    } else {
      addPhone({
        variables: {
          label: 'mobile',
          number: cleanedNumber,
          can_sms: true,
          patient_id: patient?.id,
          pharmacy_id: patient?.assigned_pharmacy_id,
        },
      });
    }
  };

  const handleOnChange = value => {
    setPhoneId(value);
    setChangesSaved(true);
    setError(null);
  };

  const handleInputChange = () => {
    setError(null);
  };

  const formatPhoneNumber = str => {
    const cleaned = ` ${str}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }

    return null;
  };

  const addPhoneNumberFromDropdown = () => {
    setPhoneId(null);
    form.setFieldsValue({ phone: '' });
    setAddPhoneNumber(true);
    setIsEditing(true);
    setError(null);
  };

  return (
    <>
      {!addPhoneNumber && !editPhoneNumber ? (
        <Col span={24}>
          <div>Please select from existing mobile number(s)</div>
          <Form name="modify-phone-form" form={form} layout="vertical">
            <div className={css(sx.flexContainer)}>
              <div className={css(sx.inputBox)}>
                <p className={css(sx.label)}>MOBILE</p>
                <Row>
                  <Col span={14}>
                    <Form.Item name="phone">
                      <Select
                        defaultValue={preferredPhone?.number && patient.preferred_phone_id}
                        onChange={handleOnChange}
                        placeholder="Select"
                        dropdownRender={menu => (
                          <>
                            {menu}
                            {phones?.length > 0 ? (
                              <>
                                <Divider
                                  style={{
                                    margin: '8px 0',
                                  }}
                                />
                                <Space
                                  align="center"
                                  style={{
                                    padding: '0 8px 4px',
                                  }}
                                >
                                  <Typography.Link
                                    onClick={() => addPhoneNumberFromDropdown()}
                                    style={{ width: '100%', whiteSpace: 'nowrap' }}
                                  >
                                    Add new phone number
                                  </Typography.Link>
                                </Space>
                              </>
                            ) : null}
                          </>
                        )}
                      >
                        {phones.map(phone => (
                          <SelectOption value={phone.id} key={`${phone.id}-${phone.number}`}>
                            {formatPhoneNumber(phone.number)}
                          </SelectOption>
                        ))}
                        {/* {phones?.length > 0 && (
                        )} */}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Button key="submit" type="link" onClick={changeInputViews}>
                      <span className={css(sx.underline)}>
                        {phoneId ? 'Edit this number' : 'Add New Number'}
                      </span>
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Form>
        </Col>
      ) : (
        <>
          <div>{addPhoneNumber ? 'Please add a mobile number' : 'Please edit this number'}</div>
          <Row>
            <Col span={16}>
              <Form name="add-phone-form" form={form} layout="vertical">
                <div className={css(sx.flexContainer)}>
                  <div className={css(sx.inputBox)}>
                    <p className={css(sx.label)}>MOBILE</p>
                    <Form.Item name="phone">
                      <InputMask
                        onChange={handleInputChange}
                        className={css(sx.inputStyles, sx.width100)}
                        mask="(999) 999-9999"
                      />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Col>
            <>
              <Col size="small" span={4} align="right">
                <Button
                  className={css(sx.marginTop25)}
                  key="submit"
                  onClick={handleSave}
                  type="link"
                >
                  <span className={css(sx.underline)}>Save</span>
                </Button>
              </Col>
              <Col size="small" span={4} align="left">
                <Button
                  className={css(sx.button, sx.marginTop25)}
                  key="cancel"
                  onClick={handleCancel}
                  type="link"
                >
                  <span className={css(sx.underline)}>Cancel</span>
                </Button>
              </Col>
            </>
          </Row>
        </>
      )}
    </>
  );
};

PhonePreferredFollowup.propTypes = {
  patient: PropTypes.object,
  phones: PropTypes.array,
  phoneId: PropTypes.number,
  preferredPhone: PropTypes.object,
  setError: PropTypes.func,
  setChangesSaved: PropTypes.func,
  setPhoneId: PropTypes.func,
  updatePhoneArr: PropTypes.func,
  setIsEditing: PropTypes.func,
};

export default PhonePreferredFollowup;
