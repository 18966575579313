import { Button, Div, font, formatPhone, padding, space } from '@zipdrug/ui';
import _get from 'lodash/get';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { SessionContext } from 'contexts/SessionContextProvider';

const sx = StyleSheet.create({
  default: {
    ...padding.build(space.quarter, space.half),
    fontSize: font.size.small,
    lineHeight: font.lineHeight.tight,
  },
  disabled: {
    color: '#00000040',
    cursor: 'not-allowed',
    background: '#f5f5f5',
    borderColor: '#d9d9d9',
    textShadow: 'none',
    boxShadow: 'none',
    ':hover': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: '#00000040',
    },
  },
});

const ReturnCallButton = ({ isCalling, onClick, phoneNumber }) => {
  const { isCallCenterActivatedContext } = useContext(CallCenterContext);
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const callCenterFeatureFlag = _get(currentUser, 'feature_flags.callCenter');

  function isCallCenterBtnDisabled() {
    return callCenterFeatureFlag && !isCallCenterActivatedContext;
  }

  return phoneNumber ? (
    <Div className="fs-hide">
      <Button
        onClick={onClick}
        disabled={isCallCenterBtnDisabled()}
        outline={!isCalling}
        styles={[sx.default, isCallCenterBtnDisabled() ? sx.disabled : null]}
        text={isCalling ? 'Calling...' : 'Return call'}
        title={`Call ${formatPhone(phoneNumber)}`}
        type="success"
      />
    </Div>
  ) : null;
};

ReturnCallButton.propTypes = {
  isCalling: PropTypes.bool,
  onClick: PropTypes.func,
  phoneNumber: PropTypes.object,
};

export default ReturnCallButton;
