export const languages = [
  {
    text: 'English',
    value: 'english',
  },
  {
    text: 'Spanish',
    value: 'spanish',
  },
];

export const statuses = [
  {
    text: 'Inactive',
    value: 'inactive',
  },
  {
    text: 'Active',
    value: 'active',
  },
];

export const lineOfBusinesses = [
  {
    text: 'MA',
    value: 'MA',
  },
  {
    text: 'COM',
    value: 'COM',
  },
  {
    text: 'MCAID',
    value: 'MCAID',
  },
];

export const defaultEnroller = {
  language: ['english'],
  line_of_business: 'MA',
  status: 'inactive',
};
