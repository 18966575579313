import { border, colors, FlexCentered, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  container: {
    ...border.build(''),
    ...border.buildRadius('bottom', space.half),
    backgroundColor: colors.gray97,
    color: colors.theme.primary.default,
    cursor: 'pointer',
    height: space.get(1.5),
    userSelect: 'none',

    ':hover': {
      borderColor: colors.gray87,
      color: colors.theme.primary.hover,
    },

    ':active': {
      color: colors.theme.primary.active,
    },
  },
  containerEmpty: {
    color: colors.gray62,
    pointerEvents: 'none',
  },
});

const RxListToggleExpandButton = ({ inactiveCount, isExpanded, onClick }) => {
  const countLabel = inactiveCount ? ` (${inactiveCount})` : '';

  let directionLabel = 'No';
  if (inactiveCount) {
    directionLabel = isExpanded ? 'Hide' : 'Show';
  }

  return (
    <FlexCentered
      onClick={onClick}
      styles={[sx.container, inactiveCount ? null : sx.containerEmpty]}
      title={`${directionLabel} inactive meds`}
    >
      {`${directionLabel} inactive meds${countLabel}`}
    </FlexCentered>
  );
};

RxListToggleExpandButton.propTypes = {
  inactiveCount: PropTypes.number,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default RxListToggleExpandButton;
