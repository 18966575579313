import React from 'react';

const SwitchPatientIcon = () => (
  <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 21L1 21" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M19 25L22.5 21L19 17" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <circle cx="10" cy="3.5" r="3.5" fill="white" />
    <path
      d="M4.5 13.5C4.5 10.4624 6.96243 8 10 8C13.0376 8 15.5 10.4624 15.5 13.5V16H4.5V13.5Z"
      fill="white"
    />
  </svg>
);

export default SwitchPatientIcon;
