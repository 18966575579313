import gql from 'graphql-tag';

const HAS_ENROLLERS = gql`
  query hasEnrollers($query: EnrollerQuery) {
    enrollers(query: $query) {
      data {
        user {
          id
        }
      }
    }
  }
`;

export default HAS_ENROLLERS;
