import { LinkDropdown } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import PatientInfoDropdownButton from '../PatientInfoDropdownButton';
import LanguageMenuContainer from './LanguageMenuContainer';

const sx = StyleSheet.create({
  menuStyles: {
    left: 0,
    right: 'initial',
  },
});

const LanguageDropdown = ({ patient }) =>
  patient ? (
    <LinkDropdown
      buttonRenderer={
        <PatientInfoDropdownButton
          icon="speechBubble"
          id="e2e-languageDropdown"
          text={upperFirst(patient.language)}
          title="Edit language"
        />
      }
      menuRenderer={<LanguageMenuContainer patient={patient} />}
      menuStyles={sx.menuStyles}
    />
  ) : null;

LanguageDropdown.propTypes = {
  patient: PropTypes.object,
};

export default LanguageDropdown;
