import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { get, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  border,
  colors,
  Div,
  Dot,
  FlexCentered,
  font,
  formatPhone,
  Icon,
  padding,
  rem,
  Row,
  space,
  transition,
  TruncateText,
  unit,
} from '@zipdrug/ui';
import CallButtonContainer from '../CallButtonContainer';

const CONTENT_TRANSITION_DURATION = 200;
const CONTENT_TRANSITION = transition.build({
  duration: CONTENT_TRANSITION_DURATION,
});

const sx = StyleSheet.create({
  arrowButton: {
    ...transition.build({
      duration: CONTENT_TRANSITION_DURATION,
      ease: 'ease-out',
      property: 'opacity',
    }),
    color: colors.theme.primary.default,
    cursor: 'pointer',
    flex: 1,
    height: font.size.small,
    position: 'relative',
    width: '100%',
    willChange: 'opacity',
    ':hover': {
      backgroundColor: colors.alpha(colors.gray80, 0.25),
      color: colors.theme.primary.hover,
    },
    ':active': {
      backgroundColor: colors.gray87,
      color: colors.theme.primary.active,
    },
  },
  arrowIcon: {
    height: '0.8rem',
    width: '0.4rem',
  },
  arrowButtonsContainer: {
    ...border.build('right'),
    backgroundColor: colors.gray98,
    flex: 'none',
    height: '100%',
    position: 'relative',
    width: space.get(1.5),
  },
  button: {
    flex: 'none',
  },
  container: {
    ...border.build('bottom'),
    height: '5.2rem',
    overflow: 'hidden',
    paddingRight: font.size.small,
    ':last-of-type': {
      borderBottomWidth: 0,
    },
  },
  content: {
    ...CONTENT_TRANSITION,
    ...padding.build(0, rem(unit(font.size.small) / 2), 0, font.size.small),
    flex: 1,
    overflowX: 'hidden',
    position: 'relative',
    willChange: 'transform',
  },
  editButton: {
    ...CONTENT_TRANSITION,
    color: colors.theme.primary.default,
    cursor: 'pointer',
    userSelect: 'none',
  },
  editButtonDot: CONTENT_TRANSITION,
  indexLabel: {
    color: colors.gray62,
    cursor: 'default',
    flex: 'none',
    lineHeight: font.lineHeight.tight,
    userSelect: 'none',
  },
  phoneText: {
    ...CONTENT_TRANSITION,
    color: colors.gray62,
    lineHeight: font.lineHeight.looser,
  },
});

export default class ContactListItem extends Component {
  static propTypes = {
    callStatus: PropTypes.string,
    index: PropTypes.number,
    isEditable: PropTypes.bool,
    isPatientContact: PropTypes.bool,
    label: PropTypes.string,
    onClickEdit: PropTypes.func,
    onUpdateContactOrder: PropTypes.func,
    phone: PropTypes.object,
    styles: aphroditePropType,
    churnedOutreachDate: PropTypes.string,
  };

  state = { isHover: false };

  handleArrowDownClick = () => this.handleUpdateContactOrder(-1);
  handleArrowUpClick = () => this.handleUpdateContactOrder(1);

  handleUpdateContactOrder = direction =>
    this.props.onUpdateContactOrder({
      direction,
      order: get(this.props, 'phone.order'),
    });

  handleClickEdit = () =>
    this.props.onClickEdit({
      ...this.props.phone,
      index: this.props.index,
    });

  handleMouseEnter = () => this.setState({ isHover: true });
  handleMouseLeave = () => this.setState({ isHover: false });

  calcHoverOpacity = () => ({ opacity: this.state.isHover ? 1 : 0 });

  renderArrowButtons = () => (
    <FlexCentered direction="column" styles={sx.arrowButtonsContainer}>
      {this.renderArrowIcon('up')}
      <Div styles={sx.indexLabel}>{this.props.index + 1}</Div>
      {this.renderArrowIcon('down')}
    </FlexCentered>
  );

  renderArrowIcon = direction =>
    this.props.isEditable ? (
      <FlexCentered
        onClick={this[`handleArrow${upperFirst(direction)}Click`]}
        style={{ opacity: this.state.isHover ? 1 : 0 }}
        styles={sx.arrowButton}
      >
        <Icon direction={direction} filled name="caret" styles={sx.arrowIcon} />
      </FlexCentered>
    ) : null;

  renderContent = () => (
    <Div styles={sx.content}>
      <Row align="center">
        <Div styles={sx.label}>{this.props.phone.label}</Div>
        {this.props.isEditable && <Dot style={this.calcHoverOpacity()} styles={sx.editButtonDot} />}
        {this.props.isEditable && this.renderEditButton()}
      </Row>
      <TruncateText
        className="fs-hide"
        data-id="e2e-contactListItemPhoneDetails"
        styles={sx.phoneText}
      >
        {formatPhone({
          extension: this.props.phone.extension,
          number: this.props.phone.number,
        })}
      </TruncateText>
    </Div>
  );

  renderEditButton = () => (
    <Div
      onClick={this.handleClickEdit}
      style={{
        ...this.calcHoverOpacity(),
        transform: `translateX(${this.state.isHover ? '2px' : 0})`,
      }}
      styles={sx.editButton}
    >
      Edit
    </Div>
  );

  render = () => (
    <Row
      align="center"
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      styles={[sx.container, this.props.styles]}
    >
      {this.renderArrowButtons()}
      {this.renderContent()}
      {!new Set(['callable_unclear', 'not_callable_dnc', 'not_callable_outside_hours']).has(
        this.props.callStatus,
      ) && (
        <CallButtonContainer
          phone={this.props.phone}
          styles={sx.button}
          disableOnACall={this.props.isPatientContact}
          churnedOutreachDate={!!this.props.churnedOutreachDate}
        />
      )}
    </Row>
  );
}
