import { border, colors, Div, font, padding, position, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  alert: {
    marginLeft: space.quarter,
  },
  alertIndicator: {
    ...border.circle,
    ...position.size('0.6rem'),
    backgroundColor: colors.red,
    left: '100%',
    marginLeft: 2,
    position: 'absolute',
    top: 0,
  },
  container: {
    ...border.build('bottom'),
    ...padding.build(0, space.default),
    backgroundColor: colors.white,
    flex: 'none',
    height: '3.2rem',
    userSelect: 'none',
  },
  heading: {
    cursor: 'default',
    fontWeight: font.weight.boldSemi,
    position: 'relative',
    color: colors.gray20,
  },
});

const ColumnHeader = ({ alertCount, styles, text }) => (
  <Row align="center" styles={[sx.container, styles]}>
    <Div styles={sx.heading} title={alertCount ? `${alertCount} unread alerts` : null}>
      <Div>{text}</Div>
      {!!alertCount && <Div styles={sx.alertIndicator} />}
    </Div>
  </Row>
);

ColumnHeader.propTypes = {
  alertCount: PropTypes.number,
  styles: aphroditePropType,
  text: PropTypes.string.isRequired,
};

export default ColumnHeader;
