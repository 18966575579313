import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import ResetModal from 'password/ResetPasswordModal';
// import { now } from 'lodash';
// import { graphql } from 'react-apollo';
// import { users } from '@zipdrug/react-api-sdk';
// import { alertOnError } from '@zipdrug/ui';
// import ResetPasswordForm from 'password/ResetPasswordForm';

const sx = StyleSheet.create({
  default: {
    marginTop: space.get(1.5),
  },
});
const ResetPasswordButtonContainer = ({ user }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const closeModal = () => setModalVisibility(false);

  return (
    <>
      <ResetModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
        closeModal={closeModal}
        user={user}
      />
      <Button
        type="primary"
        key="submit"
        onClick={() => setModalVisibility(true)}
        size="large"
        text="Reset password"
        styles={[sx.default]}
      />
    </>
  );
};

ResetPasswordButtonContainer.propTypes = {
  user: PropTypes.object,
};

export default ResetPasswordButtonContainer;

// class ResetPasswordButtonContainer extends Component {
//   static propTypes = {
//     user: PropTypes.object,
//     requestPasswordReset: PropTypes.func,
//   };

//   state = {
//     loading: false,
//     submitSucceeded: null,
//   };

//   handleSubmit = async () => {
//     const {
//       user: { email },
//       requestPasswordReset,
//     } = this.props;

//     this.setState({ loading: true });
//     return alertOnError(requestPasswordReset({ variables: { email } }))
//       .then(() => this.handleSubmitResult(true))
//       .catch(() => this.handleSubmitResult(false));
//   };

//   handleSubmitResult = submitSucceeded =>
//     this.setState({
//       submitSucceeded,
//       loading: false,
//     });

//   render = () => (
//     <ResetPasswordButton
//       {...this.props}
//       loading={this.state.loading}
//       onClick={this.handleSubmit}
//       submitSucceeded={this.state.submitSucceeded}
//     />
//   );
// }

// export default graphql(users.mutateRequestPasswordReset, { name: 'requestPasswordReset' })(
//   ResetPasswordButtonContainer,
// );
