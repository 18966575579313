import { withSession } from '@zipdrug/react-api-sdk';
import withSsoLogin from 'hoc/withSsoLogin';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import { setSiteDownBypass } from '../redux/queryReducer';
import SiteDownForm from './SiteDownForm';

const ReduxSiteDownBypass = reduxForm({
  form: 'site-down-bypass',
})(SiteDownForm);

class SiteDownContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    setSiteDownBypass: PropTypes.func,
    siteBypass: PropTypes.func,
    withSiteBypass: PropTypes.bool,
  };

  handleSubmit = ({ passcode }) =>
    this.props
      .siteBypass({ passcode })
      .catch(e => {
        let _error = 'No connection';
        if (e.graphQLErrors && e.graphQLErrors.length) {
          _error = e.graphQLErrors[0].message;
        }
        throw new SubmissionError({ _error });
      })
      .then(res => {
        if (res.data && !res.errors) {
          this.props.setSiteDownBypass({ bypass: true });
          Cookies.set('site_down_bypass', true);
          this.props.history.push({
            pathname: '/signin',
          });
        } else {
          throw new SubmissionError({ _error: 'Invalid code' });
        }
      });

  render = () => (
    <>{this.props.withSiteBypass && <ReduxSiteDownBypass onSubmit={this.handleSubmit} />}</>
  );
}

const mapDispatchToProps = dispatch => ({
  setSiteDownBypass: ({ bypass }) => dispatch(setSiteDownBypass({ bypass })),
});

export default compose(
  withSsoLogin,
  withSession,
  connect(null, mapDispatchToProps),
)(SiteDownContainer);
