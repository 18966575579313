import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { compose } from 'recompose';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import withActiveCallAnimation from '../../hoc/withActiveCallAnimation';
import withSelectedPatientId from '../../hoc/withSelectedPatientId';
import withStartOutboundCall from '../../hoc/withStartOutboundCall';
import withWorkstationPhone from '../../hoc/withWorkstationPhone';
import withCallCenter from '../../hoc/withCallCenter';
import sortListByOrderNumber from '../sortListByOrderNumber';
import ContactDropdown from './ContactDropdown';

const cleanContactItem = ({ phones, ...item }) => ({
  ...item,
  phones: sortListByOrderNumber(phones),
});

const ContactDropdownContainer = ({ contactItem, ...props }) => {
  const { updateCallCenterStatusMutationContext } = useContext(CallCenterContext);

  return (
    <ContactDropdown
      {...props}
      updateCallCenterStatusMutationContext={updateCallCenterStatusMutationContext}
      contactItem={contactItem ? cleanContactItem(contactItem) : null}
    />
  );
};

ContactDropdownContainer.propTypes = {
  contactItem: PropTypes.object,
};

export default compose(
  withCallCenter,
  withSelectedPatientId,
  withWorkstationPhone,
  withStartOutboundCall,
  withActiveCallAnimation,
)(ContactDropdownContainer);
