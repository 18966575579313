import { Button, colors, SplitDropdown, SplitDropdownButton, transition } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SnoozeFormContainer from './SnoozeFormContainer';
import SnoozeList from './SnoozeList';

const DEFAULT_SNOOZE_VALUE = '1 hour';

const sx = StyleSheet.create({
  button: {
    ...transition.build(),
    minWidth: '11.5rem',
  },
});

export default class SnoozeDropdown extends Component {
  static propTypes = {
    onSnoozeChange: PropTypes.func.isRequired,
    snoozeValue: PropTypes.any,
  };

  state = {
    isEditing: false,
    isOpen: null,
  };

  handlePrimaryButtonClick = () => {
    let newSnoozeValue = moment().add(1, 'hour');

    if (this.props.snoozeValue) {
      newSnoozeValue = null;
    }

    this.handleSnoozeValueChange(newSnoozeValue);
  };

  handleSnoozeValueChange = snoozeValue => {
    if (this.state.isOpen) {
      this.splitDropdownRef.toggleMenu();
    }

    this.props.onSnoozeChange(snoozeValue);
  };

  handleSplitDropdownRef = ref => {
    this.splitDropdownRef = ref;
  };

  handleToggleDropdown = isOpen =>
    this.setState(prevState => ({
      isEditing: !isOpen ? false : prevState.isEditing,
      isOpen,
    }));

  handleToggleEditState = () => this.setState({ isEditing: !this.state.isEditing });

  renderMenu = () =>
    this.state.isEditing ? (
      <SnoozeFormContainer
        onClickCancel={this.handleToggleEditState}
        onSubmit={this.handleSnoozeValueChange}
      />
    ) : (
      <SnoozeList
        onClickSnoozeValue={this.handleSnoozeValueChange}
        onToggleEditState={this.handleToggleEditState}
      />
    );

  renderPrimaryButtonContainer = () => (
    <Button
      id="e2e-snoozeDropdownButton"
      onClick={this.handlePrimaryButtonClick}
      size="small"
      title={this.props.snoozeValue ? 'Disable snooze' : `Snooze for ${DEFAULT_SNOOZE_VALUE}`}
    />
  );

  renderPrimaryButtonContent = () => {
    const { snoozeValue } = this.props;

    let title = DEFAULT_SNOOZE_VALUE;
    if (snoozeValue) {
      title = moment(snoozeValue).calendar(null, {
        nextDay: 'MMM D',
        nextWeek: 'MMM D',
        sameDay: 'h:mma',
        sameElse: 'MMM D',
      });
    }

    return (
      <SplitDropdownButton
        icon="clock"
        text={snoozeValue ? 'Snoozed until' : 'Snooze for'}
        textColor={snoozeValue ? colors.white : colors.gray20}
        title={title}
      />
    );
  };

  render = () => (
    <SplitDropdown
      {...this.props}
      buttonRenderer={this.renderPrimaryButtonContainer()}
      buttonStyles={[sx.button, SplitDropdownButton.containerStyles]}
      menuRenderer={this.renderMenu()}
      onClick={this.handlePrimaryButtonClick}
      onToggle={this.handleToggleDropdown}
      outline={!this.props.snoozeValue}
      ref={this.handleSplitDropdownRef}
      small
      type="orange"
    >
      {this.renderPrimaryButtonContent()}
    </SplitDropdown>
  );
}
