import gql from 'graphql-tag';

const GET_COHORT_CHILDREN = gql`
  query getCohortChildren(
    $limit: Int
    $query: JSON
    $order_by: JSON
    $skip: Int
    $cohort_parents_id: Int!
  ) {
    getCohortChildren(
      limit: $limit
      query: $query
      skip: $skip
      order_by: $order_by
      cohort_parents_id: $cohort_parents_id
    ) {
      data {
        pharmacy_id
        include_in_cohort
        is_processed
        pharmacy_name
        pharmacy_npi
        pharmacy_state
        plan_name
        group_name
        template_envelope_id
        lob_envelope_id
        lob_return_envelope_id
        lob_perforated_page_index
        member_count
        array_agg
      }
    }
  }
`;

export default GET_COHORT_CHILDREN;
