import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import {
  animation,
  Button,
  Column,
  font,
  FormDateField,
  FormSelectField,
  padding,
  Row,
  space,
} from '@zipdrug/ui';
import DropdownHeader from '../../../ui/DropdownHeader';

const CONTAINER_ANIMATION = {
  from: { transform: `translateX(${space.half})` },
  to: { transform: 'translateX(0)' },
};

const sx = StyleSheet.create({
  button: {
    flex: 1,
    margin: '0 auto',
    textAlign: 'center',
    ':first-of-type': {
      marginRight: space.half,
    },
  },
  container: {
    ...animation.build({ name: [CONTAINER_ANIMATION, animation.fadeIn] }),
    ...padding.build(font.size.small),
    width: '28rem',
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
  },
  timeSelectMenu: {
    maxHeight: '28rem',
    overflowY: 'auto',
  },
});

const BUTTON_PROPS = {
  focusable: true,
  size: 'large',
  styles: sx.button,
};

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const SnoozeForm = ({ handleSubmit, onClickCancel, snoozeTimeOptions, styles }) => (
  <Column styles={[sx.container, styles]}>
    <DropdownHeader onClickCancel={onClickCancel} title="Pick date & time" />
    <Column>
      <FormDateField {...FORMFIELD_PROPS} label="Date" name="date" placeholder="date" />
      <FormSelectField
        {...FORMFIELD_PROPS}
        items={snoozeTimeOptions}
        label="Time"
        menuStyles={sx.timeSelectMenu}
        name="time"
      />
    </Column>
    <Row>
      <Button {...BUTTON_PROPS} onClick={handleSubmit} text="Snooze" type="primary" />
      <Button {...BUTTON_PROPS} onClick={onClickCancel} outline text="Cancel" type="secondary" />
    </Row>
  </Column>
);

SnoozeForm.propTypes = {
  handleSubmit: PropTypes.func,
  onClickCancel: PropTypes.func,
  snoozeTimeOptions: PropTypes.arrayOf(PropTypes.object),
  styles: aphroditePropType,
};

export default SnoozeForm;
