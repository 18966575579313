import gql from 'graphql-tag';

const GET_COHORT_PARENTS = gql`
  query getCohortParents($limit: Int, $query: JSON, $skip: Int) {
    getCohortParents(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        pharmacy_approval_user_id
        pharmacy_approval_date
        pm_approval_user_id
        pm_approval_date
        pm_approval_user_name
        pharmacy_approval_user_name
        send_to_lob_user_id
        send_to_lob_date
        updated_at
        created_at
      }
    }
  }
`;

export default GET_COHORT_PARENTS;
