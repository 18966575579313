import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withPhoneMutation from '../../hoc/withPhoneMutation';
import { setWorkstationPhoneId } from '../../redux/queryReducer';
import PhoneListItemActions from './PhoneListItemActions';

class PhoneListItemActionsContainer extends Component {
  static propTypes = {
    item: PropTypes.object,
    mutateDeletePhone: PropTypes.func,
    onAssignToWorkstation: PropTypes.func,
    onClickEdit: PropTypes.func,
    workstationPhoneId: PropTypes.number,
  };

  handleAssignToWorkstation = event => {
    event.stopPropagation();
    this.props.onAssignToWorkstation(this.props.item.id);
  };

  handleClickEdit = event => {
    event.stopPropagation();

    if (this.props.onClickEdit) {
      this.props.onClickEdit();
    }
  };

  handleDeletePhone = async event => {
    event.stopPropagation();

    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this phone?')) {
      await this.props.mutateDeletePhone({ id: this.props.item.id });
    }
  };

  render = () => (
    <PhoneListItemActions
      isAssignedWorkstation={this.props.item.id === this.props.workstationPhoneId}
      onAssignToWorkstation={this.handleAssignToWorkstation}
      onClickEdit={this.handleClickEdit}
      onDeletePhone={this.handleDeletePhone}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  onAssignToWorkstation(phoneId) {
    dispatch(setWorkstationPhoneId({ phoneId }));
  },
});

export default withPhoneMutation(connect(null, mapDispatchToProps)(PhoneListItemActionsContainer));
