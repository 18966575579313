import gql from 'graphql-tag';

const GET_PLANS_BY_FILTER = gql`
  query getPlansByFilter($limit: Int, $filter: String) {
    getPlansByFilter(limit: $limit, filter: $filter) {
      data {
        id
        name
      }
      total
    }
  }
`;

export default GET_PLANS_BY_FILTER;
