import {
  animation,
  border,
  colors,
  Div,
  FlexCentered,
  padding,
  position,
  Separator,
  space,
  transition,
  zIndex,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { groupBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RxItemHeader from './RxItemHeader';
import RxItemPharmacySection from './RxItemPharmacySection';

const SEPARATOR_ANIMATION = {
  from: { transform: 'scaleX(0.25)' },
  to: { transform: 'scaleX(1)' },
};

const CONTAINER_INACTIVE_STYLES = {
  backgroundColor: colors.gray99,
};

const sx = StyleSheet.create({
  container: {
    ...border.build('left'),
    ...border.build('right'),
    ...border.build('top'),
    ...padding.build(space.default),
    backgroundColor: colors.white,
    position: 'relative',
    zIndex: zIndex[1],

    ':before': {
      ...position.cover,
      ...transition.build(),
      boxShadow: `0 0 ${space.half} 1px ${colors.alpha(colors.black, 0.1)}`,
      content: '" "',
      opacity: 0,
    },

    ':first-of-type': {
      ...border.buildRadius('top', space.half),
      ':before': border.buildRadius('top', space.half),
    },
  },
  containerEmpty: {
    ...CONTAINER_INACTIVE_STYLES,
    color: colors.gray62,
  },
  containerExpanded: {
    backgroundColor: colors.white,
    zIndex: zIndex[2],
    ':before': {
      opacity: 1,
    },
  },
  containerInactive: CONTAINER_INACTIVE_STYLES,
  pharmacySection: {
    width: '100%',
  },
  separator: {
    ...animation.build({
      ease: animation.ease.outExpo,
      name: [SEPARATOR_ANIMATION, animation.fadeIn],
    }),
    color: colors.theme.primary.default,
    cursor: 'pointer',
    height: 2,
    opacity: 0,
    transform: 'scaleX(1)',
    transformOrigin: 'left center',
  },
});

const sortRxClaimsByDate = rxClaims =>
  rxClaims.slice(0).sort((a, b) => {
    const momentA = moment(a.dispense_date);
    const momentB = moment(b.dispense_date);

    if (momentA.isAfter(momentB)) return -1;
    if (momentA.isBefore(momentB)) return 1;
    return 0;
  });

const formatRxClaimsList = rxClaims =>
  sortRxClaimsByDate(rxClaims).map((claim, index) => ({
    ...claim,
    isLatest: index === 0,
    isOriginal: index === rxClaims.length - 1,
  }));

export default class RxItem extends Component {
  static propTypes = {
    allClaims: PropTypes.arrayOf(PropTypes.object),
    isActive: PropTypes.bool,
    isEmpty: PropTypes.bool,
    onToggleActiveStatus: PropTypes.func,
    postalCodesByPharmacies: PropTypes.arrayOf(PropTypes.object),
    rxClaims: PropTypes.arrayOf(PropTypes.object),
  };

  state = { isExpanded: false };

  componentWillMount = () => this.buildLists();
  componentWillReceiveProps = ({ rxClaims }) => {
    if (rxClaims !== this.props.rxClaims) {
      this.buildLists(rxClaims);
    }
  };

  pharmacyList = [];
  rxClaimsList = [];

  buildLists = rxClaims => {
    if (this.props.isEmpty) return;
    this.rxClaimsList = formatRxClaimsList(rxClaims || this.props.rxClaims);
    this.pharmacyList = groupBy(this.rxClaimsList, ({ pharmacy: { name } }) => name);
  };

  buildContainerStyles = () => [
    sx.container,
    this.props.isActive ? null : sx.containerInactive,
    this.state.isExpanded ? sx.containerExpanded : null,
    this.props.isEmpty ? sx.containerEmpty : null,
  ];

  handleToggleExpanded = () => this.setState({ isExpanded: !this.state.isExpanded });

  renderPharmacyList = () =>
    Object.keys(this.pharmacyList).map(pharmacy => (
      <RxItemPharmacySection rxClaims={this.pharmacyList[pharmacy]} key={pharmacy} />
    ));

  renderContent = () =>
    this.state.isExpanded ? (
      <Div>
        <Separator horizontal styles={sx.separator} />
        {this.renderPharmacyList()}
      </Div>
    ) : null;

  render = () =>
    this.props.isEmpty ? (
      <FlexCentered styles={this.buildContainerStyles()}>No active meds</FlexCentered>
    ) : (
      <Div styles={this.buildContainerStyles()}>
        <RxItemHeader
          allClaims={this.props.allClaims}
          isActive={this.props.isActive}
          isExpanded={this.state.isExpanded}
          onToggleActiveStatus={this.props.onToggleActiveStatus}
          onToggleExpand={this.handleToggleExpanded}
          rxClaims={this.rxClaimsList}
          postalCodesByPharmacies={this.props.postalCodesByPharmacies}
        />
        {this.renderContent()}
      </Div>
    );
}
