import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useLazyQuery } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite';
import { AutoComplete, message, Modal } from 'antd';

import GET_DSA_AUTOCOMPLETE from '../../graphql/getDSAAutocomplete';
import generateVariables from './utils/ReAssignDSA.utils';

const sx = StyleSheet.create({
  searchBox: { width: '80%' },
  label: {
    margin: '0 0 2px 0 !important',
  },
});

const { Option } = AutoComplete;

const ReAssignDSA = ({ submitReassignment, onReassignCancel, visible, reassignCount }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedOption, setSelectedOption] = useState({});
  const [options, setOptions] = useState([]);

  const [getDSAAutocomplete, { loading: isDSAAutocompleteFetching, data }] = useLazyQuery(
    GET_DSA_AUTOCOMPLETE,
    {
      onError: error => {
        message.error(`Error fetching PBS Names: ${error.message}`);
      },
    },
  );

  useEffect(() => {
    if (data?.users?.data) {
      setOptions(data.users.data);
    }
  }, [data]);

  const debouncedFetchOptions = value => {
    const variables = generateVariables(value);
    getDSAAutocomplete({ variables });
  };

  const debouncedSearch = useCallback(
    debounce(value => {
      debouncedFetchOptions(value);
    }, 1000),
    [],
  );

  const onSearch = value => {
    setSearchValue(value);
    setSelectedOption({});
    if (value.length === 0) {
      setOptions([]);
    } else if (value.length > 2) {
      debouncedSearch(value);
    }
  };

  const onSelect = (value, option) => {
    const selectedOption = options[option.index];
    setSearchValue(`${selectedOption.first_name} ${selectedOption.last_name}`);
    setSelectedOption({
      first_name: selectedOption.first_name,
      last_name: selectedOption.last_name,
      id: selectedOption.id,
    });
  };

  useEffect(() => {
    if (!visible) {
      setSearchValue('');
      setSelectedOption({});
      setOptions([]);
    }
  }, [visible]);

  return (
    <Modal
      title="Reassign"
      onOk={() => submitReassignment(selectedOption)}
      onCancel={onReassignCancel}
      open={visible}
      okText="Submit"
    >
      <p>{`You have selected ${reassignCount} leads`}</p>
      <p className={css(sx.label)}>Reassign to</p>
      <AutoComplete
        placeholder="Enter PBS Name"
        value={searchValue}
        onSearch={value => onSearch(value)}
        onSelect={onSelect}
        className={css(sx.searchBox)}
        loading={isDSAAutocompleteFetching}
      >
        {options.map((option, index) => (
          <Option key={option.id} index={index} value={option.id}>
            {`${option.first_name} ${option.last_name}`}
          </Option>
        ))}
      </AutoComplete>
    </Modal>
  );
};

ReAssignDSA.propTypes = {
  submitReassignment: PropTypes.func,
  onReassignCancel: PropTypes.func,
  visible: PropTypes.bool,
  reassignCount: PropTypes.number,
};

export default ReAssignDSA;
