import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import { colors, FlexCentered, font, Icon, position, Row } from '@zipdrug/ui';

const sx = StyleSheet.create({
  backButton: {
    color: colors.theme.primary.default,
    cursor: 'pointer',
  },
  backButtonIcon: {
    width: font.size.medium,
  },
  container: {
    height: font.size.large,
    marginBottom: font.size.small,
    userSelect: 'none',
  },
  title: {
    ...position.cover,
    cursor: 'default',
  },
});

const DropdownHeader = ({ onClickCancel, showBackButton, styles, title }) => (
  <Row styles={[sx.container, styles]}>
    <FlexCentered styles={sx.title}>{title}</FlexCentered>
    {showBackButton && (
      <FlexCentered onClick={onClickCancel} styles={sx.backButton}>
        <Icon direction="left" name="arrow" styles={sx.backButtonIcon} />
      </FlexCentered>
    )}
  </Row>
);

DropdownHeader.propTypes = {
  onClickCancel: PropTypes.func,
  showBackButton: PropTypes.bool,
  styles: aphroditePropType,
  title: PropTypes.node,
};

DropdownHeader.defaultProps = {
  showBackButton: true,
};

export default DropdownHeader;
