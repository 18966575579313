import { css, StyleSheet } from 'aphrodite';
import PropTypes from 'prop-types';
import React from 'react';
import { colors, transition } from '@zipdrug/ui';

const sx = StyleSheet.create({
  autoLogoutContainer: {
    height: '50px',
    backgroundColor: 'white',
    position: 'fixed',
    top: '10px',
    right: '10px',
    zIndex: '999',
    boxShadow: '0 0 1rem 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 20px',
  },
  button: {
    ...transition.build(),
    borderRadius: 4,
    cursor: 'pointer',
    userSelect: 'none',
    color: colors.theme.primary.default,
    backgroundColor: colors.gray92,
    ':hover': { backgroundColor: colors.gray87 },
  },
  alertIcon: {
    marginLeft: '15px',
  },
  warningText: {
    color: '#E43D50',
    fontWeight: 'bold',
    marginBottom: 0,
    marginRight: '10px',
  },
});

const AutoLogoutWarning = ({ remainingTime, onDismiss }) => {
  const minutes = Math.round(remainingTime / (1000 * 60));

  return (
    <div>
      <div className={css(sx.autoLogoutContainer)}>
        <p className={css(sx.warningText)}>
          You will be logged out in {minutes} minutes due to inactivity
        </p>
        <button onClick={onDismiss} className={css(sx.button)}>
          Dismiss
        </button>
      </div>
    </div>
  );
};

AutoLogoutWarning.propTypes = {
  remainingTime: PropTypes.number,
  onDismiss: PropTypes.func,
};

export default AutoLogoutWarning;
