import { get } from 'lodash';
import PropTypes from 'prop-types';
import { createElement } from 'react';

const AudioActivityItem = ({ activity, componentRenderer, ...props }) => {
  const alert = get(activity, 'call.alert');

  return createElement(componentRenderer, {
    ...props,
    activity,
    hasAlert: alert && !alert.completed_at && !alert.deleted_at,
  });
};

AudioActivityItem.propTypes = {
  activity: PropTypes.object,
  componentRenderer: PropTypes.func,
};

export default AudioActivityItem;
