import { call } from '@zipdrug/react-api-sdk';
import _get from 'lodash/get';
import { alertOnError } from '@zipdrug/ui';
import { omit } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps, withHandlers } from 'recompose';
import withSelectedPatientId from './withSelectedPatientId';
import withWorkstationPhoneId from './withWorkstationPhoneId';
import withCallCenter from './withCallCenter';
import withRoles from '../hoc/withRoles';

export default ComponentToWrap =>
  compose(
    withCallCenter,
    graphql(call.mutateStartOutboundCall, { name: 'mutateStartOutboundCall' }),
    withRoles,
    withSelectedPatientId,
    withWorkstationPhoneId,
    withHandlers({
      onCallPhone: props => to_phone_id => {
        if (
          _get(props.currentUser, 'feature_flags.callCenter') &&
          props.isCallCenterActivatedContext
        ) {
          props.setOutboundToPhoneIdContext(to_phone_id);
        } else {
          return alertOnError(
            props.mutateStartOutboundCall({
              variables: {
                from_phone_id: props.workstationPhoneId,
                patient_id: props.selectedPatientId,
                to_phone_id,
              },
            }),
          );
        }
      },
    }),
    mapProps(props => omit(props, 'mutateStartOutboundCall')),
  )(ComponentToWrap);
