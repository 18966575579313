import { activities } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form';
import ActivityPrompt from './ActivityPrompt';

const ActivityPromptContainer = reduxForm({
  destroyOnUnmount: false,
  form: 'activityPrompt',
})(ActivityPrompt);

export default compose(
  graphql(activities.mutateCreateNote, { name: 'mutateCreateNote' }),
  withHandlers({
    onSubmit: props => async ({ note }, _, { reset, submitting }) => {
      if (props.isLoading || !note || submitting) return;

      alertOnError(
        props.mutateCreateNote({
          variables: {
            patient_id: props.patientId,
            text: note,
          },
        }),
      )
        .then(() => {
          reset();
          if (props.onSubmit) {
            props.onSubmit();
          }
        })
        .then(() => {
          return props.loadMoreRows(true);
        });
    },
  }),
)(ActivityPromptContainer);
