import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Button, Modal, Typography, Form, Input, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite';
import { UPDATE_PHARMACY_NOTE } from '../../graphql/pharmacyNotes';

// CSS styles
const sx = StyleSheet.create({
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '583px',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  uploadButton: {
    padding: '8px 10px',
    fontSize: '16px',
  },
});

const { Title } = Typography;
const EditPharmacyNotesModal = ({ updateNotes, notesInfo, onCloseModal, visible }) => {
  const [form] = Form.useForm();
  const [uploadedFile, setUploadedFile] = useState();

  const [updatePharmacyNote] = useMutation(UPDATE_PHARMACY_NOTE, {
    onError: error => {
      message.error(`Error Updating Note: ${error.message}`);
    },
    onCompleted: () => {
      message.success('Save successful');
      setUploadedFile(null);
      updateNotes();
      form.resetFields();
      onCloseModal();
    },
  });

  const onCancel = () => {
    form.resetFields();
    onCloseModal();
  };

  const submitForm = () => {
    const newFormValues = form.getFieldsValue();
    try {
      updatePharmacyNote({
        variables: {
          id: notesInfo.id,
          file: uploadedFile,
          text: newFormValues.text,
        },
      });
    } catch (info) {
      return info;
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      onOk={() => submitForm()}
      onCancel={() => onCancel()}
      open={visible}
      okText="Save"
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={() => onCancel()} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => submitForm()} className={css(sx.button)}>
          Save
        </Button>,
      ]}
    >
      <Title className="modelHeaderText" level={3}>
        Edit Note
      </Title>
      <Form name="modify-form-ref" form={form} layout="vertical">
        <p className={css(sx.label)}>Add Note</p>
        <Form.Item name="text" rules={[{ required: true, message: 'Note is required.' }]}>
          <Input.TextArea defaultValue={notesInfo.text} />
        </Form.Item>
        <div>
          <div className={css(sx.label)}>
            ADD ATTACHMENT. JPG OR PDF ONLY.
            <Upload
              name="file"
              key="file-upload"
              accept={'.jpg,.pdf'}
              multiple={false}
              beforeUpload={() => false}
              maxCount={'1'}
              onChange={({ file }) => {
                setUploadedFile(file);
              }}
              className={css(sx.uploadButton)}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
            <div>
              {notesInfo?.file_name !== null && notesInfo?.file_name !== undefined
                ? `Existing File: ${notesInfo.file_name}`
                : ''}
            </div>
            <div className={css(sx.errorMessage)}>
              {notesInfo.file_upload_status === 'fail' ? `${notesInfo.file_upload_message}` : ''}
            </div>
            <div className={css(sx.successMessage)}>
              {notesInfo.file_upload_status === 'success' ||
              notesInfo.file_upload_status === 'processing'
                ? `${notesInfo.file_upload_message}`
                : ''}
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

EditPharmacyNotesModal.propTypes = {
  updateNotes: PropTypes.func,
  notesInfo: PropTypes.object,
  onCloseModal: PropTypes.func,
  visible: PropTypes.bool,
};

export default EditPharmacyNotesModal;
