import gql from 'graphql-tag';

const GET_SERVICE_TYPES = gql`
  query pharmacyServiceType($limit: Int, $query: JSON, $skip: Int) {
    pharmacyServiceType(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        pharmacy_service_type
        pharmacy_service_sub_type
      }
    }
  }
`;

export default GET_SERVICE_TYPES;
