/* eslint-disable no-case-declarations */
import cloneDeep from 'lodash/cloneDeep';

const sortDirection = {
  ascend: 'ASC',
  descend: 'DESC',
};

const generateOrderByUtil = (key, sortKey, direction) => {
  const payload = {};
  payload[key] = {
    sortKey,
    direction: sortDirection[direction],
  };
  return payload;
};

const generateOrderBy = sortOrder => {
  const sortPayload = {
    order_by: {},
  };

  switch (sortOrder?.columnKey) {
    case 'enroller':
      sortPayload.order_by = generateOrderByUtil('user', 'first_name', sortOrder.order);
      return sortPayload;
    case 'title':
      sortPayload.order_by = generateOrderByUtil('user', 'title', sortOrder.order);
      return sortPayload;
    case 'language':
      sortPayload.order_by = generateOrderByUtil('language', 'language', sortOrder.order);
      return sortPayload;
    case 'teamLead':
      sortPayload.order_by = generateOrderByUtil('teamLead', 'first_name', sortOrder.order);
      return sortPayload;
    case 'line_of_business':
      sortPayload.order_by = generateOrderByUtil(
        'lineOfBusiness',
        sortOrder.columnKey,
        sortOrder.order,
      );
      return sortPayload;
    case 'status':
      sortPayload.order_by = generateOrderByUtil(
        sortOrder.columnKey,
        sortOrder.columnKey,
        sortOrder.order,
      );
      return sortPayload;
    default:
      if (sortOrder?.model) {
        sortPayload.order_by = generateOrderByUtil(
          sortOrder.model,
          sortOrder.columnKey,
          sortOrder.order,
        );
      }
      return sortPayload;
  }
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateEnrollerVariables = actions => {
  const { sorter, pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  // Generate Order_By
  const order_by = sorter ? generateOrderBy(sorter) : {};

  return {
    ...pageOptions,
    ...order_by,
  };
};

export default generateEnrollerVariables;
