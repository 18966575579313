import { StyleSheet } from 'aphrodite/no-important';
import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { colors, Column, Div, font, Icon, MetaRow, Row, space, Tag } from '@zipdrug/ui';

const sx = StyleSheet.create({
  avatar: {
    marginRight: font.size.small,
  },
  container: {
    userSelect: 'none',
  },
  header: {
    userSelect: 'text',
  },
  headerMeta: {
    color: colors.gray57,
    marginTop: space.quarter,
    whiteSpace: 'nowrap',
  },
  headerTitle: {
    color: colors.gray20,
    fontSize: font.size.medium,
  },
  headerTitlePending: {
    fontStyle: 'italic',
  },
  tag: {
    backgroundColor: colors.orange,
    marginLeft: space.quarter,
  },
  toggleButton: {
    cursor: 'pointer',
    fontSize: font.size.medium,
    paddingLeft: space.two,
  },
  toggleButtonIcon: {
    marginLeft: space.half,
  },
});

export default class SettingsAccordionListItemHeader extends Component {
  static propTypes = {
    avatarRenderer: PropTypes.node,
    editingMetaText: PropTypes.string,
    isEditing: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
    isPending: PropTypes.bool,
    metaItems: PropTypes.arrayOf(PropTypes.string),
    tag: PropTypes.string,
    title: PropTypes.string,
  };

  renderAvatar = () =>
    this.props.avatarRenderer
      ? cloneElement(this.props.avatarRenderer, { styles: sx.avatar })
      : null;

  renderHeader = () => (
    <Column styles={sx.header}>
      <Row align="center">
        <Div styles={[sx.headerTitle, this.props.isPending ? sx.headerTitlePending : null]}>
          {this.props.title}
        </Div>
        {this.props.tag && <Tag styles={sx.tag} text={this.props.tag} />}
      </Row>
      {this.renderMetaSection()}
    </Column>
  );

  renderMetaSection = () => {
    const { editingMetaText, isEditing, metaItems } = this.props;

    let items = [...metaItems];
    if (isEditing && editingMetaText) items = [editingMetaText];

    return <MetaRow items={items} />;
  };

  renderToggleButton = () => (
    <Row align="center" styles={sx.toggleButton} onClick={this.props.onClick}>
      <Div>Options</Div>
      <Icon
        direction={this.props.isOpen ? 'up' : 'down'}
        filled
        name="caret"
        styles={sx.toggleButtonIcon}
      />
    </Row>
  );

  render = () => (
    <Row justify="space-between" styles={sx.container}>
      <Row align="center">
        {this.renderAvatar()}
        {this.renderHeader()}
      </Row>
      {!this.props.isEditing && this.renderToggleButton()}
    </Row>
  );
}
