import { daysDiff, startOfDay } from './dateUtils';
import SegmentType from './SegmentType';

export default function buildSegment({ brand_name, fromDate, isAlternative, type, untilDate }) {
  /* eslint-disable no-param-reassign */
  if (!type) {
    type = isAlternative ? SegmentType.AlternativeFill : SegmentType.Fill;
  }

  // Any fills after today (should just be seed data) are untracked
  const now = startOfDay();
  if (fromDate > now) type = SegmentType.Untracked;

  const segment = {
    daysInRange: daysDiff(untilDate, fromDate),
    fromDate,
    type,
    untilDate,
  };
  if (brand_name) segment.brand_name = brand_name;

  return segment;
}
