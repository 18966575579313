/* eslint-disable no-console */
import { colors, Column } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { useLazyQuery } from '@apollo/react-hooks';
import { omit, isEmpty, differenceBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { OutcomesContext } from 'contexts/OutcomesProvider';
import ColumnHeader from '../ColumnHeader';
import ActivityListContainer from './ActivityListContainer';
import ActivityPromptContainer from './ActivityPromptContainer';
import queryActivities from './graphql/queryActivities.js';
import getEnrollStatusOutcomes from './graphql/getEnrollStatusOutcomes';
import getCallOutcomes from './graphql/getCallOutcomes';
import getOutcomeTypes from './graphql/getOutcomeTypes';

const sx = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '88vh',
    minWidth: '45.5rem',
  },
});

const calculateTotalActivityAlertsCount = alertsCountByType => {
  const activityAlertCounts = Object.values(omit(alertsCountByType, 'medication'));
  return activityAlertCounts.length ? activityAlertCounts.reduce((x, y) => x + y) : null;
};

const ActivityFeed = ({
  activities,
  alertsCountByType,
  currentPharmacy,
  isLoading,
  loadMoreRows,
  selectedPatientId,
  styles,
  subscribeToNewActivities,
  totalActivities,
}) => {
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const [outcomesFromContext, setOutcomesFromContext] = useState({});
  const { outcomesUpdatedContext, setOutcomesContext } = useContext(OutcomesContext);
  const [enrollStatusOutcomes, setEnrollStatusOutcomes] = useState([]);
  const [callOutcomes, setCallOutcomes] = useState([]);
  const [outcomeReasonTypes, setOutcomeReasonTypes] = useState([]);
  const [isCallOutcomesCompleted, setIsCallOutcomesCompleted] = useState(false);
  const [isEnrollOutcomesCompleted, setIsEnrollOutcomesCompleted] = useState(false);
  const [activitiesWithOutcomes, setActivitiesWithOutcomes] = useState([]);
  const [savedPatientId, setSavedPatientId] = useState(null);
  const [savedActivities, setSavedActivities] = useState([]);

  const outcomeTypeMap = {
    enrollStatusOutcome: {
      altArr: callOutcomes,
      arrProp: 'enroll_status_outcome_id',
      altArrProp: 'call_outcome_id',
      propName: 'enrollStatusOutcome',
      altPropName: 'callOutcome',
    },
    callOutcome: {
      altArr: enrollStatusOutcomes,
      arrProp: 'call_outcome_id',
      altArrProp: 'enroll_status_outcome_id',
      propName: 'callOutcome',
      altPropName: 'enrollStatusOutcome',
    },
  };

  const [getNewActivities] = useLazyQuery(queryActivities, {
    fetchPolicy: 'network-only',
    onCompleted: ({ activities }) => {
      updateOutcomes(activities?.data);
    },
  });

  const [getNewCallOutcomes, { loading: isCallOutcomesLoading }] = useLazyQuery(getCallOutcomes, {
    onCompleted: ({ getCallOutcomes }) => {
      setCallOutcomes(getCallOutcomes?.data);
      if (getCallOutcomes?.data) {
        updateActivities(activities, 'callOutcome', getCallOutcomes?.data, false);
      }
    },
  });

  const [getNewEnrollStatusOutcomes, { loading: isEnrollOutcomesLoading }] = useLazyQuery(
    getEnrollStatusOutcomes,
    {
      onCompleted: ({ getEnrollStatusOutcomes }) => {
        setEnrollStatusOutcomes(getEnrollStatusOutcomes?.data);
        if (getEnrollStatusOutcomes?.data) {
          updateActivities(activities, 'enrollStatusOutcome', getEnrollStatusOutcomes?.data, false);
        }
      },
    },
  );

  const [getOutcomeReasonTypes, { loading: isOutcomeTypesLoading }] = useLazyQuery(
    getOutcomeTypes,
    {
      onCompleted: ({ outcomeReasonTypes }) => {
        setOutcomeReasonTypes(outcomeReasonTypes?.data);
      },
    },
  );

  const createNewOutcome = async (id, type) => {
    const newOutcomeIds =
      outcomesFromContext[
        type === 'call' ? 'outcomeReasonTypeIds' : 'enroll_status_outcome_reason_ids'
      ];
    const outcomeReasons = outcomeReasonTypes
      .filter(o => newOutcomeIds.includes(o.id))
      .map(obj => {
        return {
          outcome_reason_type_id: obj.id,
          outcome_type: obj,
        };
      });
    const newOutcomeObj = {
      id,
      notes: type === 'call' ? outcomesFromContext.notes : outcomesFromContext.enroll_notes,
      patient_id: selectedPatientId,
    };

    if (type === 'call') {
      newOutcomeObj.call_outcome_reason = outcomeReasons;
    } else {
      newOutcomeObj.enroll_status_outcome_reason = outcomeReasons;
    }

    return newOutcomeObj;
  };

  const updateOutcomes = async activities => {
    if (activities) {
      let updatedActivity = differenceBy(activities, activitiesWithOutcomes, 'id')[0];
      if (!updatedActivity) {
        // account for warm transfer which is a previous call that is updated with outcome later
        if (activities[1]?.call_outcome_id !== activitiesWithOutcomes[1]?.call_outcome_id) {
          updatedActivity = activities[1];
        }
      }
      // if enroll status outcome, update enrollStatusOutcomes
      if (updatedActivity?.enroll_status_outcome_id) {
        const newOutcome = await createNewOutcome(
          updatedActivity.enroll_status_outcome_id,
          'enroll_status',
        );
        setEnrollStatusOutcomes([...enrollStatusOutcomes, newOutcome]);
        updateActivities(
          activities,
          'enrollStatusOutcome',
          [...enrollStatusOutcomes, newOutcome],
          true,
        );
      }
      // if call outcome, update callOutcomes
      if (updatedActivity?.call_outcome_id) {
        const newOutcome = await createNewOutcome(updatedActivity.call_outcome_id, 'call');
        setCallOutcomes([...callOutcomes, newOutcome]);
        updateActivities(activities, 'callOutcome', [...callOutcomes, newOutcome], true);
      }
      setScrollToBottom(true);
    }
  };

  const updateActivities = (activitiesData, activityPropName, arrayToAdd, fromOutcomesUpdate) => {
    if (activitiesData && activitiesData?.length > 0) {
      activitiesData.map(a => {
        let outcome;
        const outcomeType = outcomeTypeMap[activityPropName];
        if (a[outcomeType.arrProp]) {
          outcome = arrayToAdd.find(item => item.id === a[outcomeType.arrProp]);
          a[outcomeType.propName] = outcome;
        } else if (a[outcomeType.altArrProp]) {
          outcome = outcomeType.altArr.find(item => item.id === a[outcomeType.altArrProp]);
          a[outcomeType.altPropName] = outcome;
        }
        return a;
      });
      setSavedActivities(activitiesData);

      if (!fromOutcomesUpdate) {
        if (activityPropName === 'callOutcome') {
          setIsCallOutcomesCompleted(true);
        } else {
          setIsEnrollOutcomesCompleted(true);
        }
      } else {
        setActivitiesWithOutcomes(activitiesData);
      }
    }
  };

  useEffect(() => {
    if (selectedPatientId !== savedPatientId && !isEmpty(activities)) {
      setSavedPatientId(selectedPatientId);
      setSavedActivities(activities);
      isLoading = true;
      const outcomeVariables = {
        variables: {
          patientId: selectedPatientId,
        },
      };
      const outcomeTypesVariable = {
        variables: {
          patient_id: selectedPatientId,
        },
      };
      getOutcomeReasonTypes(outcomeTypesVariable);
      getNewCallOutcomes(outcomeVariables);
      getNewEnrollStatusOutcomes(outcomeVariables);
    } else if (selectedPatientId === savedPatientId && activities.length > savedActivities.length) {
      setSavedActivities(activities);
      updateActivities(activities, 'enrollStatusOutcome', enrollStatusOutcomes, false);
      updateActivities(activities, 'callOutcome', callOutcomes, false);
    } else if (selectedPatientId !== savedPatientId && isEmpty(activities)) {
      setSavedActivities([]);
      setActivitiesWithOutcomes([]);
    }
  }, [selectedPatientId, activities]);

  useEffect(() => {
    if (!isEmpty(outcomesUpdatedContext)) {
      getNewActivities({
        variables: {
          limit: 10,
          query: { patient_id: selectedPatientId },
          skip: 0,
        },
      });
      setOutcomesFromContext(outcomesUpdatedContext);
      setOutcomesContext({});
    }
  }, [outcomesUpdatedContext]);

  useEffect(() => {
    if (isCallOutcomesCompleted && isEnrollOutcomesCompleted) {
      isLoading = false;
      setActivitiesWithOutcomes(savedActivities);
      setIsCallOutcomesCompleted(false);
      setIsEnrollOutcomesCompleted(false);
    }
  }, [isCallOutcomesCompleted, isEnrollOutcomesCompleted]);

  return (
    <Column justify="space-between" styles={[sx.container, styles]}>
      <ColumnHeader
        alertCount={calculateTotalActivityAlertsCount(alertsCountByType)}
        text="Activity"
      />
      <ActivityListContainer
        activities={activitiesWithOutcomes}
        currentPharmacy={currentPharmacy}
        isLoading={
          isLoading && isCallOutcomesLoading && isEnrollOutcomesLoading && isOutcomeTypesLoading
        }
        loadMoreRows={loadMoreRows}
        patientId={selectedPatientId}
        subscribeToNewActivities={subscribeToNewActivities}
        totalActivities={totalActivities}
        scrollToBottom={scrollToBottom}
        setScrollToBottom={setScrollToBottom}
      />
      <ActivityPromptContainer
        isLoading={isLoading}
        patientId={selectedPatientId}
        loadMoreRows={loadMoreRows}
      />
    </Column>
  );
};

ActivityFeed.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  alertsCountByType: PropTypes.object,
  currentPharmacy: PropTypes.object,
  isLoading: PropTypes.bool,
  loadMoreRows: PropTypes.func,
  selectedPatientId: PropTypes.number,
  styles: aphroditePropType,
  subscribeToNewActivities: PropTypes.func,
  totalActivities: PropTypes.number,
};

ActivityFeed.defaultProps = {
  activities: [],
};

export default ActivityFeed;
