import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Input } from 'antd';

const sx = StyleSheet.create({
  label: {
    margin: '0 0 2px 0 !important',
  },
});

const { TextArea } = Input;

const UpdatePharmacyActiveStatusModal = ({
  submitInactiveComment,
  onHoldCancel,
  visible,
  pharmacyCount,
}) => {
  const [zipdrugActiveComment, setZipdrugActiveComment] = useState('');

  useEffect(() => {
    if (!visible) {
      setZipdrugActiveComment('');
    }
  }, [visible]);

  return (
    <Modal
      title="Put on Hold"
      onOk={() => submitInactiveComment(false, zipdrugActiveComment)}
      onCancel={onHoldCancel}
      open={visible}
      okText="Submit"
    >
      <p>{`You have selected ${pharmacyCount} pharmacies to put on hold.`}</p>
      <p className={css(sx.label)}>Comment</p>
      <TextArea
        maxLength={150}
        value={zipdrugActiveComment}
        onChange={e => setZipdrugActiveComment(e.target.value)}
      />
    </Modal>
  );
};

UpdatePharmacyActiveStatusModal.propTypes = {
  submitInactiveComment: PropTypes.func,
  onHoldCancel: PropTypes.func,
  visible: PropTypes.bool,
  pharmacyCount: PropTypes.number,
};

export default UpdatePharmacyActiveStatusModal;
