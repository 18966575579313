import { activities } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { omit } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps, withHandlers } from 'recompose';

export default Component =>
  compose(
    graphql(activities.mutateCreateDelivery, { name: 'mutateCreateDelivery' }),
    withHandlers({
      onCreateDelivery: ({ mutateCreateDelivery }) => async values =>
        alertOnError(
          mutateCreateDelivery({
            variables: values,
          }),
        ),
    }),
    mapProps(props => omit(props, 'mutateCreateDelivery')),
  )(Component);
