// featureFlags.js

const FeatureFlags = Object.freeze({
  CHURNED: 'churned',
  FEATURE_X: 'featureX',
  // Add other feature flags here...
});

export default FeatureFlags;

export const DISABLED_FEATURES = ['churned'];
