import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Tag } from 'antd';

const NewTag = ({ created_at, toggleToolTip, style }) => {
  const currentDate = new Date();
  const expirationDate = new Date(created_at);

  expirationDate.setDate(expirationDate.getDate() + 30);

  if (expirationDate > currentDate) {
    return (
      <>
        {toggleToolTip ? (
          <Tooltip title="Pharmacies that have just gone live will have this tag">
            <Tag className={style} color="#F5F5F5">
              New
            </Tag>
          </Tooltip>
        ) : (
          <Tag className={style} color="#F5F5F5">
            New
          </Tag>
        )}
      </>
    );
  }
  return null;
};

NewTag.propTypes = {
  created_at: PropTypes.number,
  toggleToolTip: PropTypes.bool,
  style: PropTypes.any,
};

export default NewTag;
