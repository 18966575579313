import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';

const SET_FILTER_PANEL_VISIBILITY = 'care-site/filterPanel/SET_FILTER_PANEL_VISIBILITY';

const initialState = {
  isFilterPanelOpen: false,
};

export default handleActions(
  {
    [SET_FILTER_PANEL_VISIBILITY]: (state, { payload: { isFilterPanelOpen } }) =>
      update(state, {
        isFilterPanelOpen: { $set: isFilterPanelOpen },
      }),
  },
  initialState,
);

// eslint-disable-next-line max-len
export const setFilterPanelVisibility = createAction(
  SET_FILTER_PANEL_VISIBILITY,
  ({ isFilterPanelOpen }) => ({ isFilterPanelOpen }),
);
