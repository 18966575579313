/* eslint-disable react/prop-types */
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Table } from 'antd';

const sx = StyleSheet.create({
  marginWrapper: {
    margin: '10px',
  },
});

const ChainPharmacyList = data => {
  const generateColumns = () => {
    const headers = [
      {
        title: 'Pharmacy Name',
        width: 250,
        dataIndex: 'chain_pharmacy',
        key: 'name',
        render: record => record?.name,
      },
      {
        title: 'NPI',
        dataIndex: 'npi',
        width: 150,
        key: 'npi',
      },
      {
        title: 'Plan Type',
        dataIndex: 'chain_pharmacy',
        key: 'is_commercial_only',
        width: 200,
        render: record => {
          return record?.is_commercial_only === true ? 'Commercial Only' : 'MA and Commercial';
        },
      },
    ];
    return headers;
  };

  return (
    <div className={css(sx.marginWrapper)}>
      <Table
        columns={generateColumns()}
        dataSource={data.data}
        size="middle"
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default ChainPharmacyList;
