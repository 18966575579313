/* eslint-disable no-case-declarations */
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';

const sortDirection = {
  ascend: 'ASC',
  descend: 'DESC',
};

const generateOrderByUtil = (key, sortKey, direction) => {
  const payload = {};
  if (sortKey === 'birthday') {
    if (direction === 'ascend') {
      payload[key] = {
        sortKey,
        direction: sortDirection.descend,
      };
    }
    if (direction === 'descend') {
      payload[key] = {
        sortKey,
        direction: sortDirection.ascend,
      };
    }
  } else {
    payload[key] = {
      sortKey,
      direction: sortDirection[direction],
    };
  }
  return payload;
};

const generateOrderBy = sortOrder => {
  const sortPayload = {
    order_by: {},
  };

  switch (sortOrder?.columnKey) {
    case 'patient':
      sortPayload.order_by = generateOrderByUtil('patient', 'first_name', sortOrder.order);
      return sortPayload;
    case 'language':
      sortPayload.order_by = generateOrderByUtil('patient', 'language', sortOrder.order);
      return sortPayload;
    /* case 'state':
      sortPayload.order_by = generateOrderByUtil('patient', 'state', sortOrder.order);
      return sortPayload; */
    case 'potential_pharmacy':
      sortPayload.order_by = generateOrderByUtil('potentialPharmacy', 'name', sortOrder.order);
      return sortPayload;
    case 'potential_pharmacy_service':
      sortPayload.order_by = generateOrderByUtil(
        'potentialPharmacyService',
        'pharmacy_service_type_id',
        sortOrder.order,
      );
      return sortPayload;
    case 'payer':
      sortPayload.order_by = generateOrderByUtil('payer', 'name', sortOrder.order);
      return sortPayload;
    case 'assigned_dsa':
      sortPayload.order_by = generateOrderByUtil('assignedDSA', 'first_name', sortOrder.order);
      return sortPayload;
    case 'cadence':
    case 'member_id':
    case 'plan_type':
    case 'lead_status':
      sortPayload.order_by = generateOrderByUtil('lead', sortOrder.columnKey, sortOrder.order);
      return sortPayload;
    case 'birthday':
      sortPayload.order_by = generateOrderByUtil('patient', 'birthday', sortOrder.order);
      return sortPayload;
    case 'campaign':
      sortPayload.order_by = generateOrderByUtil(sortOrder.columnKey, 'name', sortOrder.order);
      return sortPayload;
    default:
      if (sortOrder?.model) {
        sortPayload.order_by = generateOrderByUtil(
          sortOrder.model,
          sortOrder.columnKey,
          sortOrder.order,
        );
      }
      return sortPayload;
  }
};

const defaultQueryVariables = {
  query: {
    potentialPharmacyQuery: {
      status: 'active',
    },
  },
};

const generateQuery = filters => {
  if (!filters) {
    return { ...defaultQueryVariables };
  }

  return {
    query: {
      ...Object.entries(filters).reduce(
        (acc, curr) => {
          if (curr[1]) {
            switch (curr[0]) {
              case 'program_eligibility':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  program_eligibility: curr[1],
                };
                return acc;
              case 'patient':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  $or: _flatten(
                    _map(['first_name', 'last_name'], item => {
                      return _map(curr[1].split(' '), q => {
                        return { [item]: { $ilike: `%${q}%` } };
                      });
                    }),
                  ),
                };
                return acc;
              case 'language':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  language: curr[1],
                };
                return acc;
              case 'birthday':
                const adultFilterValue = curr[1].findIndex(value => value === 'adults');
                const minorFilterValue = curr[1].findIndex(value => value === 'minors');
                if (adultFilterValue > -1 && minorFilterValue < 0) {
                  acc.minorFilter = 'adult';
                }
                if (adultFilterValue < 0 && minorFilterValue > -1) {
                  acc.minorFilter = 'minor';
                }
                return acc;
              case 'campaign':
                acc.campaignQuery = {
                  ...acc?.campaignQuery,
                  name: curr[1],
                };
                return acc;
              case 'state':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  addressQuery: {
                    state: curr[1],
                  },
                };
                return acc;
              case 'potential_pharmacy_service':
                acc.pharmacyServiceQuery = curr[1][0];
                return acc;
              case 'potential_pharmacy':
                acc.potentialPharmacyQuery = {
                  ...acc.potentialPharmacyQuery,
                  id: curr[1],
                };
                return acc;
              case 'payer':
                acc.payerQuery = {
                  name: {
                    $ilike: `%${curr[1]}%`,
                  },
                };
                return acc;
              case 'assigned_dsa':
                acc.assignedDSAQuery = {
                  $or: _flatten(
                    _map(['first_name', 'last_name'], item => {
                      return _map(curr[1].split(' '), q => {
                        return { [item]: { $ilike: `%${q}%` } };
                      });
                    }),
                  ),
                };
                return acc;
              case 'plan_type':
                acc[curr[0]] = curr[1]?.[0];
                return acc;
              case 'member_id':
                acc[curr[0]] = {
                  $ilike: `%${curr[1]}%`,
                };
                return acc;
              case 'cadence':
                acc[curr[0]] = {
                  $or: curr[1],
                };
                return acc;
              case 'lead_status':
                const nullFilterValue = curr[1].findIndex(value => value === 'notCalled');
                if (nullFilterValue > -1) {
                  curr[1][nullFilterValue] = null;
                }
                acc[curr[0]] = {
                  $or: curr[1],
                };
                return acc;
              case 'special_notes':
                acc[curr[0]] = curr[1];
                return acc;
              default:
                return acc;
            }
          }
          return acc;
        },
        {
          ...defaultQueryVariables.query,
        },
      ),
    },
  };
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateVariables = actions => {
  const { sorter, filters, pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  // Generate Order_By
  const order_by = sorter ? generateOrderBy(sorter) : {};
  // Generate Query Variable
  const query = filters ? generateQuery(filters) : { ...defaultQueryVariables };

  return {
    ...pageOptions,
    ...query,
    ...order_by,
  };
};

export default generateVariables;
