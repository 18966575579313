import { colors, Div, font, padding, position, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import React from 'react';
import PageHeading from 'ui/PageHeading';
import SettingsPageTemplate from '../SettingsPageTemplate';
import Campaigns from '../../dashboard/components/Campaigns/Campaigns';

const sx = StyleSheet.create({
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  boldDescription: {
    fontWeight: 'bold',
  },
  page: {
    ...position.size('100%'),
    maxWidth: 'initial',
    paddingBottom: 0,
  },
  campaignPageContainer: padding.build(space.get(0), space.get(1.5), space.three),
});

export default () => (
  <SettingsPageTemplate styles={sx.page}>
    <PageHeading title="Campaigns" />
    <Div styles={sx.campaignPageContainer}>
      <Campaigns />
    </Div>
  </SettingsPageTemplate>
);
