import React from 'react';
import PropTypes from 'prop-types';

const HangupIcon = ({ color }) => (
  <svg width="24" height="11" viewBox="0 0 24 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.89743 9.16504V7.61358C7.89743 6.23113 9.01814 5.11043 10.4006 5.11043H13.5036C14.886 5.11043 16.0066 6.2311 16.0066 7.61353V9.16504C16.0066 9.64647 16.3963 10.0362 16.8772 10.0356H23.0336C23.515 10.0356 23.9047 9.64585 23.9041 9.16504V6.14725C23.9041 5.48339 23.6451 4.84 23.1506 4.39705C16.6065 -1.46519 7.29862 -1.46618 0.753492 4.39703C0.259008 4.84 4.97353e-07 5.48339 3.48158e-07 6.14727L0 9.16504C-2.67042e-09 9.64647 0.3897 10.0362 0.870515 10.0356H7.02692C7.50712 10.0356 7.89743 9.64524 7.89743 9.16504Z"
      fill={color}
    />
  </svg>
);

HangupIcon.propTypes = {
  color: PropTypes.string,
};

export default HangupIcon;
