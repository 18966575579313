import gql from 'graphql-tag';

const GET_LEAD_LIST = gql`
  query leads($limit: Int, $skip: Int, $order_by: LeadListOrderByInput, $query: JSON) {
    leads(limit: $limit, skip: $skip, order_by: $order_by, query: $query) {
      data {
        id
        member_id
        patient {
          id
          first_name
          last_name
          program_eligibility
          birthday
          addresses {
            state
          }
          language
        }
        payer {
          id
          name
        }
        plan_type
        potential_pharmacy {
          name
          chain_pharmacies {
            id
            npi
          }
          parent_pharmacies {
            parent_pharmacy {
              name
              npi
            }
          }
          created_at
          pharmacy_services {
            pharmacy_service_type_id
            pharmacy_service_type {
              pharmacy_service_type
            }
          }
        }
        lead_status
        cadence
        assigned_dsa {
          first_name
          last_name
          id
        }
        updated_at
        lead_campaign_members {
          patient_id
          campaign {
            name
          }
        }
        special_notes
        isMinor
      }
      total
      last_updated_at
    }
  }
`;

export default GET_LEAD_LIST;
