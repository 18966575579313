import { Column, Row } from '@zipdrug/ui';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import AdherenceChartLabels from './AdherenceChartLabels';
import calculatePDC, { calculateBestOutcomePDC } from './calculatePDC';
import FillTimeline from './FillTimeline';
import getTherapyFills from './getTherapyFills';
import getPDCSegments from './getPDCSegments';

const AdherenceChart = ({ allClaims, isExpanded, referenceClaim, styles }) => {
  const therapyFills = getTherapyFills(referenceClaim, allClaims);
  const currentPDC = calculatePDC(therapyFills);
  const segments = getPDCSegments(therapyFills);

  return (
    <Column styles={styles}>
      {isExpanded && (
        <AdherenceChartLabels
          bestOutcomePDC={calculateBestOutcomePDC(therapyFills)}
          currentPDC={currentPDC}
          isExpanded={isExpanded}
        />
      )}
      <Row align="center">
        {!isExpanded && <AdherenceChartLabels currentPDC={currentPDC} isExpanded={isExpanded} />}
        <FillTimeline segments={segments} showAxis={isExpanded} />
      </Row>
    </Column>
  );
};

AdherenceChart.propTypes = {
  allClaims: PropTypes.array,
  isExpanded: PropTypes.bool,
  referenceClaim: PropTypes.object,
  styles: aphroditePropType,
};

export default AdherenceChart;
