/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { cloneDeep } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import { Table, Button, message, Card } from 'antd';
import { isSystemAdmin } from 'contexts/utils/models';
import { StyleSheet, css } from 'aphrodite';
import GET_CHILD_PHARMACIES from '../../graphql/getChildPharmacies';
import ModifyHubPharmacy from './EditHubPharmacyModal';
import withRoles from '../../../../../hoc/withRoles';
import generateVariables from '../../graphql/GenerateVariables.util';

const sx = StyleSheet.create({
  statusSelect: { width: '133px' },
  tableContainer: { width: 'auto', minWidth: 'unset !important' },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
  expandButton: {
    paddingTop: 0,
    paddingLeft: '5px',
    marginTop: 0,
    height: '25px',
  },
  disabledRow: {
    background: '#f5f5f5',
    opacity: '0.5',
    cursor: 'no-drop',
  },
  inactivityCommentMargin: {
    paddingTop: '10px',
  },
  columnTitleIconMargin: {
    marginRight: '8px',
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'keep-all',
  },
  lobTitle: {
    fontSize: '12px',
  },
  networkContainer: {
    marginBottom: '4px',
    lineHeight: '1.3',
  },
});

const HubPharmacy = ({ isPharmacyTeam, pharmacyInfo, history, reRouteToGeneralInfo, isChild }) => {
  // data for the table
  const [data, setData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [tableActions] = useState({ sorter: null });

  const [
    getChildPharmacies,
    { loading: isFetchingChildPharmacies, refetch: refetchChildPharmacies },
  ] = useLazyQuery(GET_CHILD_PHARMACIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching child pharmacies list: ${error.message}`);
    },
    onCompleted: ({ pharmacies }) => {
      setData(pharmacies?.data);
    },
  });

  const generateColumns = () => {
    const headers = [
      {
        title: 'Pharmacy Location Name',
        width: '20%',
        dataIndex: 'name',
        key: 'name',
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
        },
        render: text => {
          return `${text}`;
        },
      },
      {
        title: 'NPI',
        dataIndex: 'npi',
        width: '8%',
        key: 'npi',
        sorter: {
          compare: (a, b) => a.npi - b.npi,
        },
      },
      {
        title: 'Pharmacy Address',
        dataIndex: 'address',
        width: '55%',
        key: 'address',
        render: text => {
          const street = text?.line2 ? `${text?.line1} ${text?.line2}` : text?.line1;

          if (!street) {
            return null;
          }

          return (
            <span className={'fs-hide'}>
              {street}
              {` ${text?.city}, ${text?.state}, ${text?.postal_code}`}
            </span>
          );
        },
      },
    ];

    headers.push({
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <Button type="link" onClick={() => handleEditOrAdd(true, record)}>
            Edit
          </Button>
          <span />
        </>
      ),
    });
    // }
    return headers;
  };

  const handleEditOrAdd = (isEdit, record) => {
    setIsEdit(isEdit);
    if (isEdit) {
      history.push({
        pathname: `/settings/pharmacylist/modify`,
        search: `${record?.id}`,
        state: {
          isChild,
          isEdit,
        },
      });
      window.location.reload();
    } else {
      reRouteToGeneralInfo();
    }
  };

  const fetchChildPharmacies = (ChildNpis, payload = cloneDeep(tableActions), refetch = false) => {
    const sortAndPagination = generateVariables(payload);
    const variables = {
      query: {
        npi: ChildNpis,
      },
      ...sortAndPagination,
    };

    if (refetch) {
      refetchChildPharmacies({
        ...variables,
      });
    } else if (pharmacyInfo?.name !== '') {
      getChildPharmacies({
        variables: {
          ...variables,
        },
      });
    }
  };

  useEffect(() => {
    const childNpiArr = pharmacyInfo?.child_pharmacies?.map(c => c.chain_pharmacy.npi);
    fetchChildPharmacies(childNpiArr);
  }, [pharmacyInfo]);

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data,
      size: 'middle',
      rowKey: 'id',
      rowClassName: record => record.status !== 'active' && css(sx.disabledRow),
      loading: isFetchingChildPharmacies,
    };

    return tableOptions;
  };

  return (
    <Card>
      <div className={css(sx.tableOptionsContainer)}>
        {(isPharmacyTeam || isSystemAdmin) && (
          <Button
            type="secondary"
            className={css(sx.tableOptionsButtonMargin)}
            onClick={() => handleEditOrAdd(false)}
            disabled={pharmacyInfo?.name === ''}
          >
            Add Child Pharmacy
          </Button>
        )}
      </div>

      <Table {...getTableOptions()} />

      <ModifyHubPharmacy isEdit={isEdit} />
    </Card>
  );
};

export default withRouter(withRoles(HubPharmacy));
