import { passwordField, requiredField, validateForm } from '@zipdrug/ui';
import { compose, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form';
import RegisterForm from './RegisterForm';
import withUpdateUserAndLogin from '../hoc/withUpdateUserAndLogin';
import validatePwToUsername from '../hoc/utils/validatePwToUsername';

const REGISTRATION_SCHEMA = {
  agreeToTerms: requiredField,
  first_name: requiredField,
  last_name: requiredField,
  password: {
    ...passwordField,
    required: true,
  },
};

const ERROR_MESSAGES = {
  invalidPassword: "doesn't meet our guidelines listed below",
  required: 'is required.',
  passwordMatchesUsername: "cannot match three consecutive characters of user's email",
};

const validate = values => {
  const errors = validateForm(values, REGISTRATION_SCHEMA, ERROR_MESSAGES);

  if (!values.agreeToTerms) {
    errors._error = 'You must agree to our Terms of Use to continue';
  }

  // Return errors making sure that 'agreeToTerms' field does not
  // receive an error, which causes layout/style problems
  return Object.assign(errors, { agreeToTerms: null });
};

const asyncValidate = values => {
  return validatePwToUsername(values).then(errorMessage => {
    if (errorMessage) {
      // eslint-disable-next-line no-throw-literal
      throw { password: ERROR_MESSAGES[errorMessage] };
    }
  });
};

const RegisterFormContainer = reduxForm({
  form: 'register',
  validate,
  asyncValidate,
  asyncBlurFields: ['password'],
})(RegisterForm);

export default compose(
  withUpdateUserAndLogin,
  withHandlers({
    onSubmit: ({ history, updateUserAndLogin }) => formValues =>
      updateUserAndLogin(formValues).then(() => history.replace('/members')),
  }),
)(RegisterFormContainer);
