import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Flex, Div, Bold, colors, font, space, Span } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 0,
  },
  header: {
    color: colors.orange,
  },
  marginBottom: {
    marginBottom: 10,
  },
});

export default class CallOutcomeActivityItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
  };

  renderHeading = () => {
    const outcome = this.props.activity.callOutcome?.call_outcome_reason[0]?.outcome_type?.outcome;
    const status = <Bold className="e2e-activityListItem-callOutcome">{outcome}</Bold>;
    let updatedEnrollStatus = this.props.activity.model_update?.new_fields?.enroll_status;

    switch (updatedEnrollStatus) {
      case 'interested':
        updatedEnrollStatus = 'Enrolled';
        break;
      case 'not_interested':
        updatedEnrollStatus = 'Not interested';
        break;
      default:
        break;
    }
    const enrollStatusObj = (
      <Bold className="e2e-activityListItem-callOutcome">{updatedEnrollStatus}</Bold>
    );
    return (
      <Span>
        Call outcome
        {updatedEnrollStatus ? (
          <Span> and Enrollment status changed to {enrollStatusObj}.</Span>
        ) : (
          <> {status}.</>
        )}
      </Span>
    );
  };

  renderContent = () => {
    const outcome = this.props.activity.callOutcome?.call_outcome_reason[0]?.outcome_type?.outcome;
    let pitchStatus = this.props.activity.callOutcome?.call_outcome_reason[0]?.outcome_type
      ?.pitched;

    switch (pitchStatus) {
      case 'pitched':
        pitchStatus = 'Pitched';
        break;
      case 'not_pitched':
        pitchStatus = 'Not Pitched';
        break;
      default:
        break;
    }
    const getReasons = () => {
      const reasonMap = this.props.activity.callOutcome?.call_outcome_reason?.map(
        r => r.outcome_type?.outcome_reason,
      );
      return reasonMap.join('; ');
    };
    const notes = this.props.activity.callOutcome?.notes;
    return (
      <blockquote>
        <Flex direction="column">
          {outcome === 'Not Interested' && (
            <Div styles={sx.marginBottom}>
              <Bold>PITCHED/NOT PITCHED</Bold>
              <br />
              {pitchStatus}
            </Div>
          )}
          <Div styles={sx.marginBottom}>
            <Bold>REASONS</Bold>
            <br />
            {getReasons()}
          </Div>
          <Div>
            <Bold>NOTES</Bold>
            <br />
            {notes || '-'}
          </Div>
        </Flex>
      </blockquote>
    );
  };

  render = () => {
    const { activity, ...props } = this.props;
    return (
      <SystemEventActivityItem
        {...props}
        content={activity.callOutcome ? this.renderContent() : null}
        contentHeading={null}
        contentStyles={sx.content}
        createdAt={activity.created_at}
        footerName={activity.callOutcome.call_outcome_user}
        footerStyles={sx.footer}
        headerStyles={sx.header}
        heading={this.renderHeading()}
        icon="person"
      />
    );
  };
}
