import PropTypes from 'prop-types';
import React from 'react';
import sortListByOrderNumber from '../../../ui/sortListByOrderNumber';
import AddressDropdown from './AddressDropdown';

const cleanAddressItem = ({ addresses, ...item }) => ({
  ...item,
  addresses: sortListByOrderNumber(addresses),
});

const AddressDropdownContainer = ({ patient, ...props }) => (
  <AddressDropdown {...props} patient={patient ? cleanAddressItem(patient) : null} />
);

AddressDropdownContainer.propTypes = {
  patient: PropTypes.object,
};

export default AddressDropdownContainer;
