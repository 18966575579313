import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

function calculateOnCallTime(onCallStartTime) {
  if (!onCallStartTime) {
    return null;
  }

  const totalMin = Math.floor((Date.now() - onCallStartTime) / 1000);

  const hour = Math.floor(totalMin / 60);
  let min = totalMin % 60;
  min = min < 10 ? `0${min}` : min;

  return `${hour}:${min}`;
}

const Timer = ({ onCallStartTime }) => {
  const [timer, setTimer] = useState(calculateOnCallTime(onCallStartTime));

  useEffect(() => {
    const id = setInterval(() => {
      setTimer(calculateOnCallTime(onCallStartTime));
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [onCallStartTime]);

  return <div>{timer || '-'}</div>;
};

Timer.propTypes = {
  onCallStartTime: PropTypes.any,
};

export default Timer;
