import React, { useContext } from 'react';
import _get from 'lodash/get';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { SessionContext } from 'contexts/SessionContextProvider';

function withCallCenter(WrappedComponent) {
  return props => {
    const {
      setOutboundToPhoneIdContext,
      isCallCenterActivatedContext,
      callCenterUserStatusContext,
      isPatientChurnedAlreadyCalledContext,
    } = useContext(CallCenterContext);
    const {
      state: { currentUser },
    } = useContext(SessionContext);

    return (
      <WrappedComponent
        isCallCenterActivatedContext={isCallCenterActivatedContext}
        callCenterUserStatusContext={callCenterUserStatusContext}
        isPatientChurnedAlreadyCalledContext={isPatientChurnedAlreadyCalledContext}
        setOutboundToPhoneIdContext={setOutboundToPhoneIdContext}
        callCenterFeatureFlag={_get(currentUser, 'feature_flags.callCenter')}
        {...props}
      />
    );
  };
}

export default withCallCenter;
