import gql from 'graphql-tag';

const APPROVE_COHORT = gql`
  mutation approveCohortParent(
    $id: Int!
    $role: String
    $approver: Int
    $approval_date: Date
    $approver_name: String
  ) {
    approveCohortParent(
      id: $id
      role: $role
      approver: $approver
      approval_date: $approval_date
      approver_name: $approver_name
    ) {
      data {
        id
        pharmacy_approval_user_id
        pharmacy_approval_date
        pm_approval_user_id
        pm_approval_date
        pm_approval_user_name
        pharmacy_approval_user_name
      }
    }
  }
`;

export default APPROVE_COHORT;
