import { get } from 'lodash';
import { compose, withHandlers, withProps } from 'recompose';
import withActiveCallAnimation from '../../../hoc/withActiveCallAnimation';
import withUpdateAlert from '../../../hoc/withUpdateAlert';
import withStartOutboundCall from '../../../hoc/withStartOutboundCall';
import withCallCenter from '../../../hoc/withCallCenter';
import ReturnCallButton from './ReturnCallButton';

export default compose(
  withCallCenter,
  withStartOutboundCall,
  withActiveCallAnimation,
  withUpdateAlert,
  withHandlers({
    onClick: ({
      call,
      mutateUpdateAlert,
      onCallPhone,
      callCenterFeatureFlag,
      setOutboundToPhoneIdContext,
      isCallCenterActivatedContext,
    }) => () => {
      if (callCenterFeatureFlag) {
        if (isCallCenterActivatedContext) {
          setOutboundToPhoneIdContext(get(call, 'from_phone.id'));
        }
      } else {
        onCallPhone(get(call, 'from_phone.id'));

        if (call.alert && !call.alert.completed_at) {
          mutateUpdateAlert({ completed: true, id: call.alert.id });
        }
      }
    },
  }),
  withProps(props => ({ phoneNumber: get(props, 'call.from_phone') })),
)(ReturnCallButton);
