import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Input, Typography } from 'antd';
import formatPhoneNumber from '../../../utils/FormatPhone.utils';

const sx = StyleSheet.create({
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
});

const { Text } = Typography;

const PharmacyInput = ({
  field,
  label,
  defaultValue,
  value,
  error,
  onChange,
  textFieldWidth,
  disabled = false,
  width = '200px',
}) => {
  // determine if the current input is for phone using the label
  const isPhone = label.toLowerCase().includes('phone');

  // state to hold phone text to display vs actual value in form
  const [phoneTextDisplay, setPhoneTextDisplay] = useState(
    isPhone && value ? formatPhoneNumber(value, true) : value,
  );

  let formattedPhoneInitial = '';

  if (isPhone) {
    formattedPhoneInitial = formatPhoneNumber(value, true);
  }

  return (
    <div style={{ width }}>
      <Text className={css(sx.label)}>{label}</Text>
      <br />
      <Input
        style={{ width: textFieldWidth }}
        defaultValue={isPhone ? phoneTextDisplay || formattedPhoneInitial : defaultValue}
        value={isPhone ? phoneTextDisplay || formattedPhoneInitial : value}
        disabled={disabled}
        onChange={e => {
          if (isPhone) {
            const justNumbers = e.target.value.replace(/\D/g, '').substring(0, 10);
            const valueToStore = justNumbers ? `+1${justNumbers}` : '';

            const phoneFormat = formatPhoneNumber(justNumbers);

            // the value to display
            setPhoneTextDisplay(phoneFormat);

            // actual value stored in the form
            onChange(field, valueToStore);
          } else {
            onChange(field, e.target.value);
          }
        }}
      />
      <br />
      {error && <Text className={css(sx.error)}>{error}</Text>}
    </div>
  );
};

PharmacyInput.propTypes = {
  field: PropTypes.string,
  label: PropTypes.string,
  textFieldWidth: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.string,
};

export default PharmacyInput;
