const outReachType = {
  digital: {
    value: 'SYD_RCP',
    text: 'Digital',
  },
  callCenter: {
    value: 'ZD',
    text: 'Call center',
  },
};

export default outReachType;
