import React from 'react';

const MarkNew = props => (
  <svg
    width="15"
    height="25"
    viewBox="0 0 15 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 7.625C2.66809 8.43536 3.81794 10.1459 3.81794 12.125C3.81794 14.1041 2.66809 15.8146 1 16.625"
      stroke="#5A2FD4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M3.81836 4.27246C6.56349 5.81388 8.41831 8.75303 8.41831 12.1254C8.41831 15.4567 6.60841 18.3652 3.91831 19.9213"
      stroke="#5A2FD4"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M6.83039 1C10.5896 3.2785 13.1016 7.40857 13.1016 12.1254C13.1016 16.8472 10.5842 20.981 6.81836 23.258"
      stroke="#5A2FD4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default MarkNew;
