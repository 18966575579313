import gql from 'graphql-tag';

const UPDATE_MEMBER_STATUS = gql`
  mutation updateLeads($ids: [Int]!, $modifiers: LeadListModifiers!) {
    updateLeads(ids: $ids, modifiers: $modifiers) {
      data {
        id
        member_id
        lead_status
      }
    }
  }
`;

export default UPDATE_MEMBER_STATUS;
