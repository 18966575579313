import { Column, Div, DropdownFooter, LinkButton } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import AddressListItemContainer from './AddressListItemContainer';

const sx = StyleSheet.create({
  container: {
    width: '30rem',
  },
  list: {
    maxHeight: '50rem',
    overflowY: 'auto',
  },
});

const AddressList = ({
  onEditItem,
  onOrderItem,
  onToggleEditState,
  onUpdateAddressOrder,
  patient,
}) => (
  <Column styles={sx.container}>
    <Div styles={sx.list}>
      {get(patient, 'addresses', []).map((address, index) => (
        <AddressListItemContainer
          address={address}
          index={index}
          key={address.id}
          onClickEdit={onEditItem}
          onClickOrder={onOrderItem}
          onUpdateAddressOrder={onUpdateAddressOrder}
        />
      ))}
    </Div>
    <DropdownFooter>
      <LinkButton id="e2e-addressListAddButton" onClick={onToggleEditState}>
        Add address
      </LinkButton>
    </DropdownFooter>
  </Column>
);

AddressList.propTypes = {
  onEditItem: PropTypes.func,
  onOrderItem: PropTypes.func,
  onToggleEditState: PropTypes.func,
  onUpdateAddressOrder: PropTypes.func,
  patient: PropTypes.object,
};

export default AddressList;
