import { LinkDropdown } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import PatientInfoDropdownButton from '../PatientInfoDropdownButton';
import InsuranceMenu from './InsuranceMenu';

const sx = StyleSheet.create({
  menuStyles: {
    left: '50%',
    right: 'initial',
    transform: 'translateX(-50%)',
  },
});

const InsuranceDropdown = ({ patient }) =>
  patient ? (
    <LinkDropdown
      buttonRenderer={
        <PatientInfoDropdownButton
          icon="umbrella"
          text={`${patient?.plans ? patient?.plans.length : 0} plans`}
          title="View patient insurance"
        />
      }
      menuRenderer={<InsuranceMenu patient={patient} />}
      menuStyles={sx.menuStyles}
    />
  ) : null;

InsuranceDropdown.propTypes = {
  patient: PropTypes.object,
};

export default InsuranceDropdown;
