import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  ButtonRow,
  colors,
  Column,
  Div,
  font,
  FormExtensionField,
  FormPhoneField,
  FormTextField,
  padding,
  Row,
  space,
} from '@zipdrug/ui';

const sx = StyleSheet.create({
  buttonRow: {
    marginTop: space.half,
  },
  container: {
    ...padding.build(space.get(1.5)),
    backgroundColor: colors.lightYellow,
    color: 'inherit',
  },
  containerEditing: {
    paddingTop: space.get(1.5),
  },
  extensionField: {
    flex: 'none',
    maxWidth: '12rem',
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
    marginRight: font.size.xSmall,
    maxWidth: '23.8rem',
    ':last-child': {
      marginRight: 0,
    },
  },
  header: {
    color: colors.gray57,
    marginBottom: space.get(1.5),
  },
  headerTitle: {
    color: colors.gray20,
    fontSize: font.size.medium,
    marginBottom: space.quarter,
  },
});

const BUTTON_PROPS = {
  focusable: true,
  size: 'large',
};

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const PhoneListItemForm = ({ handleSubmit, isEditing, onClickCancel }) => (
  <Column styles={isEditing ? sx.containerEditing : sx.container}>
    {!isEditing && (
      <Column styles={sx.header}>
        <Div styles={sx.headerTitle}>New phone</Div>
        <Div>Add a new phone by completing the form below.</Div>
      </Column>
    )}
    <Row>
      <FormTextField {...FORMFIELD_PROPS} label="Name" name="label" placeholder="Front desk" />
      <FormPhoneField
        {...FORMFIELD_PROPS}
        label="Phone"
        name="number"
        placeholder="(123) 456-7890"
      />
      <FormExtensionField
        {...FORMFIELD_PROPS}
        label="Extension"
        name="extension"
        optional
        placeholder="1234"
        styles={[sx.formField, sx.extensionField]}
      />
    </Row>
    <ButtonRow styles={sx.buttonRow}>
      <Button
        {...BUTTON_PROPS}
        onClick={handleSubmit}
        text={isEditing ? 'Update' : 'Add phone'}
        type="primary"
      />
      <Button {...BUTTON_PROPS} onClick={onClickCancel} outline text="Cancel" type="secondary" />
    </ButtonRow>
  </Column>
);

PhoneListItemForm.propTypes = {
  handleSubmit: PropTypes.func,
  isEditing: PropTypes.bool,
  onClickCancel: PropTypes.func,
};

export default PhoneListItemForm;
