import '@zipdrug/ui/lib/ui.css';
import 'react-virtualized/styles.css'; // required by react-virtualized
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(
  // create-react-app expects this file to be index.js not index.jsx
  // eslint-disable-next-line react/jsx-filename-extension
  <App />,
  document.getElementById('root'),
);
