import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography, Button } from 'antd';
import { withRouter } from 'react-router';

const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    width: '579px',
    height: '252px',
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  largeText: {
    fontSize: '16px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  statusSelect: {
    minWidth: '150px',
  },
  stateSelect: {
    minWidth: '50px',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
});

const { Title } = Typography;

const RedirectModal = ({ visible, onPharmacyInfoModalCancel, history }) => {
  const redirectHandler = () => {
    history.push({
      pathname: '/settings/pharmacylist',
    });
  };
  const renderFooter = () => {
    return [
      <Button onClick={() => onPharmacyInfoModalCancel()}>No</Button>,
      <Button type="primary" onClick={() => redirectHandler()}>
        Yes
      </Button>,
    ];
  };

  return (
    <Modal
      open={visible}
      onCancel={() => onPharmacyInfoModalCancel()}
      className={css(sx.modalContainer)}
      getContainer={false}
      footer={renderFooter()}
    >
      <Title className="modelHeaderText" level={3}>
        {'Add Pharmacy Before Leaving'}
      </Title>
      <div>If you leave this page, you will lose all the data entered. Do you wish to leave?</div>
    </Modal>
  );
};

RedirectModal.propTypes = {
  visible: PropTypes.bool,
  onPharmacyInfoModalCancel: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(RedirectModal);
