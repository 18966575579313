const percentAllocations = [
  {
    text: 'Up to 0%',
    value: 0,
  },
  {
    text: 'Up to 10%',
    value: 10,
  },
  {
    text: 'Up to 20%',
    value: 20,
  },
  {
    text: 'Up to 30%',
    value: 30,
  },
  {
    text: 'Up to 40%',
    value: 40,
  },
  {
    text: 'Up to 50%',
    value: 50,
  },
  {
    text: 'Up to 60%',
    value: 60,
  },
  {
    text: 'Up to 70%',
    value: 70,
  },
  {
    text: 'Up to 80%',
    value: 80,
  },
  {
    text: 'Up to 90%',
    value: 90,
  },
  {
    text: 'Up to 100%',
    value: 100,
  },
];

export default percentAllocations;
