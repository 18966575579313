import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { colors, Flex, position } from '@zipdrug/ui';
import { TopBarHeight } from './topbar/TopBar';
import { SessionContext } from '../contexts/SessionContextProvider';

const CallCenterBarHeight = '6rem';

const getStyles = (standalone, nonStandaloneTop) =>
  StyleSheet.create({
    container: {
      ...position.cover,
      top: !standalone ? nonStandaloneTop : 0,
      backgroundColor: colors.white,
      maxHeight: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  });

const Page = ({ styles, standalone, ...props }) => {
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const nonStandaloneTop =
    currentUser?.isPbsTeam || currentUser?.isSystemAdmin
      ? `calc(${TopBarHeight} + ${CallCenterBarHeight})`
      : TopBarHeight;
  return <Flex {...props} styles={[getStyles(standalone, nonStandaloneTop).container, styles]} />;
};

Page.propTypes = {
  styles: aphroditePropType,
  standalone: PropTypes.bool,
};

export default Page;
