import gql from 'graphql-tag';

const REASSIGN_DSA = gql`
  mutation updateLeadChurns($ids: [Int]!, $modifiers: LeadChurnModifiers!) {
    updateLeadChurns(ids: $ids, modifiers: $modifiers) {
      data {
        id
        assigned_user_id
        filtered_out
      }
    }
  }
`;

export default REASSIGN_DSA;
