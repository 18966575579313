import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import {
  border,
  colors,
  Column,
  Div,
  FlexItem,
  font,
  Icon,
  margin,
  padding,
  position,
  Row,
  space,
  transition,
} from '@zipdrug/ui';
import CallButtonContainer from '../../../ui/CallButtonContainer';

const INSURANCE_CARD_PADDING = font.size.small;

const sx = StyleSheet.create({
  container: {
    ...border.build(),
    ...padding.build(INSURANCE_CARD_PADDING),
    borderRadius: space.half,
    boxShadow: `0 0 2px ${colors.alpha(colors.black, 0.08)}`,
    marginBottom: font.size.small,
    ':before': {
      ...position.cover,
      ...transition.build(),
      borderRadius: space.half,
      boxShadow: `0 0 6px ${colors.alpha(colors.black, 0.1)}`,
      content: '" "',
      opacity: 0,
    },
    ':hover': {
      ':before': {
        opacity: 1,
      },
    },
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  description: {
    color: colors.gray62,
    fontSize: font.size.xSmall,
    lineHeight: font.lineHeight.loose,
    marginTop: 2,
    maxWidth: '16rem',
  },
  header: {
    whiteSpace: 'initial',
  },
  horizonLogo: {
    marginLeft: space.quarter,
  },
  meta: {
    fontSize: font.size.xSmall,
    marginTop: space.half,
  },
  metaItem: {
    ...margin.build(space.quarter, 0),
    ':first-of-type': { marginTop: 0 },
    ':last-of-type': { marginBottom: 0 },
  },
  metaTitle: {
    cursor: 'default',
    fontWeight: font.weight.bold,
    marginRight: space.half,
    userSelect: 'none',
    width: '6.5rem',
  },
  name: {
    color: colors.horizon,
    fontWeight: font.weight.bold,
    lineHeight: font.lineHeight.looser,
  },
});

const InsuranceCardHeader = ({ description, name }) => {
  const isHorizon = name.toLowerCase() === 'horizon';

  return (
    <Div styles={sx.header}>
      <Row
        align="baseline"
        style={{ color: isHorizon ? colors.horizon : colors.theme.primary.default }}
        styles={sx.name}
      >
        <Div>{name}</Div>
        {isHorizon && <Icon name="horizonLogo" styles={sx.horizonLogo} />}
      </Row>
      <Div styles={sx.description}>{description}</Div>
    </Div>
  );
};

InsuranceCardHeader.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string,
};

const InsuranceCardMetaItem = ({ label, value }) => (
  <Row align="center" styles={sx.metaItem}>
    <Div styles={sx.metaTitle}>{label}</Div>
    <Div className="fs-hide">{value}</Div>
  </Row>
);

InsuranceCardMetaItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

const InsuranceCard = ({ bin, description, group_id, member_id, name, pcn, phones }) => (
  <Row styles={sx.container}>
    <Column component={FlexItem}>
      <InsuranceCardHeader description={description} name={name} />
      <Div styles={sx.meta}>
        {member_id && <InsuranceCardMetaItem label="Member ID" value={member_id} />}
        {bin && <InsuranceCardMetaItem label="BIN" value={bin} />}
        {group_id && <InsuranceCardMetaItem label="Group" value={group_id} />}
        {pcn && <InsuranceCardMetaItem label="PCN" value={pcn} />}
      </Div>
    </Column>
    {phones && phones.length > 0 && (
      <FlexItem flex="none">
        <CallButtonContainer phone={phones[0]} title={`Call ${name} for more information`} />
      </FlexItem>
    )}
    {phones && phones.length === 0 && <FlexItem flex="none" />}
  </Row>
);

InsuranceCard.propTypes = {
  bin: PropTypes.string,
  description: PropTypes.string,
  group_id: PropTypes.string,
  member_id: PropTypes.string,
  name: PropTypes.string,
  pcn: PropTypes.string,
  phones: PropTypes.arrayOf(PropTypes.object),
};

export default InsuranceCard;
