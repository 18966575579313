import gql from 'graphql-tag';

const ADD_CAMPAIGN = gql`
  mutation addCampaign($campaignModifiers: CampaignModifiers!) {
    addCampaign(campaignModifiers: $campaignModifiers) {
      data {
        id
        payer_ids
        status
        name
        description
        file_name
        priority
        pharmacy_ids
        plan_ids
        special_notes
        assigned_enrollers
      }
    }
  }
`;

export default ADD_CAMPAIGN;
