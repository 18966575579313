import { connect } from 'react-redux';
import ModalConductor from './ModalConductor';
import { closeModal } from '../redux/modal';

const mapStateToProps = ({ modal: { modalName, modalProps } }) => ({ modalName, modalProps });
const mapDispatchToProps = dispatch => ({
  onClose() {
    dispatch(closeModal());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor);
