import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { omit, debounce } from 'lodash';
import { useLazyQuery } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite';
import { AutoComplete, message, Modal, Select, Form, Typography, Button } from 'antd';
import { languages, statuses, lineOfBusinesses } from '../../constants/constants';
import states from '../../constants/states';

import GET_ENROLLER_AUTOCOMPLETE from '../../graphql/getEnrollerAutocomplete';
import GET_TEAM_LEADS from '../../graphql/getTeamLeads';
import {
  generateEnrollersAutocompleteVariables,
  generateLeadsVariables,
} from './utils/ModifyEnroller.utils';

const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { minWidth: '170px', marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
  },
  modalContainer: {
    minWidth: '583px',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  largeText: {
    fontSize: '16px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
});

const { Title } = Typography;
const { Option } = AutoComplete;
const { Option: SelectOption } = Select;
const { Text } = Typography;

const ModifyEnroller = ({
  submitModifyEnroller,
  onModifyCancel,
  visible,
  isEdit,
  enrollerInfo,
  allPayers,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [enrollers, setEnrollers] = useState([]);
  const [enrollerNames, setEnrollerNames] = useState([]);
  const [teamLeads, setTeamLeads] = useState([]);
  const [form] = Form.useForm();

  const transformDataToNames = data => {
    return data.map(d => `${d.first_name} ${d.last_name}`.toLowerCase());
  };

  const [getEnrollerAutocomplete, { loading: isEnrollerAutocompleteFetching }] = useLazyQuery(
    GET_ENROLLER_AUTOCOMPLETE,
    {
      onCompleted: ({ users }) => {
        if (users?.data) {
          setEnrollers(users.data);
          setEnrollerNames(transformDataToNames(users.data));
        }
      },
      onError: error => {
        message.error(`Error fetching PBS Names: ${error.message}`);
      },
    },
  );

  const [getTeamLeads, { loading: isTeamLeadsFetching }] = useLazyQuery(GET_TEAM_LEADS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ users }) => {
      if (users?.data) {
        setTeamLeads(users.data);
        form.resetFields();
      }
    },
    onError: error => {
      message.error(`Error fetching Team Leads: ${error.message}`);
    },
  });

  useEffect(() => {
    const newFields = {
      name: enrollerInfo?.name || '',
      id: enrollerInfo?.id || '',
      enroller_id: enrollerInfo?.enroller_id || '',
      language: enrollerInfo?.language || [],
      line_of_business: enrollerInfo?.line_of_business || [],
      status: enrollerInfo?.status || '',
      team_lead_user: enrollerInfo?.team_lead_user || '',
      team_lead_user_id: enrollerInfo?.team_lead_user_id || null,
      state: enrollerInfo?.state || [],
      payer_id: enrollerInfo?.payer_id || [],
    };
    form.setFieldsValue(newFields);
  }, [enrollerInfo]);

  useEffect(() => {
    setEnrollers([]);
  }, [visible]);

  useEffect(() => {
    fetchTeamLeadsAndResetForm();
  }, []);

  const fetchTeamLeadsAndResetForm = () => {
    const variables = generateLeadsVariables();
    getTeamLeads({ variables });
  };

  const debouncedFetchOptions = value => {
    const variables = generateEnrollersAutocompleteVariables(value);
    getEnrollerAutocomplete({ variables });
  };

  const debouncedSearch = useCallback(
    debounce(value => {
      debouncedFetchOptions(value);
    }, 500),
    [],
  );

  const onSearch = value => {
    setSearchValue(value);
    if (value.length === 0) {
      setEnrollers([]);
    } else if (value.length > 1) {
      debouncedSearch(value);
    }
  };

  const onSelect = (value, option) => {
    const selectedOption = enrollers[option.index];
    setSelectedUser(selectedOption);
    setSearchValue(`${selectedOption.first_name} ${selectedOption.last_name}`.toLowerCase());
  };

  const submitForm = () => {
    const newFormValues = form.getFieldsValue();
    // include user id in form
    const userId = isEdit ? enrollerInfo?.enroller_id : selectedUser?.id;
    const finalFormValues = omit(newFormValues, ['name']);
    if (isEdit) {
      finalFormValues.id = userId;
    } else {
      finalFormValues.user_id = userId;
    }
    form
      .validateFields()
      .then(() => {
        form.resetFields();
        submitModifyEnroller(isEdit, finalFormValues);
      })
      .catch(info => {
        return info;
      });
  };

  return (
    <Modal
      onOk={() => submitForm()}
      onCancel={onModifyCancel}
      open={visible}
      okText={`${isEdit ? 'Save' : 'Add PBS Agent'}`}
      className={css(sx.modalContainer)}
      getContainer={false}
      footer={[
        <Button key="cancel" onClick={onModifyCancel} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => submitForm()} className={css(sx.button)}>
          {isEdit ? 'Save' : 'Add PBS Agent'}
        </Button>,
      ]}
    >
      <Title className="modelHeaderText" level={3}>
        {isEdit ? 'Edit' : 'Add'} Enroller
      </Title>
      <Form name="modify-form-ref" form={form} layout="vertical" initialValues={enrollerInfo}>
        <div className={css(sx.flexContainer)}>
          <div className={css(sx.inputBox, sx.largeInputBox)}>
            <p className={css(sx.label)}>Enroller</p>
            {isEdit && enrollerInfo?.name ? (
              <Text className={css(sx.largeText)}>{enrollerInfo.name}</Text>
            ) : (
              <Form.Item
                name="name"
                rules={[
                  () => ({
                    validator(_, value) {
                      const enrollerIndex = enrollerNames.findIndex(
                        name => name === value?.toLowerCase(),
                      );
                      if (enrollerIndex > -1) {
                        setSelectedUser(enrollers[enrollerIndex]);
                        return Promise.resolve();
                      }
                      if (value === undefined || value === '') {
                        return Promise.reject(new Error('PBS Agent is required.'));
                      } else if (enrollerIndex <= -1) {
                        return Promise.reject(new Error('Not a valid or eligible user.'));
                      }
                      return Promise.reject();
                    },
                  }),
                ]}
              >
                <AutoComplete
                  placeholder="Enter PBS Name"
                  value={searchValue}
                  onSearch={value => onSearch(value)}
                  onSelect={onSelect}
                  loading={isEnrollerAutocompleteFetching}
                  className={css(sx.largeText)}
                >
                  {enrollers.map((option, index) => (
                    <Option
                      key={option.id}
                      index={index}
                      value={`${option.first_name} ${option.last_name}`}
                    >
                      {`${option.first_name} ${option.last_name}`}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            )}
          </div>
          <div className={css(sx.inputBox)}>
            <p className={css(sx.label)}>Language</p>
            <Form.Item
              name="language"
              rules={[{ required: true, message: 'Language is required.' }]}
            >
              <Select
                mode="multiple"
                size="medium"
                key="language-select"
                defaultValue={enrollerInfo.language}
              >
                {languages.map(language => (
                  <SelectOption value={language.value} key={language.value}>
                    {language.text}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={css(sx.flexContainer)}>
          <div className={css(sx.inputBox)}>
            <p className={css(sx.label)}>Status</p>
            <Form.Item name="status" rules={[{ required: true, message: 'Status is required.' }]}>
              <Select size="large" key="status-select">
                {statuses.map(status => (
                  <SelectOption value={status.value} key={status.value}>
                    {status.text}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={css(sx.largeInputBox)}>
            <p className={css(sx.label)}>Line of Business</p>
            <Form.Item
              name="line_of_business"
              rules={[{ required: true, message: 'Line of Business is required.' }]}
            >
              <Select
                mode="multiple"
                size="medium"
                key="lob-select"
                defaultValue={enrollerInfo.lineOfBusinesses}
              >
                {lineOfBusinesses.map(lob => (
                  <SelectOption value={lob.value} key={lob.value}>
                    {lob.text}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className={css(sx.inputBox, sx.largeInputBox)}>
          <p className={css(sx.label)}>Team Lead</p>
          <Form.Item
            name="team_lead_user_id"
            rules={[{ required: true, message: 'Team Lead is required.' }]}
          >
            <Select size="medium" key="teamlead-select" loading={isTeamLeadsFetching}>
              {teamLeads.map((option, index) => (
                <SelectOption key={option.id} index={index} value={option.id}>
                  {`${option.first_name} ${option.last_name}`}
                </SelectOption>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <div>
            <p className={css(sx.label)}>Payers</p>
            <Form.Item name="payer_id" rules={[{ required: true, message: 'Payers is required.' }]}>
              <Select
                mode="multiple"
                size="medium"
                key="payer_id-select"
                defaultValue={enrollerInfo.payer_id}
              >
                {allPayers?.map(payer => (
                  <SelectOption value={payer.id} key={payer.id}>
                    {payer.name}
                  </SelectOption>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div>
            <p className={css(sx.label)}>States</p>
            <Form.Item name="state" rules={[{ required: true, message: 'State is required.' }]}>
              <Select size="medium" mode="multiple" key="state-select">
                <SelectOption value={'All'} key={'All'}>
                  ALL
                </SelectOption>

                {Object.keys(states).map(st => {
                  const state = states[st];
                  return (
                    <SelectOption value={state.value} key={state.value}>
                      {state.name}
                    </SelectOption>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

ModifyEnroller.propTypes = {
  allPayers: PropTypes.any,
  submitModifyEnroller: PropTypes.func,
  onModifyCancel: PropTypes.func,
  visible: PropTypes.bool,
  isEdit: PropTypes.bool,
  enrollerInfo: PropTypes.object,
};

export default ModifyEnroller;
