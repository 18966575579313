import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import { ActivityItem, colors, Div, font, space } from '@zipdrug/ui';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
    whiteSpace: 'pre-wrap',
  },
  header: {
    color: colors.orange,
  },
});

const NoteActivityItem = ({ activity: { note = {}, ...activity }, ...props }) => (
  <ActivityItem
    {...props}
    contentStyles={sx.content}
    createdAt={activity.created_at}
    headerStyles={sx.header}
    icon="note"
    internalUser={note.created_by_user}
    title="Note"
  >
    <Div className="fs-hide" id="e2e-noteItem" data-id="e2e-noteContent">
      {note.text || ' '}
    </Div>
  </ActivityItem>
);

NoteActivityItem.propTypes = {
  activity: PropTypes.object.isRequired,
};

export default NoteActivityItem;
