const programs = {
  zd: {
    text: 'ZipDrug Eligible',
    value: 'zipdrug_eligible',
  },
  cpesn: {
    text: 'CPESN Eligible',
    value: 'cpesn_eligible',
  },
  mcaid: {
    text: 'ZipDrug for Medicaid',
    value: 'zipdrug_and_cpesn_eligible',
  },
};

export default programs;
