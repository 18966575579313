import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside';
import { StyleSheet, css } from 'aphrodite/no-important';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { COLORS } from './constants';
import commonSx from './styles';

import PhonesDropdown from './PhonesDropdown';

const sx = StyleSheet.create({
  enrollerBtn: {
    padding: '6px',
    height: '100%',

    background: 'white',
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid green',

    display: 'flex',
    alignItems: 'center',
    color: COLORS.GREEN,
    borderRadius: '6px',
    ':hover': {
      background: COLORS.LIGHT_GREEN,
    },
  },
  addEnrollerContainer: {
    position: 'relative',
    height: '100%',
  },
  infoSection: {
    fontWeight: 'bold',
    margin: '0px 20px',
  },
});

function AddEnrollerButton({ names, onClick, callBtnHandler }) {
  const [showPhonesDropdown, setShowPhonesDropdown] = useState(false);

  return (
    <ClickOutside onClickOutside={() => setShowPhonesDropdown(false)} style={{ height: '100%' }}>
      <div className={css([commonSx.ml10, sx.addEnrollerContainer])}>
        <button
          className={css(sx.enrollerBtn)}
          onClick={() => {
            setShowPhonesDropdown(showPhonesDropdown => !showPhonesDropdown);
            onClick();
          }}
        >
          <div className={css(sx.infoSection)}>PBS Agent</div>

          {showPhonesDropdown ? (
            <UpOutlined style={{ color: COLORS.GREEN }} />
          ) : (
            <DownOutlined style={{ color: COLORS.GREEN }} />
          )}
        </button>

        <PhonesDropdown
          isLoading={!names}
          isVisible={showPhonesDropdown}
          callBtnHandler={index => {
            setShowPhonesDropdown(!showPhonesDropdown);
            callBtnHandler(index);
          }}
          primaryTexts={names}
          secondaryTexts={names && new Array(names.length).fill('')}
        />
      </div>
    </ClickOutside>
  );
}

AddEnrollerButton.propTypes = {
  names: PropTypes.array,
  callBtnHandler: PropTypes.func,
  onClick: PropTypes.func,
};

export default AddEnrollerButton;
