import {
  ArrowButton,
  Button,
  colors,
  Column,
  Div,
  font,
  FormEmailField,
  Row,
  space,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const sx = StyleSheet.create({
  backButtonRow: {
    marginTop: space.oneAndTwoTenths,
  },
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
    marginBottom: space.oneAndTwoTenths,
  },
  submitButton: {
    flex: 1,
    fontWeight: font.weight.medium,
  },
  submitButtonSuccess: {
    pointerEvents: 'none',
  },
  field: {
    marginBottom: space.oneAndTwoTenths,
  },
  backButton: {
    fontWeight: font.weight.medium,
  },
});

export default class ForgotPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitSucceeded: PropTypes.bool,
    submitting: PropTypes.bool,
    onBackButtonClick: PropTypes.func,
  };

  handleKeyPress = event => {
    // Submit form on Enter keypress
    if (event.which === 13) {
      event.preventDefault();
      this.props.handleSubmit();
    }
  };

  render = () => (
    <Column>
      <Div styles={sx.description}>
        Enter your email address below and we’ll send you a link to reset your password.
      </Div>
      <FormEmailField
        label="Email address"
        name="email"
        onKeyPress={this.handleKeyPress}
        preserveDimensions={false}
        shouldReplaceLabelWithError
        styles={sx.field}
      />
      <ArrowButton
        loading={this.props.submitting}
        onClick={this.props.handleSubmit}
        styles={sx.submitButton}
        success={this.props.submitSucceeded}
        text={this.props.submitSucceeded ? 'Sent! Check your email.' : 'Send a reset link'}
      />
      <Row styles={sx.backButtonRow}>
        <Button
          focusable
          onClick={this.props.onBackButtonClick}
          outline
          size="micro"
          text="Back to sign in"
          type="primary"
          styles={sx.backButton}
        />
      </Row>
    </Column>
  );
}
