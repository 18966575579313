import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { AutoComplete, Typography } from 'antd';
import { debounce } from 'lodash';
import { getPlacePredictions, getPlaceDetails, cleanAddress } from './utils/autoCompleteUtils';

const sx = StyleSheet.create({
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  autoComplete: {
    display: 'block',
    minWidth: '250px',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
  marginBottom10: {
    marginBottom: '15px',
  },
});

const { Text } = Typography;
const { Option } = AutoComplete;

const AddressAutoComplete = ({
  field,
  label,
  value,
  defaultValue,
  error,
  onChange,
  disabled = false,
  addressId,
  updateAddressInfo,
}) => {
  // const [addressSearchQuery, setAddressSearchQuery] = useState(defaultValue);
  const [predictions, setPredictions] = useState([]);

  const debouncedSearch = useCallback(
    debounce(async addressSearchQuery => {
      const placesPredictions = await getPlacePredictions(addressSearchQuery);
      setPredictions(placesPredictions);
    }, 500),
    [],
  );

  const onAddressSearch = addressSearchQuery => {
    updateAddressInfo(field, addressSearchQuery);
    if (addressSearchQuery.length === 0) {
      setPredictions([]);
    } else if (addressSearchQuery.length > 2) {
      debouncedSearch(addressSearchQuery);
    }
  };

  const onAddressSelect = async (addressSearchQuery, option) => {
    const selectedAddressDetails = await getPlaceDetails(option.key);
    const cleanedAddressDetals = cleanAddress(selectedAddressDetails?.[0]?.address_components);
    const cleanedAddressKeys = Object.keys(cleanedAddressDetals);
    cleanedAddressKeys.forEach(comp => {
      const details = cleanedAddressDetals[comp];
      updateAddressInfo(comp, details);
    });
    onChange('address', { id: addressId, ...cleanedAddressDetals });
  };

  return (
    <div>
      <Text className={css(sx.label)}>{label}</Text>
      <br />
      <AutoComplete
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={onAddressSearch}
        onSelect={onAddressSelect}
        className={css(sx.autoComplete)}
      >
        {predictions?.map((prediction, index) => (
          <Option key={prediction?.place_id} index={index} value={prediction?.description}>
            {`${prediction?.description}`}
          </Option>
        ))}
      </AutoComplete>
      {error && <Text className={css(sx.error)}>{error}</Text>}
    </div>
  );
};

AddressAutoComplete.propTypes = {
  label: PropTypes.string,
  addressId: PropTypes.number,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  updateAddressInfo: PropTypes.func,
  value: PropTypes.string,
  field: PropTypes.string,
};

export default AddressAutoComplete;
