import gql from 'graphql-tag';

export const GET_PHARMACY_DATA = gql`
  query pharmacies($limit: Int, $query: JSON, $skip: Int, $zipdrugOnly: Boolean) {
    pharmacies(limit: $limit, query: $query, skip: $skip, zipdrugOnly: $zipdrugOnly) {
      data {
        id
        address {
          id
          line1
          line2
          city
          state
          postal_code
        }
        name
        hours
        champion
        skip_number
        status
        maintenance_threshold
        created_at
        associate_service_coordinator {
          id
          first_name
          last_name
        }
        languages
        zipdrug_active_comment
        phones {
          id
          number
          label
        }
        chain_pharmacies {
          id
          npi
          parent_pharmacy_npi
          chain_pharmacy {
            id
            name
            npi
            address {
              id
              state
            }
            is_commercial_only
          }
        }
        child_pharmacies {
          id
          npi
          parent_pharmacy_npi
          name
        }
        npi
        parent_pharmacy {
          id
          name
          npi
        }
        pharmacy_networks {
          id
          pharmacy_id
          network_type {
            id
            name
            lob
            lob_order
            pharmacy_network_type_order
            order_tie_breaker_rule
          }
        }
        pharmacy_services {
          id
          pharmacy_service_type_id
          pharmacy_id
        }
        payer {
          id
          name
        }
      }
      limit
      skip
      total
    }
  }
`;

export const GET_PHARMACY_LIST = gql`
  query pharmacies($limit: Int, $query: JSON, $skip: Int) {
    pharmacies(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        address {
          id
          line1
          line2
          city
          state
          postal_code
        }
        name
        status
        npi
        parent_pharmacy {
          id
          name
          npi
        }
        associate_service_coordinator {
          id
          first_name
          last_name
        }
        pharmacy_networks {
          id
          pharmacy_id
          network_type {
            id
            name
            lob
            lob_order
            pharmacy_network_type_order
            order_tie_breaker_rule
          }
        }
        payer {
          id
          name
        }
        maintenance_threshold
      }
      limit
      skip
      total
    }
  }
`;
