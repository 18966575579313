import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';

const CLOSE_MODAL = 'care-site/modal/CLOSE_MODAL';
const OPEN_MODAL = 'care-site/modal/OPEN_MODAL';

const initialState = {
  modalName: null,
  modalProps: null,
};

export default handleActions(
  {
    [CLOSE_MODAL]: state =>
      update(state, {
        modalName: { $set: null },
        modalProps: { $set: null },
      }),

    [OPEN_MODAL]: (state, { payload: { modalName, modalProps } }) =>
      update(state, {
        modalName: { $set: modalName },
        modalProps: { $set: modalProps },
      }),
  },
  initialState,
);

export const openModal = createAction(OPEN_MODAL, ({ modalName, modalProps }) => ({
  modalName,
  modalProps,
}));

export const closeModal = createAction(CLOSE_MODAL);
