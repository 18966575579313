import React from 'react';

const ZipdrugEligibleIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>ZipDrug Badge</title>
    <g id="ZipDrug-Enhanced" stroke="none" fill="none">
      <g id="Badges" transform="translate(-80.000000, -20.000000)">
        <g id="ZipDrug-Badge" transform="translate(80.000000, 20.000000)">
          <circle id="CPSEN-Badge" stroke="#000000" fill="#294B6A" cx="8" cy="8" r="7.5" />
          <g id="Z" transform="translate(5.000000, 4.000000)" fill="#FFFFFF" fillRule="nonzero">
            <polygon
              id="Path"
              points="2.02885482 6.67582418 6 6.67582418 6 8 0 8 0 7.03296703 3.89540126 1.33516484 0.00541027953 1.33516484 0.00541027953 0 5.91343553 0 5.91343553 0.945054945"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ZipdrugEligibleIcon;
