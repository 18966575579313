import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { createElement, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { WorkstationPhoneContext } from 'contexts/WorkstationPhoneContextProvider';
import LoadingPage from '../ui/LoadingPage';
import { SessionContext, ActionTypes } from '../contexts/SessionContextProvider';
import { queryCurrentUser } from '../graphql/users';
import { queryPhones } from '../graphql/phones';
import fetchOnceOptions from '../hoc/utils/fetchOnceOptions';
import { isAuthenticated } from './AuthenticatedComponent';

// const PhonePaths = ['/create-phone', '/register-phone'];

/**
 * This cookie is use to set if user is using a new browser session or not. New browser session means the user
 * restarted/close their browser. This cookie will auto expire since we did not set an expiration date.
 * We will use this cookie to determine when to auto log off the user.
 */

const AuthenticatedRoute = ({ component, path, allowedRoles, allowedFeatureFlags, ...rest }) => {
  const { userId, state, dispatch, ready } = useContext(SessionContext);
  const { workstationPhoneId } = useContext(WorkstationPhoneContext);
  const { currentUser } = state;
  const hasRole = get(currentUser, 'hasRole');

  const { loading: userLoading, data: userData } = useQuery(queryCurrentUser, {
    ...fetchOnceOptions(`currentUser_${userId}`, { skip: !userId }, true),
    notifyOnNetworkStatusChange: true,
    onError(e) {
      if (e) {
        dispatch({
          type: ActionTypes.ERROR,
          payload: e,
        });
      }
    },
    onCompleted(data) {
      if (data) {
        dispatch({
          type: ActionTypes.SET_CURRENT_USER,
          payload: data,
        });
      }
    },
  });

  const { loading: phonesLoading } = useQuery(queryPhones, {
    ...fetchOnceOptions('phones', {
      skip: !userId || !userData || !workstationPhoneId,
      variables: {
        query: {
          is_zipdrug: !hasRole?.pharmacistUser || !hasRole?.pharmacistAdmin,
          id: workstationPhoneId,
        },
      },
    }),
    notifyOnNetworkStatusChange: true,
  });

  // const workstationPhone = workstationPhoneId ? get(phonesData, 'phones.data[0]') : null;

  const loading = phonesLoading || userLoading;

  const hasAllowedRole = () => {
    if (currentUser) {
      const userRoles = currentUser?.roles?.map(role => role.type);
      const userFlags = currentUser?.feature_flags;
      // user is allowed to access page if:
      // allowedRoles is defined && the user has that role
      // allowedFeatureFlags is defined && the user had that flag
      const isAllowed =
        (allowedRoles ? allowedRoles.some(allowedRole => userRoles.includes(allowedRole)) : true) &&
        (allowedFeatureFlags
          ? Object.keys(allowedFeatureFlags).every(flag =>
              allowedFeatureFlags[flag]
                ? allowedFeatureFlags[flag] === userFlags?.[flag]
                : userFlags?.[flag] !== true,
            )
          : true);
      return isAllowed;
    }
    return true;
  };

  return (
    <Route
      {...rest}
      render={renderProps => {
        if (loading || !ready) {
          return <LoadingPage />;
        }
        if (!isAuthenticated(userId)) {
          return <Redirect to={{ pathname: '/signin' }} />;
        }
        // if (
        //   isPhoneUnregistered() &&
        //   currentUser &&
        //   !callCenterFeatureFlag &&
        //   !hasRole?.pharmacistUser &&
        //   !hasRole?.pharmacistAdmin
        // ) {
        //   return <Redirect to={{ pathname: '/register-phone' }} />;
        // }
        if (!hasAllowedRole()) {
          return <Redirect to={{ pathname: '/members' }} />;
        }
        if (component) {
          return createElement(component, renderProps);
        }
        return <Redirect to={{ pathname: '/members' }} />;
      }}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any,
  path: PropTypes.string,
  allowedRoles: PropTypes.array,
  allowedFeatureFlags: PropTypes.object,
};

export default AuthenticatedRoute;
