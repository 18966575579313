import React, { useContext } from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Button, formatPhone, padding, position } from '@zipdrug/ui';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { SessionContext } from 'contexts/SessionContextProvider';
import activeCallAnimation from './activeCallAnimation';

const sx = StyleSheet.create({
  button: {
    ...padding.build(0),
    ...position.size('3.4rem'),
  },
  disabled: {
    color: '#00000040',
    cursor: 'not-allowed',
    background: '#f5f5f5',
    borderColor: '#d9d9d9',
    textShadow: 'none',
    boxShadow: 'none',
    ':hover': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: '#00000040',
    },
  },
  iconAnimation: activeCallAnimation,
});

const CallButton = ({
  isActivePharmacy = true,
  isCalling,
  onClick,
  phone,
  styles,
  title,
  disableOnACall,
  churnedOutreachDate,
}) => {
  const {
    isCallCenterActivatedContext,
    callCenterUserStatusContext,
    isPatientChurnedAlreadyCalledContext,
  } = useContext(CallCenterContext);
  const { state } = useContext(SessionContext);
  const { currentUser } = state;
  const callCenterFeatureFlag = get(currentUser, 'feature_flags.callCenter');

  function isCallCenterBtnDisabled() {
    let value;
    if (
      callCenterFeatureFlag &&
      (!isCallCenterActivatedContext ||
        (callCenterUserStatusContext === 'oncall' && disableOnACall) ||
        !isActivePharmacy ||
        churnedOutreachDate ||
        isPatientChurnedAlreadyCalledContext)
    ) {
      value = true;
    } else if (
      callCenterFeatureFlag &&
      callCenterUserStatusContext === 'available' &&
      isActivePharmacy
    ) {
      value = false;
    } else {
      value = false;
    }
    return value;
  }
  return (
    <Button
      icon="phone"
      iconProps={{ styles: isCalling ? sx.iconAnimation : null }}
      onClick={isCallCenterBtnDisabled() ? null : onClick}
      outline={!isCalling}
      styles={[sx.button, styles, isCallCenterBtnDisabled() ? sx.disabled : null]}
      title={title || `Call ${formatPhone(phone)}`}
      type="primary"
    />
  );
};

CallButton.propTypes = {
  isActivePharmacy: PropTypes.bool,
  isCalling: PropTypes.bool,
  onClick: PropTypes.func,
  phone: PropTypes.object,
  styles: aphroditePropType,
  title: PropTypes.string,
  disableOnACall: PropTypes.bool,
  churnedOutreachDate: PropTypes.bool,
};

export default CallButton;
