import { phones as phonesSelection, withSession } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import fetchOnceOptions from './utils/fetchOnceOptions';
import withRoles from './withRoles';

const withPhones = Component =>
  graphql(phonesSelection.queryPhones, {
    skip: ({ userId }) => !userId,
    options: ({
      userId,
      isAdmin,
      hasRole: { pharmacistUser, pharmacistAdmin },
      workstationPhoneId,
    }) => {
      const query = { is_zipdrug: !pharmacistUser && !pharmacistAdmin };
      if (workstationPhoneId) query.id = workstationPhoneId;
      if (!isAdmin) query.user_id = userId;

      return fetchOnceOptions('phones', {
        variables: { query },
      });
    },
    props: ({
      data: { loading: isLoading, phones: result },
      ownProps: { workstationPhoneId },
    }) => ({
      isLoading,
      phones: workstationPhoneId ? null : get(result, 'data', []),
      workstationPhone: workstationPhoneId ? get(result, 'data[0]') : null,
    }),
  })(Component);

export default Component => compose(withRoles, withSession, withPhones)(Component);
