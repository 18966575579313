import PropTypes from 'prop-types';
import React from 'react';
import { withHandlers } from 'recompose';
import AddressListItem from './AddressListItem';

const AddressListItemContainer = ({ onClickOrder, ...props }) => (
  <AddressListItem {...props} onClickOrder={onClickOrder} />
);

AddressListItemContainer.propTypes = {
  address: PropTypes.object,
  onClickOrder: PropTypes.func,
};

export default withHandlers({
  onClickOrder: ({ address, onClickOrder }) => () => onClickOrder(address),
})(AddressListItemContainer);
