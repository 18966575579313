/* eslint-disable react/prop-types */
import { font } from '@zipdrug/ui';
import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import withRoles from 'hoc/withRoles';
import MembersTable from './components/MemberListTable/MembersTable';
import withCurrentUser from '../../../hoc/withCurrentUser';

const sx = StyleSheet.create({
  membersContainer: {
    padding: '3rem',
  },
  memberContainerHeader: {
    fontSize: '3.6rem',
    marginBottom: '17px',
    color: '#231E33',
    fontWeight: font.weight.medium,
  },
});

const MembersList = ({ currentUser, isPharmacistTeam, isSystemAdminOrAsc }) => {
  return (
    <div className={css(sx.membersContainer)}>
      <p className={css(sx.memberContainerHeader)}>
        {isPharmacistTeam ? 'My Members' : 'Members List'}
      </p>
      <MembersTable
        user={currentUser}
        isPharmacistTeam={isPharmacistTeam}
        isSystemAdminOrAsc={isSystemAdminOrAsc}
      />
    </div>
  );
};

export default withCurrentUser(withRoles(MembersList));
