import { Div, padding, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import buildRxListsFromClaims from './buildRxListsFromClaims';
import RxList from './RxList';
import RxListToggleExpandButton from './RxListToggleExpandButton';
import RxPrescriberGroupHeader from './RxPrescriberGroupHeader';

const sx = StyleSheet.create({
  container: {
    ...padding.build(space.default, 0),
    width: '100%',
    ':first-of-type': { paddingTop: 0 },
  },
  list: {
    width: '100%',
  },
});

const RxPrescriberGroup = ({
  allClaims,
  onToggleShowInactiveClaims,
  prescriber,
  prescriberClaims,
  showInactiveClaims,
}) => {
  if (!prescriberClaims) return null;

  const { active: activeClaimsByNdc, inactive: inactiveClaimsByNdc } = buildRxListsFromClaims(
    prescriberClaims,
  );
  const inactiveCount = inactiveClaimsByNdc ? Object.keys(inactiveClaimsByNdc).length : null;

  return (
    <Div styles={sx.container}>
      <RxPrescriberGroupHeader {...prescriber} />
      <Div styles={sx.list}>
        <RxList allClaims={allClaims} rxClaimsByNdcCode={activeClaimsByNdc} />
        {inactiveCount && showInactiveClaims && (
          <RxList allClaims={allClaims} rxClaimsByNdcCode={inactiveClaimsByNdc} />
        )}
        <RxListToggleExpandButton
          inactiveCount={inactiveCount}
          isExpanded={showInactiveClaims}
          onClick={onToggleShowInactiveClaims}
        />
      </Div>
    </Div>
  );
};

RxPrescriberGroup.propTypes = {
  allClaims: PropTypes.arrayOf(PropTypes.object),
  onToggleShowInactiveClaims: PropTypes.func,
  prescriber: PropTypes.object,
  prescriberClaims: PropTypes.arrayOf(PropTypes.object),
  showInactiveClaims: PropTypes.bool,
};

export default RxPrescriberGroup;
