import { animation, Column, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withHandlers } from 'recompose';
import DeliveryForm from './DeliveryForm';
import DeliveryFormFeaturePlaceholder from './DeliveryFormFeaturePlaceholder';
import DeliveryFormSuccess from './DeliveryFormSuccess';

const CONTAINER_ANIMATION = {
  from: { transform: `translateX(${space.half})` },
  to: { transform: 'translateX(0)' },
};

const sx = StyleSheet.create({
  container: {
    ...animation.build({ name: [CONTAINER_ANIMATION, animation.fadeIn] }),
    overflowX: 'hidden',
    width: '30rem',
  },
});

const DeliveryFormConductor = ({ currentPharmacy, styles, submitSucceeded, ...props }) => {
  let content = null;

  if (!isNil(currentPharmacy) && !currentPharmacy.onfleet_id) {
    content = <DeliveryFormFeaturePlaceholder {...props} />;
  } else if (submitSucceeded) {
    content = <DeliveryFormSuccess {...props} />;
  } else {
    content = <DeliveryForm {...props} />;
  }

  return <Column styles={[sx.container, styles]}>{content}</Column>;
};

DeliveryFormConductor.propTypes = {
  currentPharmacy: PropTypes.object,
  styles: aphroditePropType,
  submitSucceeded: PropTypes.bool,
};

export default withHandlers({
  onClickCancel: ({ onClickCancel, submitting }) => () => {
    if (!submitting && onClickCancel) {
      onClickCancel();
    }
  },
})(DeliveryFormConductor);
