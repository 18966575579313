import {
  animation,
  colors,
  Column,
  Div,
  DropdownFooter,
  font,
  Icon,
  LinkButton,
  Nbsp,
  padding,
  Snackbar,
  space,
  Span,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { compose, withHandlers, withState } from 'recompose';

const sx = StyleSheet.create({
  container: {
    ...padding.build(0, font.size.small, font.size.small, font.size.small),
    fontSize: font.size.xSmall,
    lineHeight: font.lineHeight.looser,
    textAlign: 'center',
  },
  emoji: {
    ...animation.build(),
    color: colors.green,
    fontSize: '6.6rem',
  },
  orderNumber: {
    fontWeight: font.weight.medium,
    color: colors.gray62,
    marginTop: '0.2rem',
  },
  snackbar: {
    marginTop: font.size.small,
  },
  text: {
    color: colors.gray52,
    marginTop: space.quarter,
    maxWidth: '15.2rem',
  },
  title: {
    fontSize: font.size.small,
  },
});

const DeliveryFormSuccess = ({
  hideOnfleetExplainer,
  onClickCancel,
  onClickOnfleet,
  onfleetShortId,
  onHideOnfleetExplainer,
}) => (
  <Fragment>
    <Column align="center" justify="center" styles={sx.container}>
      <Div styles={sx.emoji}>🚛</Div>
      <Div styles={sx.title}>Your delivery was created</Div>
      <Div styles={sx.orderNumber}>Delivery #{onfleetShortId}</Div>
      <Div styles={sx.text}>
        You can assign and track this delivery by signing into your Onfleet account.
      </Div>
      {hideOnfleetExplainer !== 'true' && (
        <Snackbar
          onClickClose={onHideOnfleetExplainer}
          text={`
            Onfleet is the delivery platform that
            Zipdrug program pharmacies use to
            create, manage, and track all of their
            medication deliveries.
          `}
          title={
            <Span>
              👋
              <Nbsp />
              <Nbsp />
              What’s Onfleet?
            </Span>
          }
          styles={sx.snackbar}
        />
      )}
    </Column>
    <DropdownFooter>
      <LinkButton onClick={onClickCancel}>Cancel</LinkButton>
      <LinkButton onClick={onClickOnfleet}>
        <Span>Visit Onfleet</Span>
        <Icon name="externalLink" style={{ marginLeft: space.quarter }} />
      </LinkButton>
    </DropdownFooter>
  </Fragment>
);

DeliveryFormSuccess.propTypes = {
  hideOnfleetExplainer: PropTypes.string,
  onClickCancel: PropTypes.func,
  onClickOnfleet: PropTypes.func,
  onfleetShortId: PropTypes.string,
  onHideOnfleetExplainer: PropTypes.func,
};

const getStateFromLocalStorage = () => window.localStorage.getItem('hideOnfleetExplainer');

export default compose(
  withState('hideOnfleetExplainer', 'onHideOnfleetExplainer', getStateFromLocalStorage),
  withHandlers({
    onHideOnfleetExplainer: ({ onHideOnfleetExplainer }) => () => {
      window.localStorage.setItem('hideOnfleetExplainer', 'true');
      onHideOnfleetExplainer('true');
    },
  }),
)(DeliveryFormSuccess);
