import gql from 'graphql-tag';

const ADD_PHARMACY_NETWORK_TYPE = gql`
  mutation addPharmacyNetworkType($modifiers: PharmacyNetworkTypeUpdateModifiers!) {
    addPharmacyNetworkType(modifiers: $modifiers) {
      data {
        name
        id
        lob
      }
    }
  }
`;

export default ADD_PHARMACY_NETWORK_TYPE;
