import { Role } from 'contexts/utils/models';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';

const defaultPayload = {
  skip: 0,
  limit: 30,
};

export const generateEnrollersAutocompleteVariables = searchValue => {
  const trimmedSearchValue = searchValue.trim();
  return {
    ...defaultPayload,
    query: {
      roles: {
        type: {
          $in: [Role.PbsUser],
        },
      },
      enroller_id: {
        $eq: null,
      },
      $or: _flatten(
        _map(['first_name', 'last_name'], item => {
          return _map(trimmedSearchValue.split(' '), q => {
            return { [item]: { $ilike: `%${q}%` } };
          });
        }),
      ),
    },
  };
};

export const generateLeadsVariables = () => {
  return {
    ...defaultPayload,
    query: {
      roles: {
        type: {
          $in: [Role.PbsLead],
        },
      },
    },
  };
};
