import { get } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { refetchPatients } from '../redux/queryReducer';

const mapDispatchToProps = (dispatch, { refetchPatientsCount }) => ({
  onRefetchPatients() {
    dispatch(refetchPatients({ refetchPatientsCount: refetchPatientsCount + 1 }));
  },
});

const mapStateToProps = state => ({
  // We use the count as an ID for the query and increasing it will refetch the query
  refetchPatientsCount: get(state, 'query.patients.refetchPatientsCount'),
});

export default Component =>
  compose(connect(mapStateToProps), connect(null, mapDispatchToProps))(Component);
