import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery, useMutation, useApolloClient } from '@apollo/react-hooks';
import { cloneDeep, debounce, uniq } from 'lodash';
import PropTypes from 'prop-types';
import { AutoComplete, Button, Form, message, Modal, Select, Spin, Typography, Radio } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import {
  PHARMACY_NAMES,
  GET_PAYERS,
  GET_POSTAL_CODES,
  GET_STATE_FROM_ZIPCODE,
  BULK_DELETE_POSTAL_CODES,
  BULK_DELETE_POTENTIAL_POSTAL_CODES,
} from '../../graphql/zipcodeManagementCalls';
import cleanZipcodes from '../../Utils/CleanZipcodes';
import errorHandle from '../../Utils/ErrorHandling';

// CSS styles
const sx = StyleSheet.create({
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#ff4d4f',
    position: 'relative',
    bottom: '20px',
    padding: '0px 5px',
    backgroundColor: 'white',
  },
  label: {
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  modalContainer: {
    minWidth: '583px',
  },
  title: {
    fontWeight: '500',
  },
  toggleText: {
    fontSize: '16px',
  },
  radioGroup: {
    display: 'flex',
    margin: '8px 0 16px 0',
  },
  radioButton: {
    borderRadius: '0 0 0 0 ',
    height: '32px',
    width: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const { Title, Text } = Typography;
const { Option } = AutoComplete;

const BulkRemoveZipcodesModal = ({ onCloseModal, zipcodeType, visible }) => {
  const [form] = Form.useForm();
  const [selectedZipcodesType, setSelectedZipcodesType] = useState(zipcodeType);
  const [payersData, setPayersData] = useState([]);
  const [pharmacyData, setPharmacyData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedPayer, setSelectedPayer] = useState('');
  const [selectedPharmacy, setSelectedPharmacy] = useState();
  const [state, setState] = useState('');
  const [zipcodeEntry, setZipcodeEntry] = useState([]);
  const [error, setError] = useState(null);
  const apolloClient = useApolloClient();

  const [getPharmacyAutocomplete, { loading: isPharmacyAutoCompleteFetching }] = useLazyQuery(
    PHARMACY_NAMES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ pharmacyNames }) => {
        if (pharmacyNames) {
          setPharmacyData(pharmacyNames?.data);
        }
      },
      onError: error => {
        message.error(`Error fetching pharmacies: ${error.message}`);
      },
    },
  );

  const [getPayers] = useLazyQuery(GET_PAYERS, {
    onCompleted: ({ payers }) => {
      if (payers) {
        setPayersData(payers?.data);
      }
    },
    onError: error => {
      message.error(`Error fetching payers: ${error.message}`);
    },
  });

  const [getStateFromZipcode] = useMutation(GET_STATE_FROM_ZIPCODE, {
    onCompleted: ({ transformZipcodeToState }) => {
      if (transformZipcodeToState) {
        const newStatesArr = uniq([...transformZipcodeToState.data]).join(', ');
        setState(newStatesArr);
      }
    },
    onError: error => {
      message.error(`Error getting state from zip code: ${error.message}`);
    },
  });

  const getCleanZipCodes = () => {
    const newFormValues = form.getFieldValue();
    const cleanZipCodes = cleanZipcodes(newFormValues.zipcodes);

    return cleanZipCodes;
  };

  const [bulkDeleteAssignedPostalCodes] = useMutation(BULK_DELETE_POSTAL_CODES, {
    onError: error => {
      const errorMessage = errorHandle(error.message);
      setError(errorMessage);
    },
    onCompleted: () => {
      message.success('Assigned postal codes has been successfully deleted');
      const cleanZipCodes = getCleanZipCodes();
      const zipcodesType = selectedZipcodesType;
      setSelectedZipcodesType('assigned');
      setState('');
      form.resetFields();
      onCloseModal(zipcodesType, cleanZipCodes);
    },
  });

  const [bulkDeletePotentialPostalCodes] = useMutation(BULK_DELETE_POTENTIAL_POSTAL_CODES, {
    onError: error => {
      const errorMessage = errorHandle(error.message);
      setError(errorMessage);
    },
    onCompleted: () => {
      message.success('Potential postal codes has been successfully deleted');
      const cleanZipCodes = getCleanZipCodes();
      const zipcodesType = selectedZipcodesType;
      setSelectedZipcodesType('assigned');
      setState('');
      form.resetFields();
      onCloseModal(zipcodesType, cleanZipCodes);
    },
  });

  const fetchZipcodeOptions = async value => {
    const variables = {
      limit: 100,
      filter: value,
    };
    const { data } = await apolloClient.query({
      query: GET_POSTAL_CODES,
      variables,
      fetchPolicy: 'network-only',
    });

    return data ? data?.getPostalCodesByFilter?.data : [];
  };

  function DebounceSelect({ fetchOptions, debounceTimeout = 500, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
      const loadOptions = value => {
        fetchRef.current += 1;
        const currentArray = form.getFieldValue('zipcodes');
        const clonedArr = cloneDeep(currentArray);
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
        if (value.includes(',')) {
          let newArr = [];
          form.resetFields(['zipcodes']);
          if (clonedArr) {
            newArr = [
              ...cleanZipcodes(clonedArr),
              ...value
                .replace(/\s/g, '')
                .split(',')
                .filter(a => a),
            ];
          } else {
            newArr = value
              .replace(/\s/g, '')
              .split(',')
              .filter(a => a);
          }
          form.setFieldsValue({ zipcodes: newArr });
          updateStateFromZip(newArr);
        } else {
          fetchOptions(value).then(newOptions => {
            if (fetchId !== fetchRef.current) {
              return;
            }
            const mappedNewOptions = newOptions.map(option => {
              return {
                label: option,
                value: option,
              };
            });
            setOptions(mappedNewOptions);
            setFetching(false);
          });
        }
      };

      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
  }

  DebounceSelect.propTypes = {
    debounceTimeout: PropTypes.number,
    fetchOptions: PropTypes.func,
    onChange: PropTypes.func,
  };

  const onCancel = () => {
    setSelectedZipcodesType('assigned');
    form.resetFields();
    setError(null);
    setState('');
    onCloseModal();
  };

  const submitForm = () => {
    setError(null);
    const pharmacyId = selectedPharmacy?.id;
    form
      .validateFields()
      .then(() => {
        if (selectedZipcodesType === 'assigned') {
          bulkDeleteAssignedPostalCodes({
            variables: {
              payer_id: selectedPayer,
              pharmacy_id: pharmacyId,
              postal_codes: getCleanZipCodes(),
            },
          });
        } else {
          bulkDeletePotentialPostalCodes({
            variables: {
              pharmacy_id: pharmacyId,
              postal_codes: getCleanZipCodes(),
            },
          });
        }
      })
      .catch(info => {
        return info;
      });
  };

  const getPharmacyDataOptions = value => {
    const variables = {
      skip: 0,
      limit: 100,
      zipdrugOnly: false,
      query: {
        name: { $ilike: `%${value}%` },
        status: { $and: [{ $ne: null }, { $ne: 'inactive' }] },
      },
    };
    getPharmacyAutocomplete({ variables });
  };

  const getPharmacyData = useCallback(
    debounce(value => {
      getPharmacyDataOptions(value);
    }, 500),
    [],
  );

  const updateStateFromZip = value => {
    getStateFromZipcode({
      variables: {
        postal_codes: value,
      },
    });
  };

  const handleRadioButtonClick = e => {
    setSelectedZipcodesType(e);
  };

  const onSearch = value => {
    setSearchValue(value);
    if (value.length === 0) {
      setPharmacyData([]);
    } else if (value.length > 1) {
      getPharmacyData(value);
    }
  };

  const onSelect = (value, option) => {
    const selectedOption = pharmacyData[option.index];
    setSelectedPharmacy(selectedOption);
    setSearchValue(value);
  };

  const onZipSelect = newValue => {
    if (newValue) {
      updateStateFromZip(newValue.map(s => s.value));
    }
    setZipcodeEntry(newValue);
  };

  const handlePayerChange = value => {
    setSelectedPayer(value);
  };

  useEffect(() => {
    form.resetFields([]);
    setPharmacyData([]);
    setError(null);
    getPayers({
      variables: {
        query: {
          name: 'Anthem',
        },
      },
    });
  }, [visible]);

  useEffect(() => {
    form.resetFields();
    setError(null);
  }, [selectedZipcodesType]);

  return (
    <Modal
      getContainer={false}
      onOk={submitForm}
      onCancel={onCancel}
      open={visible}
      okText="Save"
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={onCancel} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={submitForm} className={css(sx.button)}>
          Save
        </Button>,
      ]}
    >
      <Title className={css(sx.title)} level={3}>
        Bulk Remove Zipcodes
      </Title>
      <Text className={css(sx.toggleText)}>
        Do you want to remove from an assigned or potential pharmacy?
      </Text>
      <Radio.Group
        optionType="button"
        buttonStyle="solid"
        className={css(sx.radioGroup)}
        value={selectedZipcodesType}
        onChange={e => handleRadioButtonClick(e?.target?.value)}
      >
        <Radio.Button className={css(sx.radioButton)} value="assigned">
          Assigned
        </Radio.Button>
        <Radio.Button className={css(sx.radioButton)} value="potential">
          Potential
        </Radio.Button>
      </Radio.Group>
      <Form name="modify-form-ref" form={form} layout="vertical">
        {selectedZipcodesType === 'assigned' && (
          <>
            <p className={css(sx.label)}>PAYER</p>
            <Form.Item
              name="payer"
              rules={[{ required: true, message: 'PAYER IS REQUIRED.' }]}
              validateTrigger="submit"
            >
              <Select onChange={handlePayerChange}>
                {payersData.map(p => (
                  <Select.Option value={p.id} key={p.id}>
                    {p.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}
        <p className={css(sx.label)}>PHARMACY</p>
        <Form.Item
          name="pharmacy"
          rules={[{ required: true, message: 'PHARMACY IS REQUIRED.' }]}
          validateTrigger="submit"
        >
          <AutoComplete
            placeholder="Enter Pharmacy"
            value={searchValue}
            onSearch={value => onSearch(value)}
            onSelect={onSelect}
            loading={isPharmacyAutoCompleteFetching}
            className={css(sx.largeText)}
          >
            {pharmacyData.map((option, index) => (
              <Option key={option.id} index={index} value={`${option.name} ${option.npi}`}>
                {option.name} {option.npi}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <p className={css(sx.label)}>ZIP CODE (CAN ADD MULTIPLE ZIP CODES, SEPARATE WITH COMMAS)</p>
        <Form.Item
          name="zipcodes"
          rules={[
            () => ({
              validator(_, value) {
                if (!value || value?.length === 0) {
                  return Promise.reject(new Error('ZIP CODE IS REQUIRED.'));
                }
                return Promise.resolve();
              },
            }),
          ]}
          validateTrigger="submit"
        >
          <DebounceSelect
            fetchOptions={fetchZipcodeOptions}
            mode="multiple"
            value={zipcodeEntry}
            onChange={newValue => onZipSelect(newValue)}
          />
        </Form.Item>
        {error && <Text className={css(sx.error)}>{error}</Text>}
        <p className={css(sx.label)}>STATE(S)</p>
        <p>{state}</p>
      </Form>
    </Modal>
  );
};

BulkRemoveZipcodesModal.propTypes = {
  onCloseModal: PropTypes.func,
  zipcodeType: PropTypes.string,
  visible: PropTypes.bool,
};

export default BulkRemoveZipcodesModal;
