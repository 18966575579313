import gql from 'graphql-tag';

const GET_CAMPAIGNSLIST = gql`
  query campaigns($limit: Int, $skip: Int) {
    campaigns(limit: $limit, skip: $skip) {
      data {
        name
        status
      }
      limit
      skip
    }
  }
`;

export default GET_CAMPAIGNSLIST;
