import { compose, withHandlers } from 'recompose';
import withActiveCallAnimation from '../hoc/withActiveCallAnimation';
import withStartOutboundCall from '../hoc/withStartOutboundCall';
import CallButton from './CallButton';

export default compose(
  withStartOutboundCall,
  withActiveCallAnimation,
  withHandlers({
    onClick: ({ onCallPhone, phone }) => () => {
      if (phone && phone.id) {
        onCallPhone(phone.id);
      }
    },
  }),
)(CallButton);
