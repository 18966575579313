import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Form, Input, message } from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import { addNewEmail, updateNewEmail } from '../../../graphql/patients';

// CSS styles
const sx = StyleSheet.create({
  marginTop25: {
    marginTop: 25,
  },
  label: {
    margin: 0,
    fontSize: 12,
    color: 'black',
  },
  underline: {
    textDecoration: 'underline',
  },
  width100: {
    width: '100%',
  },
});

const EmailPreferredFollowup = ({ email, setEmailId, setError, patient }) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  const [addEmail] = useMutation(addNewEmail, {
    onError: () => {
      setError('AN ERROR HAS OCCURRED. PLEASE TRY AGAIN.');
    },
    onCompleted: ({ addEmail }) => {
      message.success(`Email has been successfully added`);
      patient.emails.push(addEmail.data);
      if (addEmail?.data?.id) {
        setEmailId(addEmail?.data?.id);
      }
      form.resetFields();
    },
  });

  const [updateEmail] = useMutation(updateNewEmail, {
    onError: () => {
      setError('AN ERROR HAS OCCURRED. PLEASE TRY AGAIN.');
    },
    onCompleted: ({ updateEmail }) => {
      message.success(`Email has been successfully updated`);
      patient.emails[0] = updateEmail.data;
      if (updateEmail?.data?.id) {
        setEmailId(updateEmail?.data?.id);
      }
    },
  });

  const handleSave = () => {
    const newEmail = form.getFieldValue('email');
    setError(null);
    if (email && email !== newEmail) {
      form
        .validateFields()
        .then(() => {
          updateEmail({
            variables: {
              id: patient.emails[0].id,
              modifiers: {
                email: newEmail,
              },
            },
          });
        })
        .catch(info => {
          return info;
        });
    } else if (!email) {
      form
        .validateFields()
        .then(() => {
          addEmail({
            variables: {
              patientId: patient.id,
              modifiers: {
                email: newEmail,
              },
            },
          });
        })
        .catch(info => {
          return info;
        });
    } else {
      message.success(`Email address saved. Continue to save preferred follow-up.`);
    }
  };

  const handleCancel = () => {
    form.setFieldsValue({ email: null });
    setEmailId(null);
    setError(null);
  };

  const detectChange = e => {
    if (e.target.value !== email) {
      setIsEditing(true);
      setError(null);
    } else {
      setIsEditing(false);
      setError(null);
    }
  };

  useEffect(() => {
    if (isEditing) {
      setEmailId(null);
    } else {
      setEmailId(patient?.emails[0]?.id);
    }
  }, [isEditing]);

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [email]);

  return (
    <>
      <div>{email ? 'Please check existing email' : 'Please add an email'}</div>
      <Row>
        <Col span={16}>
          <Form
            name="modify-form-ref"
            form={form}
            layout="vertical"
            onChange={e => detectChange(e)}
            validateTrigger="handleSave"
          >
            <div className={css(sx.flexContainer)}>
              <div className={css(sx.inputBox)}>
                <p className={css(sx.label)}>EMAIL</p>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'PLEASE ENTER A VALID EMAIL ADDRESS',
                    },
                    {
                      required: true,
                      message: 'EMAIL ADDRESS IS A REQUIRED FIELD',
                    },
                  ]}
                >
                  {email ? <Input defaultValue={email} /> : <Input />}
                </Form.Item>
              </div>
            </div>
          </Form>
        </Col>
        <Col size="small" span={4} align="right">
          <Button className={css(sx.marginTop25)} key="submit" type="link" onClick={handleSave}>
            <span className={css(sx.underline)}>Save</span>
          </Button>
        </Col>
        <Col size="small" span={4} align="left">
          <Button className={css(sx.marginTop25)} key="cancel" type="link" onClick={handleCancel}>
            <span className={css(sx.underline)}>Clear</span>
          </Button>
        </Col>
      </Row>
    </>
  );
};

EmailPreferredFollowup.propTypes = {
  email: PropTypes.string,
  patient: PropTypes.object,
  setEmailId: PropTypes.func,
  setError: PropTypes.func,
};

export default EmailPreferredFollowup;
