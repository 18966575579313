import { connect } from 'react-redux';
import { openModal } from '../../../redux/modal';
import NoteActivityItem from './NoteActivityItem';

const mapDispatchToProps = (dispatch, { activity }) => ({
  onClickDelete: () =>
    dispatch(
      openModal({
        modalName: 'CONFIRM_DELETE_ACTIVITY_ITEM',
        modalProps: { activity },
      }),
    ),
});

export default connect(null, mapDispatchToProps)(NoteActivityItem);
