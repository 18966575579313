import gql from 'graphql-tag';

const alertField = `
call_id
completed_at
id
type
`;

const basicUserFields = `
first_name
id
last_name
`;

const deliveryField = `
complete_after
complete_before
completed_at
completion_note
created_by_user {
  ${basicUserFields}
}
destination_address {
  label
}
failure_reason
id
note
short_id
signature_upload_id
state
tracking_url
worker_id
`;

const phoneField = `
can_sms
extension
id
is_zipdrug
label
number
patient_id
pharmacy_id`;

const activityFields = `
call {
  alert {
    ${alertField}
  }
  direction
  file_url
  from_phone {
    ${phoneField}
  }
  from_user {
    ${basicUserFields}
  }
  id
  recording_duration
  to_user_id
  to_pharmacy_id
  to_phone {
    ${phoneField}
  }
  to_user {
    ${basicUserFields}
  }
}
created_at
deleted_at
delivery {
  ${deliveryField}
}
id
interaction {
  survey_type
  invitation_date
  created_by_user {
    ${basicUserFields}
  }
  pharmacy {
    name
  }
}
letter {
  created_at
  created_by_user {
    ${basicUserFields}
  }
  expected_delivery_date
  id
  returned_to_sender_date
  to_address_id
}
model_update {
  created_by_user {
    ${basicUserFields}
  }
  id
  new_fields
  old_fields
}
note {
  alert {
    ${alertField}
  }
  created_by_user {
    ${basicUserFields}
  }
  id
  text
}
patient {
  ${basicUserFields}
}
call_outcome_id
enroll_status_outcome_id
type
`;

const queryActivities = gql`
  query activities($limit: Int, $query: JSON, $skip: Int) {
    activities(limit: $limit, query: $query, skip: $skip) {
      data {
        ${activityFields}
      }
      limit
      skip
      total
    }
  }
`;

export default queryActivities;
