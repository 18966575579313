import React from 'react';

const HoldIcon = () => (
  <svg width="10" height="17" viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="1.5"
      y1="1.5"
      x2="1.5"
      y2="15.5"
      stroke="#048802"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="8.5"
      y1="1.5"
      x2="8.5"
      y2="15.5"
      stroke="#048802"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export default HoldIcon;
