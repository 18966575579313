import React from 'react';
import PropTypes from 'prop-types';
import { Button, AutoComplete, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite';

const sx = StyleSheet.create({
  inputField: { width: '200px', marginBottom: '8px', display: 'block' },
  searchContainer: {
    padding: '8px',
    maxWidth: '250px',
  },
  searchButton: { width: '90px' },
});

const { Option } = Select;

const SearchbuttonValues = {};

const SearchBox = ({
  placeholder,
  onSearch,
  onReset,
  onFilterSelection,
  options,
  value,
  defaultValue,
  loading,
}) => {
  const AutoCompleteBox = () => {
    SearchbuttonValues.name = 'Search';
    SearchbuttonValues.icon = <SearchOutlined />;
    return (
      <AutoComplete
        className={css(sx.inputField)}
        placeholder={placeholder}
        value={value}
        onSearch={value => onSearch(value)}
        onSelect={(value, option) => {
          onFilterSelection(value, option);
        }}
        options={options}
        defaultValue={defaultValue}
      />
    );
  };

  const SelectBox = () => {
    SearchbuttonValues.name = 'Apply';
    SearchbuttonValues.icon = null;
    return (
      <Select
        className={css(sx.inputField)}
        mode="multiple"
        placeholder={placeholder}
        onChange={value => {
          onSearch(value);
        }}
        defaultValue={defaultValue || []}
        value={value || []}
        loading={loading}
        optionFilterProp="children"
      >
        {options.map((option, index) => {
          return (
            <Option key={option.id} index={index} value={option.id}>
              {option.name}
            </Option>
          );
        })}
      </Select>
    );
  };

  const generateInputFied = () => (options?.length > 0 ? SelectBox() : AutoCompleteBox());

  return (
    <div className={css(sx.searchContainer)}>
      {generateInputFied()}
      <Space>
        <Button
          type="primary"
          onClick={onFilterSelection}
          icon={SearchbuttonValues.icon}
          size="small"
          className={css(sx.searchButton)}
        >
          {SearchbuttonValues.name}
        </Button>
        <Button onClick={onReset} size="small" className={css(sx.searchButton)}>
          Reset
        </Button>
      </Space>
    </div>
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  onFilterSelection: PropTypes.func,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  defaultValue: PropTypes.string,
  loading: PropTypes.bool,
};

export default SearchBox;
