const getLeadListFilters = isAdmin => {
  const leadStatusFilters = [
    { text: '--', value: 'notCalled' },
    { text: 'Called', value: 'called' },
    { text: 'Enrolled', value: 'enrolled' },
    { text: 'Call Back Later', value: 'call_back_later' },
  ];

  if (isAdmin) {
    leadStatusFilters.push({ text: 'Filter Out', value: 'filter_out' });
  }
  return leadStatusFilters;
};

export default getLeadListFilters;
