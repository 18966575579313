import React from 'react';

const ExclamationPoint = () => (
  <svg width="6" height="21" viewBox="0 0 6 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.319599 2.98293C0.148883 1.38958 1.39753 0 3 0C4.60247 0 5.85112 1.38958 5.6804 2.98293L4.6598 12.5085C4.56892 13.3568 3.85309 14 3 14C2.14691 14 1.43108 13.3568 1.3402 12.5085L0.319599 2.98293Z"
      fill="#696969"
    />
    <circle cx="3" cy="19" r="2" fill="#696969" />
  </svg>
);

export default ExclamationPoint;
