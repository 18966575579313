import PropTypes from 'prop-types';
import { cloneElement, Component, createRef } from 'react';

export default class SettingsAccordionListForm extends Component {
  static propTypes = {
    component: PropTypes.node,
    onClickCancel: PropTypes.func,
    onScrolledIntoView: PropTypes.func,
    // eslint-disable-next-line react/no-unused-prop-types
    shouldBeScrolled: PropTypes.bool,
  };

  componentDidMount = () => this.scrollIntoView();

  componentWillReceiveProps = nextProps => {
    const { shouldBeScrolled } = nextProps;

    if (shouldBeScrolled) {
      this.scrollIntoView();
    }
  };

  scrollIntoView = () => {
    // eslint-disable-next-line react/no-find-dom-node
    const scrollTarget = this.inputRef.current;

    if (scrollTarget) {
      scrollTarget.scrollIntoView({ behavior: 'smooth' });

      if (this.props.onScrolledIntoView) {
        this.props.onScrolledIntoView();
      }
    }
  };

  inputRef = createRef(null);
  handleRef = ref => {
    this.inputRef = ref;
  };

  render = () =>
    cloneElement(this.props.component, {
      onClickCancel: this.props.onClickCancel,
      ref: this.handleRef,
    });
}
