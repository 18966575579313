import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const queryPharmacies = gql`
  query pharmacies($limit: Int, $query: JSON, $skip: Int) {
    pharmacies(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        canAccessTasks
        name
        onfleet_id
        npi
        maintenance_threshold
        pharmacy_asc_id
        status
        parent_pharmacy {
          name
          npi
        }
        chain_pharmacies {
          id
          npi
          parent_pharmacy_npi
        }
      }
      limit
      skip
      total
    }
  }
`;

export const GET_UPDATED_PHARMACY_SUBSCRIPTION = gql`
  subscription getPharmacySubscription($id: Int!) {
    getPharmacySubscription(id: $id) {
      id
    }
  }
`;
