import { patients, withSession } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRefreshPatients from '../../../hoc/withRefreshPatients';
import SnoozeDropdown from './SnoozeDropdown';

class SnoozeDropdownContainer extends Component {
  static propTypes = {
    mutateUpdatePatient: PropTypes.func,
    onRefetchPatients: PropTypes.func,
    patient: PropTypes.object,
  };

  componentWillMount = () => this.handleExpiredSnoozeValue(this.snoozed_until);
  componentWillReceiveProps = ({ patient: { snoozed_until } }) =>
    this.handleExpiredSnoozeValue(snoozed_until);

  get snoozed_until() {
    return get(this.props, 'patient.snoozed_until');
  }

  handleExpiredSnoozeValue = value => {
    if (value && moment(value).isBefore()) {
      this.handleSnoozeChange(null);
    }
  };

  handleSnoozeChange = async newValue =>
    alertOnError(
      this.props.mutateUpdatePatient({
        variables: {
          id: this.props.patient.id,
          modifier: {
            snoozed_until: newValue ? newValue.valueOf() : null,
          },
        },
      }),
    ).then(this.props.onRefetchPatients);

  render = () => (
    <SnoozeDropdown
      {...this.props}
      onSnoozeChange={this.handleSnoozeChange}
      snoozeValue={this.snoozed_until}
    />
  );
}

export default compose(
  graphql(patients.mutateUpdatePatient, { name: 'mutateUpdatePatient' }),
  withSession,
  withRefreshPatients,
)(SnoozeDropdownContainer);
