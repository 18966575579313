import { requiredField, validateForm } from '@zipdrug/ui';
import { queryPharmacies } from 'graphql/pharmacies';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import withRoles from '../../hoc/withRoles';
import withUserMutation from '../../hoc/withUserMutation';
import TeamListInviteForm from './TeamListInviteForm';

const INITIAL_VALUES = {
  email: null,
  user_type: null,
  title: null,
  pharmacy: null,
};

const ERROR_MESSAGES = {
  invalidEmail: 'is invalid',
  required: 'is required.',
};

const TEAM_MEMBER_SCHEMA = {
  email: {
    required: true,
    type: 'email',
  },
  user_type: requiredField,
  title: {
    required: false,
  },
  pharmacy: {
    required: false,
  },
  ticket_number: {
    required: true,
  },
};

const TITLE_TYPES = {
  care_manager: ['Admin', 'Care Manager'],
  pharmacy: ['Pharmacist', 'Pharmacy Technician'],
};

const validate = values => validateForm(values, TEAM_MEMBER_SCHEMA, ERROR_MESSAGES);

const ReduxTeamListInviteForm = reduxForm({
  form: 'team[new]',
  initialValues: INITIAL_VALUES,
  validate,
})(TeamListInviteForm);

class TeamListInviteFormContainer extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    hasRole: PropTypes.object,
    isPharmacyTeam: PropTypes.bool,
    isPbsTeam: PropTypes.bool,
    isPharmacistTeam: PropTypes.bool,
    mutateCreateUser: PropTypes.func,
    onClickCancel: PropTypes.func.isRequired,
    ExtUserTypesMapping: PropTypes.object,
    getPharmaciesByNpi: PropTypes.func,
    isPharmaciesLoading: PropTypes.bool,
    pharmacies: PropTypes.array,
    currentUser: PropTypes.object,
  };

  handleSubmit = async values => {
    const { email, title, user_type, pharmacy } = values;

    const { mutateCreateUser, onClickCancel } = this.props;
    const data = {
      email,
      user_type: user_type?.value,
    };

    if (title) {
      data.title = title.value;
    }
    data.ticket_number = values.ticket_number;

    if (pharmacy) {
      const pharmacyObj = this.props.pharmacies.find(p => p.id === pharmacy.value);
      data.pharmacy_id = pharmacy.value;
      data.pharmacy_name = pharmacyObj?.name;
    }
    const result = await mutateCreateUser(data);

    if (onClickCancel) {
      onClickCancel();
    }
    return result;
  };

  generateTitleTypes = () => {
    const titleTypesArr = [...TITLE_TYPES.pharmacy];
    return titleTypesArr.map(user_type => ({
      label: user_type,
      value: user_type,
    }));
  };

  generateUserTypes = () => {
    return Object.values(this.props.ExtUserTypesMapping).map(title_type => ({
      label: title_type,
      value: title_type,
    }));
  };

  generatePharmTypes = () => {
    const pharmaciesFiltered =
      this.props.currentUser.roles[0].type === 'pharmacy_asc'
        ? this.props.pharmacies.filter(p => p.pharmacy_asc_id === this.props.currentUser.id)
        : this.props.pharmacies;
    return pharmaciesFiltered.map(p => ({
      label: `${p.name} - ${p.npi}`,
      value: p.id,
    }));
  };

  render = () => (
    <>
      {(this.props.hasRole.systemAdmin ||
        this.props.hasRole.pharmacyAscAdmin ||
        this.props.hasRole.pharmacyAsc) &&
        !this.props.isPharmaciesLoading && (
          <ReduxTeamListInviteForm
            {...this.props}
            onClickCancel={this.props.onClickCancel}
            onSubmit={this.handleSubmit}
            userTypes={this.generateUserTypes()}
            titleTypes={this.generateTitleTypes()}
            pharmTypes={this.generatePharmTypes()}
          />
        )}
    </>
  );
}

export default compose(
  withRoles,
  withCurrentPharmacy,
  withUserMutation,
  graphql(queryPharmacies, {
    options: () => ({
      fetchPolicy: 'network-only',
      variables: {
        query: {
          pharmacy_asc_id: {
            $not: null,
          },
        },
        limit: 500,
      },
    }),
    props: ({ data: { loading, pharmacies: result } }) => ({
      isPharmaciesLoading: loading,
      pharmacies: get(result, 'data', []),
    }),
  }),
)(TeamListInviteFormContainer);
