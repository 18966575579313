/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { StyleSheet, css } from 'aphrodite';
import { Table, message, Typography, Tooltip, Button } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import GET_NOTES from '../../graphql/getNotes';
import UPDATE_NOTES from '../../graphql/updateNotes';
import dateConverter from '../../../../shared/utils/dateConverter';
import localToUtcDateConverter from '../../../../shared/utils/localToUtcDateConverter';
import withSelectPatient from '../../../../../hoc/withSelectPatient';
import generateVariables from '../../utils/GenerateVariables.utils';
import filterSearchBox from '../Filters/Search/Search';
import states from '../../constants/states';

const sx = StyleSheet.create({
  marginWrapper: {
    margin: '10px',
  },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
  disabledRow: {
    background: '#f5f5f5',
    opacity: '0.5',
  },
});

const { Text, Link } = Typography;

const defaultSelectedNotes = {
  selectedNoteIds: [],
  selectedNoteRecords: [],
};

const NotesListTable = ({ onSelectPatient, history }) => {
  const [data, setData] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState(defaultSelectedNotes);
  const [tableActions, setTableActions] = useState({});
  const [fetchNotes, { loading: isFetchingNotes, refetch: refetchNotes }] = useLazyQuery(
    GET_NOTES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error fetching notes: ${error.message}`);
      },
      onCompleted: ({ getNotes }) => {
        setData(getNotes);
      },
    },
  );

  const [updateNotes, { loading: isUpdatingNotes }] = useMutation(UPDATE_NOTES, {
    onError: error => {
      message.error(`Error updating Note: ${error.message}`);
    },
    onCompleted: ({ dismissNotes }) => {
      const clonedData = cloneDeep(data?.data);
      const noteIds = dismissNotes?.data?.map(note => note.id);

      const resultData = clonedData.map(note => {
        if (noteIds.includes(note.id)) {
          note.dismissed_at = dismissNotes?.data[0]?.dismissed_at;
        }
        return note;
      });

      setData({ data: resultData, total: data?.total });
      setSelectedNotes(defaultSelectedNotes);
      message.success('Successfully Updated Notes!');
    },
  });

  useEffect(() => {
    fetchNotesList();
  }, []);

  const onMemberClick = patientId => {
    onSelectPatient(patientId);
    history.push('/members');
  };

  const generateColumns = () => {
    const headers = [
      {
        title: 'Status',
        width: 100,
        dataIndex: 'text',
        key: 'status',
        filters: [
          { text: '#help', value: '#help' },
          { text: '#received', value: '#received' },
        ],
        filteredValue: tableActions?.filters?.status || null,
        filterMultiple: false,
        render: text => {
          if (text.match(/#help/i)) {
            return '#help';
          } else if (text.match(/#received/i)) {
            return '#received';
          }

          return '#unknown';
        },
      },
      {
        title: 'Created By',
        width: 210,
        dataIndex: 'created_by_user',
        key: 'created_by',
        render: (text, record) => {
          return (
            <>
              <Text strong>{`${text?.first_name} ${text?.last_name}`}</Text>
              <br />
              <Tooltip
                title={dateConverter(
                  localToUtcDateConverter(new Date(record?.created_at)),
                  'MM/DD/YYYY | h:mm a',
                )}
              >
                <span>
                  {dateConverter(
                    localToUtcDateConverter(new Date(record?.created_at)),
                    'MM/DD/YYYY',
                  )}
                </span>
              </Tooltip>
              <br />
            </>
          );
        },
      },
      {
        title: 'Patient',
        dataIndex: 'activity',
        key: 'patient',
        width: 150,
        render: record => {
          return (
            <Link
              className={'fs-hide'}
              underline
              onClick={() => onMemberClick(record?.patient?.id)}
            >
              {`${record?.patient?.first_name} ${record?.patient?.last_name}`}
            </Link>
          );
        },
      },
      {
        title: 'Member ID',
        dataIndex: 'activity',
        key: 'member_id',
        width: 125,
        render: text => {
          return (
            <div className={'fs-hide'}>
              {text?.patient?.memberships?.map(membership => membership?.member_id)?.join(', ')}
            </div>
          );
        },
      },
      {
        title: 'Pharmacy Name',
        dataIndex: 'activity',
        key: 'pharmacy_name',
        width: 175,
        ...filterSearchBox(
          'pharmacy_name',
          'Pharmacy Name',
          tableActions?.filters?.pharmacy_name,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.pharmacy_name || null,
        render: record => {
          return record?.patient?.assigned_pharmacy?.name;
        },
      },
      {
        title: 'Pharmacy State',
        dataIndex: 'activity',
        key: 'pharmacy_state',
        width: 175,
        filters: states,
        filteredValue: tableActions?.filters?.pharmacy_state || null,
        render: record => {
          return record?.patient?.assigned_pharmacy?.address?.state;
        },
      },
      {
        title: 'Note',
        dataIndex: 'text',
        key: 'note',
        width: 350,
        ...filterSearchBox(
          'note',
          'Note',
          tableActions?.filters?.note,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.note || null,
        render: record => {
          return (
            <Tooltip title={record}>
              <span>{record.length > 130 ? `${record.substring(0, 130)} ...` : record}</span>
            </Tooltip>
          );
        },
      },
      {
        title: 'Plan',
        dataIndex: 'activity',
        key: 'plan',
        width: 200,
        render: (text, record) => {
          return text?.patient?.plans?.map(plan => {
            return (
              <div key={`${plan?.id}-${record?.id}`}>
                <Text strong>{plan?.name}</Text>
                <br />
                <Text>{plan?.description}</Text>
                <br />
              </div>
            );
          });
        },
      },
    ];
    return headers;
  };

  const fetchNotesList = (payload = cloneDeep(tableActions), refetch = false) => {
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }
    const variables = generateVariables(payload);

    if (refetch) {
      refetchNotes({
        ...variables,
      });
    } else {
      fetchNotes({
        variables: {
          ...variables,
        },
      });
    }
  };

  const cleanFilters = filters => {
    if (tableActions.filters) {
      // Diff between new and old keys to see what key was updated.
      const newFilter = Object.keys(tableActions.filters).filter(
        key => filters[key] !== tableActions.filters[key],
      );

      const overwriteFilter = [];

      Object.keys(filters).forEach(key => {
        if (key !== newFilter?.[0] && overwriteFilter.includes(key)) {
          filters[key] = null;
        }
      });
    }

    return filters;
  };

  const onTableChange = (pagination, filters, sorter, { action }) => {
    const cleanedFilters = cleanFilters(filters);

    setTableActions({
      pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
      filters: cleanedFilters,
    });

    if (action === 'paginate') {
      fetchNotesList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchNotesList({
        filters: cleanedFilters,
        sorter,
      });
    }
  };

  // Useful when you update an value and the filter/sort is not applied.
  const refreshList = () => {
    fetchNotesList(tableActions, true);
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: {
        current: 0,
      },
      filters: null,
      sorter: null,
    };

    setTableActions(payload);
    fetchNotesList(payload);
  };

  const onNoteDismiss = async (noteIds, status) => {
    updateNotes({
      variables: { ids: noteIds, dismissed_at: status },
    });
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data?.data,
      size: 'middle',
      onChange: onTableChange,
      rowKey: 'id',
      rowClassName: record => record.dismissed_at !== null && css(sx.disabledRow),
      loading: isFetchingNotes || isUpdatingNotes,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: data?.total || 0,
        current: tableActions?.pagination?.current || 1,
      },
      rowSelection: {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedNotes({ selectedNoteIds: selectedRowKeys, selectedNoteRecords: selectedRows });
        },
        selectedRowKeys: selectedNotes.selectedNoteIds,
      },
    };

    return tableOptions;
  };

  return (
    <div className={css(sx.marginWrapper)}>
      <div className={css(sx.tableOptionsContainer)}>
        <Button type="secondary" className={css(sx.tableOptionsButtonMargin)} onClick={refreshList}>
          Refresh List
        </Button>
        <Button
          type="secondary"
          className={css(sx.tableOptionsButtonMargin)}
          onClick={clearAllFilters}
        >
          Clear All Filters
        </Button>
        {selectedNotes?.selectedNoteIds?.length > 0 && (
          <>
            <Button
              type="secondary"
              className={css(sx.tableOptionsButtonMargin)}
              onClick={() => onNoteDismiss(selectedNotes?.selectedNoteIds, Date.now())}
            >
              Dismiss Note
            </Button>
            <Button
              type="secondary"
              className={css(sx.tableOptionsButtonMargin)}
              onClick={() => onNoteDismiss(selectedNotes?.selectedNoteIds, null)}
            >
              Un-Dismiss Note
            </Button>
          </>
        )}
      </div>
      <Table {...getTableOptions()} />
    </div>
  );
};

export default withSelectPatient(withRouter(NotesListTable));
