import { colors, FlexCentered } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DropdownHeader from '../../../ui/DropdownHeader';

const sx = StyleSheet.create({
  featurePlaceholder: {
    color: colors.gray45,
    height: '5rem',
  },
});

const DeliveryFormFeaturePlaceholder = ({ onClickCancel }) => (
  <Fragment>
    <DropdownHeader onClickCancel={onClickCancel} title="Onfleet needs configuration" />
    <FlexCentered styles={sx.featurePlaceholder}>
      Please configure your onfleet account
    </FlexCentered>
  </Fragment>
);

DeliveryFormFeaturePlaceholder.propTypes = {
  onClickCancel: PropTypes.func,
};

export default DeliveryFormFeaturePlaceholder;
