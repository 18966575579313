import gql from 'graphql-tag';

const UPDATE_CAMPAIGN = gql`
  mutation updateCampaign($id: Int!, $campaignModifiers: CampaignModifiers!) {
    updateCampaign(id: $id, campaignModifiers: $campaignModifiers) {
      data {
        id
        payer_ids
        name
        description
        status
        file_name
        file_upload_message
        file_upload_status
        priority
        pharmacy_ids
        plan_ids
        special_notes
        assigned_enrollers
      }
    }
  }
`;

export default UPDATE_CAMPAIGN;
