import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const queryPharmacyServices = gql`
  query pharmacyServices($limit: Int, $skip: Int, $ids: [Int]!) {
    pharmacyServices(limit: $limit, ids: $ids, skip: $skip) {
      data {
        id
        pharmacy_service_type_id
        pharmacy_id
        pharmacy_service_type {
          pharmacy_service_type
        }
      }
      total
    }
  }
`;
