import { models, patients } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { graphql } from 'react-apollo';
import { compose, withHandlers, withProps, withState } from 'recompose';
import withRefreshPatients from '../../../hoc/withRefreshPatients';
import LanguageMenu from './LanguageMenu';

export default compose(
  graphql(patients.mutateUpdatePatient, { name: 'mutateUpdatePatient' }),
  withRefreshPatients,
  withState('isEditing', 'onToggleEdit', false),
  withState('selectedLanguage', 'onSelectLanguage', null),
  withHandlers({
    onToggleEdit: ({ onToggleEdit }) => () => onToggleEdit(isEditing => !isEditing),
    onSelectLanguage: ({ onSelectLanguage }) => language => onSelectLanguage(language),
  }),
  withHandlers({
    onSubmit: props => async () => {
      const {
        mutateUpdatePatient,
        onRefetchPatients,
        onToggleEdit,
        patient,
        selectedLanguage,
      } = props;

      return alertOnError(
        mutateUpdatePatient({
          variables: {
            id: patient.id,
            modifier: {
              language: models.LanguageType[selectedLanguage],
            },
          },
        }),
      )
        .then(onRefetchPatients)
        .then(onToggleEdit);
    },
  }),
  withProps({ languageOptions: Object.keys(models.LanguageType) }),
)(LanguageMenu);
