import { phones } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { mutateCreatePhone } from 'graphql/phones';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Component, createElement } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withPharmacyId from './withPharmacyId';
import withRoles from './withRoles';

const withPhoneMutation = ComponentToWrap =>
  class PhoneMutationComponent extends Component {
    static propTypes = {
      hasRole: PropTypes.object,
      mutateCreatePhone: PropTypes.func,
      mutateDeletePhone: PropTypes.func,
      mutateUpdatePhone: PropTypes.func,
      pharmacy_id: PropTypes.number,
      currentUser: PropTypes.object,
    };

    get query() {
      const { pharmacistUser, pharmacistAdmin } = this.props.hasRole;
      return {
        query: phones.queryPhones,
        variables: {
          query: {
            is_zipdrug: !pharmacistUser && !pharmacistAdmin,
          },
        },
      };
    }

    handleCreatePhone = variables =>
      alertOnError(
        this.props.mutateCreatePhone({
          update: (store, { data: { createPhone } }) => {
            const data = store.readQuery(this.query);

            // We need to increment the total phone count after adding a new phone
            data.phones.data.push(createPhone.data);
            data.phones.total += 1;

            store.writeQuery({ ...this.query, data });
          },
          variables: {
            pharmacy_id: this.props.pharmacy_id,
            user_id: this.props.currentUser?.id,
            ...variables,
          },
        }),
      );

    handleDeletePhone = variables =>
      alertOnError(
        this.props.mutateDeletePhone({
          update: store => {
            const data = store.readQuery(this.query);
            const itemId = get(variables, 'id');

            data.phones.data = data.phones.data.filter(u => u.id !== itemId);
            data.phones.total -= 1;

            store.writeQuery({ ...this.query, data });
          },
          variables,
        }),
      );

    handleUpdatePhone = variables => alertOnError(this.props.mutateUpdatePhone({ variables }));

    render = () =>
      createElement(ComponentToWrap, {
        ...this.props,
        mutateCreatePhone: this.handleCreatePhone,
        mutateDeletePhone: this.handleDeletePhone,
        mutateUpdatePhone: this.handleUpdatePhone,
      });
  };

export default ComponentToWrap =>
  compose(
    graphql(mutateCreatePhone, { name: 'mutateCreatePhone' }),
    graphql(phones.mutateDeletePhone, { name: 'mutateDeletePhone' }),
    graphql(phones.mutateUpdatePhone, { name: 'mutateUpdatePhone' }),
    withRoles,
    withPharmacyId,
    withPhoneMutation,
  )(ComponentToWrap);
