import PropTypes from 'prop-types';
import React from 'react';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';
import PinLoginPage from './PinLoginPage';

const PinLoginPageContainer = ({ history }) => (
  <StandaloneFormPageTemplate title="Enter PIN">
    <PinLoginPage history={history} />
  </StandaloneFormPageTemplate>
);

PinLoginPageContainer.propTypes = {
  history: PropTypes.object.isRequired,
};

export default PinLoginPageContainer;
