import gql from 'graphql-tag';

const basicFields = `
  email
  first_name
  id
  joined_at
  last_name
  inbound_eligible
  call_center_user_status
  roles {
    id
    pharmacy_id
    type
  }
  feature_flags
  title
`;

const PIN_LOGIN = gql`
  mutation pinLogin($pinModifiers: PinModifiers!) {
    pinLogin(pinModifiers: $pinModifiers) {
      data {
        user {
          ${basicFields}
        }
        refreshToken
        accessToken
        expirationToken
        daysUntilPasswordExpires
        redirect
      }
    }
  }
`;

export default PIN_LOGIN;
