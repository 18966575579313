import React, { Component } from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { Button, space } from '@zipdrug/ui';

const sx = StyleSheet.create({
  default: {
    marginTop: space.get(1.5),
    marginBottom: space.get(1.5),
  },
  success: {
    pointerEvents: 'none',
  },
});

export default class CopyToClipboardButton extends Component {
  static propTypes = {
    email: PropTypes.string,
  };

  state = {
    copiedToClipboard: null,
  };

  handleClick = email => {
    const textField = document.createElement('textarea');
    textField.innerText = email;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({ copiedToClipboard: true });
  };

  render = () => {
    const { copiedToClipboard } = this.state;
    const { email } = this.props;
    return (
      <Button
        onClick={() => this.handleClick(email)}
        size="large"
        styles={[sx.default, copiedToClipboard ? sx.success : null]}
        text={copiedToClipboard ? 'Copied to clipboard!' : `Copy ${email} to clipboard`}
        type={copiedToClipboard ? 'success' : 'primary'}
      />
    );
  };
}
