import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import { Flex, Div, Bold, colors, font, space } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 0,
  },
  header: {
    color: colors.orange,
  },
  marginBottom: {
    marginBottom: 10,
  },
});

export default class SurveyActivityItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
  };

  renderContent = () => {
    return (
      <blockquote>
        <Flex direction="column">
          <Div styles={sx.marginBottom}>
            <Bold>TYPE</Bold>
            <br />
            {this.props.activity.interaction.survey_type}
          </Div>
          <Div styles={sx.marginBottom}>
            <Bold>INVITATION DATE</Bold>
            <br />
            {moment(this.props.activity.interaction.invitation_date).format('MM/DD/YYYY')}
          </Div>
        </Flex>
      </blockquote>
    );
  };

  render = () => {
    const { activity, ...props } = this.props;
    const { created_by_user, pharmacy } = get(activity, 'interaction');
    const pharmacyName = pharmacy?.name.split(/(?<=^\S+)\s/);
    return (
      <SystemEventActivityItem
        {...props}
        content={activity.interaction ? this.renderContent() : null}
        contentStyles={sx.content}
        createdAt={activity.created_at}
        footerName={
          created_by_user || {
            firstName: pharmacyName?.[0],
            lastName: pharmacyName?.[1],
          }
        }
        footerStyles={sx.footer}
        headerStyles={sx.header}
        heading="Survey Sent"
        icon="person"
      />
    );
  };
}
