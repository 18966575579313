import React from 'react';
import ForgotPasswordFormContainer from './ForgotPasswordFormContainer';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';

const ForgotPasswordPage = () => (
  <StandaloneFormPageTemplate title="Forgot password">
    <ForgotPasswordFormContainer />
  </StandaloneFormPageTemplate>
);

export default ForgotPasswordPage;
