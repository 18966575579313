import gql from 'graphql-tag';

const GET_PHARMACY_ASCS = gql`
  query getPharmacyAscs {
    getPharmacyAscs {
      data {
        id
        first_name
        last_name
      }
    }
  }
`;

export default GET_PHARMACY_ASCS;
