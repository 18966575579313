import React from 'react';
import { StyleSheet } from 'aphrodite/no-important';
import { colors, Div, font, padding, position, space } from '@zipdrug/ui';
import SettingsPageTemplate from 'settings/SettingsPageTemplate';
import ZipCodeAssignmentList from 'dashboard/components/ZipCodeAssignmentList/ZipCodeAssignmentList';
import PageHeading from '../../ui/PageHeading';

const sx = StyleSheet.create({
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  boldDescription: {
    fontWeight: 'bold',
  },
  page: {
    ...position.size('100%'),
    maxWidth: 'initial',
    paddingBottom: 0,
  },
  notesPageContainer: padding.build(space.get(0), space.get(1.5), space.three),
});

const ZipCodeAssignmentPage = () => {
  return (
    <SettingsPageTemplate styles={sx.page}>
      <PageHeading title="Zip Code Assignment" />
      <Div styles={sx.notesPageContainer}>
        <ZipCodeAssignmentList />
      </Div>
    </SettingsPageTemplate>
  );
};

export default ZipCodeAssignmentPage;
