import {
  ActivityAvatarColumn,
  ActivityItem,
  Avatar,
  border,
  colors,
  Column,
  Div,
  font,
  Icon,
  position,
  rem,
  space,
  unit,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  avatar: {
    backgroundColor: colors.white,
  },
  avatarIcon: position.size(font.size.xSmall),
  content: {
    lineHeight: font.lineHeight.looser,
  },
  contentContainer: {
    ...border.build('left', {
      color: colors.gray87,
      width: 2,
    }),
    color: colors.gray62,
    marginTop: space.half,
    paddingLeft: space.half,
  },
  contentHeading: {
    fontSize: font.size.micro,
    fontWeight: font.weight.bold,
    marginBottom: space.quarter,
    textTransform: 'uppercase',
  },
  contentWrapper: {
    maxWidth: '34rem',
    paddingTop: rem((unit(ActivityAvatarColumn.avatarSize) - unit(font.size.small)) / 4),
  },
  heading: {
    lineHeight: font.lineHeight.looser,
  },
});

const SystemEventActivityAvatar = ({ color, icon, side }) => (
  <ActivityAvatarColumn side={side}>
    <Avatar circle styles={sx.avatar}>
      <Icon name={icon} style={{ color }} styles={sx.avatarIcon} />
    </Avatar>
  </ActivityAvatarColumn>
);

SystemEventActivityAvatar.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  side: PropTypes.string,
};

const SystemEventActivityItem = ({
  color,
  content,
  contentHeading,
  createdAt,
  footerName,
  footerStyles,
  heading,
  icon,
  id,
}) => (
  <ActivityItem
    avatarRenderer={avatarProps => (
      <SystemEventActivityAvatar {...avatarProps} color={color} icon={icon} />
    )}
    className="e2e-activityListItem"
    contentRenderer={() => (
      <Column styles={sx.contentWrapper}>
        <Div styles={sx.heading}>{heading}</Div>
        {content && (
          <Div styles={sx.contentContainer}>
            {contentHeading && <Div styles={sx.contentHeading}>{contentHeading}</Div>}
            <Div styles={sx.content}>{content}</Div>
          </Div>
        )}
      </Column>
    )}
    createdAt={createdAt}
    footerName={footerName}
    id={id}
    footerStyles={footerStyles}
    showLeftAvatar
  />
);

SystemEventActivityItem.defaultProps = {
  color: colors.gray80,
};

SystemEventActivityItem.propTypes = {
  color: PropTypes.string,
  content: PropTypes.node,
  contentHeading: PropTypes.node,
  createdAt: PropTypes.number,
  footerName: PropTypes.object,
  footerStyles: aphroditePropType,
  heading: PropTypes.node,
  icon: PropTypes.string,
  id: PropTypes.string,
};

export default SystemEventActivityItem;
