import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

export const MembersListContext = React.createContext();

export const ActionTypes = {
  UPDATE_MEMBERLIST_TABLE_SETTINGS: 'UPDATE_MEMBERLIST_TABLE_SETTINGS',
};

const defaultPagination = {
  current: 0,
  defaultPageSize: 25, // need to change here default page size.
};

const initialState = {
  tableActions: {
    pagination: defaultPagination,
    filters: null,
    sorter: null,
  },
  defaultTableActions: {
    sorter: {
      columnKey: 'enrolled_date',
      order: 'descend',
    },
    pagination: defaultPagination,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_MEMBERLIST_TABLE_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        tableActions: payload,
      };
    }
    default:
      throw new Error();
  }
};

const MembersListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MembersListContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </MembersListContext.Provider>
  );
};

MembersListProvider.propTypes = {
  children: PropTypes.node,
};

export default React.memo(MembersListProvider);
