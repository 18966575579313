/* eslint-disable react/prop-types */
import { colors, Flex } from '@zipdrug/ui';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import { StyleSheet, css } from 'aphrodite';
import Confetti from 'react-dom-confetti';
import { Table, Button, message, Typography, Space, Select, List, Modal, Progress } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import PageHeading from 'ui/PageHeading';
import confettiConfig from '../../../LeadList/constants/confettiConfig';
import UPDATE_COHORT_CHILDREN from '../../graphql/updateCohort';
import GET_COHORT_PARENTS from '../../graphql/getCohortParents';
import APPROVE_COHORT from '../../graphql/approveCohort';
import withSelectPatient from '../../../../../hoc/withSelectPatient';
import generateVariables from '../../utils/GenerateVariables.utils';
import filterSearchBox from '../Filters/Search/Search';
import states from '../../constants/states';
import GET_COHORT_CHILDREN from '../../graphql/getCohortChildren';
import GET_COHORT_STATUS from '../../graphql/getCohortStatus';
import GET_COHORT_FILTERS from '../../graphql/getCohortFilters';
import SEND_COHORT_EMAIL from '../../graphql/sendCohortEmail';
import GET_FINAL_COHORT_COUNT from '../../graphql/getFinalCohortCount';

const { Text, Title } = Typography;
const { Option } = Select;
const { Meta } = List.Item;

const sx = StyleSheet.create({
  headingStyle: {
    paddingLeft: '0px',
  },
  marginWrapper: {
    margin: '10px',
  },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
  disabledRow: {
    background: '#aaaaaa',
    opacity: '0.5',
  },
  textContainer: {
    display: 'flex !important',
    justifyContent: 'space-between',
  },
  textStyles: {
    color: colors.gray62,
  },
  subtitleText: {
    color: colors.gray62,
  },
  approvalText: {
    color: colors.gray62,
    fontSize: '12px',
  },
  approvalTitle: {
    color: colors.gray62,
    fontSize: '16px',
    fontWeight: '500',
  },
  modalTitle: {
    color: colors.gray62,
    fontWeight: '500',
  },
  approvalContainer: {
    minWidth: 195,
    marginBottom: 16,
  },
  modalContainer: {
    minWidth: '583px',
  },
  mailSentStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  mailSentStatusText: {
    paddingLeft: '10px',
    fontSize: '16px',
    color: '#006A00',
    lineHeight: '19px',
  },
  mailProgressContainer: {
    maxWidth: '450px',
    margin: '20px 0',
    color: colors.gray62,
  },
  mailProgressText: {
    whiteSpace: 'nowrap',
    marginLeft: 15,
    color: colors.gray62,
  },
  pharmacyFilterButtons: {
    height: '24px',
    lineHeight: '0px',
    margin: '7px 5px 0px 5px',
  },
});

const tableFilterCategories = ['pharmacy_name', 'state', 'plan_name', 'group_name'];

const defaultSelectedRows = {
  selectedRowIds: [],
  selectedRowRecords: [],
};

const CohortTable = ({ user }) => {
  const [finalCohortTotal, setFinalCohortTotal] = useState(0);
  const [cohortTotal, setCohortTotal] = useState(0);
  const [selectedRows, setSelectedRows] = useState(defaultSelectedRows);
  const [approvalStatus, setApprovalStatus] = useState({});
  const [filters, setFilters] = useState({});
  const [tableActions, setTableActions] = useState({});
  const [isDeletedRowSelected, setIsDeletedRowSelected] = useState(false);
  const [role, setRole] = useState('');
  const [rowIds, setRowIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [hasTwoApprovals, setHasTwoApprovals] = useState(false);
  const [isEditingDisabled, setIsEditingDisabled] = useState(false);
  const [isCohortSent, setIsCohortSent] = useState(false);
  const [mailProcessingPercentage, setMailProcessingPercentage] = useState(0);
  const [latestParentId, setLatestParentId] = useState(0);
  const [latestParentDate, setLatestParentDate] = useState('');
  const [childrenData, setChildrenData] = useState([]);
  const [memberCount, setMemberCount] = useState(0);

  const [pharmacyNameFilterValue, setPharmacyNameFilterValue] = useState([]);

  const generateFiltersObject = data => {
    const newFilters = {};
    tableFilterCategories.forEach(category => {
      newFilters[category] = [];
      data.forEach(d => {
        if (!newFilters[category].find(f => f.text === d[category])) {
          if (d[category]) {
            newFilters[category].push({
              text: d[category],
              value: d[category],
            });
          }
        }
      });
    });
    setFilters(newFilters);
  };
  const generateApprovalObject = data => {
    const approvedParent = data;
    const cohortAlreadyApproved =
      (role === 'pm' && approvedParent?.pm_approval_user_id) ||
      (role === 'pharmacy' && approvedParent?.pharmacy_approval_user_id);

    if (approvedParent) {
      if (cohortAlreadyApproved) {
        setIsEditingDisabled(true);
      }
      const approvalStatuses = {};
      if (approvedParent.pharmacy_approval_user_id) {
        approvalStatuses.pharmacy_approval = {
          approver: approvedParent.pharmacy_approval_user_id,
          date: moment(approvedParent.pharmacy_approval_date).format('MM/DD/YYYY'),
          approver_name: approvedParent.pharmacy_approval_user_name,
        };
      }
      if (approvedParent.pm_approval_user_id) {
        approvalStatuses.pm_approval = {
          approver: approvedParent.pm_approval_user_id,
          date: moment(approvedParent.pm_approval_date).format('MM/DD/YYYY'),
          approver_name: approvedParent.pm_approval_user_name,
        };
      }
      setApprovalStatus(approvalStatuses);

      if (approvedParent.pharmacy_approval_user_id && approvedParent.pm_approval_user_id) {
        setHasTwoApprovals(true);
      }
    }
  };

  const [fetchCohortParents, { loading: isFetchingCohortParents }] = useLazyQuery(
    GET_COHORT_PARENTS,
    {
      fetchPolicy: 'network-only',
      onError: error => {
        message.error(`Error fetching rows: ${error.message}`);
      },
      onCompleted: ({ getCohortParents }) => {
        if (getCohortParents?.data.length > 0) {
          const parentDate = getCohortParents?.data[0]?.created_at;
          setLatestParentDate(parentDate || 0);
          const currentParent = getCohortParents?.data[0];
          setLatestParentId(currentParent?.id);
          if (
            currentParent?.send_to_lob_date !== null &&
            currentParent?.send_to_lob_user_id !== null
          ) {
            setIsCohortSent(true);
            getFinalCohortCount({
              variables: { cohort_parents_id: latestParentId },
            });
          } else if (
            currentParent?.pharmacy_approval_user_id &&
            currentParent?.pm_approval_user_id
          ) {
            setTimeout(() => onSendToMail(), 500);
          }
          generateApprovalObject(currentParent);
        }
      },
    },
  );

  const [sendCohortEmail] = useMutation(SEND_COHORT_EMAIL, {
    onError: error => {
      message.error(`Error Sending Cohort: ${error.message}`);
    },
    onCompleted: ({ sendCohortEmail }) => {
      if (sendCohortEmail?.data) {
        setIsCohortSent(true);
        getFinalCohortCount({
          variables: { cohort_parents_id: latestParentId },
        });
      }
    },
  });

  const [getFinalCohortCount] = useLazyQuery(GET_FINAL_COHORT_COUNT, {
    onError: error => {
      message.error(`Error Sending Cohort: ${error.message}`);
    },
    onCompleted: ({ getFinalCohortCount }) => {
      if (getFinalCohortCount) {
        setFinalCohortTotal(getFinalCohortCount?.total);
      }
    },
  });

  const [fetchCohortChildren, { loading: isFetchingCohortChildren }] = useLazyQuery(
    GET_COHORT_CHILDREN,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        message.error(`Error fetching rows: ${error.message}`);
      },
      onCompleted: ({ getCohortChildren }) => {
        setChildrenData(getCohortChildren?.data);
        setCohortTotal(getCohortChildren?.data?.length);
      },
    },
  );

  const getChildrenData = variables => {
    if (variables) {
      fetchCohortChildren({
        variables: {
          ...variables,
          cohort_parents_id: latestParentId,
        },
      });
    } else {
      fetchCohortChildren({
        variables: {
          cohort_parents_id: latestParentId,
        },
      });
    }
  };

  const [updateCohort, { loading: isUpdatingCohort }] = useMutation(UPDATE_COHORT_CHILDREN, {
    onError: error => {
      message.error(`Error deleting row: ${error.message}`);
    },
    onCompleted: ({ updateCohort }) => {
      const clonedData = cloneDeep(childrenData);
      const rowIds = updateCohort?.data?.map(row => row.id);
      const includeValue = updateCohort?.data[0]?.include_in_cohort;
      const resultData = clonedData.map(row => {
        // not updating on rows with multiple - take a look at this.
        if (rowIds.includes(row.array_agg[0])) {
          row.include_in_cohort = includeValue;
        }
        return row;
      });
      setChildrenData(resultData);
      setSelectedRows(defaultSelectedRows);
      message.success('Successfully Updated Cohort!');
    },
  });

  const [getCohortStatus] = useLazyQuery(GET_COHORT_STATUS, {
    onError: error => {
      message.error(`Error getting cohort status: ${error.message}`);
    },
    onCompleted: ({ getCohortStatus }) => {
      setMailProcessingPercentage(getCohortStatus?.total);
    },
  });

  const [getCohortFilters] = useLazyQuery(GET_COHORT_FILTERS, {
    onError: error => {
      message.error(`Error getting cohort status: ${error.message}`);
    },
    onCompleted: ({ getAllCohortData }) => {
      if (getAllCohortData?.data) {
        generateFiltersObject(getAllCohortData?.data);
      }
    },
  });

  const [approveCohortParent, { loading: isApprovingCohort }] = useMutation(APPROVE_COHORT, {
    onError: error => {
      message.error(`Error approving cohort: ${error.message}`);
    },
    onCompleted: ({ approveCohortParent }) => {
      setSelectedRows(defaultSelectedRows);
      generateApprovalObject(approveCohortParent?.data[0]);
      message.success('Successfully Approved Cohort!');
      if (
        approveCohortParent?.data[0]?.pharmacy_approval_user_id &&
        approveCohortParent?.data[0]?.pm_approval_user_id
      ) {
        setTimeout(() => onSendToMail(), 500);
      }
    },
  });

  const fetchCohortStatus = () => {
    getCohortStatus({
      variables: {
        cohort_parents_id: latestParentId,
      },
    });
  };

  useEffect(() => {
    if (latestParentId !== 0 && latestParentId) {
      getChildrenData();
    }
    // get the all children data for the filters and for initial pagination
    getCohortFilters({
      variables: {
        cohort_parents_id: latestParentId,
      },
    });
  }, [latestParentId]);

  const getUserRole = user => {
    return user?.roles[0]?.type.includes('pharmacy') ? 'pharmacy' : 'pm';
  };

  useEffect(() => {
    const role = getUserRole(user);
    setRole(role);
    fetchCohortsList();
  }, []);

  const handlePharmacyFilter = (value, confirm) => {
    let newTableActions;
    if (value.length >= 1) {
      newTableActions = {
        ...tableActions,
        filters: {
          ...tableActions.filters,
          pharmacy_name: value,
        },
      };
    } else {
      newTableActions = {
        ...tableActions,
        filters: {
          ...tableActions.filters,
          pharmacy_name: null,
        },
      };
    }
    setTableActions(newTableActions);
    confirm();
    fetchCohortsList(newTableActions);
  };

  const generateColumns = () => {
    const headers = [
      {
        title: 'Member Count',
        dataIndex: 'member_count',
        key: 'member_count',
        width: 150,
        render: text => {
          return text;
        },
      },
      {
        title: 'Pharmacy Name',
        width: 280,
        dataIndex: 'pharmacy_name',
        key: 'pharmacy_name',
        filters: filters?.pharmacy_name,
        filteredValue: tableActions?.filters?.pharmacy_name || null,
        filterMultiple: true,
        filterDropdown: ({ filters, confirm }) => {
          return (
            <div style={{ padding: '7px', textAlign: 'center' }}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: 200, display: 'block' }}
                placeholder="Select to filter pharmacy"
                value={pharmacyNameFilterValue}
                onChange={values => setPharmacyNameFilterValue(values)}
                className={css(sx.textStyles)}
              >
                {filters?.map((filter, i) => {
                  return (
                    <Option
                      className={css(sx.textStyles)}
                      key={`${filter.text + i}`}
                      value={filter.value}
                    >
                      {filter.text}
                    </Option>
                  );
                })}
              </Select>
              <Button
                className={css(sx.pharmacyFilterButtons)}
                type="primary"
                onClick={() => handlePharmacyFilter(pharmacyNameFilterValue, confirm)}
              >
                Apply
              </Button>
              <Button
                className={css(sx.pharmacyFilterButtons)}
                onClick={() => {
                  handlePharmacyFilter([], confirm);
                  setPharmacyNameFilterValue([]);
                }}
              >
                Reset
              </Button>
            </div>
          );
        },
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder:
          tableActions?.sorter?.columnKey === 'pharmacy_name' && tableActions?.sorter?.order,
        render: text => {
          return text;
        },
      },
      {
        title: 'Pharmacy NPI',
        dataIndex: 'pharmacy_npi',
        key: 'pharmacy_npi',
        width: 180,
        ...filterSearchBox(
          'pharmacy_npi',
          'Pharmacy NPI',
          tableActions?.filters?.pharmacy_npi,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.pharmacy_npi || null,
        render: text => {
          return text;
        },
      },
      {
        title: 'State',
        dataIndex: 'pharmacy_state',
        key: 'state',
        width: 100,
        filters: states,
        filteredValue: tableActions?.filters?.state || null,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'state' && tableActions?.sorter?.order,
        render: text => {
          return text;
        },
      },
      {
        title: 'Plan Name',
        width: 350,
        dataIndex: 'plan_name',
        key: 'plan_name',
        filters: filters?.plan_name,
        filteredValue: tableActions?.filters?.plan_name || null,
        filterMultiple: true,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'plan_name' && tableActions?.sorter?.order,
        render: text => {
          return text;
        },
      },
      {
        title: 'Group Name',
        width: 157,
        dataIndex: 'group_name',
        key: 'group_name',
        filters: filters?.group_name,
        filteredValue: tableActions?.filters?.group_name || null,
        filterMultiple: true,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'group_name' && tableActions?.sorter?.order,
        render: text => {
          return text;
        },
      },
      {
        title: 'Template Envelope ID',
        dataIndex: 'template_envelope_id',
        key: 'template_envelope_id',
        width: 250,
        ...filterSearchBox(
          'template_envelope_id',
          'Template Envelope ID',
          tableActions?.filters?.template_envelope_id,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.template_envelope_id || null,
        render: text => {
          return text;
        },
      },
      {
        title: 'Lob Envelope ID',
        dataIndex: 'lob_envelope_id',
        key: 'lob_envelope_id',
        width: 250,
        ...filterSearchBox(
          'lob_envelope_id',
          'Lob Envelope ID',
          tableActions?.filters?.lob_envelope_id,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.lob_envelope_id || null,
        render: text => {
          return text;
        },
      },
      {
        title: 'Lob Return Envelope ID',
        dataIndex: 'lob_return_envelope_id',
        key: 'lob_return_envelope_id',
        width: 250,
        ...filterSearchBox(
          'lob_return_envelope_id',
          'Lob Return Envelope ID',
          tableActions?.filters?.lob_return_envelope_id,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.lob_return_envelope_id || null,
        render: text => {
          return text;
        },
      },
      {
        title: 'Lob Perforated Page Index',
        dataIndex: 'lob_perforated_page_index',
        key: 'lob_perforated_page_index',
        width: 250,
        ...filterSearchBox(
          'lob_perforated_page_index',
          'Lob Perforated Page Index',
          tableActions?.filters?.lob_perforated_page_index,
          tableActions,
          setTableActions,
        ),
        filteredValue: tableActions?.filters?.lob_perforated_page_index || null,
        render: text => {
          return text;
        },
      },
    ];
    return headers;
  };

  const fetchCohortsList = (payload = cloneDeep(tableActions)) => {
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }
    // get formatted variables
    const variables = generateVariables(payload);
    if (latestParentId) {
      getChildrenData(variables);
    } else {
      fetchCohortParents();
    }
  };

  const onTableChange = (pagination, filters, sorter, { action }) => {
    setTableActions({
      pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
      filters,
      sorter,
    });

    if (action === 'paginate') {
      fetchCohortsList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchCohortsList({
        filters,
        sorter,
      });
    }
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: {
        current: 0,
      },
      filters: null,
      sorter: null,
    };
    setPharmacyNameFilterValue([]);
    setTableActions(payload);
    fetchCohortsList(payload);
    getCohortFilters({
      variables: {
        cohort_parents_id: latestParentId,
      },
    });
  };

  const onRemoveRow = async rowIds => {
    setRowIds(rowIds);
    setModalType('remove');
    setShowModal(true);
  };

  const onAddBackRow = async rowIds => {
    setRowIds(rowIds);
    setModalType('addBack');
    setShowModal(true);
  };

  const onSendToMail = async () => {
    setRowIds(childrenData.filter(d => d.include_in_cohort === true).flatMap(d => d.array_agg));
    setModalType('sendToMail');
    setShowModal(true);
  };

  const onApproveCohort = async () => {
    setRowIds(childrenData.filter(d => d.include_in_cohort === true).flatMap(d => d.array_agg));
    setModalType('approve');
    setShowModal(true);
  };

  const onSelectedRow = (selectedRowKeys, rowsSelected) => {
    const deletedRow = rowsSelected.find(r => r.include_in_cohort !== true);
    setIsDeletedRowSelected(!!deletedRow);
    setSelectedRows({
      selectedRowIds: selectedRowKeys,
      selectedRowRecords: rowsSelected,
    });
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: childrenData,
      onChange: onTableChange,
      rowKey: 'array_agg',
      scroll: { x: 1500, y: 'auto' },
      rowClassName: record => record.include_in_cohort !== true && css(sx.disabledRow),
      loading:
        isFetchingCohortParents ||
        isUpdatingCohort ||
        isApprovingCohort ||
        isFetchingCohortChildren,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: cohortTotal || 0,
        current: tableActions?.pagination?.current || 1,
      },
      rowSelection: {
        type: 'checkbox',
        onChange: onSelectedRow,
        selectedRowKeys: selectedRows.selectedRowIds,
      },
    };

    return tableOptions;
  };

  const CustomCheckCircle = () => {
    return <CheckCircleFilled style={{ margin: '10px 0px', fontSize: 25, color: '#1E6704' }} />;
  };

  const CustomCheckOutlineCircle = () => {
    return <CheckCircleOutlined style={{ margin: '10px 0px', fontSize: 25, color: '#006A00' }} />;
  };

  const ApprovalMeta = props => {
    const { approver, date, type } = props;
    return (
      <Meta
        avatar={<CustomCheckCircle />}
        title={<span className={css(sx.approvalTitle)}>Approved</span>}
        key={`${type}-${date}`}
        description={<p className={css(sx.approvalText)}>{`by ${approver} on ${date}`}</p>}
      />
    );
  };

  const renderApprovalStatus = () => {
    return (
      <Flex>
        {Object.keys(approvalStatus).map(approval => (
          <Flex className={css(sx.approvalContainer)} key={approval}>
            {approval === 'pharmacy_approval' && (
              <ApprovalMeta
                approver={approvalStatus.pharmacy_approval?.approver_name}
                date={approvalStatus.pharmacy_approval.date}
                type="pharmacy_approval"
              />
            )}
            {approval === 'pm_approval' && (
              <ApprovalMeta
                approver={approvalStatus.pm_approval?.approver_name}
                date={approvalStatus.pm_approval.date}
                type="pm_approval"
              />
            )}
          </Flex>
        ))}
      </Flex>
    );
  };

  const getModalTitle = modalType => {
    switch (modalType) {
      case 'sendToMail':
        return 'Send To Mail';
      default:
        return 'Confirmation';
    }
  };

  const generateModalContent = modalType => {
    switch (modalType) {
      case 'remove':
        return (
          <Text className={css(sx.textStyles)}>
            Are you sure you want to remove these items from the mailing list?
          </Text>
        );
      case 'addBack':
        return (
          <Text className={css(sx.textStyles)}>
            Are you sure you want to add these items back to the mailing list?
          </Text>
        );
      case 'approve':
        return (
          <Text className={css(sx.textStyles)}>
            Are you sure you want to approve this cohort list?
          </Text>
        );
      case 'sendToMail':
        return (
          <>
            {renderApprovalStatus()}
            <Text className={css(sx.textStyles)}>
              This cohort has 2 approvers. Would you like to send mail?
            </Text>
          </>
        );
      default:
        return <Text className={css(sx.textStyles)}>Are you sure you want to do that?</Text>;
    }
  };

  const generateModalSubmitAction = modalType => {
    const arrayOfIds = rowIds.flatMap(row => row);
    switch (modalType) {
      case 'remove':
        updateCohort({
          variables: { ids: arrayOfIds, action: 'remove' },
        });
        setShowModal(false);
        break;
      case 'addBack':
        updateCohort({
          variables: { ids: arrayOfIds, action: 'add' },
        });
        setShowModal(false);
        break;
      case 'approve':
        approveCohortParent({
          variables: {
            id: latestParentId,
            role,
            approver: user.id,
            approval_date: Date.now(),
            approver_name: `${user.first_name} ${user.last_name}`,
          },
        });
        setShowModal(false);
        break;
      case 'sendToMail':
        sendCohortEmail({
          variables: {
            parent_id: latestParentId,
          },
        });
        fetchCohortStatus();
        setShowModal(false);
        break;
      default:
        setShowModal(false);
        break;
    }
  };

  const titleDate = () => {
    const parentDate = moment(latestParentDate).format('- MM-DD-YYYY');
    if (parentDate === 'Invalid date') {
      return '';
    }
    return parentDate;
  };

  useEffect(() => {
    // get the member count total every time the children data changes
    let total = 0;
    childrenData.forEach(item => {
      total += +item.member_count;
    });
    setMemberCount(total);
    // get the status and total # of processed cohorts if the cohort has been sent
    if (isCohortSent === true) {
      getCohortStatus({
        variables: {
          cohort_parents_id: latestParentId,
        },
      });
      getFinalCohortCount({
        variables: { cohort_parents_id: latestParentId },
      });
    }
  }, [childrenData]);

  return (
    <>
      {isCohortSent && (
        <Confetti active={mailProcessingPercentage === 100} config={confettiConfig} />
      )}
      <div className={css(sx.marginWrapper)}>
        <PageHeading title={`Anthem Cohort List ${titleDate()}`} className={css(sx.headingStyle)} />
        {isCohortSent && (
          <>
            <div className={css(sx.mailSentStatus)}>
              <CustomCheckOutlineCircle />
              <div className={css(sx.mailSentStatusText)}> Cohort List has been sent to mail </div>
            </div>

            <Flex className={css(sx.mailProgressContainer)}>
              <Progress
                percent={(mailProcessingPercentage / finalCohortTotal) * 100}
                strokeColor="#1E6704"
                showInfo={false}
              />
              <Text className={css(sx.mailProgressText)}>
                {Math.round(mailProcessingPercentage)} of {finalCohortTotal} processed
              </Text>
            </Flex>
          </>
        )}
        {renderApprovalStatus()}

        <div className={css(sx.tableOptionsContainer)}>
          {selectedRows?.selectedRowIds?.length === 0 && !hasTwoApprovals && !isEditingDisabled && (
            <Button
              type="primary"
              className={css(sx.tableOptionsButtonMargin)}
              onClick={() => onApproveCohort()}
            >
              Approve Cohort
            </Button>
          )}
          {hasTwoApprovals && !isCohortSent && (
            <Button
              type="primary"
              className={css(sx.tableOptionsButtonMargin)}
              onClick={() => onSendToMail()}
            >
              Send to Mail
            </Button>
          )}
          {!isCohortSent && (
            <Button
              type="secondary"
              className={css(sx.tableOptionsButtonMargin)}
              onClick={clearAllFilters}
            >
              Clear Filters
            </Button>
          )}
          {selectedRows?.selectedRowIds?.length > 0 &&
            !isDeletedRowSelected &&
            !hasTwoApprovals &&
            !isEditingDisabled && (
              <Button
                type="secondary"
                className={css(sx.tableOptionsButtonMargin)}
                onClick={() => onRemoveRow(selectedRows?.selectedRowIds)}
              >
                Remove from Cohort
              </Button>
            )}
          {selectedRows?.selectedRowIds?.length > 0 &&
            isDeletedRowSelected &&
            !hasTwoApprovals &&
            !isEditingDisabled && (
              <Button
                type="secondary"
                className={css(sx.tableOptionsButtonMargin)}
                onClick={() => onAddBackRow(selectedRows?.selectedRowIds)}
              >
                Add Back to Cohort
              </Button>
            )}
        </div>
        <Space className={css(sx.textContainer)}>
          <Text className={css(sx.subtitleText)}>Total Members: {memberCount || 0}</Text>
          {childrenData.length > 0 && (
            <Text className={css(sx.subtitleText)}>
              Last Generated: {moment(latestParentDate).format('MMMM DD, YYYY')}
            </Text>
          )}
        </Space>
        <Table {...getTableOptions()} />

        <Modal
          onOk={() => generateModalSubmitAction(modalType)}
          onCancel={() => setShowModal(false)}
          open={showModal}
          okText={modalType === 'sendToMail' ? 'Send To Mail' : 'Confirm'}
          submitText="Confirm"
          cancelText={modalType === 'sendToMail' ? 'Later' : 'Cancel'}
          className={css(sx.modalContainer)}
        >
          <Title level={3} className={css(sx.modalTitle)}>
            {getModalTitle(modalType)}
          </Title>
          <div>{generateModalContent(modalType)}</div>
        </Modal>
      </div>
    </>
  );
};

export default withSelectPatient(withRouter(CohortTable));
