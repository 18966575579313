/* eslint-disable no-case-declarations */
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';

const sortDirection = {
  ascend: 'ASC',
  descend: 'DESC',
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateOrderBy = sortOrder => {
  const sortPayload = {
    order_by: {},
  };
  const payload = {};

  payload[sortOrder.columnKey] = {
    sortKey: 'first_name',
    direction: sortDirection[sortOrder.order],
  };

  sortPayload.order_by = payload;

  return sortPayload;
};

const generateQuery = filters => {
  if (!filters) {
    return {};
  }

  return {
    query: {
      ...Object.entries(filters).reduce((acc, curr) => {
        if (curr[1]) {
          switch (curr[0]) {
            case 'member':
              acc.nameQuery = {
                ...acc?.nameQuery,
                $or: _flatten(
                  _map(['first_name', 'last_name'], item => {
                    return _map(curr[1].split(' '), q => {
                      return { [item]: { $ilike: `${q}` } };
                    });
                  }),
                ).filter((e, i, arr) => i === 0 || i === arr.length - 1),
              };
              return acc;
            case 'assigned_user':
              acc.assignedDSAQuery = {
                $or: _flatten(
                  _map(['first_name', 'last_name'], item => {
                    return _map(curr[1].split(' '), q => {
                      return { [item]: { $ilike: `%${q}%` } };
                    });
                  }),
                ),
              };
              return acc;
            default:
              return acc;
          }
        }
        return acc;
      }, {}),
    },
  };
};

const generateVariables = actions => {
  const { sorter, filters, pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  // Generate Order_By
  const order_by = sorter ? generateOrderBy(sorter) : {};

  // Generate Query Variable
  const query = filters ? generateQuery(filters) : {};

  return {
    ...pageOptions,
    ...query,
    ...order_by,
  };
};

export default generateVariables;
