import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography, Button } from 'antd';

const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '579px',
    minHeight: '252px',
  },
  description: {
    height: '99px',
    width: '539px',
    fontSize: '16px',
  },
  buttonCancel: {
    width: '80px',
    fontSize: '16px',
  },
  buttonOk: {
    width: '80px',
    fontSize: '16px',
  },
});

const { Title, Text } = Typography;

const EditASCModal = ({ onSaveOk, onSaveCancel, visible }) => {
  return (
    <Modal
      onCancel={onSaveCancel}
      open={visible}
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={() => onSaveCancel('asc')} className={css(sx.buttonCancel)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => onSaveOk('asc')}
          className={css(sx.buttonOk)}
        >
          Save
        </Button>,
      ]}
    >
      <Title level={3}>Account Service Coordinator Selected</Title>
      <div className={css(sx.description)}>
        <Text>
          {'Click "Save" to change the ASC for this hub pharmacy and its child pharmacies'}
        </Text>
      </div>
    </Modal>
  );
};

EditASCModal.propTypes = {
  onSaveOk: PropTypes.func,
  onSaveCancel: PropTypes.func,
  visible: PropTypes.bool,
};

export default EditASCModal;
