import { fields, patients } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { compose, withHandlers } from 'recompose';
import { graphql } from 'react-apollo';
import AddressList from './AddressList';

const sanitizeAddresses = addresses =>
  addresses.slice(0).map(address => pick(address, ...fields.AddressModifier));

const AddressListContainer = ({ onUpdateAddressOrder, ...props }) => (
  <AddressList {...props} onUpdateAddressOrder={onUpdateAddressOrder} />
);

AddressListContainer.propTypes = {
  onUpdateAddressOrder: PropTypes.func,
};

export default compose(
  graphql(patients.mutateUpdatePatient, { name: 'mutateUpdatePatient' }),
  withHandlers({
    onUpdateAddressOrder: ({ mutateUpdatePatient, patient }) => async ({ direction, order }) => {
      const newOrder = order - direction;

      const addresses = sanitizeAddresses(patient.addresses);
      if (newOrder < 0 || newOrder >= addresses.length) return;

      const currentAddress = addresses[order];
      const replaceAddress = addresses[newOrder];
      addresses[newOrder] = currentAddress;
      addresses[order] = replaceAddress;

      await alertOnError(
        mutateUpdatePatient({
          variables: {
            id: patient.id,
            modifier: { addresses },
          },
        }),
      );
    },
  }),
)(AddressListContainer);
