/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import cloneDeep from 'lodash/cloneDeep';
import { Typography, Tabs, message } from 'antd';
import { colors, Div, font, padding, space } from '@zipdrug/ui';
import { withRouter } from 'react-router';
import { useLazyQuery } from '@apollo/react-hooks';
import withRoles from 'hoc/withRoles';
import { isSystemAdmin, isPharmacyTeam } from 'contexts/utils/models.js';
import GET_ZIP_CODES_COVERAGE from '../../graphql/zipcodeCoverage.js';
import GET_POTENTIAL_ZIP_CODES_COVERAGE from '../../graphql/potentialZipcodeCoverage';
import GeneralPharmacyInfoTab from './GeneralInformationTab';
import PharmacyNotesTab from './PharmacyNotesTab';
import HubPharmacy from './HubPharmacyTab';
import Hours from './PharmacyHoursTab';
import { GET_PHARMACY_DATA } from '../../graphql/getPharmacyList';

const sx = StyleSheet.create({
  largeInputBox: { width: '50%' },
  inputBox: { marginRight: '16px' },
  flexContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    minWidth: '1441px',
    Height: '100%',
  },
  tabs: {
    color: colors.bluePurple,
  },
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  largeText: {
    fontSize: '16px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  statusSelect: {
    minWidth: '150px',
  },
  stateSelect: {
    minWidth: '50px',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
  ModifyPageContainer: padding.build(space.get(0), space.get(1.5), space.three),
  title: { color: '#231E33', fontWeight: font.weight.medium, fontSize: '24px', lineHeight: 1.35 },
});

const { Text } = Typography;
const { TabPane } = Tabs;

const PharmacyTabsPage = ({ location, hasRole, history, isPbsTeam }) => {
  const [isHubPharmacy, setIsHubPharmacy] = useState(false);
  const [pharmacyInfo, setPharmacyInfo] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [zipcodeCoverage, setZipcodeCoverage] = useState([]);
  const [potentialZipcodeCoverage, setPotentialZipcodeCoverage] = useState([]);
  const [pharmTabActiveKey, setPharmTabActiveKey] = useState('1');
  const [parentNpi, setParentNpi] = useState();
  const [isChild, setIsChild] = useState(false);

  const [getPharmacyList, { refetch: refetchPharmacyList }] = useLazyQuery(GET_PHARMACY_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching pharmacy list: ${error.message}`);
    },
    onCompleted: ({ pharmacies }) => {
      setIsChild(!!pharmacies?.data[0]?.parent_pharmacy?.npi);
      const formatedRecord = cleanPharmacyRecord(pharmacies.data[0]);
      setPharmacyInfo(formatedRecord);
    },
  });

  const [getZIPCodeCoverage] = useLazyQuery(GET_ZIP_CODES_COVERAGE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error Fetching zip code coverage: ${error.message}`);
    },
    onCompleted: ({ postalCodes }) => {
      if (postalCodes?.data) {
        const zipcodeFilter = postalCodes.data.map(p => p.postal_code);
        setZipcodeCoverage(Array.from(new Set(zipcodeFilter)));
      }
    },
  });

  const [getPotentialZIPCodeCoverage] = useLazyQuery(GET_POTENTIAL_ZIP_CODES_COVERAGE, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error Fetching potential zip codes: ${error.message}`);
    },
    onCompleted: ({ potentialPostalCodes }) => {
      if (potentialPostalCodes?.data) {
        setPotentialZipcodeCoverage(potentialPostalCodes.data.map(p => p.potential_postal_code));
      }
    },
  });

  const cleanPharmacyRecord = record => {
    const phone = record?.phones?.filter(phone => phone?.label === 'Main Line')?.[0];
    let address = cloneDeep(record?.address);
    if (!address) {
      address = {};
    }

    if (!address?.line2) {
      address.line2 = '';
    }

    return {
      id: record?.id,
      phone: {
        id: phone?.id,
        number: phone?.number,
      },
      hours: record?.hours,
      address,
      name: record?.name,
      npi: record?.npi,
      status: record?.status,
      associate_service_coordinator: record?.associate_service_coordinator || {},
      is_commercial_only: record?.is_commercial_only,
      pharmacy_networks: record?.pharmacy_networks.map(n => n.network_type?.id),
      pharmacy_services: record?.pharmacy_services,
      languages: record?.languages || [],
      child_pharmacies: record?.chain_pharmacies,
      skip_number: record?.skip_number,
      champion: record?.champion,
    };
  };

  const reRouteToGeneralInfo = () => {
    setIsHubPharmacy(false);
    setIsChild(true);
    resetGeneralInfoTab();
    setPharmTabActiveKey('1');
  };
  const resetGeneralInfoTab = () => {
    setIsEdit(false);
    setParentNpi(pharmacyInfo?.npi);
    setPharmacyInfo({
      name: '',
      npi: '',
      status: 'stand_by',
      phoneNumber: pharmacyInfo?.phone?.number,
      is_commercial_only: true,
      address: {
        city: '',
        state: '',
        postal_code: '',
        line1: '',
        line2: '',
      },
      associate_service_coordinator: pharmacyInfo?.associate_service_coordinator,
      pharmacy_networks: [],
      pharmacy_services: [],
      languages: [],
      zipcode_coverage: [],
      potential_zipcodes: [],
    });
  };

  useEffect(() => {
    if (location?.search) {
      setIsEdit(true);
      getPharmacyList({
        variables: {
          query: {
            id: history.location.search.slice(1),
          },
        },
      });
    } else {
      resetGeneralInfoTab();
      setIsEdit(false);
      setPharmacyInfo({
        name: '',
        npi: '',
        status: 'stand_by',
        phoneNumber: '',
        is_commercial_only: true,
        address: {
          city: '',
          state: '',
          postal_code: '',
          line1: '',
          line2: '',
        },
        associate_service_coordinator: {},
        pharmacy_networks: [],
        pharmacy_services: [],
        languages: [],
        zipcode_coverage: [],
        potential_zipcodes: [],
      });
    }
  }, location);

  useEffect(() => {
    if (pharmacyInfo?.id) {
      getZIPCodeCoverage({
        variables: {
          query: {
            pharmacy_id: pharmacyInfo?.id,
          },
        },
      });

      getPotentialZIPCodeCoverage({
        variables: {
          query: {
            pharmacy_id: pharmacyInfo?.id,
          },
        },
      });
    }
  }, [pharmacyInfo]);

  const renderTitle = () => {
    let title;
    if (isPharmacyTeam || isSystemAdmin) {
      switch (true) {
        case !isEdit && !isChild:
          title = 'Add Pharmacy';
          break;
        case !isEdit && isChild:
          title = 'Add Child Pharmacy';
          break;
        case isEdit && isChild:
          title = `Editing ${pharmacyInfo?.name} Child Pharmacy`;
          break;
        default:
          title = `Editing ${pharmacyInfo?.name} Pharmacy`;
          break;
      }
    } else {
      title = pharmacyInfo?.name;
    }
    return title;
  };

  return (
    <Div className={css(sx.ModifyPageContainer)}>
      <Text className={css(sx.title)} level={3}>
        {renderTitle()}
      </Text>
      {!isChild ? (
        <Tabs
          defaultActiveKey="1"
          size="large"
          className={css(sx.tabs)}
          activeKey={pharmTabActiveKey}
          onTabClick={key => setPharmTabActiveKey(key)}
        >
          <TabPane tab="General Information" key="1">
            <GeneralPharmacyInfoTab
              isChild={isChild}
              isEdit={isEdit}
              readOnly={isPbsTeam}
              pharmacyInfo={pharmacyInfo}
              refetchPharmacyInfo={refetchPharmacyList || getPharmacyList}
              pharmNetworkTypes={location?.state?.pharmNetworkTypes}
              setIsHubPharmacy={setIsHubPharmacy}
              isHubPharmacy={isHubPharmacy}
              setPharmacyInfo={setPharmacyInfo}
              setIsEdit={setIsEdit}
              zipcodeCoverage={zipcodeCoverage}
              potentialZipcodeCoverage={potentialZipcodeCoverage}
              parentNpi={parentNpi}
            />
          </TabPane>
          {!isPbsTeam && isHubPharmacy && (
            <TabPane tab="Hub" key="2">
              <HubPharmacy
                pharmacyInfo={pharmacyInfo}
                reRouteToGeneralInfo={reRouteToGeneralInfo}
              />
            </TabPane>
          )}
          <TabPane
            tab="Hours"
            key={isHubPharmacy ? '3' : '2'}
            disabled={!isEdit && !pharmacyInfo && (!pharmacyInfo?.id || !hasRole.isPharmacyTeam)}
          >
            <Hours readOnly={isPbsTeam} isEdit={isEdit} pharmacyInfo={pharmacyInfo} />
          </TabPane>
          {!isPbsTeam && (
            <TabPane
              tab="Notes"
              disabled={!isEdit || !pharmacyInfo?.id}
              key={isHubPharmacy ? '4' : '3'}
            >
              <PharmacyNotesTab canEdit={isEdit} pharmacy={pharmacyInfo} />
            </TabPane>
          )}
        </Tabs>
      ) : (
        <GeneralPharmacyInfoTab
          isChild={isChild}
          isEdit={isEdit}
          readOnly={isPbsTeam}
          pharmacyInfo={pharmacyInfo}
          refetchPharmacyInfo={refetchPharmacyList || getPharmacyList}
          pharmNetworkTypes={location?.state?.pharmNetworkTypes}
          setIsHubPharmacy={setIsHubPharmacy}
          isHubPharmacy={isHubPharmacy}
          setPharmacyInfo={setPharmacyInfo}
          setIsEdit={setIsEdit}
          zipcodeCoverage={zipcodeCoverage}
          potentialZipcodeCoverage={potentialZipcodeCoverage}
          parentNpi={parentNpi}
        />
      )}
    </Div>
  );
};

PharmacyTabsPage.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(withRoles(PharmacyTabsPage));
