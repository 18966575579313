import gql from 'graphql-tag';

const SEND_COHORT_EMAIL = gql`
  mutation sendCohortEmail($parent_id: Int!) {
    sendCohortEmail(parent_id: $parent_id) {
      data {
        id
        send_to_lob_user_id
        send_to_lob_date
      }
    }
  }
`;

export default SEND_COHORT_EMAIL;
