import { Button, ButtonRow, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  actions: {
    marginTop: space.default,
  },
});

const BUTTON_PROPS = {
  outline: true,
  rounded: true,
  size: 'medium',
  type: 'primary',
};

const PhoneListItemActions = ({
  isAssignedWorkstation,
  onAssignToWorkstation,
  onClickEdit,
  onDeletePhone,
}) => (
  <Row justify="space-between" styles={sx.actions}>
    <ButtonRow>
      {!isAssignedWorkstation && (
        <Button
          {...BUTTON_PROPS}
          onClick={onAssignToWorkstation}
          text="Assign to this work station"
        />
      )}
      <Button {...BUTTON_PROPS} onClick={onClickEdit} text="Edit phone" />
    </ButtonRow>
    {!isAssignedWorkstation && (
      <Button {...BUTTON_PROPS} onClick={onDeletePhone} text="Delete phone" type="warning" />
    )}
  </Row>
);

PhoneListItemActions.propTypes = {
  isAssignedWorkstation: PropTypes.bool,
  onAssignToWorkstation: PropTypes.func,
  onClickEdit: PropTypes.func,
  onDeletePhone: PropTypes.func,
};

export default PhoneListItemActions;
