import PropTypes from 'prop-types';
import React from 'react';
import RxItem from './rx-item/RxItem';
import RxItemContainer from './rx-item/RxItemContainer';

const sortClaimsByBrandName = claimsByNdcCode =>
  Object.keys(claimsByNdcCode)
    .slice(0)
    .sort((a, b) => {
      const aItem = claimsByNdcCode[a][0].brand_name.toLowerCase();
      const bItem = claimsByNdcCode[b][0].brand_name.toLowerCase();

      if (aItem < bItem) return -1;
      if (aItem > bItem) return 1;
      return 0;
    })
    .map(npi => claimsByNdcCode[npi]);

const RxList = ({ allClaims, rxClaimsByNdcCode }) => {
  if (!rxClaimsByNdcCode || !Object.keys(rxClaimsByNdcCode).length) {
    return <RxItem isEmpty />;
  }

  const sortedClaims = sortClaimsByBrandName(rxClaimsByNdcCode);
  return Object.keys(sortedClaims).map(claim => (
    <RxItemContainer
      allClaims={allClaims}
      isActive={sortedClaims[claim][0].is_active}
      key={sortedClaims[claim][0].ndc_code}
      rxClaims={sortedClaims[claim]}
    />
  ));
};

RxList.propTypes = {
  allClaims: PropTypes.arrayOf(PropTypes.object),
  rxClaimsByNdcCode: PropTypes.object,
};

export default RxList;
