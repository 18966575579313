/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { cloneDeep, isEmpty } from 'lodash';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Table, Select, Button, message } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import GET_ENROLLERS_LIST from '../../graphql/getEnrollersList';
import ADD_ENROLLER from '../../graphql/addEnroller';
import UPDATE_ENROLLER from '../../graphql/updateEnroller';
import generateEnrollerVariables from '../../utils/GenerateVariables.utils';
import ModifyEnroller from '../ModifyEnroller/ModifyEnroller';
import { statuses, defaultEnroller } from '../../constants/constants';

const { Option } = Select;

const sx = StyleSheet.create({
  statusSelect: { width: '133px' },
  tableOptionsContainer: {
    marginBottom: '20px',
  },
  tableOptionsButtonMargin: {
    marginRight: '16px',
  },
});

const defaultTableActions = {
  sorter: null,
  pagination: null,
  filters: null,
};

export const GET_PAYERS = gql`
  query payers($query: JSON) {
    payers(query: $query) {
      data {
        name
        id
      }
      total
    }
  }
`;

const EnrollersList = () => {
  const [data, setData] = useState([]);
  const [tableActions, setTableActions] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [currentEnrollerInfo, setCurrentEnrollerInfo] = useState({});
  const [showModifyEnrollerModal, setShowModifyEnrollerModal] = useState(false);
  const [payerById, setPayerById] = useState(null);

  const updateEnrollerList = (enrollerData, isEdit) => {
    const clonedData = cloneDeep(data);
    const enrollerId = enrollerData?.id;
    const resultData = isEdit
      ? clonedData.map(enroller => {
          if (enroller.id === enrollerId) {
            enroller = { ...enrollerData };
          }
          return enroller;
        })
      : [{ ...enrollerData }, ...clonedData];
    setData(resultData);
    message.success(`Successfully ${isEdit ? 'updated' : 'added'} PBS`);
  };

  const [getPayers, { data: payersData }] = useLazyQuery(GET_PAYERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching payers: ${error.message}`);
    },
    onCompleted: ({ payers }) => {
      if (payers?.data) {
        const payerById = payers?.data?.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
        setPayerById(payerById);
      }
      fetchEnrollersList();
    },
  });

  const [
    getEnrollersList,
    { loading: isFetchingEnrollersList, data: enrollersData },
  ] = useLazyQuery(GET_ENROLLERS_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching PBS list: ${error.message}`);
    },
    onCompleted: ({ enrollers }) => {
      setData(enrollers?.data);
    },
  });

  const [addEnroller] = useMutation(ADD_ENROLLER, {
    onError: error => {
      message.error(`Error adding PBS Agent: ${error.message}`);
    },
    onCompleted: ({ addEnroller }) => {
      updateEnrollerList(addEnroller?.data, false);
    },
  });

  const [updateEnroller] = useMutation(UPDATE_ENROLLER, {
    onError: error => {
      message.error(`Error updating PBS Agent: ${error.message}`);
    },
    onCompleted: ({ updateEnroller }) => {
      updateEnrollerList(updateEnroller?.data, true);
    },
  });

  const submitModifyEnroller = (isEdit, form) => {
    const modifiedForm = {
      ...form,
      state: sanitizeStates(form.state),
    };

    if (isEdit) {
      updateEnroller({
        variables: { ...modifiedForm },
      });
    } else {
      addEnroller({
        variables: { ...modifiedForm },
      });
    }
    setShowModifyEnrollerModal(false);
  };

  // if state contains all remove other states in states array
  const sanitizeStates = states => {
    if (states === null) {
      return [];
    } else if (states.includes('All')) {
      return ['All'];
    }
    return states;
  };

  const openModifyModal = (isEdit, enrollerInfo) => {
    setIsEdit(isEdit);
    const newEnrollerInfo = isEdit
      ? {
          name: `${enrollerInfo?.user?.first_name} ${enrollerInfo?.user?.last_name}`,
          id: enrollerInfo?.user?.id,
          enroller_id: enrollerInfo?.id,
          language: enrollerInfo?.user?.user_languages.map(userLang => userLang.language),
          line_of_business: enrollerInfo?.line_of_business,
          status: enrollerInfo?.status,
          team_lead_user: `${enrollerInfo?.team_lead_user?.first_name} ${enrollerInfo?.team_lead_user?.last_name}`,
          team_lead_user_id: enrollerInfo?.team_lead_user?.id,
          state: sanitizeStates(enrollerInfo?.state),
          payer_id: enrollerInfo?.payer_id === null ? [] : enrollerInfo?.payer_id,
        }
      : defaultEnroller;
    setCurrentEnrollerInfo(newEnrollerInfo);
  };

  const onStatusChange = (enrollerStatus, enrollerInfo) => {
    const newEnrollerInfo = {
      id: enrollerInfo?.id,
      status: enrollerStatus,
    };
    updateEnroller({
      variables: newEnrollerInfo,
    });
  };

  const generateColumns = () => {
    const headers = [
      {
        title: 'PBS Name',
        dataIndex: 'user',
        key: 'enroller',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'enroller' && tableActions?.sorter?.order,
        render: text => {
          return `${text?.first_name} ${text?.last_name}`;
        },
      },
      {
        title: 'Title',
        dataIndex: 'user',
        key: 'title',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'title' && tableActions?.sorter?.order,
        render: text => {
          return `${text?.title}`;
        },
      },
      {
        title: 'Language',
        dataIndex: 'user',
        key: 'language',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'language' && tableActions?.sorter?.order,
        render: text => {
          return text?.user_languages?.map(lang => <div key={lang.language}>{lang.language}</div>);
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'status' && tableActions?.sorter?.order,
        render: (text, record) => {
          return (
            <Select
              defaultValue={text || 'inactive'}
              onChange={value => {
                onStatusChange(value, record);
              }}
              className={css(sx.statusSelect)}
              key={record.id}
              value={text || 'inactive'}
            >
              {statuses.map(status => (
                <Option value={status.value} key={status.value}>
                  {status.text}
                </Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: 'Team Lead',
        dataIndex: 'team_lead_user',
        key: 'teamLead',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'teamLead' && tableActions?.sorter?.order,
        render: text => {
          return `${text?.first_name} ${text?.last_name}`;
        },
      },
      {
        title: 'Payers',
        dataIndex: 'payer_id',
        key: 'payer_id',
        render: payerIds => {
          return (
            (payerById &&
              payerIds?.map(
                (payerId, index) =>
                  `${payerById[payerId]?.name}${index !== payerIds.length - 1 ? ', ' : ''}`,
              )) ||
            'None Assigned'
          );
        },
      },
      {
        title: 'States',
        dataIndex: 'state',
        key: 'state',
        render: states => {
          return states?.includes('All')
            ? 'All'
            : states?.sort().map(state => `${state} `) || 'None Assigned';
        },
      },
      {
        title: 'Line Of Business',
        dataIndex: 'line_of_business',
        key: 'line_of_business',
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder:
          tableActions?.sorter?.columnKey === 'line_of_business' && tableActions?.sorter?.order,
        render: texts => {
          return texts.map(text => <div key={text}>{text}</div>);
        },
      },
      {
        title: 'Action',
        key: 'action',

        render: text => {
          return (
            <Button type="link" onClick={() => openModifyModal(true, text)}>
              Edit
            </Button>
          );
        },
      },
    ];
    return headers;
  };

  const fetchEnrollersList = (payload = cloneDeep(tableActions)) => {
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }
    const variables = generateEnrollerVariables(payload);

    getEnrollersList({
      variables: {
        ...variables,
      },
    });
  };

  useEffect(() => {
    getPayers();
  }, []);

  useEffect(() => {
    if (!isEmpty(currentEnrollerInfo)) {
      setShowModifyEnrollerModal(true);
    }
  }, [currentEnrollerInfo]);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    setTableActions({
      pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
      filters,
      sorter,
    });

    if (action === 'paginate') {
      fetchEnrollersList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchEnrollersList({
        pagination: {...tableActions.pagination, current: 1},
        filters,
        sorter,
      });
    }
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: {...tableActions.pagination, pageSize: 25, current: 1},
      filters: null,
      sorter: defaultTableActions.sorter,
    };

    setTableActions(payload);
    fetchEnrollersList(payload);
  };

  const onModifyCancel = () => {
    setCurrentEnrollerInfo({});
    setShowModifyEnrollerModal(false);
  };

  return (
    <>
      <div className={css(sx.tableOptionsContainer)}>
        <Button
          type="secondary"
          className={css(sx.tableOptionsButtonMargin)}
          onClick={clearAllFilters}
        >
          Clear All Filters
        </Button>
        <Button type="secondary" onClick={() => openModifyModal(false, {})}>
          Add PBS Agent
        </Button>
      </div>
      <Table
        columns={generateColumns()}
        dataSource={data}
        size="middle"
        onChange={onTableChange}
        className={'fs-hide'}
        rowKey="id"
        loading={isFetchingEnrollersList}
        pagination={{
          defaultPageSize: 25,
          showSizeChanger: true,
          pageSize: tableActions?.pagination?.pageSize,
          pageSizeOptions: [25, 50, 100],
          total: enrollersData?.enrollers?.total || 0,
          current: tableActions?.pagination?.current || 1,
        }}
      />

      <ModifyEnroller
        allPayers={payersData?.payers?.data}
        visible={showModifyEnrollerModal}
        submitModifyEnroller={submitModifyEnroller}
        onModifyCancel={onModifyCancel}
        isEdit={isEdit}
        enrollerInfo={currentEnrollerInfo}
      />
    </>
  );
};

export default EnrollersList;
