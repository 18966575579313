import { withSession } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import fetchOnceOptions from './utils/fetchOnceOptions';
import { queryCurrentUser } from '../graphql/users';

export default function withCurrentUser(Component) {
  const ComponentWithData = graphql(queryCurrentUser, {
    // Include the user id in the cache key since it is not part of variables
    skip: ({ userId }) => !userId,
    options: ({ userId }) => fetchOnceOptions(`currentUser_${userId}`, {}, true),
    props: ({ data }) => ({
      currentUser: get(data, 'currentUser.data'),
      loadingCurrentUser: data.loading,
    }),
  })(Component);

  return withSession(ComponentWithData);
}
