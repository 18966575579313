import { pharmacies, withSession } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import fetchOnceOptions from './utils/fetchOnceOptions';
import withPharmacyId from './withPharmacyId';

const withCurrentPharmacy = ComponentToWrap =>
  graphql(pharmacies.queryPharmacies, {
    skip: ({ userId, pharmacy_id }) => {
      return !userId || !pharmacy_id;
    },
    options: ({ pharmacy_id }) =>
      fetchOnceOptions('currentPharmacy', {
        variables: {
          query: { id: pharmacy_id },
        },
      }),
    props: ({ data: { pharmacies: result } }) => ({
      currentPharmacy: get(result, 'data[0]'),
    }),
  })(ComponentToWrap);

export default Component => compose(withSession, withPharmacyId, withCurrentPharmacy)(Component);
