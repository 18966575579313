import { groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import withCurrentUser from 'hoc/withCurrentUser';
import { sortList, Spinner, position, colors, font } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import SettingsAccordionList from '../SettingsAccordionList';
import TeamListInviteFormContainer from './TeamListInviteFormContainer';
import TeamListItem from './TeamListItem';

const sortTeamList = users => {
  const { false: pendingUsers = [], true: activeUsers = [] } = groupBy(
    users,
    ({ joined_at }) => !!joined_at,
  );

  return [...sortList(activeUsers, 'first_name'), ...pendingUsers];
};

const sx = StyleSheet.create({
  emptyState: {
    ...position.cover,
    color: colors.gray45,
    fontSize: font.size.medium,
  },
});

const TeamList = ({ users, isSystemAdminOrAsc, isLoading, currentUser }) => (
  <>
    {!isLoading ? (
      <SettingsAccordionList
        createItemButtonText="Invite team member"
        createItemFormRenderer={<TeamListInviteFormContainer currentUser={currentUser} />}
        isSystemAdminOrAsc={isSystemAdminOrAsc}
        emptyStateText="Get started by inviting a team member"
        itemRenderer={<TeamListItem />}
        items={users}
        listLabel="member"
        sortList={sortTeamList}
        title="Team"
      />
    ) : (
      <Spinner loading={isLoading} styles={sx.emptyState} />
    )}
  </>
);

TeamList.propTypes = {
  users: PropTypes.array,
  isSystemAdminOrAsc: PropTypes.bool,
  isLoading: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default withCurrentUser(TeamList);
