import {
  colors,
  Div,
  DropdownFooter,
  font,
  LinkButton,
  NewDropdown,
  padding,
  Row,
  space,
} from '@zipdrug/ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import FocusTrap from 'focus-trap-react';
import { upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  container: {
    position: 'relative',
    width: '29rem',
  },
  content: {
    ...padding.build(space.half, space.half, font.size.small),
    width: '100%',
    position: 'relative',
  },
  label: {
    color: colors.gray52,
    fontSize: font.size.xSmall,
    fontWeight: font.weight.medium,
    marginBottom: space.quarter,
    outline: 'none',
    userSelect: 'none',
  },
});

const LanguageDropdownLabel = props => (
  <Div {...props} styles={sx.label}>
    Language
  </Div>
);

const LanguageMenu = ({
  isEditing,
  languageOptions,
  onSelectLanguage,
  onSubmit,
  onToggleEdit,
  patient: { language },
  selectedLanguage,
}) =>
  isEditing ? (
    <FocusTrap className={css(sx.container)}>
      <Row styles={sx.content}>
        <NewDropdown
          id="e2e-languageMenuDropdown"
          items={languageOptions}
          labelRenderer={LanguageDropdownLabel}
          onChange={onSelectLanguage}
          placeholder="Select a language..."
          tabIndex="0"
        />
      </Row>
      <DropdownFooter>
        <LinkButton onClick={onToggleEdit} tabIndex={selectedLanguage ? '1' : '0'}>
          Cancel
        </LinkButton>
        <LinkButton
          disabled={!selectedLanguage}
          id="e2e-languageMenuSaveButton"
          onClick={onSubmit}
          tabIndex={selectedLanguage ? '0' : '-1'}
        >
          Save
        </LinkButton>
      </DropdownFooter>
    </FocusTrap>
  ) : (
    <Div styles={sx.container}>
      <Div styles={sx.content}>
        <LanguageDropdownLabel />
        <Div>{upperFirst(language)}</Div>
      </Div>
      <DropdownFooter>
        <LinkButton id="e2e-languageMenuChangeButton" onClick={onToggleEdit} tabIndex="0">
          Change
        </LinkButton>
      </DropdownFooter>
    </Div>
  );

LanguageMenu.propTypes = {
  isEditing: PropTypes.bool,
  languageOptions: PropTypes.arrayOf(PropTypes.string),
  onSelectLanguage: PropTypes.func,
  onSubmit: PropTypes.func,
  onToggleEdit: PropTypes.func,
  patient: PropTypes.object,
  selectedLanguage: PropTypes.string,
};

export default LanguageMenu;
