import gql from 'graphql-tag';

const GET_LEAD_CHURN_LIST = gql`
  query leadChurns($limit: Int, $skip: Int, $order_by: LeadChurnListOrderByInput, $query: JSON) {
    leadChurns(limit: $limit, skip: $skip, order_by: $order_by, query: $query) {
      data {
        id
        first_name
        last_name
        patient_id
        assigned_user {
          first_name
          last_name
          id
        }
        filtered_out
        filtered_by_user_id
        churned_outreach_date
        created_at
        deleted_at
        updated_at
      }
      total
      last_updated_at
    }
  }
`;

export default GET_LEAD_CHURN_LIST;
