import gql from 'graphql-tag';

const SEARCH_MEMBERS = gql`
  query searchMembers($limit: Int, $query: JSON, $skip: Int, $searchCategory: String) {
    searchMembers(limit: $limit, query: $query, skip: $skip, searchCategory: $searchCategory) {
      data {
        id
        first_name
        last_name
        birthday
        phone_numbers
        member_ids
        anthem_mcid
      }
    }
  }
`;

export default SEARCH_MEMBERS;
