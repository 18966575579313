import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Typography, Button } from 'antd';

const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '579px',
    minHeight: '236px',
  },
  description: {
    fontSize: '16px',
  },
  title: {
    fontWeight: '500',
  },
});

const { Title, Text } = Typography;

const BulkRemoveConfirmationModal = ({ zipcodeType, zipcodes, onCloseModal, visible }) => {
  return (
    <Modal
      onCancel={onCloseModal}
      open={visible}
      className={css(sx.modalContainer)}
      footer={[
        <Button key="cancel" onClick={onCloseModal} className={css(sx.buttonCancel)}>
          Close
        </Button>,
      ]}
    >
      <Title className={css(sx.title)} level={3}>
        Bulk Remove Zipcodes Confirmation
      </Title>
      <div className={css(sx.description)}>
        {zipcodeType === 'assigned' ? (
          <Text>{`You have removed the following zipcodes from this pharmacy: ${zipcodes} `}</Text>
        ) : (
          <Text>
            {`You have removed the potential and assigned pharmacies from the following zipcodes: ${zipcodes}`}
          </Text>
        )}
      </div>
    </Modal>
  );
};

BulkRemoveConfirmationModal.propTypes = {
  zipcodeType: PropTypes.string,
  zipcodes: PropTypes.array,
  onCloseModal: PropTypes.func,
  visible: PropTypes.bool,
};

export default BulkRemoveConfirmationModal;
