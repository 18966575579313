import { dateUtils } from '@zipdrug/ui';
import { daysDiff } from './dateUtils';

function getDateRange({ fill, limit }) {
  if (!fill) return null;

  let fromDate = dateUtils.startOfDay(fill.dispense_date);
  let untilDate = dateUtils.addDays(fromDate, fill.days_supply);

  // Push the date range forward / back to fit the range
  if (fromDate < limit.fromDate) fromDate = limit.fromDate;
  if (untilDate > limit.untilDate) untilDate = limit.untilDate;

  const daysInRange = daysDiff(untilDate, fromDate);
  if (daysInRange < 1) return null;

  return {
    ...fill,
    daysInRange,
    fromDate,
    untilDate,
  };
}

function sortTherapyFills(therapyFills) {
  // Sort the therapy fills by how they should be charted
  return therapyFills.sort((a, b) => {
    // choose the earliest start date
    if (a.dispense_date < b.dispense_date) return -1;
    if (a.dispense_date > b.dispense_date) return 1;

    // choose the non-alternative therapy
    if (!a.isAlternative && b.isAlternative) return -1;
    if (a.isAlternative && !b.isAlternative) return 1;

    // choose the longer supply
    if (a.days_supply > b.days_supply) return -1;
    if (a.days_supply < b.days_supply) return 1;

    return 0;
  });
}

export default function getTherapyDateRanges({ therapyFills, limit: { fromDate, untilDate } }) {
  const therapyDateRanges = sortTherapyFills(therapyFills)
    .map(fill =>
      getDateRange({
        fill,
        limit: {
          fromDate,
          untilDate,
        },
      }),
    )
    // Filter out nulls
    .filter(d => d);

  return therapyDateRanges;
}
