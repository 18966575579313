import { alerts as alertsSelection } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';

const buildAlertsCountByTypeMap = (alertsCountByType = []) => {
  const formatAlertsCountByType = alertsCountByType.map(({ count, type }) => ({
    [`${type}`]: count,
  }));

  return Object.assign({}, ...formatAlertsCountByType);
};

export default Component =>
  graphql(alertsSelection.queryAlertsCountByType, {
    options: ({ selectedPatientId }) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        id: selectedPatientId,
      },
    }),
    props: ({ data: { alertsCountByType: result } }) => ({
      alertsCountByType: buildAlertsCountByTypeMap(get(result, 'data')),
    }),
  })(Component);
