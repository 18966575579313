import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import { createElement } from 'react';
import { Div, padding, space } from '@zipdrug/ui';

const sx = StyleSheet.create({
  default: {
    ...padding.build(space.default),
  },
});

const PageSection = ({ component, styles, ...props }) =>
  createElement(component, {
    ...props,
    styles: [sx.default, styles],
  });

PageSection.propTypes = {
  component: PropTypes.func,
  styles: aphroditePropType,
};

PageSection.defaultProps = {
  component: Div,
};

export default PageSection;
