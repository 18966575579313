import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import {
  ArrowButton,
  Bold,
  Button,
  font,
  FormExtensionField,
  FormPhoneField,
  FormTextField,
  padding,
  position,
  Row,
  space,
  Span,
} from '@zipdrug/ui';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';

const sx = StyleSheet.create({
  arrowButton: {
    flex: 'none',
  },
  backButton: {
    ...position.size(space.two),
    ...padding.build(0),
  },
  backButtonIcon: {
    height: font.size.xSmall,
    width: 'auto',
  },
  buttonRow: {
    marginTop: space.half,
  },
  extensionField: {
    maxWidth: space.six,
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
    marginRight: font.size.xSmall,
    ':last-child': {
      marginRight: 0,
    },
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const CreateWorkstationPhonePage = ({ handleSubmit, onClickBack }) => (
  <StandaloneFormPageTemplate
    title={
      <Span>
        <Bold>Add the phone&nbsp;</Bold>
        <Span>that you’ll be using at your work station.</Span>
      </Span>
    }
  >
    <Row>
      <FormTextField
        {...FORMFIELD_PROPS}
        autoFocus
        id="e2e-createWorkstationPhonePageFormTextField"
        label="Name"
        name="label"
        placeholder="Front counter"
      />
    </Row>
    <Row>
      <FormPhoneField
        {...FORMFIELD_PROPS}
        id="e2e-createWorkstationPhonePageFormPhoneField"
        label="Phone number"
        name="number"
        placeholder="(123) 456-7890"
      />
      <FormExtensionField
        {...FORMFIELD_PROPS}
        id="e2e-createWorkstationPhonePageFormExtensionField"
        label="Extension"
        name="extension"
        optional
        placeholder="1234"
        styles={[sx.formField, sx.extensionField]}
      />
    </Row>
    <Row align="center" justify="space-between" styles={sx.buttonRow}>
      <Button
        icon="arrow"
        iconProps={{ direction: 'left', styles: sx.backButtonIcon }}
        onClick={onClickBack}
        outline
        styles={sx.backButton}
        tabIndex="-1"
        title="Go back"
        type="primary"
      />
      <ArrowButton
        onClick={handleSubmit}
        styles={sx.arrowButton}
        text="Go to dashboard"
        title="Go to my dashboard"
        id="e2e-createWorkstationPhonePageArrowButton"
      />
    </Row>
  </StandaloneFormPageTemplate>
);

CreateWorkstationPhonePage.propTypes = {
  handleSubmit: PropTypes.func,
  onClickBack: PropTypes.func,
};

export default CreateWorkstationPhonePage;
