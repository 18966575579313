import moment from 'moment';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { font, space, Span } from '@zipdrug/ui';
import SystemEventActivityItem from './SystemEventActivityItem';

const sx = StyleSheet.create({
  content: {
    lineHeight: font.lineHeight.looser,
    paddingTop: space.half,
  },
  footer: {
    marginTop: 10,
  },
});

export default class LetterActivityItem extends Component {
  static propTypes = {
    activity: PropTypes.object,
  };

  renderHeading = () => <Span> A letter was returned to sender.</Span>;

  render = () => {
    const { activity, ...props } = this.props;
    const content = `Enrollment letter was returned to sender on ${moment(
      activity.letter.returned_to_sender_date,
    ).format('dddd, MMMM Do YYYY, h:mm A')}`;
    return (
      <SystemEventActivityItem
        {...props}
        content={content}
        contentStyles={sx.content}
        createdAt={activity.letter.returned_to_sender_date}
        footerName={activity.letter.created_by_user}
        footerStyles={sx.footer}
        headerStyles={sx.header}
        heading={this.renderHeading()}
        icon="paperPlane"
      />
    );
  };
}
