const removeCommaEnding = string => {
  let newString = string.trim();
  const lastIndex = newString[newString.length - 1];
  if (lastIndex === ',') {
    newString = string.substring(0, newString.length - 1);
  }
  return newString;
};

const errorHandle = message => {
  const index = message.indexOf('Codes:');
  const code = message.slice(index + 6, index + 7);
  const codeOneIndex = message.indexOf('1:');
  const codeTwoIndex = message.indexOf('2:');
  const codeThreeIndex = message.indexOf('3:');
  const codeFourIndex = message.indexOf('4:');
  const codeFiveIndex = message.indexOf('5:');
  const codeSixIndex = message.indexOf('6:');

  const errorMessages = [];
  switch (code) {
    case '1': {
      const codeTwoEndIndex = codeTwoIndex > -1 ? codeTwoIndex : null;
      const codeThreeEndIndex = codeThreeIndex > -1 ? codeThreeIndex : null;
      const codeFourEndIndex = codeFourIndex > -1 ? codeFourIndex : null;
      const codeFiveEndIndex = codeFiveIndex > -1 ? codeFiveIndex : null;
      const codeSixEndIndex = codeSixIndex > -1 ? codeSixIndex : null;

      const zipcodes = removeCommaEnding(
        message.slice(
          codeOneIndex + 3,
          codeTwoEndIndex ||
            codeThreeEndIndex ||
            codeFourEndIndex ||
            codeFiveEndIndex ||
            codeSixEndIndex ||
            message.length,
        ),
      );
      errorMessages.push(`One or more of these zip codes are invalid: ${zipcodes}`);
      break;
    }
    case '2': {
      const codeThreeEndIndex = codeThreeIndex > -1 ? codeThreeIndex : null;
      const zipcodes = removeCommaEnding(
        message.slice(codeTwoIndex + 3, codeThreeEndIndex || message.length),
      );
      errorMessages.push(
        `The following zip code(s) entered are already added to potential or assigned to this pharmacy: ${zipcodes}`,
      );
      break;
    }
    case '3': {
      const zipcodes = message.slice(codeThreeIndex + 3, message.length);
      errorMessages.push(
        `The following zip code(s) are not a Potential zip code(s): ${zipcodes}. Zip codes must be added in Potential Pharmacy before being assigned.`,
      );
      break;
    }
    case '4': {
      const codeSixEndIndex = codeSixIndex > -1 ? codeSixIndex : null;
      const zipcodes = removeCommaEnding(
        message.slice(codeFourIndex + 3, codeSixEndIndex || message.length),
      );
      errorMessages.push(
        `The following zip code(s) are not Assigned zip code(s): ${zipcodes}. Zip codes must be Assigned Zip codes to delete.`,
      );
      break;
    }
    case '5': {
      const codeSixEndIndex = codeSixIndex > -1 ? codeSixIndex : null;
      const zipcodes = removeCommaEnding(
        message.slice(codeFiveIndex + 3, codeSixEndIndex || message.length),
      );
      errorMessages.push(
        `The following zip code(s) are not Potential zip code(s): ${zipcodes}. Zip codes must be Potential Zip codes to delete.`,
      );
      break;
    }
    case '6': {
      const zipcodes = removeCommaEnding(message.slice(codeSixIndex + 3, message.length));
      errorMessages.push(`The following zip code(s) were already deleted: ${zipcodes}.`);
      break;
    }
    default:
      break;
  }
  return errorMessages.join(', ');
};

export default errorHandle;
