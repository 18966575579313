import { LinkDropdown } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import PatientInfoDropdownButton from '../PatientInfoDropdownButton';
import GuardianForm from './GuardianForm';
import GuardianMenu from './GuardianMenu';

const sx = StyleSheet.create({
  menuStyles: {
    left: 0,
    right: 'initial',
  },
});

const GuardianDropdown = ({ patient }) => {
  const [patientInfo, setPatientInfo] = useState(cloneDeep(patient));

  const [onEdit, setOnEdit] = useState(false);

  const onToggleEdit = () => {
    setOnEdit(!onEdit);
  };

  const updatePatientInfo = info => {
    setPatientInfo(info);
    onToggleEdit();
  };

  const menu = onEdit ? (
    <GuardianForm
      patientInfo={patientInfo}
      updatePatientInfo={updatePatientInfo}
      onToggleEdit={onToggleEdit}
    />
  ) : (
    <GuardianMenu patientInfo={patientInfo} onToggleEdit={onToggleEdit} />
  );

  return (
    <>
      {patientInfo ? (
        <LinkDropdown
          buttonRenderer={
            <PatientInfoDropdownButton
              icon="human"
              id="e2e-guardianDropdown"
              text={
                patientInfo?.guardian_name
                  ? `${patientInfo?.guardian_name} (Guardian)`
                  : 'No Guardian'
              }
              title="Edit Guardian"
            />
          }
          menuRenderer={menu}
          menuStyles={sx.menuStyles}
        />
      ) : null}
    </>
  );
};

GuardianDropdown.propTypes = {
  patient: PropTypes.object,
};

export default GuardianDropdown;
