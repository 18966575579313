const languages = [
  {
    text: 'English',
    value: 'english',
  },
  {
    text: 'Spanish',
    value: 'spanish',
  },
  //   {
  //     text: 'Arabic',
  //     value: 'arabic',
  //   },
  //   {
  //     text: 'Gujarathi',
  //     value: 'gujarathi',
  //   },
  //   {
  //     text: 'Greek',
  //     value: 'greek',
  //   },
  //   {
  //     text: 'Hindi',
  //     value: 'hindi',
  //   },
  //   {
  //     text: 'Italian',
  //     value: 'italian',
  //   },
  //   {
  //     text: 'Korean',
  //     value: 'korean',
  //   },
  //   {
  //     text: 'Mandarin',
  //     value: 'mandarin',
  //   },
  //   {
  //     text: 'Polish',
  //     value: 'polish',
  //   },
  //   {
  //     text: 'Portuguese',
  //     value: 'portuguese',
  //   },
  //   {
  //     text: 'Russian',
  //     value: 'russian',
  //   },
  //   {
  //     text: 'Tagalog',
  //     value: 'tagalog',
  //   },
  //   {
  //     text: 'Vienamese',
  //     value: 'vietnamese',
  //   },
  //   {
  //     text: 'Mandar',
  //     value: 'mandar',
  //   },
  //   {
  //     text: 'Haitian',
  //     value: 'Haitian',
  //   },
  //   {
  //     text: 'Norwegian',
  //     value: 'Norwegian',
  //   },
  //   {
  //     text: 'Efik',
  //     value: 'Efik',
  //   },
  //   {
  //     text: 'Swedish',
  //     value: 'swedish',
  //   },
  //   {
  //     text: 'Egyptian',
  //     value: 'egyptian',
  //   },
  //   {
  //     text: 'N/A',
  //     value: null,
  //   },
];

export default languages;
