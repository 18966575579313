import { postalCodes as postalCodesSelection, withSession } from '@zipdrug/react-api-sdk';
import { get, omit } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps, withProps } from 'recompose';
import fetchOnceOptions from './utils/fetchOnceOptions';
import withActivePharmacies from './withActivePharmacies';
import withRoles from './withRoles';
import withSelectedPatientInfo from './withSelectedPatientInfo';

const withPharmaciesForPostalCodes = Component =>
  graphql(postalCodesSelection.queryPostalCodes, {
    skip: ({ userId, hasRole: { pharmacistUser, pharmacistAdmin } }) =>
      !userId || (pharmacistUser && pharmacistAdmin),
    options: ({ payer_id, postalCodes }) =>
      fetchOnceOptions('postalCodes', {
        variables: {
          query: {
            payer_id,
            postal_code: { $in: postalCodes },
          },
        },
        networkOnly: true,
      }),
    props: ({ data: { postalCodes: result } }) => ({
      pharmacyIdsForPostalCode: get(result, 'data', []).map(({ pharmacy_id }) => pharmacy_id),
    }),
  })(Component);

export default Component =>
  compose(
    withSession,
    withRoles,
    withSelectedPatientInfo,
    mapProps(({ selectedPatient, ...props }) => ({
      ...props,
      payer_id: selectedPatient?.payer_id,
      plans: selectedPatient?.plans,
      postalCodes: selectedPatient?.addresses.map(({ postal_code }) => postal_code),
    })),
    withPharmaciesForPostalCodes,
    withProps(({ plan_id, pharmacyNetworkTypes, pharmacyIdsForPostalCode }) => ({
      query: {
        id: {
          $in: pharmacyIdsForPostalCode,
        },
        status: 'active',
      },
      pharmacyNetworkTypes,
      plan_id,
    })),
    withActivePharmacies,
    mapProps(({ activePharmacies, ...props }) => ({
      ...omit(props, ['query', 'language', 'postalCodes']),
      pharmaciesForPostalCodes: activePharmacies,
    })),
  )(Component);
