import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@zipdrug/ui';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import { setPasswordExpiration } from '../redux/queryReducer';

const sx = StyleSheet.create({
  autoLogoutContainer: {
    width: '350px',
    height: '50px',
    backgroundColor: 'white',
    position: 'fixed',
    top: '10px',
    right: '10px',
    zIndex: '999',
    boxShadow: '0 0 1rem 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  alertIcon: {
    marginLeft: '15px',
  },
  closeIcon: {
    cursor: 'pointer',
    marginLeft: '20px',
  },
  warningText: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 0,
    marginLeft: '10px',
  },
});

const PasswordExpirationWarning = props => {
  return (
    <div>
      {props.passwordExpiration && props.passwordExpiration <= 20 ? (
        // needs to change to 10
        <div className={css(sx.autoLogoutContainer)}>
          <div className={css(sx.alertIcon)}>
            <Icon name={'clock'} />
          </div>
          <p className={css(sx.warningText)}>
            Your password will expire in {props.passwordExpiration} days.
          </p>
          <div
            className={css(sx.closeIcon)}
            onClick={() => props.setPasswordExpiration({ passwordExpiration: 100 })}
            role={'presentation'}
          >
            <Icon name={'close'} />
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

const mapStateToProps = store => ({
  passwordExpiration: store.query.user.passwordExpiration,
});

const mapDispatchToProps = dispatch => ({
  setPasswordExpiration: ({ passwordExpiration }) =>
    dispatch(setPasswordExpiration({ passwordExpiration })),
});

PasswordExpirationWarning.propTypes = {
  passwordExpiration: PropTypes.string,
  setPasswordExpiration: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpirationWarning);
