import { Column, FlexItem, Timeline } from '@zipdrug/ui';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import FillTimelineAxis from './FillTimelineAxis';
import FillTimelineSegment from './FillTimelineSegment';
import SegmentType from './SegmentType';

const FillTimeline = ({ segments, showAxis, styles }) => (
  <Column component={FlexItem} styles={styles}>
    <Timeline segmentRenderer={FillTimelineSegment} segments={segments} />
    {showAxis && <FillTimelineAxis />}
  </Column>
);

FillTimeline.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      daysInRange: PropTypes.number.isRequired,
      fromDate: PropTypes.number.isRequired,
      type: PropTypes.oneOf(Object.values(SegmentType)).isRequired,
    }),
  ),
  showAxis: PropTypes.bool,
  styles: aphroditePropType,
};

export default FillTimeline;
