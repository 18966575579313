import React from 'react';

const InboundIcon = props => (
  <svg
    width="23"
    height="23"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.36842 1H14.6316V11.4545H20L11.5 21L3 11.4545H8.36842V1Z"
      stroke="#048802"
      strokeWidth="2"
    />
  </svg>
);

export default InboundIcon;
