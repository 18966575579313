import { Button, colors, Div, font, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { cloneElement, Component } from 'react';
import PageHeading from '../ui/PageHeading';
import PageSection from '../ui/PageSection';
import PageSectionTitle from '../ui/PageSectionTitle';
import SettingsAccordionListForm from './SettingsAccordionListForm';

const sx = StyleSheet.create({
  emptyList: {
    color: colors.gray57,
    fontSize: font.size.medium,
    paddingTop: space.four,
    textAlign: 'center',
  },
});

export default class SettingsAccordionList extends Component {
  static propTypes = {
    isSystemAdminOrAsc: PropTypes.bool,
    createItemButtonText: PropTypes.string,
    createItemFormRenderer: PropTypes.node,
    emptyStateText: PropTypes.string,
    itemRenderer: PropTypes.node,
    items: PropTypes.array,
    listLabel: PropTypes.string,
    sortList: PropTypes.func,
    title: PropTypes.string,
  };

  static defaultProps = {
    items: [],
  };

  state = { createMode: false, shouldBeScrolled: true };

  handleDisableCreateItem = () => this.setState({ createMode: false });
  handleEnableCreateItem = () => this.setState({ createMode: true, shouldBeScrolled: true });
  handleScrollIntoView = () => this.setState({ shouldBeScrolled: false });

  sortList = () => (this.props.sortList ? this.props.sortList(this.props.items) : this.props.items);

  renderContent = () =>
    !this.props.items.length && !this.state.createMode
      ? this.renderEmptyState()
      : this.renderList();

  renderEmptyState = () => (
    <PageSection styles={sx.emptyList}>{this.props.emptyStateText}</PageSection>
  );

  renderList = () =>
    this.sortList().map(item =>
      cloneElement(this.props.itemRenderer, {
        item,
        key: item.id,
      }),
    );

  renderListLabel = () => {
    const { items, listLabel } = this.props;
    const label = items.length === 1 ? listLabel : `${listLabel}s`;

    return <PageSectionTitle>{`${items.length} ${label}`}</PageSectionTitle>;
  };

  render = () => (
    <Div>
      <PageHeading title={this.props.title}>
        {this.props.isSystemAdminOrAsc && (
          <Button
            onClick={this.handleEnableCreateItem}
            size="large"
            text={this.props.createItemButtonText}
            type="primary"
          />
        )}
      </PageHeading>
      <Div>
        {this.renderListLabel()}
        {this.renderContent()}
        {this.state.createMode && (
          <SettingsAccordionListForm
            component={this.props.createItemFormRenderer}
            onClickCancel={this.handleDisableCreateItem}
            onScrolledIntoView={this.handleScrollIntoView}
            shouldBeScrolled={this.state.shouldBeScrolled}
          />
        )}
      </Div>
    </Div>
  );
}
