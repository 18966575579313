import { get, orderBy } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withSelectedPatientInfo from './withSelectedPatientInfo';
import { checkPatientMinorEligibility } from '../graphql/patients';

const withMinorEligibility = Component =>
  graphql(checkPatientMinorEligibility, {
    skip: ({ selectedPatient }) => !selectedPatient,
    options: ({ selectedPatient }) => {
      const { id, addresses, plans } = selectedPatient;
      let plan_type = null;
      let state = null;

      if (plans?.length > 0) {
        plan_type = plans?.find(plan => plan?.is_primary)?.plan_type;
      }

      if (addresses?.length > 0) {
        const primaryAddress = orderBy(addresses, ['order'], ['asc'])[0];
        state = primaryAddress?.state;
      }

      return {
        fetchPolicy: 'no-cache',
        variables: {
          patient_id: id,
          plan_type,
          state,
        },
      };
    },
    props: ({ data: { loading: isLoading, checkPatientMinorEligibility: result } }) => ({
      isLoading,
      isPatientMinorEligible: get(result, 'data'),
    }),
  })(Component);

export default Component => compose(withSelectedPatientInfo, withMinorEligibility)(Component);
