import { Column, Div, DropdownFooter, LinkButton } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import preferenceMap from './PreferenceMap';

const sx = StyleSheet.create({
  container: {
    width: '30rem',
    padding: '1.5rem',
  },
  list: {
    maxHeight: '50rem',
    overflowY: 'auto',
  },
});

const formatPhoneNumber = str => {
  const cleaned = ` ${str}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};

const PreferredFollowupMenu = ({ patient, toggleModal, preferredPhone }) => {
  const [preferredPhoneNumber, setPreferredPhoneNumber] = useState(null);

  const resetPreferredFollowup = () => {
    patient.preferred_followup = null;
    return 'None';
  };

  useEffect(() => {
    if (patient?.preferred_followup === 'phone') {
      if (preferredPhone) {
        setPreferredPhoneNumber(formatPhoneNumber(preferredPhone.number));
      } else if (!preferredPhone) {
        resetPreferredFollowup();
      }
    }
  }, [preferredPhone]);

  return (
    <Column styles={sx.container}>
      <strong>Preferred Follow-Up</strong>
      <Div styles={sx.list}>
        {patient?.preferred_followup ? preferenceMap[patient?.preferred_followup].label : 'None'}
      </Div>
      <Div styles={sx.list}>
        {patient?.preferred_followup === 'email' && patient?.emails[0]?.email}
        {patient?.preferred_followup === 'phone' &&
        patient?.preferred_phone_id &&
        preferredPhoneNumber
          ? preferredPhoneNumber
          : ''}
      </Div>
      <DropdownFooter>
        <LinkButton onClick={toggleModal}>Select Preference</LinkButton>
      </DropdownFooter>
    </Column>
  );
};

PreferredFollowupMenu.propTypes = {
  patient: PropTypes.object,
  toggleModal: PropTypes.func,
  preferredPhone: PropTypes.object,
};

export default PreferredFollowupMenu;
