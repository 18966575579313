import { border, colors, font, padding, SmallCaps, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React from 'react';

const sx = StyleSheet.create({
  default: {
    ...border.build('bottom', {
      color: colors.gray97,
      width: 2,
    }),
    ...padding.build(font.size.xSmall, space.get(1.5)),
  },
});

const PageSectionTitle = ({ styles, ...props }) => (
  <SmallCaps {...props} styles={[sx.default, styles]} />
);

PageSectionTitle.propTypes = {
  styles: aphroditePropType,
};

export default PageSectionTitle;
