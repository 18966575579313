import {
  colors,
  Column,
  Div,
  font,
  Icon,
  MetaRow,
  position,
  Row,
  space,
  zIndex,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import ContactDropdownContainer from '../../ui/contact-dropdown/ContactDropdownContainer';

const sx = StyleSheet.create({
  container: {
    marginBottom: font.size.medium,
    minHeight: space.two,
    zIndex: zIndex[3],
  },
  icon: {
    ...position.size(font.size.medium),
    color: colors.gray62,
    marginRight: space.half,
  },
  meta: {
    fontSize: font.size.xSmall,
  },
});

const formatPrescriberName = name => {
  let prescriberName = name;
  if (!prescriberName) return 'Unknown';
  prescriberName = prescriberName.replace('_', ' ');
  prescriberName = prescriberName.toLowerCase();
  prescriberName = prescriberName.replace(/\b\w/g, l => l.toUpperCase());
  const segments = prescriberName.split(' ');
  const suffixIndex = segments.length - 1;

  if (segments[suffixIndex].toLowerCase() === 'md') {
    segments[suffixIndex - 1] = `${segments[suffixIndex - 1]},`;
    segments[suffixIndex] = 'M.D.';
  }

  return segments.join(' ');
};

const RxPrescriberGroupHeader = ({ id, name, npi, phones }) => (
  <Row align="center" justify="space-between" styles={sx.container}>
    <Row align="center">
      <Icon name="doctor" styles={sx.icon} />
      <Column>
        <Div>{formatPrescriberName(name)}</Div>
        <MetaRow items={[`NPI: ${npi}`]} styles={sx.meta} />
      </Column>
    </Row>
    <ContactDropdownContainer contactItem={{ id, phones }} isEditable={false} />
  </Row>
);

RxPrescriberGroupHeader.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  npi: PropTypes.number,
  phones: PropTypes.arrayOf(PropTypes.object),
};

export default RxPrescriberGroupHeader;
