const states = {
  AL: {
    name: 'Alabama',
    text: 'AL',
    value: 'AL',
  },
  AK: {
    name: 'Alaska',
    text: 'AK',
    value: 'AK',
  },
  AZ: {
    name: 'Arizona',
    text: 'AZ',
    value: 'AZ',
  },
  AR: {
    name: 'Arkansas',
    text: 'AR',
    value: 'AR',
  },
  CA: {
    name: 'California',
    text: 'CA',
    value: 'CA',
  },
  CO: {
    name: 'Colorado',
    text: 'CO',
    value: 'CO',
  },
  CT: {
    name: 'Connecticut',
    text: 'CT',
    value: 'CT',
  },
  DE: {
    name: 'Delaware',
    text: 'DE',
    value: 'DE',
  },
  DC: {
    name: 'District Of Columbia',
    text: 'DC',
    value: 'DC',
  },
  FL: {
    name: 'Florida',
    text: 'FL',
    value: 'FL',
  },
  GA: {
    name: 'Georgia',
    text: 'GA',
    value: 'GA',
  },
  HI: {
    name: 'Hawaii',
    text: 'HI',
    value: 'HI',
  },
  ID: {
    name: 'Idaho',
    text: 'ID',
    value: 'ID',
  },
  IL: {
    name: 'Illinois',
    text: 'IL',
    value: 'IL',
  },
  IN: {
    name: 'Indiana',
    text: 'IN',
    value: 'IN',
  },
  IA: {
    name: 'Iowa',
    text: 'IA',
    value: 'IA',
  },
  KS: {
    name: 'Kansas',
    text: 'KS',
    value: 'KS',
  },
  KY: {
    name: 'Kentucky',
    text: 'KY',
    value: 'KY',
  },
  LA: {
    name: 'Louisiana',
    text: 'LA',
    value: 'LA',
  },
  ME: {
    name: 'Maine',
    text: 'ME',
    value: 'ME',
  },
  MD: {
    name: 'Maryland',
    text: 'MD',
    value: 'MD',
  },
  MA: {
    name: 'Massachusetts',
    text: 'MA',
    value: 'MA',
  },
  MI: {
    name: 'Michigan',
    text: 'MI',
    value: 'MI',
  },
  MN: {
    name: 'Minnesota',
    text: 'MN',
    value: 'MN',
  },
  MS: {
    name: 'Mississippi',
    text: 'MS',
    value: 'MS',
  },
  MO: {
    name: 'Missouri',
    text: 'MO',
    value: 'MO',
  },
  MT: {
    name: 'Montana',
    text: 'MT',
    value: 'MT',
  },
  NE: {
    name: 'Nebraska',
    text: 'NE',
    value: 'NE',
  },
  NV: {
    name: 'Nevada',
    text: 'NV',
    value: 'NV',
  },
  NH: {
    name: 'New Hampshire',
    text: 'NH',
    value: 'NH',
  },
  NJ: {
    name: 'New Jersey',
    text: 'NJ',
    value: 'NJ',
  },
  NM: {
    name: 'New Mexico',
    text: 'NM',
    value: 'NM',
  },
  NY: {
    name: 'New York',
    text: 'NY',
    value: 'NY',
  },
  NC: {
    name: 'North Carolina',
    text: 'NC',
    value: 'NC',
  },
  ND: {
    name: 'North Dakota',
    text: 'ND',
    value: 'ND',
  },
  OH: {
    name: 'Ohio',
    text: 'OH',
    value: 'OH',
  },
  OK: {
    name: 'Oklahoma',
    text: 'OK',
    value: 'OK',
  },
  OR: {
    name: 'Oregon',
    text: 'OR',
    value: 'OR',
  },
  PA: {
    name: 'Pennsylvania',
    text: 'PA',
    value: 'PA',
  },
  RI: {
    name: 'Rhode Island',
    text: 'RI',
    value: 'RI',
  },
  SC: {
    name: 'South Carolina',
    text: 'SC',
    value: 'SC',
  },
  SD: {
    name: 'South Dakota',
    text: 'SD',
    value: 'SD',
  },
  TN: {
    name: 'Tennessee',
    text: 'TN',
    value: 'TN',
  },
  TX: {
    name: 'Texas',
    text: 'TX',
    value: 'TX',
  },
  UT: {
    name: 'Utah',
    text: 'UT',
    value: 'UT',
  },
  VT: {
    name: 'Vermont',
    text: 'VT',
    value: 'VT',
  },
  VA: {
    name: 'Virginia',
    text: 'VA',
    value: 'VA',
  },
  WA: {
    name: 'Washington',
    text: 'WA',
    value: 'WA',
  },
  WV: {
    name: 'West Virginia',
    text: 'WV',
    value: 'WV',
  },
  WI: {
    name: 'Wisconsin',
    text: 'WI',
    value: 'WI',
  },
  WY: {
    name: 'Wyoming',
    text: 'WY',
    value: 'WY',
  },
};

export default states;
