import { fields, patients, sanitizeAddresses } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { reduxForm, SubmissionError } from 'redux-form';
import AddressForm from './AddressForm';

const ADDRESS_LABEL_MAP = ['Home', 'Office', 'Other'];

const DEFAULT_INITIAL_VALUES = {
  label: 'Home',
};

const ReduxAddressForm = reduxForm({
  form: 'addressForm',
})(AddressForm);

const validationError = () => {
  throw new SubmissionError({ address: 'is invalid' });
};

class AddressFormContainer extends Component {
  static propTypes = {
    address: PropTypes.object,
    mutateUpdatePatient: PropTypes.func,
    onClickCancel: PropTypes.func,
    patient: PropTypes.object,
  };

  state = {
    animateCustomLabel: false,
    didSelectAddressFromGoogle: false,
    showCustomLabel: false,
    defaultAddress: false,
  };

  componentWillMount() {
    this.initialValues = this.buildInitialValues();
  }

  initialValues = {};

  buildInitialValues = () => {
    const { address } = this.props;
    if (address?.order === 0) {
      this.setState({ defaultAddress: true });
    }

    if (Object.keys(address).length) {
      const defaultValues = {
        ...address,
        address: {
          city: address.city,
          postalCode: address.postal_code,
          state: address.state,
          street1: address.line1,
        },
      };

      if (ADDRESS_LABEL_MAP.includes(address.label)) {
        return defaultValues;
      }

      this.setState({ showCustomLabel: true });

      return Object.assign({}, defaultValues, {
        customLabel: address.label,
        label: 'Other',
      });
    }

    return DEFAULT_INITIAL_VALUES;
  };

  handleChange = values => {
    if (Object.keys(values).length && values.label) {
      let newState = {
        showCustomLabel: values.label === 'Other',
      };

      if (!this.state.showCustomLabel) {
        newState = Object.assign({}, newState, {
          animateCustomLabel: true,
        });
      }

      this.setState(newState);
    }
  };

  handleClickDelete = () => {
    const { address, onClickCancel, patient } = this.props;

    if (patient.addresses.length === 1) {
      // eslint-disable-next-line no-alert
      window.alert('Sorry! You can not delete the last address associated with a member.');
      return onClickCancel();
    }

    const addresses = sanitizeAddresses(patient.addresses);
    addresses.splice(address.index, 1);

    return this.handleMutation({ addresses });
  };

  handleMutation = async modifier =>
    alertOnError(
      this.props.mutateUpdatePatient({
        variables: {
          id: this.props.patient.id,
          modifier,
        },
      }),
    )
      .then(({ data }) => {
        this.props.patient.addresses = data.updatePatient.data.addresses.sort((addr1, addr2) =>
          addr1.order < addr2.order ? -1 : null,
        );
      })
      .then(() => this.props.onClickCancel(false));

  handlePlaceSelected = () => this.setState({ didSelectAddressFromGoogle: true });
  handleDefaultAddress = value => this.setState({ defaultAddress: value });

  handleSubmit = ({ index, ...values }) => {
    if (!values.address) validationError();

    const { didSelectAddressFromGoogle, defaultAddress } = this.state;
    const isInitialValues = Object.is(values.address, this.initialValues.address);

    if (!isInitialValues && !didSelectAddressFromGoogle) {
      throw new SubmissionError({ address: 'must be selected from dropdown' });
    }

    const addresses = sanitizeAddresses(this.props.patient.addresses);
    const { city, longitude, postalCode, state, street1 } = values.address;

    if (!city || !state) validationError();

    let label = values.label;
    if (values.label === 'Other') {
      label = values.customLabel || 'Other';
    }

    const address = {
      ...pick(values, ...fields.AddressModifier),
      ...pick(values.address, ...fields.AddressModifier),
      label,
      line1: street1,
      lon: longitude,
      postal_code: postalCode,
    };
    // in edit case index is a number
    if (defaultAddress) {
      if (Number.isInteger(index)) {
        addresses.splice(index, 1);
      }
      addresses.unshift(address);
    } else if (Number.isInteger(index)) {
      addresses.splice(index, 1, address);
    } else {
      addresses.push(address);
    }

    addresses.forEach((addrs, index) => {
      addrs.order = index;
    });

    return this.handleMutation({ addresses });
  };

  render = () => (
    <ReduxAddressForm
      {...this.props}
      animateCustomLabel={this.state.animateCustomLabel}
      initialValues={this.initialValues}
      labelOptions={ADDRESS_LABEL_MAP}
      onChange={this.handleChange}
      onClickDelete={this.handleClickDelete}
      onPlaceSelected={this.handlePlaceSelected}
      onSubmit={this.handleSubmit}
      showCustomLabel={this.state.showCustomLabel}
      onDefaultAddress={this.handleDefaultAddress}
    />
  );
}

export default graphql(patients.mutateUpdatePatient, {
  name: 'mutateUpdatePatient',
})(AddressFormContainer);
