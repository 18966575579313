import gql from 'graphql-tag';

const ADD_ENROLLER = gql`
  mutation addEnroller(
    $user_id: Int!
    $language: [LanguageEnum!]!
    $status: StatusEnum!
    $line_of_business: [LineOfBusinessEnum!]
    $team_lead_user_id: Int!
    $state: [StateEnum!]!
    $payer_id: [Int!]!
  ) {
    addEnroller(
      user_id: $user_id
      language: $language
      status: $status
      line_of_business: $line_of_business
      team_lead_user_id: $team_lead_user_id
      state: $state
      payer_id: $payer_id
    ) {
      data {
        id
        status
        line_of_business
        payer_id
        state
        user {
          id
          first_name
          last_name
          title
          user_languages {
            language
          }
        }
        team_lead_user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

export default ADD_ENROLLER;
