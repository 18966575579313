import React from 'react';

const Mute = () => (
  <svg width="15" height="23" viewBox="0 0 15 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4.17188" width="6.34483" height="13.4828" rx="3.17241" fill="white" />
    <path
      d="M1 7.93066V10.31C1 13.8141 3.84068 16.6548 7.34483 16.6548V16.6548C10.849 16.6548 13.6897 13.8141 13.6897 10.31V7.93066"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="7.55078"
      y1="17.6553"
      x2="7.55078"
      y2="22.0001"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Mute;
