import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, SplitDropdown, SplitDropdownButton } from '@zipdrug/ui';
import PreferredFollowupModal from './PreferredFollowupModal';
import PreferredFollowupMenu from './PreferredFollowupMenu';
import preferenceMap from './PreferenceMap';

const PreferredFollowupButton = ({ patient, refetchSelectedPatientInfo, ...props }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [preferredPhone, setPreferredPhone] = useState(null);

  let containerRef = null;

  const handleRef = ref => {
    containerRef = ref;
  };

  const toggleModal = () => {
    setIsVisible(!isVisible);
  };

  const handleOnToggle = isOpen => {
    setIsOpen(isOpen);
  };

  const renderMenu = () => {
    return (
      <PreferredFollowupMenu
        patient={patient}
        toggleModal={toggleModal}
        preferredPhone={preferredPhone}
      />
    );
  };

  const handlePrimaryActionClick = () => {
    if (!isOpen) {
      containerRef.toggleMenu();
    } else {
      containerRef.toggleMenu();
    }
  };

  useEffect(() => {
    if (patient) {
      const preferredPhone = patient?.phones.find(
        p => p.id === patient.preferred_phone_id && p.can_sms === true,
      );
      setPreferredPhone(preferredPhone);
    }
  }, [patient]);

  return (
    <>
      <PreferredFollowupModal
        onCloseModal={() => toggleModal()}
        patient={patient}
        refetchSelectedPatientInfo={refetchSelectedPatientInfo}
        visible={isVisible}
        preferredPhone={preferredPhone}
      />
      {
        <SplitDropdown
          {...props}
          buttonRenderer={<Button size="small" />}
          buttonStyles={SplitDropdownButton.containerStyles}
          menuRenderer={renderMenu()}
          onClick={() => handlePrimaryActionClick()}
          onToggle={isOpen => handleOnToggle(isOpen)}
          preventCloseOnOutsideClick={false}
          ref={handleRef}
          small
        >
          <SplitDropdownButton
            icon="message"
            text="Preferred Follow-Up"
            title={
              patient?.preferred_followup
                ? preferenceMap[patient?.preferred_followup].label
                : 'None'
            }
          />
        </SplitDropdown>
      }
    </>
  );
};

PreferredFollowupButton.propTypes = {
  patient: PropTypes.object,
  refetchSelectedPatientInfo: PropTypes.func,
};

export default PreferredFollowupButton;
