import { Column, Div, DropdownFooter, LinkButton } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  container: {
    width: '30rem',
    padding: '1.5rem',
  },
  list: {
    maxHeight: '50rem',
    overflowY: 'auto',
  },
});

const EmailMenu = ({ email, patient, toggleModal }) => (
  <Column styles={sx.container}>
    <strong>Email</strong>
    <Div styles={sx.list}>{patient?.emails[0]?.email ? email : 'None'}</Div>
    <DropdownFooter>
      <LinkButton onClick={toggleModal}>
        {patient?.emails[0]?.email ? 'Edit Email' : 'Add Email'}
      </LinkButton>
    </DropdownFooter>
  </Column>
);

EmailMenu.propTypes = {
  email: PropTypes.string,
  patient: PropTypes.object,
  toggleModal: PropTypes.func,
};

export default EmailMenu;
