import React from 'react';
import { formatPhone } from '@zipdrug/ui';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite/no-important';
import commonSx, { getBtnColor } from './styles';

import { PickupIcon } from './svg';

const sx = StyleSheet.create({
  phonesDropdown: {
    display: 'flex',
    align: 'flex-start',
    flexDirection: 'column',
    position: 'absolute',
    padding: '17px',
    width: '300px',
    background: 'white',
    border: '1px solid #DDDDDD',
    boxShadow: '2px 3px 3px grey',
    borderRadius: '10px',
    top: '45px',
    left: '0',
    zIndex: '999999',
    color: '#231E33',
  },
  show: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '12px',
    marginBottom: '10px',
    justifyContent: 'space-between',
    borderTop: '1px solid #D9D9D9',
  },
  firstRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'space-between',
  },
  primaryText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
  phoneText: {
    fontSize: '12px',
    marginTop: '5px',
  },
});

function PhonesDropdown({ isVisible, primaryTexts, secondaryTexts, callBtnHandler, isLoading }) {
  if (isLoading) {
    return (
      <div className={css(sx.phonesDropdown, isVisible ? sx.show : sx.hide)}>
        <LoadingOutlined style={{ fontSize: '23px', marginLeft: '25px' }} />
      </div>
    );
  }

  return (
    <div className={css(sx.phonesDropdown, isVisible ? sx.show : sx.hide)}>
      {primaryTexts.length ? (
        primaryTexts.map((ptext, i) => (
          <div key={`${ptext}${secondaryTexts[i]}`} className={css(i !== 0 ? sx.row : sx.firstRow)}>
            <div className="fs-hide">
              <div className={css(sx.primaryText)}>{ptext}</div>
              <div className={css(sx.phoneText)}>{formatPhone(secondaryTexts[i])}</div>
            </div>

            <Tooltip
              placement="bottom"
              title="Outbound Call"
              overlayClassName="tooltip-class"
              overlayStyle={{ zIndex: 99999999999 }}
            >
              <Button
                className={css(getBtnColor('brightGreen'), commonSx.square, commonSx.ml10)}
                onClick={() => {
                  callBtnHandler(i);
                }}
              >
                <PickupIcon />
              </Button>
            </Tooltip>
          </div>
        ))
      ) : (
        <div>No options available</div>
      )}
    </div>
  );
}

PhonesDropdown.propTypes = {
  isLoading: PropTypes.bool,
  isVisible: PropTypes.bool,
  primaryTexts: PropTypes.array,
  secondaryTexts: PropTypes.array,
  callBtnHandler: PropTypes.func,
};

export default PhonesDropdown;
