import PropTypes from 'prop-types';
import React from 'react';
import { Bold } from '@zipdrug/ui';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';
import RegisterFormContainer from './RegisterFormContainer';

const RegisterPage = ({ history }) => (
  <StandaloneFormPageTemplate title={<Bold>Join Zipdrug</Bold>}>
    <RegisterFormContainer history={history} />
  </StandaloneFormPageTemplate>
);

RegisterPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default RegisterPage;
