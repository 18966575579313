import { last } from 'lodash';
import appendGapSegments from './appendGapSegments';
import buildSegment from './buildSegment';
import { daysDiff } from './dateUtils';
import SegmentType from './SegmentType';

function appendNonOverlappingTherapy(segments, { firstClaimDate, previousUntilDate, therapy }) {
  if (previousUntilDate < therapy.fromDate) {
    appendGapSegments(segments, {
      firstClaimDate,
      fromDate: previousUntilDate,
      untilDate: therapy.fromDate,
    });
  }

  segments.push(buildSegment(therapy));
}

function appendOverlappingTherapy(segments, { previous, therapy }) {
  const hasPriority = previous.type === SegmentType.AlternativeFill && !therapy.isAlternative;

  const endsOnBeforePrevious = therapy.untilDate <= previous.untilDate;
  if (endsOnBeforePrevious && !hasPriority) {
    // Ignore this therapy date range since it is encompassed with the previous
    // and does not have priority over the previous fill
    return;
  }

  // The therapy will either be longer than the previous or have priority
  const previousUntilDate = previous.untilDate;

  // End the previous when this therapy starts
  /* eslint-disable no-param-reassign */
  previous.untilDate = therapy.fromDate;
  previous.daysInRange = daysDiff(previous.untilDate, previous.fromDate);
  /* eslint-enable no-param-reassign */

  // Add this segment
  segments.push(buildSegment(therapy));

  if (therapy.untilDate < previousUntilDate) {
    // Add the remainder of the previous segment
    segments.push(
      buildSegment({
        ...previous,
        fromDate: therapy.untilDate,
        untilDate: previousUntilDate,
      }),
    );
  }
}

export default function appendTherapySegments(
  segments,
  { firstClaimDate, fromDate, therapyDateRanges },
) {
  therapyDateRanges.forEach(therapy => {
    const previous = last(segments.filter(s => s.type !== SegmentType.Hole));
    const startsAfterPreviousEnds = previous && therapy.fromDate >= previous.untilDate;

    if (!previous || startsAfterPreviousEnds) {
      appendNonOverlappingTherapy(segments, {
        firstClaimDate,
        previousUntilDate: previous ? previous.untilDate : fromDate,
        therapy,
      });
      return;
    }

    appendOverlappingTherapy(segments, { previous, therapy });
  });
}
