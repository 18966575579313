import React from 'react';
import PropTypes from 'prop-types';
import SessionContextProvider from './SessionContextProvider';
import PharmacyContextProvider from './PharmacyContextProvider';
import WorkstationPhoneContextProvider from './WorkstationPhoneContextProvider';
import CallCenterContextProvider from './CallCenterContextProvider';
import LeadListProvider from './LeadListProvider';
import LeadChurnListProvider from './LeadChurnListProvider';
import PharmacyListProvider from './PharmacyListProvider';
import OutcomesProvider from './OutcomesProvider';
import MemberListProvider from './MemberListProvider';

export {
  SessionContextProvider,
  PharmacyContextProvider,
  WorkstationPhoneContextProvider,
  LeadListProvider,
};

const ContextProvider = ({ children }) => {
  return (
    <SessionContextProvider>
      <PharmacyContextProvider>
        <WorkstationPhoneContextProvider>
          <CallCenterContextProvider>
            <MemberListProvider>
              <LeadListProvider>
                <LeadChurnListProvider>
                  <PharmacyListProvider>
                    <OutcomesProvider>{children}</OutcomesProvider>
                  </PharmacyListProvider>
                </LeadChurnListProvider>
              </LeadListProvider>
            </MemberListProvider>
          </CallCenterContextProvider>
        </WorkstationPhoneContextProvider>
      </PharmacyContextProvider>
    </SessionContextProvider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node,
};

export default React.memo(ContextProvider);
