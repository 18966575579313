import { Bold, Spinner } from '@zipdrug/ui';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import LoginFormContainer from './LoginFormContainer';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';
import { NODE_API_URL, API_HTTP_URL } from '../settings.js';
import SiteDownContainer from './SiteDownContainer';

const LoginPage = ({ history }) => {
  const [isAnthemEmployee, setIsAnthemEmployee] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSiteDown, setIsSiteDown] = useState(false);

  const checkAnthemEmployee = async () => {
    const root = NODE_API_URL || API_HTTP_URL.replace('/graphql', '');
    const ipUrl = `${root}/get-ip`;
    return fetch(ipUrl, {
      method: 'GET',
      credentials: 'include',
    })
      .then(async response => {
        if (![401, 200].includes(response?.status)) {
          setIsSiteDown(true);
        } else {
          const jsonResponse = await response.json();
          if (jsonResponse?.ip) {
            setIsAnthemEmployee(true);
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsSiteDown(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    checkAnthemEmployee();
  }, []);

  const renderTitle = () => {
    if (isLoading) {
      return '';
    } else if (isSiteDown) {
      return <Bold>The site is currently down</Bold>;
    } else if (isAnthemEmployee) {
      return 'Sign in to Care Manager';
    } else if (!isAnthemEmployee) {
      return 'Sign in to Care Control';
    }
  };

  const renderPage = () => {
    if (isLoading) {
      return <Spinner loading />;
    } else if (isSiteDown) {
      return <SiteDownContainer history={history} withSiteBypass={false} />;
    }
    return <LoginFormContainer history={history} isAnthemEmployee={isAnthemEmployee} />;
  };

  return (
    <StandaloneFormPageTemplate title={renderTitle()}>{renderPage()}</StandaloneFormPageTemplate>
  );
};

LoginPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default LoginPage;
