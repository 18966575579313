import { withSession } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRoles from './withRoles';
import { queryPharmacies } from '../graphql/pharmacies';

const withActivePharmacies = Component =>
  graphql(queryPharmacies, {
    skip: ({ userId, hasRole: { pharmacistUser, pharmacistAdmin } }) =>
      !userId || (pharmacistUser && pharmacistAdmin),
    options: ({ query, pharmacyNetworkTypes, plan_id }) => ({
      fetchPolicy: 'network-only',
      variables: {
        query: {
          ...query,
          pharmacyNetworkTypes,
          plan_id,
        },
        // TODO implement paging
        limit: 1000,
      },
    }),
    props: ({ data: { pharmacies: result } }) => ({
      activePharmacies: get(result, 'data'),
    }),
  })(Component);

export default Component => compose(withSession, withRoles, withActivePharmacies)(Component);
