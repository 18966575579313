import { Bold, colors, EnDash, Nbsp, Span } from '@zipdrug/ui';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DeliveryActivityItem from './DeliveryActivityItem';

const ScheduledDeliveryActivityItem = ({
  activity: {
    created_at,
    delivery: {
      complete_after,
      complete_before,
      created_by_user,
      destination_address,
      note,
      short_id,
    },
  },
  ...props
}) => {
  const addressLabel = get(destination_address, 'label', 'destination');
  const completeBeforeMoment = moment(complete_before);

  const dateTimeLabel = (
    <Span>
      {completeBeforeMoment.format('MMMM D, YYYY')}
      {complete_after && (
        <Fragment>
          <Span>
            <Nbsp />
            between
            <Nbsp />
          </Span>
          <Span>
            {moment(complete_after).format('h:mma')}
            <Nbsp />
            <EnDash />
            <Nbsp />
            {completeBeforeMoment.format('h:mma')}
          </Span>
        </Fragment>
      )}
    </Span>
  );

  return (
    <DeliveryActivityItem
      {...props}
      color={colors.gray80}
      content={note}
      contentHeading={note ? 'Delivery Note' : null}
      createdAt={created_at}
      footerName={created_by_user}
      heading={
        <Span>
          was <Bold>scheduled</Bold> for {addressLabel} on {dateTimeLabel}.
        </Span>
      }
      id="e2e-scheduledDeliveryItem"
      short_id={short_id}
    />
  );
};

ScheduledDeliveryActivityItem.propTypes = {
  activity: PropTypes.object,
};

export default ScheduledDeliveryActivityItem;
