import {
  colors,
  dateUtils,
  Dot,
  font,
  margin,
  rem,
  Row,
  space,
  Span,
  TimelineSegment,
  unit,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import fillTimelineDashedLine from './fill_timeline_dashed_line.svg';
import SegmentType from './SegmentType';

const DOT_SIZE = 8;

const sx = StyleSheet.create({
  tooltipDot: {
    ...margin.build(0, rem(unit(font.size.xSmall) / 2)),
    backgroundColor: colors.alpha(colors.white, 0.25),
  },
});

const formatTooltipDate = date =>
  moment(date)
    .utc()
    .format('MMM D');

export default class FillTimelineSegment extends Component {
  static displayName = 'FillTimelineSegment';

  static propTypes = {
    brand_name: PropTypes.string,
    daysInRange: PropTypes.number.isRequired,
    fromDate: PropTypes.number.isRequired,
    styles: aphroditePropType,
    type: PropTypes.oneOf(Object.values(SegmentType)).isRequired,
    untilDate: PropTypes.number.isRequired,
  };

  buildInlineStyle = () => {
    const { daysInRange, untilDate } = this.props;

    let color = colors.green;
    if (this.isType(SegmentType.Untracked)) color = colors.gray92;
    if (this.isType(SegmentType.Hole)) color = colors.red;

    return {
      color,
      width: `${(daysInRange / dateUtils.daysInYear(untilDate)) * 100}%`,
    };
  };

  buildLineInlineStyle = () => ({
    backgroundColor: this.isType(SegmentType.AlternativeFill) ? colors.transparent : colors.current,
    backgroundImage: this.isType(SegmentType.AlternativeFill)
      ? `url(${fillTimelineDashedLine})`
      : null,
  });

  buildTooltipProps = () => {
    const { daysInRange, fromDate, brand_name, untilDate } = this.props;

    if (this.isType(SegmentType.Untracked)) return null;

    let title;
    let text;
    const formattedFromDate = formatTooltipDate(fromDate);

    if (this.isType(SegmentType.AlternativeFill)) {
      title = `${daysInRange}-day fill for therapeutic alternative`;
      text = (
        <Row align="center">
          <Span>{formattedFromDate}</Span>
          {brand_name && (
            <Fragment>
              <Dot noTransition size={space.fifth} styles={sx.tooltipDot} />
              <Span>{brand_name}</Span>
            </Fragment>
          )}
        </Row>
      );
    } else if (this.isType(SegmentType.Hole)) {
      title = `Missed ${daysInRange} days`;
      text = `${formattedFromDate} - ${formatTooltipDate(untilDate)}`;
    } else {
      text = formattedFromDate;
      title = `${daysInRange}-day fill`;
    }

    return {
      // Align tooltip arrow to center of "fillDot",
      // or if segment is a hole, align tooltip arrow to center of segment.
      offset: this.isType(SegmentType.Hole) ? null : `-50% + ${DOT_SIZE / 2} + 0.5px`,
      title,
      text,
    };
  };

  isType = type => this.props.type === type;

  render = () => (
    <TimelineSegment
      disableHover={this.isType(SegmentType.Untracked)}
      lineStyle={this.buildLineInlineStyle()}
      showFillDot={this.isType('fill')}
      style={this.buildInlineStyle()}
      styles={[sx.container, this.props.styles]}
      tooltipProps={this.buildTooltipProps()}
    />
  );
}
