import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  Role,
  isRole,
  isSystemAdmin,
  isAdmin,
  isPbsTeam,
  isPharmacyTeam,
  isPharmacistTeam,
  isProjectManager,
} from '../contexts/utils/models';

export const SessionContext = React.createContext();

const prefix = type => `@SESSION/${type}`;

export const ActionTypes = {
  SET_CURRENT_USER: prefix('SET_CURRENT_USER'),
  ERROR: prefix('ERROR'),
};

const initialState = {
  currentUser: null,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_USER: {
      const { payload: data } = action;
      const user = get(data, 'currentUser.data');
      return {
        ...state,
        currentUser: {
          ...user,
          hasRole: {
            pbsUser: isRole(user, [Role.PbsUser]),
            pbsLead: isRole(user, [Role.PbsLead]),
            pbsAdmin: isRole(user, [Role.PbsAdmin]),
            pharmacyBusinessDev: isRole(user, [Role.PharmacyBusinessDev]),
            pharmacyBusinessDevAdmin: isRole(user, [Role.PharmacyBusinessDevAdmin]),
            pharmacyAsc: isRole(user, [Role.PharmacyAsc]),
            pharmacyAscAdmin: isRole(user, [Role.PharmacyAscAdmin]),
            pharmacistUser: isRole(user, [Role.PharmacistUser]),
            pharmacistAdmin: isRole(user, [Role.PharmacistAdmin]),
            systemAdmin: isRole(user, [Role.SystemAdmin]),
            projectManager: isRole(user, [Role.ProjectManager]),
          },
          isAdmin: isAdmin(user),
          isSystemAdmin: isSystemAdmin(user),
          isPbsTeam: isPbsTeam(user),
          isPharmacistTeam: isPharmacistTeam(user),
          isPharmacyTeam: isPharmacyTeam(user),
          isProjectManager: isProjectManager(user),
        },
        error: null,
      };
    }
    case ActionTypes.ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      throw new Error();
  }
};

const SessionContextProvider = ({ children, ready, userId }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SessionContext.Provider
      value={{
        ready,
        userId,
        state,
        dispatch,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    ready: get(state, 'session.ready'),
    userId: get(state, 'session.user.id', null),
  };
};

SessionContextProvider.propTypes = {
  ready: PropTypes.bool,
  userId: PropTypes.number,
  children: PropTypes.node,
};

export default connect(mapStateToProps)(React.memo(SessionContextProvider));
