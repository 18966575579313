import { Column, FlexItem, font, Div, margin, media, padding, Row, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import AppNav from './AppNav';
import Page from './Page';

const sx = StyleSheet.create({
  container: {
    ...margin.build(0),
    ...padding.build(space.two, space.default, space.default, space.default),
    width: '100%',

    [media.mobile.medium]: {
      ...margin.build(0, 'auto'),
      ...padding.build(space.four, 0, 0, 0),
      maxWidth: '34rem',
    },
  },
  content: {
    width: '100%',
  },
  title: {
    fontSize: '2.6rem',
    lineHeight: font.lineHeight.looser,
    marginBottom: space.oneAndTwoTenths,
    fontWeight: font.weight.medium,
    width: '100%',
    color: '#231E33',
  },
});

const StandaloneFormPageTemplate = ({ children, title }) => (
  <Page align="center" direction="column" standalone>
    <AppNav />
    <Row component={FlexItem} styles={sx.content}>
      <Column align="stretch" styles={sx.container}>
        <Div styles={sx.title}>{title}</Div>
        {children}
      </Column>
    </Row>
  </Page>
);

StandaloneFormPageTemplate.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
};

export default StandaloneFormPageTemplate;
