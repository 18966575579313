import gql from 'graphql-tag';

const ADD_CHAIN_PHARMACY = gql`
  mutation addChainPharmacy($npi: Float!, $parentNpi: Float!) {
    addChainPharmacy(npi: $npi, parentNpi: $parentNpi) {
      data {
        id
        npi
        parent_pharmacy_npi
      }
    }
  }
`;
export default ADD_CHAIN_PHARMACY;
