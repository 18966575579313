import gql from 'graphql-tag';

const GET_ENROLLERS_FOR_CAMPAIGNS = gql`
  query getEnrollersForCampaigns($skip: Int) {
    getEnrollersForCampaigns(skip: $skip) {
      data {
        id
        email
        first_name
        last_name
        enroller_id
      }
    }
  }
`;

export default GET_ENROLLERS_FOR_CAMPAIGNS;
