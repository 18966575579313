import gql from 'graphql-tag';

const SEND_NEW_PIN = gql`
  mutation sendNewPin($email: String!) {
    sendNewPin(email: $email) {
      data
      errors
    }
  }
`;

export default SEND_NEW_PIN;
