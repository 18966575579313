import React, { useState, useRef, useContext, useEffect } from 'react';
import _get from 'lodash/get';
import { useMutation } from '@apollo/react-hooks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SessionContext } from 'contexts/SessionContextProvider';
import { setPatientId } from '../redux/queryReducer.js';
import { UPDATE_CALL_CENTER_USER_STATUS } from '../ui/CallCenter/queries';

export const CallCenterContext = React.createContext();

const CallCenterContextProvider = ({ children, patientId, setCurrentPatientId }) => {
  const {
    state: { currentUser },
  } = useContext(SessionContext);
  const [pharmacyStatus, setPharmacyStatus] = useState(null);
  const [pharmacyReassigned, setPharmacyReassigned] = useState(null);
  const [outboundToPhoneIdContext, setOutboundToPhoneIdContext] = useState(null);
  const [isCallCenterActivatedContext, setIsCallCenterActivatedContext] = useState(false);
  const [isPatientChurnedAlreadyCalledContext, setisPatientChurnedAlreadyCalledContext] = useState(
    false,
  );
  const [callCenterUserStatus, setCallCenterUserStatus] = useState(
    _get(currentUser, 'call_center_user_status'),
  );
  const [supervisingCall, setSupervisingCall] = useState(null);
  const [callId, setCallId] = useState(null);
  const deregisterTwilioRef = useRef(false);
  const lastCallCenterStatusRef = useRef(null);

  const [updateCallCenterUserStatusMutation] = useMutation(UPDATE_CALL_CENTER_USER_STATUS, {
    onError: error => {
      // eslint-disable-next-line
      console.error(`Error when updating call center user status: ${error.message}`);
    },
  });

  const [updateCallCenterUserStatusOfflineMutationContext] = useMutation(
    UPDATE_CALL_CENTER_USER_STATUS,
    {
      variables: {
        callCenterUserStatus: 'offline',
      },
    },
  );

  useEffect(() => {
    setisPatientChurnedAlreadyCalledContext(false);
  }, [patientId]);

  useEffect(() => {
    if (_get(currentUser, 'call_center_user_status')) {
      setCallCenterUserStatus(_get(currentUser, 'call_center_user_status'));
    }

    // Check if the user status from the back end is set to offline. We have a cron job that updates the status to
    // offline when there is no activity. We set the status to unavailable to reflect the correct value.
    if (
      _get(currentUser, 'feature_flags.callCenter') &&
      _get(currentUser, 'call_center_user_status') === 'offline'
    ) {
      updateCallCenterStatusMutationContext('unavailable');
    }
  }, [currentUser]);

  async function updateCallCenterStatusMutationContext(status, callId = null) {
    setCallId(callId);

    await updateCallCenterUserStatusMutation({
      variables: {
        callCenterUserStatus: status,
        callId,
      },
    });

    // We only want to save status other than oncall or postcall. This is needed to keep track of the last status before
    // going on a oncall status so we know how to restore the status after the call.
    if (status !== 'oncall' && status !== 'postcall') {
      lastCallCenterStatusRef.current = status;
    }

    setCallCenterUserStatus(status);
  }

  return (
    <CallCenterContext.Provider
      value={{
        pharmacyStatus,
        setPharmacyStatus,
        pharmacyReassigned,
        setPharmacyReassigned,
        outboundToPhoneIdContext,
        setOutboundToPhoneIdContext,
        isCallCenterActivatedContext,
        setIsCallCenterActivatedContext,
        isPatientChurnedAlreadyCalledContext,
        setisPatientChurnedAlreadyCalledContext,
        selectedPatientId: patientId,
        setSelectedPatientId: setCurrentPatientId,
        callCenterUserStatusContext: callCenterUserStatus,
        setCallCenterUserStatusContext: setCallCenterUserStatus,
        updateCallCenterStatusMutationContext,
        deregisterTwilioRefContext: deregisterTwilioRef,
        lastCallCenterStatusRefContext: lastCallCenterStatusRef,
        updateCallCenterUserStatusOfflineMutationContext,
        supervisingCallContext: supervisingCall,
        setSupervisingCallContext: setSupervisingCall,
        callIdContext: callId,
      }}
    >
      {children}
    </CallCenterContext.Provider>
  );
};

const mapDispatchToProps = dispatch => ({
  setCurrentPatientId: patient_id => {
    dispatch(setPatientId({ patient_id }));
  },
});

const mapStateToProps = state => {
  const { query } = state;
  return {
    patientId: query.all.patient_id,
  };
};

CallCenterContextProvider.propTypes = {
  children: PropTypes.node,
  patientId: PropTypes.number,
  setCurrentPatientId: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CallCenterContextProvider));
