import gql from 'graphql-tag';

const GET_DSA_AUTOCOMPLETE = gql`
  query users($skip: Int, $limit: Int, $query: JSON) {
    users(skip: $skip, limit: $limit, query: $query) {
      data {
        id
        first_name
        last_name
      }
    }
  }
`;

export default GET_DSA_AUTOCOMPLETE;
