import React from 'react';

const UnholdIcon = () => (
  <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="7.5"
      y1="1.5"
      x2="7.5"
      y2="15.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <line
      x1="14.5"
      y1="1.5"
      x2="14.5"
      y2="15.5"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path d="M3.37305 1L20.9999 14.4804" stroke="#035E01" strokeWidth="2" strokeLinecap="round" />
    <path d="M2 2.51953L19.6269 15.9999" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default UnholdIcon;
