import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, AutoComplete, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { StyleSheet, css } from 'aphrodite';

const { Text } = Typography;

const sx = StyleSheet.create({
  autoComplete: { width: '188px', marginBottom: '8px', display: 'block' },
  searchContainer: {
    padding: '8px',
    maxWidth: 204,
  },
  searchButton: { width: '90px' },
  error: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#ff4d4f',
  },
  errorContainer: {
    paddingTop: 8,
  },
});

const SearchBox = ({
  dataIndex,
  placeholder,
  onSearch,
  onReset,
  onFilterSelection,
  options,
  value,
  defaultValue,
}) => {
  const [hasErrors, setHasErrors] = useState(false);
  const [errorValue, setErrorValue] = useState('');

  const removeErrors = () => {
    setHasErrors(false);
    setErrorValue('');
  };

  const handleOnSelect = (searchValue = value, option) => {
    if (dataIndex === 'zipcode' && value?.length < 3 && value !== defaultValue) {
      setHasErrors(true);
      setErrorValue(value);
    } else {
      onFilterSelection(searchValue, option);
      removeErrors();
    }
  };

  const handleSearch = value => {
    if (value !== errorValue) {
      removeErrors();
    }
    onSearch(value);
  };

  const handleReset = () => {
    removeErrors();
    onReset();
  };

  return (
    <div className={css(sx.searchContainer)}>
      <AutoComplete
        className={css(sx.autoComplete)}
        placeholder={placeholder}
        value={value}
        onSearch={value => handleSearch(value)}
        onSelect={(value, option) => {
          onFilterSelection(value, option);
        }}
        options={options}
        defaultValue={defaultValue}
        status={hasErrors ? 'error' : ''}
      />
      <Space>
        <Button
          type="primary"
          onClick={handleOnSelect}
          icon={<SearchOutlined />}
          size="small"
          className={css(sx.searchButton)}
          disabled={value?.length === 0 || hasErrors}
        >
          Search
        </Button>
        <Button onClick={handleReset} size="small" className={css(sx.searchButton)}>
          Reset
        </Button>
      </Space>
      {hasErrors && (
        <div className={css(sx.errorContainer)}>
          <Text className={css(sx.error)}>Zip code must be at least 3 digits.</Text>
        </div>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  dataIndex: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.array]),
  onFilterSelection: PropTypes.func,
  onSearch: PropTypes.func,
  onReset: PropTypes.func,
  defaultValue: PropTypes.string,
};

export default SearchBox;
