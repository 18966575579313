import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import { Heading, padding, Row, space } from '@zipdrug/ui';

const PAGE_PADDING = space.get(1.5);

const sx = StyleSheet.create({
  container: padding.build(PAGE_PADDING, 0, PAGE_PADDING, PAGE_PADDING),
});

const PageHeading = ({ children, styles, title, ...props }) => (
  <Row {...props} align="center" justify="space-between" styles={[sx.container, styles]}>
    <Heading>{title}</Heading>
    {children}
  </Row>
);

PageHeading.propTypes = {
  children: PropTypes.node,
  styles: aphroditePropType,
  title: PropTypes.string.isRequired,
};

export default PageHeading;
