/* eslint-disable no-case-declarations */
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';

const generateQuery = filters => {
  if (!filters) {
    return {};
  }
  return {
    query: Object.entries(filters).reduce((acc, curr) => {
      if (curr[1]) {
        switch (curr[0]) {
          case 'address':
            acc.addresses = {
              ...acc?.addresses,
              $or: _flatten(
                _map(['city', 'line1', 'line2', 'postal_code'], item => {
                  return _map(curr[1].split(' '), q => {
                    return { [item]: { $ilike: `%${q}%` } };
                  });
                }),
              ),
            };
            return acc;
          case 'state':
            acc.addresses = {
              ...acc?.addresses,
              state: {
                $in: curr[1],
              },
            };
            return acc;
          case 'phones':
            // remove all non-numeric characters for queries such as 1(800)-454-3434
            curr[1] = curr[1].replace(/\D+/g, '');
            acc.phones = {
              number: {
                $ilike: `%${curr[1]}%`,
              },
              label: 'Main Line',
            };
            return acc;
          case 'pharmacy_networks':
            acc.pharmacyNetworkTypes = {
              lob: curr[1],
            };
            return acc;
          case 'name':
            acc[curr[0]] = {
              $ilike: `%${curr[1]}%`,
            };
            return acc;
          case 'npi':
            acc.npi = curr[1];
            return acc;
          case 'postal_codes':
            acc.postalCodes = {
              postal_code: {
                $ilike: `${curr[1]}`,
              },
            };
            return acc;
          default:
            return acc;
        }
      }
      return acc;
    }, {}),
  };
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateVariables = actions => {
  const { filters, pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  // Generate Order_By
  // const order_by = sorter ? generateOrderBy(sorter) : {};
  // Generate Query Variable
  const query = generateQuery(filters);

  return {
    ...pageOptions,
    ...query,
  };
};

export default generateVariables;
