/* eslint-disable react/jsx-indent */
import { colors, Row, space } from '@zipdrug/ui';
import { Button, Skeleton, Space, Input } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';
import withRoles from 'hoc/withRoles';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  actions: {
    marginTop: space.default,
  },
  label: {
    margin: 0,
    fontSize: 12,
    color: 'black',
  },
  ticket_box: {
    marginLeft: 15,
  },
  row: {
    alignItems: 'flex-end !important',
  },
  btnStyle: {
    color: `${colors.bluePurple} !important`,
    borderColor: `${colors.bluePurple} !important`,
  },
});

const BUTTON_PROPS = {
  outline: true,
  rounded: true,
  size: 'medium',
};

const TeamListItemActions = ({
  enrollerCheck,
  isCheckingEnrollers,
  onClickEdit,
  onDeactivateUser,
  onResendInvitation,
  onToggleEligibleInbound,
  user,
  userIsAdmin,
  currentUser,
  isAdmin,
  isExternalUser,
  isSystemAdminOrAsc,
  onAddingTicketNumber,
  deleteError,
  isPbsOrSystemAdmin,
  ...props
}) => {
  return (
    <Row {...props} justify="space-between" styles={sx.actions} className={css(sx.row)}>
      {!isCheckingEnrollers ? (
        <>
          <Space justify="start">
            {!user.joined_at && isSystemAdminOrAsc && isExternalUser && (
              <Button {...BUTTON_PROPS} onClick={onResendInvitation}>
                Resend invitation
              </Button>
            )}
            {user.joined_at && isSystemAdminOrAsc && isExternalUser && (
              <Button {...BUTTON_PROPS} onClick={onClickEdit}>
                Edit profile
              </Button>
            )}
            {user.joined_at && isAdmin && isPbsOrSystemAdmin && (
              <Button {...BUTTON_PROPS} className="btn-style" onClick={onToggleEligibleInbound}>
                {user.inbound_eligible
                  ? 'Remove Inbound Eligibility'
                  : `Make user inbound eligible`}
              </Button>
            )}
          </Space>
          {isSystemAdminOrAsc && (
            <Row className={css(sx.row)}>
              <Button
                {...BUTTON_PROPS}
                onClick={onDeactivateUser}
                danger
                disabled={!isSystemAdminOrAsc}
                {...props}
              >
                {`Delete ${user.joined_at ? 'member' : 'invitation'}`}
              </Button>
              <div className={css(sx.ticket_box)}>
                <p className={css(sx.label)}>ZD Pharmacy User Group Access Ticket Number</p>
                <Input
                  status={deleteError && 'error'}
                  placeholder="REQ..."
                  onChange={onAddingTicketNumber}
                />
              </div>
            </Row>
          )}
        </>
      ) : (
        <>
          <Space justify="start">
            <Skeleton.Button active size={32} style={{ width: 101 }} shape="default" />
            <Skeleton.Button active size={32} style={{ width: 210 }} shape="default" />
          </Space>
          <Space justify="end">
            <Skeleton.Button active size={32} style={{ width: 133 }} shape="default" />
          </Space>
        </>
      )}
    </Row>
  );
};

TeamListItemActions.propTypes = {
  enrollerCheck: PropTypes.bool,
  isCheckingEnrollers: PropTypes.bool,
  onClickEdit: PropTypes.func,
  onToggleEligibleInbound: PropTypes.func,
  onDeactivateUser: PropTypes.func,
  onResendInvitation: PropTypes.func,
  currentUser: PropTypes.object,
  user: PropTypes.shape({
    joined_at: PropTypes.instanceOf(Date),
    inbound_eligible: PropTypes.bool,
    roles: PropTypes.array,
  }),
  userIsAdmin: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isExternalUser: PropTypes.bool,
  isSystemAdminOrAsc: PropTypes.bool,
  onAddingTicketNumber: PropTypes.func,
  deleteError: PropTypes.bool,
  isPbsOrSystemAdmin: PropTypes.bool,
};

export default withRoles(TeamListItemActions);
