import {
  ArrowButton,
  Button,
  Div,
  ErrorMessage,
  font,
  FormTextField,
  Row,
  space,
} from '@zipdrug/ui';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { StyleSheet } from 'aphrodite';

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters` : undefined;
const maxLength6 = maxLength(6);
const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters` : undefined;
const minLength6 = minLength(6);

const sx = StyleSheet.create({
  arrowButton: {
    flex: 'none',
    marginBottom: space.oneAndTwoTenths,
    width: '100%',
    fontWeight: font.weight.medium,
  },
  formField: {
    marginBottom: space.oneAndTwoTenths,
  },
  secondaryActionRow: {
    marginBottom: space.oneAndTwoTenths,
  },
  secondaryText: {
    color: '#919191',
    marginBottom: space.oneAndTwoTenths,
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const PinLogin = ({
  error,
  handleSubmit,
  onResendPin,
  onFormFieldKeyUp,
  submitFailed,
  submitting,
  resending,
  pinError,
}) => {
  return (
    <Fragment>
      <Div styles={sx.secondaryText}>Check your email for a new PIN</Div>
      <FormTextField
        {...FORMFIELD_PROPS}
        id="e2e-pinLoginNumberField"
        label="Enter PIN"
        name="pin"
        onKeyUp={onFormFieldKeyUp}
        maxLength={6}
        validate={[maxLength6, minLength6]}
      />
      {(error || pinError) && submitFailed && (
        <ErrorMessage styles={sx.formField}>{error || pinError}</ErrorMessage>
      )}
      <ArrowButton
        id="e2e-pinLoginSubmitButton"
        loading={submitting}
        onClick={handleSubmit}
        styles={sx.arrowButton}
        text="Submit"
      />
      <Row styles={sx.secondaryActionRow}>
        <Button
          loading={resending}
          onClick={onResendPin}
          outline
          size="micro"
          text="Resend PIN"
          type="primary"
        />
      </Row>
    </Fragment>
  );
};

PinLogin.propTypes = {
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onFormFieldKeyUp: PropTypes.func,
  onResendPin: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
  resending: PropTypes.bool,
  pinError: PropTypes.string,
};

export default compose(
  withHandlers({
    onFormFieldKeyUp: ({ handleSubmit }) => event => {
      if (event.which === 13) {
        // Submit form if user presses "Enter" key
        event.preventDefault();
        handleSubmit();
      }
    },
  }),
)(PinLogin);
