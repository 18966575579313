import gql from 'graphql-tag';

const GET_COHORT_FILTERS = gql`
  query getAllCohortData($cohort_parents_id: Int!) {
    getAllCohortData(cohort_parents_id: $cohort_parents_id) {
      data {
        pharmacy_name
        plan_name
        group_name
      }
    }
  }
`;

export default GET_COHORT_FILTERS;
