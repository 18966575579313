import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import PropTypes from 'prop-types';
import withCurrentUser from '../../../hoc/withCurrentUser';
import CohortTable from './components/CohortTable/CohortTable';

const sx = StyleSheet.create({
  cohortListContainer: {
    padding: '15px',
  },
});

const CohortList = ({ currentUser }) => {
  return (
    <div className={css(sx.cohortListContainer)}>
      <CohortTable user={currentUser} />
    </div>
  );
};

CohortList.propTypes = {
  currentUser: PropTypes.object,
};

export default withCurrentUser(CohortList);
