import gql from 'graphql-tag';

const DISMISS_FOLLOWUP = gql`
  mutation dismissRequiresFollowup($patientId: Int!) {
    dismissRequiresFollowup(patientId: $patientId) {
      data {
        active_at
        birthday
        enroll_status
        first_name
        id
        is_dnc
        language
        last_name
        latest_alert_at
        snoozed_until
        event_source
        special_notes
        dismiss_requires_followup
      }
    }
  }
`;

export default DISMISS_FOLLOWUP;
