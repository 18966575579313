import React from 'react';

const MarkNew = props => (
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1" y="3" width="19" height="15" rx="3" stroke="#5A2FD4" strokeWidth="2" />
    <path
      d="M2 4.5L9.71963 10.933C10.4613 11.5511 11.5387 11.5511 12.2804 10.933L20 4.5"
      stroke="#5A2FD4"
      strokeWidth="2"
    />
    <circle cx="20.5" cy="3.5" r="3.5" fill="#5A2FD4" />
  </svg>
);

export default MarkNew;
