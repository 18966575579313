import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_POTENTIAL_PHARMACY_CODES = gql`
  query potentialPostalCodes($limit: Int, $query: JSON, $skip: Int) {
    potentialPostalCodes(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        name
        pharmacy_id
        potential_postal_code
      }
      limit
      skip
    }
  }
`;

export const BULK_ADD_POTENTIAL_POSTAL_CODES = gql`
  mutation bulkAddPotentialPostalCodes($pharmacy_id: Int!, $postal_codes: [String]!) {
    bulkAddPotentialPostalCodes(pharmacy_id: $pharmacy_id, postal_codes: $postal_codes) {
      data {
        id
        pharmacy_id
        potential_postal_code
      }
    }
  }
`;

export const BULK_ADD_ASSIGNED_POSTAL_CODES = gql`
  mutation bulkAddPostalCodes($payer_id: Int!, $pharmacy_id: Int!, $postal_codes: [String]!) {
    bulkAddPostalCodes(
      payer_id: $payer_id
      pharmacy_id: $pharmacy_id
      postal_codes: $postal_codes
    ) {
      data {
        id
        pharmacy_id
        postal_code
      }
    }
  }
`;

export const GET_ZIPCODE_COVERAGE_REPORT_FILE = gql`
  query getZipcodeCoverageReportFile {
    getZipcodeCoverageReportFile {
      skip
      data {
        file_url
      }
    }
  }
`;

export const GET_POTENTIAL_AND_ASSIGNED_POSTALCODES = gql`
  query potentialAndAssignedPostalCodes($limit: Int, $query: JSON, $skip: Int) {
    potentialAndAssignedPostalCodes(limit: $limit, query: $query, skip: $skip) {
      data {
        postal_code
        state
        potentialPharmacies {
          id
          zipcodeId
          name
          npi
        }
        assignedPharmacies {
          id
          zipcodeId
          name
          npi
          payer {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export const GET_POSTALCODE_LIST = gql`
  query postalCodes($limit: Int, $query: JSON, $skip: Int) {
    postalCodes(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        postal_code
        pharmacy_id
        payer {
          id
          name
        }
        pharmacy {
          id
          name
          npi
          patients {
            id
          }
        }
      }
    }
  }
`;

export const GET_POSTAL_CODES = gql`
  query getPostalCodesByFilter($limit: Int, $filter: String) {
    getPostalCodesByFilter(limit: $limit, filter: $filter) {
      data
      errors
      total
    }
  }
`;

export const PHARMACY_NAMES = gql`
  query pharmacyNames($limit: Int, $query: JSON, $skip: Int, $zipdrugOnly: Boolean) {
    pharmacyNames(limit: $limit, query: $query, skip: $skip, zipdrugOnly: $zipdrugOnly) {
      data {
        id
        name
        npi
      }
    }
  }
`;

export const GET_PAYERS = gql`
  query payers($query: JSON) {
    payers(query: $query) {
      data {
        id
        name
      }
    }
  }
`;

export const GET_STATE_FROM_ZIPCODE = gql`
  mutation transformZipcodeToState($postal_codes: [String]!) {
    transformZipcodeToState(postal_codes: $postal_codes) {
      data
    }
  }
`;

export const DELETE_POTENTIAL_PHARMACY = gql`
  mutation deletePotentialPostalCode($id: Int!) {
    deletePotentialPostalCode(id: $id) {
      data
    }
  }
`;

export const DELETE_ASSIGNED_PHARMACY = gql`
  mutation deletePostalCode($id: Int!) {
    deletePostalCode(id: $id) {
      data
    }
  }
`;

export const GET_PATIENT_COUNT_BY_POSTAL_CODE = gql`
  query getPatientCountWithPostalCoverage($postalCode: String!, $pharmacyId: Int!) {
    getPatientCountWithPostalCoverage(postalCode: $postalCode, pharmacyId: $pharmacyId) {
      data
    }
  }
`;

export const BULK_DELETE_POSTAL_CODES = gql`
  mutation bulkDeletePostalCodes($payer_id: Int!, $pharmacy_id: Int!, $postal_codes: [String]!) {
    bulkDeletePostalCodes(
      payer_id: $payer_id
      pharmacy_id: $pharmacy_id
      postal_codes: $postal_codes
    ) {
      data
    }
  }
`;

export const BULK_DELETE_POTENTIAL_POSTAL_CODES = gql`
  mutation bulkDeletePotentialPostalCodes($pharmacy_id: Int!, $postal_codes: [String]!) {
    bulkDeletePotentialPostalCodes(pharmacy_id: $pharmacy_id, postal_codes: $postal_codes) {
      data
    }
  }
`;
