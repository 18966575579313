import React from 'react';

const CPESNIcon = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ZipDrug-Enhanced" stroke="none" fill="none">
      <g id="Badges" transform="translate(-20.000000, -20.000000)">
        <g id="CPSEN-Badge" transform="translate(20.000000, 20.000000)">
          <circle id="Base" stroke="#231E33" fill="#6A296A" cx="8" cy="8" r="7.5" />
          <g id="C" transform="translate(5.000000, 4.000000)" fill="#FFFFFF">
            <path
              d="M5.98990749,5.29946524 C5.93271657,6.1372549 5.64087468,6.79679144 5.11438183,7.27807487 C4.58788898,7.75935829 3.8940286,8 3.03280067,8 C2.09083263,8 1.34987384,7.66399287 0.809924306,6.99197861 C0.269974769,6.31996435 0,5.39750446 0,4.22459893 L0,3.7486631 C0,3 0.124474348,2.34046346 0.373423045,1.77005348 C0.622371741,1.19964349 0.978132885,0.762032086 1.44070648,0.457219251 C1.90328007,0.152406417 2.44070648,0 3.0529857,0 C3.90075694,0 4.58368377,0.240641711 5.10176619,0.721925134 C5.61984861,1.20320856 5.91925988,1.87878788 6,2.7486631 L4.48612279,2.7486631 C4.4491169,2.2459893 4.31707317,1.88146168 4.08999159,1.65508021 C3.86291001,1.42869875 3.51724138,1.31550802 3.0529857,1.31550802 C2.54835997,1.31550802 2.17073171,1.50713012 1.92010093,1.89037433 C1.66947014,2.27361854 1.54079058,2.86809269 1.53406224,3.67379679 L1.53406224,4.26203209 C1.53406224,5.10338681 1.65433137,5.71836007 1.89486964,6.10695187 C2.13540791,6.49554367 2.51471825,6.68983957 3.03280067,6.68983957 C3.50042052,6.68983957 3.84945332,6.57664884 4.07989907,6.35026738 C4.31034483,6.12388592 4.44238856,5.77361854 4.47603028,5.29946524 L5.98990749,5.29946524 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CPESNIcon;
