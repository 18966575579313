import { DropdownFooter, LinkButton } from '@zipdrug/ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import InputMask from 'react-input-mask';
import { isEmpty, find } from 'lodash';
import formatPhoneNumber from 'dashboard/components/PharmacyList/utils/FormatPhone.utils';

import { GuardianDropdownLabel } from './GuardianMenu';
import UPDATE_PATIENT_GUARDIAN from '../graphql/updatePatientGuardian';

const sx = StyleSheet.create({
  container: {
    position: 'relative',
    width: '29rem',
  },
  content: {
    padding: '1rem 1rem 0 1rem',
    width: '100%',
    position: 'relative',
  },
  inputStyles: {
    width: '100%',
    height: '40px',
    border: '1px solid #5009B5',
    borderRadius: '4px',
    outline: 'none',
    padding: '5px',
    fontSize: '16px',
    color: '#231E33',
  },
});

const GuardianForm = ({ patientInfo, onToggleEdit, updatePatientInfo }) => {
  const [form] = Form.useForm();
  const [guardianInfo, setGuardianInfo] = useState({});
  const [disableSave, setDisableSave] = useState(null);

  useEffect(() => {
    if (patientInfo) {
      const { phones, guardian_name, guardian_phone_id } = patientInfo;
      if (guardian_name || guardian_phone_id) {
        const guardian_phone = find(phones, { id: guardian_phone_id });
        const cleanPhoneNumber = formatPhoneNumber(guardian_phone?.number);

        setGuardianInfo({ guardian_name, phone_number: cleanPhoneNumber });
      }
    }
  }, [patientInfo]);

  useEffect(() => {
    if (!isEmpty(guardianInfo)) {
      form.resetFields();
      form.setFieldsValue(guardianInfo);
      setDisableSave(false);
    }
  }, [guardianInfo]);

  const [updatePatientGuardian] = useMutation(UPDATE_PATIENT_GUARDIAN, {
    onError: error => {
      message.error(`Error Updating Guardian: ${error.message}`);
    },
    onCompleted: ({ updatePatientGuardian }) => {
      setDisableSave(null);
      message.info(`Guardian info saved.`);
      updatePatientInfo(updatePatientGuardian?.data);
    },
  });

  const onSave = () => {
    const { guardian_name, phone_number } = form.getFieldsValue();
    form
      .validateFields()
      .then(() => {
        updatePatientGuardian({
          variables: {
            patient_id: patientInfo?.id,
            guardian_name: guardian_name && guardian_name !== '' ? guardian_name.trim() : null,
            phone_number:
              phone_number && phone_number !== '' ? `+1${phone_number.replace(/\D/g, '')}` : null,
          },
        });
      })
      .catch(info => {
        return info;
      });
  };

  return (
    <div className={css(sx.container)}>
      <div className={css(sx.content)}>
        <GuardianDropdownLabel />
        <Form name="guardian-form" form={form} layout="vertical">
          <Form.Item name="guardian_name">
            <InputMask
              className={css(sx.inputStyles)}
              placeholder="Enter guardian first and last name"
            />
          </Form.Item>
          <Form.Item
            name="phone_number"
            rules={[
              {
                pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
                message: 'Invalid guardian phone.',
              },
            ]}
          >
            <InputMask
              className={css(sx.inputStyles)}
              placeholder="Enter guardian phone number"
              mask="(999) 999-9999"
            />
          </Form.Item>
        </Form>
      </div>
      <DropdownFooter>
        <LinkButton id="e2e-guardianFormCancelButton" onClick={onToggleEdit}>
          Cancel
        </LinkButton>
        <LinkButton id="e2e-guardianFormSaveButton" disabled={disableSave} onClick={onSave}>
          Save
        </LinkButton>
      </DropdownFooter>
    </div>
  );
};

GuardianForm.propTypes = {
  patientInfo: PropTypes.object,
  onToggleEdit: PropTypes.func,
  updatePatientInfo: PropTypes.func,
};

export default GuardianForm;
