import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, SplitDropdown, SplitDropdownButton } from '@zipdrug/ui';
import AddEmailDropdownModal from './AddEmailDropdownModal';
import EmailMenu from './EmailMenu';

const EmailButton = ({ patient, refetchSelectedPatientInfo, ...props }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let containerRef = null;

  const handleRef = ref => {
    containerRef = ref;
  };

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOnToggle = isOpen => {
    setIsOpen(isOpen);
  };

  const renderMenu = email => {
    return <EmailMenu patient={patient} email={email} toggleModal={toggleModal} />;
  };

  const handlePrimaryActionClick = () => {
    if (!isOpen) {
      containerRef.toggleMenu();
    } else {
      containerRef.toggleMenu();
    }
  };

  return (
    <>
      <AddEmailDropdownModal
        onCloseModal={() => toggleModal()}
        patient={patient}
        visible={isModalVisible}
        refetchSelectedPatientInfo={refetchSelectedPatientInfo}
      />
      {
        <SplitDropdown
          {...props}
          buttonRenderer={<Button size="small" />}
          buttonStyles={SplitDropdownButton.containerStyles}
          menuRenderer={renderMenu(patient?.emails[0]?.email)}
          onClick={() => handlePrimaryActionClick()}
          onToggle={isOpen => handleOnToggle(isOpen)}
          preventCloseOnOutsideClick={false}
          ref={handleRef}
          small
        >
          <SplitDropdownButton
            icon="mail"
            text="Email"
            title={patient?.emails[0]?.email ? patient?.emails[0]?.email : 'None'}
          />
        </SplitDropdown>
      }
    </>
  );
};

EmailButton.propTypes = {
  patient: PropTypes.object,
  refetchSelectedPatientInfo: PropTypes.func,
};

export default EmailButton;
