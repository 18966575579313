import { get, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { reduxForm } from 'redux-form';
import { fields } from '@zipdrug/react-api-sdk';
import { mutateUpdateUser } from 'graphql/users';
import { alertOnError, emailField, requiredField, validateForm } from '@zipdrug/ui';
import UserProfileForm from './UserProfileForm';
import { UserTypesMapping, ExtUserTypesMapping } from '../contexts/utils/models';

const USER_SCHEMA = {
  email: {
    ...emailField,
    required: true,
  },
  first_name: requiredField,
  last_name: requiredField,
  title: requiredField,
  ticket_number: {
    required: false,
  },
};

const ERROR_MESSAGES = {
  required: 'is required.',
};

// XXX TODO: replace these with real data
const TITLE_TYPES = ['Pharmacist', 'Pharmacy Technician'];

const validate = values => validateForm(values, USER_SCHEMA, ERROR_MESSAGES);

const ReduxUserProfileForm = reduxForm({
  enableReinitialize: true,
  validate,
})(UserProfileForm);

class UserProfileFormContainer extends Component {
  static propTypes = {
    mutateUpdateUser: PropTypes.func,
    onSubmit: PropTypes.func,
    user: PropTypes.object, // .isRequired,
    isCurrentUser: PropTypes.bool,
  };

  buildInitialValues = () => {
    const { user: { title, ...user } = {} } = this.props;
    return {
      ...user,
      title: {
        label: title,
        value: title,
      },
      user_type: {
        label: UserTypesMapping[user?.roles[0]?.type],
        value: UserTypesMapping[user?.roles[0]?.type],
      },
    };
  };

  handleSubmit = async ({
    title: { value: title },
    user_type: { value: user_type },
    ...values
  }) => {
    const { mutateUpdateUser, onSubmit, user, isCurrentUser } = this.props;

    const modifier = {
      ...pick(values, ...fields.UserModifier),
      title,
    };

    if (!isCurrentUser) {
      modifier.user_type = user_type;
      modifier.ticket_number = values.ticket_number;
    }

    const result = await alertOnError(
      mutateUpdateUser({
        variables: {
          id: user.id,
          modifier,
          pharmacy_id: user.roles[0]?.pharmacy_id,
        },
      }),
    );

    if (onSubmit) onSubmit();
    return result;
  };

  generateTitleTypes = () =>
    TITLE_TYPES.map(title => ({
      label: title,
      value: title,
    }));

  generateUserRoleTypes = () =>
    Object.keys(ExtUserTypesMapping).map(role => ({
      label: ExtUserTypesMapping[role],
      value: ExtUserTypesMapping[role],
    }));

  render = () => (
    <ReduxUserProfileForm
      {...this.props}
      form={`user[${get(this.props, 'user.id') || 'new'}]`}
      initialValues={this.buildInitialValues()}
      onSubmit={this.handleSubmit}
      titleTypes={this.generateTitleTypes()}
      userRoleTypes={this.generateUserRoleTypes()}
    />
  );
}

export default graphql(mutateUpdateUser, { name: 'mutateUpdateUser' })(UserProfileFormContainer);
