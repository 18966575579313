import { ActivityItem, colors, font, padding, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReturnCallButtonContainer from './ReturnCallButtonContainer';
import CallOutcomeActivityItem from '../activity-item/CallOutcomeActivityItem';

const sx = StyleSheet.create({
  header: {
    ...padding.build(font.size.xSmall, space.default),
    color: colors.red,
  },
});

const MissedCallActivityItem = ({ activity, currentPharmacy, ...opts }) => {
  const props = { ...opts };

  let title;
  if (get(activity, 'call.from_phone.patient_id')) {
    // a call from a patient is a missed call
    title = 'Missed call';
    props.externalUser = activity.patient;
  } else {
    // a call from zd / the pharmacy is a canceled call
    title = 'Canceled call';
    props.internalUser = get(activity, 'call.from_phone.is_zipdrug')
      ? { name: 'Zipdrug' }
      : currentPharmacy;
  }

  return (
    <>
      <ActivityItem
        {...props}
        activity={activity}
        createdAt={activity.created_at}
        hasAlert={
          !get(activity, 'call.alert.completed_at', true) && !get(activity, 'call.alert.deleted_at')
        }
        headerButton={<ReturnCallButtonContainer call={activity.call} />}
        headerStyles={sx.header}
        icon="noPhone"
        title={title}
      />
      {activity.callOutcome && (
        <CallOutcomeActivityItem
          activity={activity}
          className="e2e-activityListItem"
          key={activity.id}
        />
      )}
    </>
  );
};

MissedCallActivityItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.number,
    call: PropTypes.object,
    patient: PropTypes.object,
    created_at: PropTypes.any,
    callOutcome: PropTypes.object,
  }),
  currentPharmacy: PropTypes.object,
};

export default MissedCallActivityItem;
