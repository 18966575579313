import gql from 'graphql-tag';

const GET_ZIP_CODES_COVERAGE = gql`
  query postalCodes($limit: Int, $query: JSON, $skip: Int) {
    postalCodes(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        pharmacy_id
        postal_code
      }
    }
  }
`;

export default GET_ZIP_CODES_COVERAGE;
