import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

export const PharmacyListContext = React.createContext();

export const ActionTypes = {
  UPDATE_PHARMACYLIST_TABLE_SETTINGS: 'UPDATE_PHARMACYLIST_TABLE_SETTINGS',
};

const sortOrder = {
  model: 'pharmacy',
  columnKey: 'name',
  field: 'name',
  order: 'ascend',
};

const defaultPagination = {
  current: 0,
  defaultPageSize: 100,
};

const initialState = {
  tableActions: {
    pagination: defaultPagination,
    filters: null,
    sorter: null,
  },
  defaultTableActions: {
    sorter: sortOrder,
    pagination: defaultPagination,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_PHARMACYLIST_TABLE_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        tableActions: payload,
      };
    }
    default:
      throw new Error();
  }
};

const PharmacyListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PharmacyListContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </PharmacyListContext.Provider>
  );
};

PharmacyListProvider.propTypes = {
  children: PropTypes.node,
};

export default React.memo(PharmacyListProvider);
