const status = {
  types: [
    {
      name: 'Active',
      key: 'active',
      value: 'active',
      text: 'Active',
    },
    {
      name: 'Standby',
      key: 'stand_by',
      value: 'stand_by',
      text: 'Standby',
    },
    {
      name: 'Paused-Operational',
      key: 'paused_operational',
      value: 'paused_operational',
      text: 'Paused-Operational',
    },
    {
      name: 'Long-term Hold',
      key: 'longterm_hold',
      value: 'longterm_hold',
      text: 'Long-Term Hold',
    },
    {
      name: 'Inactive',
      key: 'inactive',
      value: 'inactive',
      text: 'Inactive',
    },
  ],
  mapping: {
    active: 'Active',
    stand_by: 'Standby',
    paused_operational: 'Paused-Operational',
    longterm_hold: 'Long-Term Hold',
    inactive: 'Inactive',
  },
};

export default status;
