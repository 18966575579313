import { get } from 'lodash';
import { compose, withHandlers, withProps } from 'recompose';
import withCurrentlyPlayingCallId from '../../../hoc/withCurrentlyPlayingCallId';
import withUpdateAlert from '../../../hoc/withUpdateAlert';
import AudioActivityItem from './AudioActivityItem';

export default compose(
  withUpdateAlert,
  withCurrentlyPlayingCallId,
  withHandlers({
    onPlayAudio: ({ activity = {}, mutateUpdateAlert, setCurrentlyPlayingCallId }) => () => {
      const call = activity.call || {};

      if (call.alert && !call.alert.completed_at) {
        mutateUpdateAlert({ completed: true, id: call.alert.id });
      }

      setCurrentlyPlayingCallId(call.id);
    },
  }),
  withProps(({ activity, currentlyPlayingCallId }) => {
    const callId = get(activity, 'call.id');
    return { isPlaying: callId === currentlyPlayingCallId };
  }),
)(AudioActivityItem);
