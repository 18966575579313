import { Button, SplitDropdown, SplitDropdownButton } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { isBoolean, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DeliveryFormContainer from '../delivery-form/DeliveryFormContainer';
import AddressFormContainer from './AddressFormContainer';
import AddressListContainer from './AddressListContainer';

const EMPTY_OBJECT = {};

const sx = StyleSheet.create({
  disableAnimation: {
    animationName: 'initial',
    transition: 'none',
  },
});

export default class AddressDropdown extends Component {
  static propTypes = {
    patient: PropTypes.object,
  };

  state = {
    editItem: EMPTY_OBJECT,
    isEditing: false,
    isOpen: false,
    orderItem: EMPTY_OBJECT,
    preventCloseOnOutsideClick: false,
  };

  componentWillReceiveProps(nextProps, nextState) {
    if (!nextState.isOpen) {
      this.setState({
        isEditing: false,
        preventCloseOnOutsideClick: false,
      });
    }
  }

  containerRef = null;

  handleToggleDropdown = isOpen =>
    this.setState({ isOpen }, () => {
      if (!isOpen && Object.keys(this.state.orderItem).length) {
        this.handleToggleOrderState();
      }
    });

  handleClickEditAddress = item => {
    this.handleToggleEditState();
    this.setState({
      editItem: item,
      preventCloseOnOutsideClick: true,
    });
  };

  handleClickOrderAddress = item => {
    this.handleToggleOrderState();
    this.setState({ orderItem: item });
  };

  handlePreventCloseOnOutsideClick = () =>
    this.setState(prevState => ({
      preventCloseOnOutsideClick: !prevState.preventCloseOnOutsideClick,
    }));

  handlePrimaryActionClick = () => {
    const address = get(this.props.patient, 'addresses[0]', EMPTY_OBJECT);

    this.setState({ shouldAnimate: false });

    if (!this.state.isOpen) {
      this.containerRef.toggleMenu();
    } else if (Object.keys(this.state.orderItem).length) {
      this.containerRef.toggleMenu();
    }

    this.handleClickOrderAddress(address);
  };

  handleRef = ref => {
    this.containerRef = ref;
  };

  handleToggleEditState = isEditing =>
    this.setState(prevState => {
      const newState = { isEditing: isBoolean(isEditing) ? isEditing : !prevState.isEditing };

      if (!newState.isEditing) {
        newState.editItem = EMPTY_OBJECT;
        newState.preventCloseOnOutsideClick = false;
      }

      return newState;
    });

  handleToggleOrderState = () =>
    this.setState(
      prevState => ({ isOrdering: !prevState.isOrdering }),
      () => {
        if (!this.state.isOrdering) {
          this.setState({ orderItem: EMPTY_OBJECT, shouldAnimate: true });
        }
      },
    );

  renderMenu = () => {
    const { editItem, isEditing, isOrdering, orderItem, shouldAnimate } = this.state;

    if (isOrdering) {
      return (
        <DeliveryFormContainer
          initialValues={orderItem}
          onClickCancel={this.handleToggleOrderState}
          styles={shouldAnimate ? null : sx.disableAnimation}
        />
      );
    } else if (isEditing) {
      return (
        <AddressFormContainer
          address={editItem}
          onClickCancel={this.handleToggleEditState}
          patient={this.props.patient}
        />
      );
    }

    return (
      <AddressListContainer
        onEditItem={this.handleClickEditAddress}
        onOrderItem={this.handleClickOrderAddress}
        onToggleEditState={this.handleToggleEditState}
        patient={this.props.patient}
      />
    );
  };

  render = () => {
    const primaryLabel = get(this.props.patient, 'addresses[0].label', '');
    return (
      <SplitDropdown
        {...this.props}
        buttonRenderer={<Button size="small" />}
        buttonStyles={SplitDropdownButton.containerStyles}
        menuRenderer={this.renderMenu()}
        menuStyles={this.state.shouldAnimate ? null : sx.disableAnimation}
        onClick={this.handlePrimaryActionClick}
        onToggle={this.handleToggleDropdown}
        preventCloseOnOutsideClick={this.state.isEditing}
        ref={this.handleRef}
        small
        toggleButtonProps={{ id: 'e2e-addressDropdownDropButton' }}
        type="violet"
      >
        {primaryLabel && (
          <SplitDropdownButton icon="shoppingCart" text="Deliver to" title={primaryLabel} />
        )}
      </SplitDropdown>
    );
  };
}
