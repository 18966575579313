import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { mutateUpdateUser } from 'graphql/users';
import { getUrlParams } from '@zipdrug/ui';
import { graphql } from 'react-apollo';
import EmailVerificationPage from './EmailVerificationPage';

class EmailVerificationPageContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    mutateUpdateUser: PropTypes.func,
  };

  state = {
    isVerified: null,
    loading: true,
  };

  componentWillMount() {
    this.handleEmailVerification();
  }

  handleButtonClick = () => this.props.history.replace('/signin');

  handleEmailVerification = async () => {
    const urlParams = getUrlParams();
    this.props
      .mutateUpdateUser({
        variables: {
          modifier: { token: urlParams.token },
        },
      })
      .then(() => this.handleVerificationResults(true))
      .catch(() => this.handleVerificationResults(false));
  };

  handleVerificationResults = isVerified =>
    this.setState({
      isVerified,
      loading: false,
    });

  render = () => (
    <EmailVerificationPage
      isVerified={this.state.isVerified}
      loading={this.state.loading}
      onButtonClick={this.handleButtonClick}
    />
  );
}

export default graphql(mutateUpdateUser, { name: 'mutateUpdateUser' })(
  EmailVerificationPageContainer,
);
