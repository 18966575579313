/* eslint-disable react/prop-types */
import { colors } from '@zipdrug/ui';
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Confetti from 'react-dom-confetti';
import { Table, Typography, Select, Button, message, Space, Tag } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { LeadListContext, ActionTypes } from 'contexts/LeadListProvider';
import { ZipdrugEligibleIcon, CPESNIcon } from 'ui/CallCenter/svg';
import filterSearchBox from '../Filters/Search/Search';
import withSelectPatient from '../../../../../hoc/withSelectPatient';
import NewTag from '../../../../../ui/NewTag/NewTag';
import withRoles from '../../../../../hoc/withRoles';
import ReAssignDSA from '../ReAssignDSA/ReAssignDSA';
import GET_CAMPAIGN_LIST from '../../graphql/getCampaignsList';
import GET_SPECIAL_NOTES from '../../graphql/getSpecialNotes';
import GET_LEAD_LIST from '../../graphql/getLeadList';
import GET_STATES from '../../graphql/getstates';
import GET_PHARMACY from '../../graphql/getPharmacy';
// import GET_PHARMACY_SERVICE_TYPES from '../../graphql/getPharmacyServiceTypes';
import UPDATE_MEMBER_STATUS from '../../graphql/updateMemberStatus';
import REASSIGN_DSA from '../../graphql/reAssignDSA';
import languages from '../../constants/languages';
import cadence from '../../constants/cadence';
import getLeadListFilters from '../../constants/leadStatus';
import confettiConfig from '../../constants/confettiConfig';
import generateVariables from '../../utils/GenerateVariables.utils';
import planType from '../../constants/PlanType';
import programs from '../../constants/programs';
// import serviceTypes from '../../constants/serviceTypes';
// import ages from '../../constants/ages';

const { Link, Text } = Typography;
const { Option } = Select;

const sx = StyleSheet.create({
  statusSelect: { width: '133px' },
  tableContainer: { width: 'auto', minWidth: 'unset !important' },
  leadListTableOptionsContainer: {
    marginBottom: '20px',
  },
  leadListTableOptionsButtonMargin: {
    marginRight: '16px',
  },
  dsaReassign: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  disabledRow: {
    background: '#f5f5f5',
    opacity: '0.5',
  },
  leadsTextContainer: {
    display: 'flex !important',
    justifyContent: 'space-between',
  },
  leadsText: {
    color: colors.gray52,
  },
  IconsContainer: {
    display: 'flex',
  },
  doubleIcon: {
    display: 'block',
  },
  patientName: {
    marginLeft: '5px',
  },
  iconsFlex: {
    display: 'flex',
  },
  iconsBlock: {
    display: 'block',
  },
  singleIcon: {
    marginTop: '8px',
  },
  pharmacyNameAlign: {
    marginRight: '10px',
    display: 'inline-block',
  },
  newPharmacy: {
    border: '1px solid #D9D9D9',
    height: '23px',
    marginTop: '10px',
    color: '#231E33',
    backgroundColor: '#F7F7F7',
  },
  newPharmacyHover: {
    width: '378px',
    height: '37px',
    backgroundColor: '#231E33',
  },
  hubPharmacyTag: {
    backgroundColor: '#F7F7F7',
    border: '1px solid #D9D9D9 !important',
    color: '#231E33',
    fontSize: '12px',
  },
});

const defaultSelectedMembers = {
  memberIds: [],
  memberRecords: [],
};

const defaultSelectedDSA = {
  first_name: null,
  last_name: null,
  id: null,
};

const LeadListTable = ({ onSelectPatient, history, isAdmin, hasRole }) => {
  const [data, setData] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [selectedMembers, setSelectedMembers] = useState(defaultSelectedMembers);
  const [filterPharmacies, setFilterPharmacies] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [selectedDSA, setSelectedDSA] = useState(defaultSelectedDSA);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState({ show: false, memberId: null });
  const [campaignList, setCampaignsList] = useState([]);
  const [specialNotesList, setSpecialNotesList] = useState([]);
  const [lastUpdateDate, setLastUpdateDate] = useState(null);
  // const [serviceTypesList, setServiceTypesList] = useState([]);
  const { state, dispatch } = useContext(LeadListContext);
  const { tableActions, defaultTableActions } = state;

  const [getCampaignsList] = useLazyQuery(GET_CAMPAIGN_LIST, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching campaigns: ${error.message}`);
    },
    onCompleted: ({ campaigns }) => {
      setCampaignsList(
        campaigns?.data
          .filter(c => c.status === 'active')
          .map(c => {
            return { text: c.name, value: c.name };
          }),
      );
    },
  });

  const [getSpecialNotes] = useLazyQuery(GET_SPECIAL_NOTES, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching special notes: ${error.message}`);
    },
    onCompleted: ({ getSpecialNotes }) => {
      setSpecialNotesList(
        getSpecialNotes.map(note => {
          return { text: note, value: note };
        }),
      );
    },
  });

  const [
    getLeadList,
    { loading: isFetchingLeadList, data: leadsData, refetch: refetchLeadList },
  ] = useLazyQuery(GET_LEAD_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching lead list: ${error.message}`);
    },
    onCompleted: ({ leads }) => {
      setTotalLeads(leads?.total);
      setData(leads?.data);
      getCampaignsList();
      setLastUpdateDate(leads?.last_updated_at);
      getSpecialNotes();
    },
  });

  const [reAssignDSA, { loading: isReassigningDSA }] = useMutation(REASSIGN_DSA, {
    onError: error => {
      message.error(`Error re-assigning PBS: ${error.message}`);
    },
    onCompleted: ({ updateLeads }) => {
      const clonedData = cloneDeep(data);
      const memberIds = updateLeads?.data?.map(lead => lead.id);

      const resultData = clonedData.map(member => {
        if (memberIds.includes(member.id)) {
          member.assigned_dsa = { ...selectedDSA };
        }
        return member;
      });
      setData(resultData);
      setSelectedDSA(defaultSelectedDSA);
      setSelectedMembers(defaultSelectedMembers);
      setShowReassignModal(false);
      message.success('Successfully re-assigned PBS');
    },
  });

  const [updateMemberStatus, { loading: isUpdatingMemberStatus }] = useMutation(
    UPDATE_MEMBER_STATUS,
    {
      onError: error => {
        message.error(`Error updating Status: ${error.message}`);
      },
      onCompleted: ({ updateLeads }) => {
        const clonedData = cloneDeep(data);
        const memberIds = updateLeads?.data?.map(lead => lead.id);

        const resultData = clonedData.map(member => {
          if (memberIds.includes(member.id)) {
            member.lead_status = updateLeads?.data[0]?.lead_status;
          }
          return member;
        });

        setData(resultData);
        setSelectedMembers(defaultSelectedMembers);

        if (updateLeads?.data[0]?.lead_status === 'enrolled') {
          setShowConfetti({ ...showConfetti, show: true });
        } else {
          message.success('Successfully Updated Status');
        }
      },
    },
  );

  const [updateStatesFilter, { loading: isloadingState }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error getting States: ${error.message}`);
    },
    onCompleted: ({ getUniquePatientStates }) => {
      setFilterStates(getUniquePatientStates?.data?.states);
    },
  });

  const [getUniquePharmacies, { loading: isFilterPharmacyLoading }] = useLazyQuery(GET_PHARMACY, {
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error getting Pharmacies: ${error.message}`);
    },
    onCompleted: ({ getUniquePharmacyLists }) => {
      setFilterPharmacies(getUniquePharmacyLists?.data?.potential_pharmacies);
    },
  });

  // const [getPharmServiceTypes] = useLazyQuery(GET_PHARMACY_SERVICE_TYPES, {
  //   fetchPolicy: 'cache-and-network',
  //   onError: error => {
  //     message.error(`Error getting Pharmacy Services: ${error.message}`);
  //   },
  //   onCompleted: ({ pharmacyServiceType }) => {
  //     setServiceTypesList(pharmacyServiceType?.data);
  //   },
  // });

  const fetchUniquePharmacies = () => {
    const query = {};
    query.states = tableActions?.filters?.state || [];

    getUniquePharmacies({
      variables: {
        query,
      },
    });
  };

  const onMemberClick = patientId => {
    onSelectPatient(patientId);
    history.push('/members');
  };

  const onStatusChange = async (leadId, status) => {
    let filterIds = leadId;

    const generateFilteredIds = (leads, status) => {
      return leads?.memberRecords.reduce((acc, curr) => {
        if (curr?.lead_status === status) {
          acc.push(curr?.id);
        }
        return acc;
      }, []);
    };

    if (leadId?.memberIds) {
      if (status === 'filter_out') {
        filterIds = generateFilteredIds(leadId, null);
      } else if (status === 'notCalled') {
        filterIds = generateFilteredIds(leadId, 'filter_out');
      }
    }

    if (filterIds.length === 0) {
      message.error('You can only filter out leads that have not been called yet.');
    } else {
      updateMemberStatus({
        variables: {
          ids: filterIds,
          modifiers: {
            lead_status: status === 'notCalled' ? null : status,
          },
        },
      });
    }
  };

  const submitReassignment = selectedOption => {
    if (!selectedOption?.id) {
      message.error('Please enter a valid Direct Sales Agent.');
    } else {
      setSelectedDSA(selectedOption);
      reAssignDSA({
        variables: {
          ids: selectedMembers?.memberIds,
          modifiers: {
            assigned_dsa_user_id: selectedOption.id,
          },
        },
      });
    }
  };

  function getPatientIcons(program_eligibility) {
    switch (program_eligibility) {
      case 'zipdrug_eligible':
        return <ZipdrugEligibleIcon />;
      case 'cpesn_eligible':
        return <CPESNIcon />;
      case 'zipdrug_and_cpesn_eligible':
        return (
          <>
            <span className={css(sx.doubleIcon)}>
              <ZipdrugEligibleIcon />
            </span>
            <span className={css(sx.doubleIcon)}>
              <CPESNIcon />
            </span>
          </>
        );
      default:
        return null;
    }
  }

  function getMinorIcon(leadData) {
    if (leadData.isMinor) {
      return (
        <>
          <Button type="primary" size="small">
            Minor
          </Button>
        </>
      );
    }
    return null;
  }

  // function getPharmacyServiceName(servicesArr) {
  //   const newServiceArr = servicesArr.map(s => s.pharmacy_service_type_id);
  //   if (newServiceArr.includes(1) && newServiceArr.includes(8)) {
  //     // return 'ZipDrug for Medicaid';
  //     return serviceTypes.zipdrugForMedicaid.text;
  //   }

  //   if (newServiceArr.includes(1)) {
  //     return serviceTypes.zipdrug.text;
  //   }

  //   if (newServiceArr.includes(8)) {
  //     return serviceTypes.cpesn.text;
  //   }

  //   return '';
  // }

  // function pharmacyServicesTableFilter(types) {
  //   const newArr = types.filter(
  //     s =>
  //       s.pharmacy_service_type === 'Zipdrug' ||
  //       s.pharmacy_service_type === 'CPESN (Community Pharmacy Enhanced Services Network)',
  //   );

  //   const newServiceTypes = newArr.map(s => {
  //     return {
  //       text: s.pharmacy_service_type,
  //       value: [s.id],
  //       name: s.pharmacy_service_type,
  //     };
  //   });

  //   if (newServiceTypes?.find(s => s.name === 'Zipdrug')) {
  //     const index = newServiceTypes?.findIndex(object => {
  //       return object.name === 'Zipdrug';
  //     });
  //     newServiceTypes[index].text = 'ZipDrug';
  //   }

  //   const zipdrugForMedicaid = {
  //     text: 'ZipDrug for Medicaid',
  //     name: 'ZipDrug for Medicaid',
  //     value: newArr.map(s => s.id),
  //   };
  //   newServiceTypes.push(zipdrugForMedicaid);
  //   return newServiceTypes;
  // }

  const checkHubPharmacy = potentialPharmacy => {
    const hasParents = potentialPharmacy?.parent_pharmacies?.length !== 0;
    const hasChildren = potentialPharmacy?.chain_pharmacies?.length !== 0;

    if (hasParents || hasChildren) {
      return (
        <>
          <div className={css(sx.pharmacyNameAlign)}>
            {hasParents
              ? potentialPharmacy.parent_pharmacies[0].parent_pharmacy.name
              : potentialPharmacy.name}
          </div>
          <Tag className={css(sx.hubPharmacyTag)}>Hub</Tag>
          <NewTag
            created_at={potentialPharmacy?.created_at}
            toggleToolTip
            style={css(sx.newPharmacy)}
          />
        </>
      );
    }
    return (
      <div>
        <div>{potentialPharmacy?.name}</div>
        <NewTag
          created_at={potentialPharmacy?.created_at}
          toggleToolTip
          style={css(sx.newPharmacy)}
        />
      </div>
    );
  };

  const generateColumns = () => {
    const headers = [
      {
        dataIndex: 'patient',
        key: 'program_eligibility',
        filters: Object.values(programs),
        filterMultiple: true,
        filteredValue: tableActions?.filters?.program_eligibility || null,
        render: text => {
          return text.program_eligibility ? <>{getPatientIcons(text.program_eligibility)}</> : null;
        },
      },
      {
        title: 'Name',
        dataIndex: 'patient',
        key: 'patient',
        ...filterSearchBox('patient', 'Name', tableActions?.filters?.patient),
        filteredValue: tableActions?.filters?.patient || null,
        // sorter: true,
        // sortDirections: ['ascend', 'descend', 'ascend'],
        // sortOrder: tableActions?.sorter?.columnKey === 'patient' && tableActions?.sorter?.order,
        render: text => (
          <Link underline onClick={() => onMemberClick(text?.id)}>
            {`${text?.first_name} ${text?.last_name}`}
          </Link>
        ),
      },
      {
        title: 'Age',
        dataIndex: 'patient',
        key: 'birthday',
        // filters: ages,
        // filterMultiple: true,
        // filteredValue: tableActions?.filters?.birthday || null,
        // sorter: true,
        // sortDirections: ['ascend', 'descend', 'ascend'],
        // sortOrder: tableActions?.sorter?.columnKey === 'birthday' && tableActions?.sorter?.order,
        render: (text, record) => {
          return text.birthday ? <>{getMinorIcon(record)}</> : null;
        },
      },
      {
        title: 'Payer',
        dataIndex: 'payer',
        key: 'payer',
        ...filterSearchBox('payer', 'Payer', tableActions?.filters?.payer),
        filteredValue: tableActions?.filters?.payer || null,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'payer' && tableActions?.sorter?.order,
        render: text => {
          return `${text?.name}`;
        },
      },
      {
        title: 'Network',
        dataIndex: 'plan_type',
        key: 'plan_type',
        width: 133,
        filters: Object.values(planType),
        filteredValue: tableActions?.filters?.plan_type || null,
        filterMultiple: false,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'plan_type' && tableActions?.sorter?.order,
        render: text => {
          return planType[text]?.text;
        },
      },
      {
        title: 'Potential Pharmacy',
        dataIndex: 'potential_pharmacy',
        key: 'potential_pharmacy',
        width: 200,
        ...filterSearchBox(
          'potential_pharmacy',
          'Potential Pharmacy',
          tableActions?.filters?.potential_pharmacy || null,
          fetchUniquePharmacies,
          filterPharmacies,
          isFilterPharmacyLoading,
        ),
        filteredValue: tableActions?.filters?.potential_pharmacy || null,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder:
          tableActions?.sorter?.columnKey === 'potential_pharmacy' && tableActions?.sorter?.order,
        render: text => {
          return <>{checkHubPharmacy(text)}</>;
        },
      },
      // We might need to put this column back in later
      // {
      //   title: 'Pharmacy Services',
      //   dataIndex: 'potential_pharmacy',
      //   key: 'potential_pharmacy_service',
      //   filterMultiple: false,
      //   filters: serviceTypesList.length > 0 && pharmacyServicesTableFilter(serviceTypesList),
      //   filteredValue: tableActions?.filters?.potential_pharmacy_service || null,
      //   sortDirections: ['ascend', 'descend', 'ascend'],
      //   sortOrder:
      //     tableActions?.sorter?.columnKey === 'potential_pharmacy_service' &&
      //     tableActions?.sorter?.order,
      //   render: text => {
      //     return <>{getPharmacyServiceName(text?.pharmacy_services)}</>;
      //   },
      // },
      {
        title: 'State',
        key: 'state',
        dataIndex: 'patient',
        filters: filterStates?.map(state => {
          return { ...state, text: `${state?.name} (${state?.value})` };
        }),
        filteredValue: tableActions?.filters?.state || null,
        // sorter: true, We will not be supporting sorting on State for now. Uncomment when BE is supporting this.
        // sortDirections: ['ascend', 'descend', 'ascend'],
        // sortOrder: tableActions?.sorter?.columnKey === 'state' && tableActions?.sorter?.order,
        render: text => {
          return `${text?.addresses?.[0]?.state}`;
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            updateStatesFilter();
          }
        },
      },
      {
        title: 'Status',
        key: 'lead_status',
        dataIndex: 'lead_status',
        filters: getLeadListFilters(isAdmin || hasRole.pbsLead),
        filteredValue: tableActions?.filters?.lead_status || null,
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'],
        sortOrder: tableActions?.sorter?.columnKey === 'lead_status' && tableActions?.sorter?.order,
        render: (text, record) => {
          return (
            <>
              {showConfetti?.memberId === record?.id && (
                <Confetti active={showConfetti?.show} config={confettiConfig} />
              )}
              <Select
                defaultValue={text || 'notCalled'}
                onChange={value => {
                  setShowConfetti({ show: false, memberId: record?.id });
                  onStatusChange(record.id, value);
                }}
                className={css(sx.statusSelect)}
                key={record.id}
                value={text || 'notCalled'}
                disabled={record?.lead_status === 'filter_out'}
              >
                {getLeadListFilters(isAdmin || hasRole.pbsLead).map(status => {
                  if (status.value === 'filter_out' && text !== null) {
                    return null;
                  }

                  return (
                    <Option value={status.value} key={status.value}>
                      {status.text}
                    </Option>
                  );
                })}
              </Select>
            </>
          );
        },
      },
    ];

    if (isAdmin || hasRole.pbsLead) {
      const dsaColumns = [
        {
          title: 'Cadence',
          key: 'cadence',
          dataIndex: 'cadence',
          filters: cadence,
          filteredValue: tableActions?.filters?.cadence || null,
          sorter: true,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sortOrder: tableActions?.sorter?.columnKey === 'cadence' && tableActions?.sorter?.order,
        },
        {
          title: 'PBS',
          key: 'assigned_dsa',
          dataIndex: 'assigned_dsa',
          ...filterSearchBox('assigned_dsa', 'PBS'),
          filteredValue: tableActions?.filters?.assigned_dsa || null,
          sorter: true,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sortOrder:
            tableActions?.sorter?.columnKey === 'assigned_dsa' && tableActions?.sorter?.order,
          render: text => {
            return `${text?.first_name} ${text?.last_name}`;
          },
        },
      ];

      dsaColumns.forEach(col => headers.push(col));
    }

    headers.push({
      title: 'Language',
      dataIndex: 'patient',
      key: 'language',
      filters: languages,
      filteredValue: tableActions?.filters?.language || null,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: tableActions?.sorter?.columnKey === 'language' && tableActions?.sorter?.order,
      render: text => {
        return `${text?.language?.[0]?.toUpperCase() + text?.language?.slice(1) || '--'}`;
      },
    });

    headers.push({
      title: 'Campaign',
      dataIndex: 'lead_campaign_members',
      key: 'campaign',
      filters: campaignList,
      filteredValue: tableActions?.filters?.campaign || null,
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: tableActions?.sorter?.columnKey === 'campaign' && tableActions?.sorter?.order,
      render: text => {
        return text.map((c, index) => {
          return (
            <>
              {c?.campaign?.name}
              {index < text.length - 1 && ', '}
              <br />
            </>
          );
        });
      },
    });

    headers.push({
      title: 'Special Notes',
      dataIndex: 'special_notes',
      key: 'special_notes',
      filters: specialNotesList,
      filteredValue: tableActions?.filters?.special_notes || null,
      render: text => {
        return `${text || '--'}`;
      },
    });

    return headers;
  };

  const fetchLeadList = (payload = cloneDeep(tableActions), refetch = false) => {
    // If no headxers are present in some scenarios, ANTD does some pagic and assign the sorter to the last column in the headers on any action...
    // So if Sorter is present check the order and if its false that means its ANTD Magic, if anything else means its actually sort.
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }

    const variables = generateVariables(payload);

    if (refetch) {
      refetchLeadList({
        ...variables,
      });
    } else {
      getLeadList({
        variables: {
          ...variables,
        },
      });
    }
  };

  useEffect(() => {
    fetchLeadList();
    // getPharmServiceTypes();
    if (tableActions?.filters?.state && tableActions?.filters.state?.length) {
      // if state filter already found then load the state list
      updateStatesFilter();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => setShowConfetti({ show: false, memberId: null }), 3000);
  }, [showConfetti?.show]);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    dispatch({
      type: ActionTypes.UPDATE_LEADLIST_TABLE_SETTINGS,
      payload: {
        pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
        filters,
        sorter,
      },
    });

    // To stay consistent always re-direct user to page 1 on filter/sort.
    if (action === 'paginate') {
      fetchLeadList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchLeadList({
        pagination: {...pagination, current: 1},
        filters,
        sorter,
      });
    }
  };

  // Useful when you update an value and the filter/sort is not applied.
  const refreshList = () => {
    fetchLeadList(tableActions, true);
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: {...tableActions.pagination, pageSize: 25, current: 1},
      filters: null,
      sorter: null,
    };

    dispatch({
      type: ActionTypes.UPDATE_LEADLIST_TABLE_SETTINGS,
      payload,
    });

    fetchLeadList(payload);
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data,
      size: 'middle',
      onChange: onTableChange,
      rowKey: record => record.id,
      rowClassName: record => record.lead_status === 'filter_out' && css(sx.disabledRow),
      loading:
        isFetchingLeadList ||
        isReassigningDSA ||
        isUpdatingMemberStatus ||
        isloadingState ||
        isFilterPharmacyLoading,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: leadsData?.leads?.total || 0,
        current: tableActions?.pagination?.current || 1,
        pageSize: tableActions?.pagination?.pageSize,
      },
      onRow: record => {
        return {
          className: record.lead_status === 'filter_out' ? css(sx.disabledRow) : '',
        };
      },
    };
    if (isAdmin || hasRole.pbsLead) {
      tableOptions.rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedMembers({ memberIds: selectedRowKeys, memberRecords: selectedRows });
        },
        selectedRowKeys: selectedMembers.memberIds,
      };
    }

    return tableOptions;
  };

  return (
    <>
      <div className={css(sx.leadListTableOptionsContainer)}>
        <Button
          type="secondary"
          className={css(sx.leadListTableOptionsButtonMargin)}
          onClick={refreshList}
        >
          Refresh List
        </Button>
        <Button
          type="secondary"
          onClick={clearAllFilters}
          className={css(sx.leadListTableOptionsButtonMargin)}
        >
          Clear All Filters
        </Button>
        {selectedMembers?.memberIds?.length > 0 && (
          <>
            <Button
              type="secondary"
              onClick={() => setShowReassignModal(true)}
              className={css(sx.leadListTableOptionsButtonMargin)}
            >
              Reassign
            </Button>
            <Button
              type="secondary"
              onClick={() => onStatusChange(selectedMembers, 'filter_out')}
              className={css(sx.leadListTableOptionsButtonMargin)}
            >
              Filter Out Leads
            </Button>
            <Button
              type="secondary"
              onClick={() => onStatusChange(selectedMembers, 'notCalled')}
              className={css(sx.leadListTableOptionsButtonMargin)}
            >
              Filter In Leads
            </Button>
          </>
        )}
      </div>
      <Space className={css(sx.leadsTextContainer)}>
        <Text className={css(sx.leadsText)}>Total Leads: {totalLeads}</Text>
        {data?.length > 0 && (
          <Text className={css(sx.leadsText)}>
            Last Updated: {lastUpdateDate && moment(lastUpdateDate).format('MMMM Do YYYY')}
          </Text>
        )}
      </Space>
      <Table {...getTableOptions()} />

      {showReassignModal && (
        <ReAssignDSA
          visible={showReassignModal}
          submitReassignment={submitReassignment}
          onReassignCancel={() => setShowReassignModal(false)}
          reassignCount={selectedMembers?.memberIds?.length}
        />
      )}
    </>
  );
};

export default withSelectPatient(withRouter(withRoles(LeadListTable)));
