import { users } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { isAdmin } from '../../contexts/utils/models';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import withUserMutation from '../../hoc/withUserMutation';
import TeamListItemActions from './TeamListItemActions';
import hasEnrollers from './graphql/hasEnrollers';

class TeamListItemActionsContainer extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    currentPharmacy: PropTypes.object,
    enrollerCheck: PropTypes.bool,
    mutateDeleteUser: PropTypes.func,
    mutateSendInvitation: PropTypes.func,
    mutateUpdateUser: PropTypes.func,
    onClickEdit: PropTypes.func,
    user: PropTypes.object,
    currentUser: PropTypes.object,
    isCheckingEnrollers: PropTypes.bool,
  };

  state = {
    ticket_number: '',
    error: false,
  };

  handleClickEdit = event => {
    event.stopPropagation();

    if (this.props.onClickEdit) {
      this.props.onClickEdit();
    }
  };

  handleDeactivateUser = async event => {
    event.stopPropagation();
    if (!this.state.ticket_number) {
      this.setState({ error: true });
    }

    const { mutateDeleteUser, user } = this.props;
    const action = user.joined_at ? 'delete this member' : 'delete the invitation';

    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to ${action}?`)) {
      return alertOnError(
        mutateDeleteUser({
          id: user.id,
          modifier: { is_active: false, ticket_number: this.state.ticket_number },
        }),
      );
    }
  };

  handleResendInvitation = async event => {
    event.stopPropagation();

    return alertOnError(
      this.props.mutateSendInvitation({
        variables: {
          id: this.props.user.id,
          pharmacy_name: get(this.props, 'currentPharmacy.name'),
        },
      }),
    ).then(() =>
      // eslint-disable-next-line no-alert
      window.alert('Invitation sent'),
    );
  };

  handleToggleInboundEligible = async event => {
    event.stopPropagation();
    const { user, mutateUpdateUser } = this.props;
    const modifier = { inbound_eligible: !user.inbound_eligible };

    return mutateUpdateUser({
      id: user.id,
      modifier,
    });
  };

  handleAddingTicketNumber = event => {
    this.setState({ ticket_number: event?.target?.value, error: false });
  };

  render = () => {
    const { user, currentUser, enrollerCheck, isCheckingEnrollers } = this.props;
    return (
      <TeamListItemActions
        enrollerCheck={enrollerCheck}
        isCheckingEnrollers={isCheckingEnrollers}
        onClickEdit={this.handleClickEdit}
        onDeactivateUser={this.handleDeactivateUser}
        onResendInvitation={this.handleResendInvitation}
        onToggleEligibleInbound={this.handleToggleInboundEligible}
        user={user}
        userIsAdmin={isAdmin(user)}
        currentUser={currentUser}
        isExternalUser={user.roles.find(
          r => r.type === 'pharmacist_user' || r.type === 'pharmacist_admin',
        )}
        onAddingTicketNumber={this.handleAddingTicketNumber}
        deleteError={this.state.error}
        isPbsOrSystemAdmin={user.roles.find(
          r => r.type.includes('pbs') || r.type === 'system_admin',
        )}
      />
    );
  };
}

export default compose(
  withCurrentPharmacy,
  graphql(users.mutateSendInvitation, { name: 'mutateSendInvitation' }),
  graphql(hasEnrollers, { name: 'hasEnrollers' }),
  withUserMutation,
)(TeamListItemActionsContainer);
