const priorities = [
  {
    text: '0 - Normal Priority',
    value: 0,
  },
  {
    text: '1',
    value: 1,
  },
  {
    text: '2',
    value: 2,
  },
  {
    text: '3',
    value: 3,
  },
  {
    text: '4',
    value: 4,
  },
  {
    text: '5 - Highest Priority',
    value: 5,
  },
];

export default priorities;
