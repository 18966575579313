import gql from 'graphql-tag';

const UPDATE_PATIENT_GUARDIAN = gql`
  mutation updatePatientGuardian($patient_id: Int!, $guardian_name: String, $phone_number: String) {
    updatePatientGuardian(
      patient_id: $patient_id
      guardian_name: $guardian_name
      phone_number: $phone_number
    ) {
      data {
        id
        guardian_name
        guardian_phone_id
        phones {
          id
          label
          number
          extension
          can_sms
        }
      }
    }
  }
`;

export default UPDATE_PATIENT_GUARDIAN;
