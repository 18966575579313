import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  animation,
  colors,
  Div,
  font,
  Icon,
  padding,
  position,
  rem,
  Row,
  space,
  transition,
  unit,
} from '@zipdrug/ui';

const sx = StyleSheet.create({
  container: {
    ...padding.build(rem(unit(font.size.medium) / 2), font.size.small),
    cursor: 'pointer',
    width: '100%',
    ':hover': {
      backgroundColor: colors.gray97,
    },
  },
  icon: {
    ...position.size(font.size.large),
    ...transition.build({
      duration: 400,
      ease: animation.ease.outExpo,
    }),
    marginRight: space.half,
    transform: `scale(${unit(font.size.medium) / unit(font.size.large)}) translateZ(0)`,
    willChange: 'transform',
  },
  iconHover: {
    transform: 'scale(1) translateZ(0)',
  },
  label: {
    color: colors.theme.primary.default,
  },
});

export default class SnoozeListItem extends Component {
  static propTypes = {
    format: PropTypes.func,
    icon: Icon.propTypes.name,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.object,
  };

  state = { isHover: false };

  handleClick = () => this.props.onClick(this.props.value);
  handleMouseEnter = () => this.setState({ isHover: true });
  handleMouseLeave = () => this.setState({ isHover: false });

  render = () => (
    <Row
      align="center"
      justify="space-between"
      onClick={this.handleClick}
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      styles={sx.container}
    >
      <Row align="center">
        <Icon name={this.props.icon} styles={[sx.icon, this.state.isHover ? sx.iconHover : null]} />
        <Div styles={sx.label}>{this.props.label}</Div>
      </Row>
      {this.props.value && <Div>{this.props.format(this.props.value)}</Div>}
    </Row>
  );
}
