import { patients } from '@zipdrug/react-api-sdk';
import { alertOnError, sortList } from '@zipdrug/ui';
import withZipdrugEnhancedMatching from 'hoc/withZipdrugEnhancedMatching';
import { graphql } from 'react-apollo';
import {
  compose,
  mapProps,
  onlyUpdateForKeys,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import withActivePharmaciesFiltered from '../../../hoc/withActivePharmaciesFiltered';
import withCurrentPharmacy from '../../../hoc/withCurrentPharmacy';
import withPharmaciesForPostalCodes from '../../../hoc/withPharmaciesForPostalCodes';
import withRefreshPatients from '../../../hoc/withRefreshPatients';
import withSelectedPatientInfo from '../../../hoc/withSelectedPatientInfo';
import PharmacyForm from './PharmacyForm';

const formatPharmacyList = (pharmacies, currentPharmacyId, maintenanceCount) =>
  sortList(
    (pharmacies || [])
      .filter(({ maintenance_threshold }) => maintenance_threshold <= maintenanceCount)
      .map(({ id, name, npi, parent_pharmacy }) => ({ id, name, npi, parent_pharmacy }))
      .filter(({ id }) => id !== currentPharmacyId),
    'name',
  );

export default compose(
  graphql(patients.mutateUpdatePatient, { name: 'mutateUpdatePatient' }),
  withSelectedPatientInfo,
  withRefreshPatients,
  withState('isLoading', 'toggleIsLoading', false),
  withState('selectedPharmacyId', 'onSelectPharmacy', null),
  mapProps(({ selectedPatient, ...props }) => {
    /*
      get the LOB of the patients latest membership/plan and use that to filter which pharmacies to get
    */

    const latestPlanLob = [];
    let mostRecentPlanId;
    if (selectedPatient?.memberships?.length) {
      const sortedMemberships = selectedPatient?.memberships.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });

      mostRecentPlanId = sortedMemberships[0]?.plan_id;
      const latestPlanInfo = selectedPatient?.plans.find(
        plan => plan.id === parseInt(mostRecentPlanId, 10),
      );

      const planLob = latestPlanInfo?.plan_type;
      latestPlanLob.push(planLob);
    }

    return {
      ...props,
      assigned_pharmacy_id: selectedPatient?.assigned_pharmacy_id,
      maintenance_count: selectedPatient?.maintenance_count,
      payer_id: selectedPatient?.payer_id,
      postalCodes: selectedPatient?.addresses?.map(({ postal_code }) => postal_code),
      pharmacyNetworkTypes: {
        lob: latestPlanLob,
      },
      plan_id: mostRecentPlanId,
    };
  }),
  withPharmaciesForPostalCodes,
  withActivePharmaciesFiltered,
  withCurrentPharmacy,
  withZipdrugEnhancedMatching,
  withProps(props => {
    const { assigned_pharmacy_id, matchedPharmacies, maintenance_count } = props;

    const bestMatchPharmacies = formatPharmacyList(
      matchedPharmacies,
      assigned_pharmacy_id,
      maintenance_count,
    );
    if (bestMatchPharmacies?.length > 0) {
      const filteredBestMatchPharmacies = bestMatchPharmacies.reduce((acc, val) => {
        const { id, name, npi, parent_pharmacy } = val;
        if (parent_pharmacy) {
          const { id, name, npi } = parent_pharmacy;
          const itemIndex = acc.findIndex(pharmacy => pharmacy.npi === parent_pharmacy.npi);
          if (itemIndex === -1) {
            acc.push({ id, name: `${name} (Hub) - ${npi}`, npi });
          }
          return acc;
        }
        const itemIndex = acc.findIndex(pharmacy => pharmacy.npi === npi);
        if (itemIndex === -1) {
          acc.push({ id, name: `${name} - ${npi}`, npi });
        }
        return acc;
      }, []);
      return { pharmacyItems: filteredBestMatchPharmacies };
    }
    return { pharmacyItems: [] };
  }),
  withHandlers({
    onSelectPharmacy: ({ onSelectPharmacy }) => ({ id } = {}) => onSelectPharmacy(id),
    onSubmit: props => async () => {
      const {
        mutateUpdatePatient,
        onRefetchPatients,
        onToggleEdit,
        selectedPatientId,
        selectedPharmacyId,
        toggleIsLoading,
        onReAssignPharmacy,
      } = props;
      toggleIsLoading(true);
      return alertOnError(
        mutateUpdatePatient({
          variables: {
            id: selectedPatientId,
            modifier: {
              assigned_pharmacy_id: selectedPharmacyId,
            },
          },
        }),
      )
        .then(() => {
          toggleIsLoading(false);
        })
        .then(() => {
          onToggleEdit();
        })
        .then(() => {
          return onRefetchPatients;
        })
        .then(() => {
          onReAssignPharmacy(selectedPharmacyId);
        });
    },
  }),
  onlyUpdateForKeys(['isLoading', 'pharmacyItems', 'selectedPharmacyId', 'isUpdating']),
)(PharmacyForm);
