import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import SnoozeForm from './SnoozeForm';

const SNOOZE_TIME_VALUES = [
  '6:00am',
  '6:30am',
  '7:00am',
  '7:30am',
  '8:00am',
  '8:30am',
  '9:00am',
  '9:30am',
  '10:00am',
  '10:30am',
  '11:00am',
  '11:30am',
  '12:00pm',
  '12:30pm',
  '1:00pm',
  '1:30pm',
  '2:00pm',
  '2:30pm',
  '3:00pm',
  '3:30pm',
  '4:00pm',
  '4:30pm',
  '5:00pm',
  '5:30pm',
  '6:00pm',
  '6:30pm',
  '7:00pm',
  '7:30pm',
  '8:00pm',
  '8:30pm',
];

const formatDateTime = (date, time) => moment(`${date} ${time}`, 'YYYY-MM-DD h:mma Z');

const ReduxSnoozeForm = reduxForm({
  enableReinitialize: true,
  form: 'snoozeForm',
})(SnoozeForm);

export default class SnoozeFormContainer extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
  };

  buildInitialValues = () => {
    const time = moment()
      .startOf('hour')
      .add(1, 'hour')
      .format('h:mma');

    return {
      date: moment().format('YYYY-MM-DD'),
      time: {
        label: time,
        value: time,
      },
    };
  };

  buildSnoozeTimeOptions = () => SNOOZE_TIME_VALUES.map(value => ({ label: value, value }));

  handleSubmit = values => {
    let date = values.date;

    if (!date) {
      date = moment().format('YYYY-MM-DD');
    }

    this.props.onSubmit(formatDateTime(date, values.time.value));
  };

  validateForm = ({ date, time: { value: time } }) => {
    const errors = {};
    const errorText = 'must be in the future';

    if (date) {
      const momentDate = moment(date).utc();
      const now = moment().utc();

      if (momentDate.isBefore(now, 'day')) {
        errors.date = errorText;
      }

      if (momentDate.isAfter(now.add(100, 'years'), 'day')) {
        errors.date = 'must be sooner';
      }
    }

    if (time) {
      const formattedDateTime = formatDateTime(date || moment(), time);
      if (moment(formattedDateTime).isBefore(moment())) {
        errors.time = errorText;
      }
    }

    return errors;
  };

  render = () => (
    <ReduxSnoozeForm
      {...this.props}
      initialValues={this.buildInitialValues()}
      onSubmit={this.handleSubmit}
      snoozeTimeOptions={this.buildSnoozeTimeOptions()}
      validate={this.validateForm}
    />
  );
}
