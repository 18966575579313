import { compose, withHandlers, withState } from 'recompose';
import RxPrescriberGroup from './RxPrescriberGroup';

export default compose(
  withState('showInactiveClaims', 'toggleShowInactiveClaims', false),
  withHandlers({
    onToggleShowInactiveClaims: ({ toggleShowInactiveClaims }) => () =>
      toggleShowInactiveClaims(showInactiveClaims => !showInactiveClaims),
  }),
)(RxPrescriberGroup);
