import { get } from 'lodash';
import withRoles from 'hoc/withRoles';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import { phoneField, requiredField, validateForm } from '@zipdrug/ui';
import withPhoneMutation from '../hoc/withPhoneMutation';
import { setWorkstationPhoneId } from '../redux/queryReducer';
import CreateWorkstationPhonePage from './CreateWorkstationPhonePage';

const ERROR_MESSAGES = {
  invalidPhone: 'is invalid',
  required: 'is required.',
};

const CREATE_WORKSTATION_PHONE_SCHEMA = {
  label: requiredField,
  number: {
    ...phoneField,
    required: true,
  },
};

const validate = values => validateForm(values, CREATE_WORKSTATION_PHONE_SCHEMA, ERROR_MESSAGES);
const ReduxCreateWorkstationPhonePage = reduxForm({
  form: 'createWorkstationPhone',
  validate,
})(CreateWorkstationPhonePage);

class CreateWorkstationPhonePageContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    onAssignToWorkstation: PropTypes.func,
    mutateCreatePhone: PropTypes.func,
    isPbsTeam: PropTypes.bool,
    isPharmacyTeam: PropTypes.bool,
  };

  handleClickBack = () => this.props.history.push('/register-phone');

  handleSubmit = async values =>
    this.props.mutateCreatePhone({ ...values }).then(({ data }) => {
      const createdPhoneId = get(data, 'createPhone.data.id');

      this.props.onAssignToWorkstation(createdPhoneId);
      if (this.props?.isPbsTeam) {
        this.props.history.replace('/leadlist');
      } else if (this.props?.isPharmacyTeam) {
        this.props.history.replace('/settings/pharmacylist');
      } else {
        this.props.history.replace('/members');
      }
    });

  render = () => (
    <ReduxCreateWorkstationPhonePage
      onClickBack={this.handleClickBack}
      onSubmit={this.handleSubmit}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  onAssignToWorkstation(phoneId) {
    dispatch(setWorkstationPhoneId({ phoneId }));
  },
});

export default compose(
  withRoles,
  withPhoneMutation,
  connect(null, mapDispatchToProps),
)(CreateWorkstationPhonePageContainer);
