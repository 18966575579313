const states = [
  {
    name: 'Alabama',
    text: 'AL',
    value: 'AL',
  },
  {
    name: 'Alaska',
    text: 'AK',
    value: 'AK',
  },
  {
    name: 'Arizona',
    text: 'AZ',
    value: 'AZ',
  },
  {
    name: 'Arkansas',
    text: 'AR',
    value: 'AR',
  },
  {
    name: 'California',
    text: 'CA',
    value: 'CA',
  },
  {
    name: 'Colorado',
    text: 'CO',
    value: 'CO',
  },
  {
    name: 'Connecticut',
    text: 'CT',
    value: 'CT',
  },
  {
    name: 'Delaware',
    text: 'DE',
    value: 'DE',
  },
  {
    name: 'District Of Columbia',
    text: 'DC',
    value: 'DC',
  },
  {
    name: 'Florida',
    text: 'FL',
    value: 'FL',
  },
  {
    name: 'Georgia',
    text: 'GA',
    value: 'GA',
  },
  {
    name: 'Hawaii',
    text: 'HI',
    value: 'HI',
  },
  {
    name: 'Idaho',
    text: 'ID',
    value: 'ID',
  },
  {
    name: 'Illinois',
    text: 'IL',
    value: 'IL',
  },
  {
    name: 'Indiana',
    text: 'IN',
    value: 'IN',
  },
  {
    name: 'Iowa',
    text: 'IA',
    value: 'IA',
  },
  {
    name: 'Kansas',
    text: 'KS',
    value: 'KS',
  },
  {
    name: 'Kentucky',
    text: 'KY',
    value: 'KY',
  },
  {
    name: 'Louisiana',
    text: 'LA',
    value: 'LA',
  },
  {
    name: 'Maine',
    text: 'ME',
    value: 'ME',
  },
  {
    name: 'Maryland',
    text: 'MD',
    value: 'MD',
  },
  {
    name: 'Massachusetts',
    text: 'MA',
    value: 'MA',
  },
  {
    name: 'Michigan',
    text: 'MI',
    value: 'MI',
  },
  {
    name: 'Minnesota',
    text: 'MN',
    value: 'MN',
  },
  {
    name: 'Mississippi',
    text: 'MS',
    value: 'MS',
  },
  {
    name: 'Missouri',
    text: 'MO',
    value: 'MO',
  },
  {
    name: 'Montana',
    text: 'MT',
    value: 'MT',
  },
  {
    name: 'Nebraska',
    text: 'NE',
    value: 'NE',
  },
  {
    name: 'Nevada',
    text: 'NV',
    value: 'NV',
  },
  {
    name: 'New Hampshire',
    text: 'NH',
    value: 'NH',
  },
  {
    name: 'New Jersey',
    text: 'NJ',
    value: 'NJ',
  },
  {
    name: 'New Mexico',
    text: 'NM',
    value: 'NM',
  },
  {
    name: 'New York',
    text: 'NY',
    value: 'NY',
  },
  {
    name: 'North Carolina',
    text: 'NC',
    value: 'NC',
  },
  {
    name: 'North Dakota',
    text: 'ND',
    value: 'ND',
  },
  {
    name: 'Ohio',
    text: 'OH',
    value: 'OH',
  },
  {
    name: 'Oklahoma',
    text: 'OK',
    value: 'OK',
  },
  {
    name: 'Oregon',
    text: 'OR',
    value: 'OR',
  },
  {
    name: 'Pennsylvania',
    text: 'PA',
    value: 'PA',
  },
  {
    name: 'Rhode Island',
    text: 'RI',
    value: 'RI',
  },
  {
    name: 'South Carolina',
    text: 'SC',
    value: 'SC',
  },
  {
    name: 'South Dakota',
    text: 'SD',
    value: 'SD',
  },
  {
    name: 'Tennessee',
    text: 'TN',
    value: 'TN',
  },
  {
    name: 'Texas',
    text: 'TX',
    value: 'TX',
  },
  {
    name: 'Utah',
    text: 'UT',
    value: 'UT',
  },
  {
    name: 'Vermont',
    text: 'VT',
    value: 'VT',
  },
  {
    name: 'Virginia',
    text: 'VA',
    value: 'VA',
  },
  {
    name: 'Washington',
    text: 'WA',
    value: 'WA',
  },
  {
    name: 'West Virginia',
    text: 'WV',
    value: 'WV',
  },
  {
    name: 'Wisconsin',
    text: 'WI',
    value: 'WI',
  },
  {
    name: 'Wyoming',
    text: 'WY',
    value: 'WY',
  },
];

export default states;
