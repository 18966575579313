import PropTypes from 'prop-types';
import React from 'react';
import { sortList } from '@zipdrug/ui';
import SettingsAccordionList from '../SettingsAccordionList';
import PhoneListItemContainer from './PhoneListItemContainer';
import PhoneListItemFormContainer from './PhoneListItemFormContainer';

const sortPhonesByLabel = list => sortList(list, 'label');

const PhoneList = ({ phones }) => (
  <SettingsAccordionList
    createItemButtonText="Add phone"
    createItemFormRenderer={<PhoneListItemFormContainer />}
    emptyStateText="Get started by adding a phone number"
    itemRenderer={<PhoneListItemContainer />}
    items={phones}
    listLabel="phone"
    sortList={sortPhonesByLabel}
    title="Phones"
  />
);

PhoneList.propTypes = {
  phones: PropTypes.arrayOf(PropTypes.object),
};

export default PhoneList;
