import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';
import withRoles from 'hoc/withRoles';
import {
  Button,
  ButtonRow,
  colors,
  Column,
  Div,
  font,
  FormSelectField,
  FormTextField,
  space,
} from '@zipdrug/ui';
import { Row, Typography } from 'antd';
import { UserTypesMapping } from '../contexts/utils/models';

const sx = StyleSheet.create({
  buttonRow: {
    marginTop: space.half,
  },
  container: {
    color: 'inherit',
    marginTop: space.get(1.5),
  },
  emailField: {
    maxWidth: '23.8rem',
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
    marginRight: font.size.xSmall,
    ':last-child': {
      marginRight: 0,
    },
  },
  formFieldInput: {
    textOverflow: 'ellipsis',
  },
  successMessage: {
    color: colors.green,
  },
});

const BUTTON_PROPS = {
  focusable: true,
  size: 'large',
  styles: sx.button,
};

const FORMFIELD_PROPS = {
  inputStyles: sx.formFieldInput,
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const { Text } = Typography;

const UserProfileForm = ({
  handleSubmit,
  isCurrentUser,
  onClickCancel,
  submitSucceeded,
  submitting,
  titleTypes,
  userRoleTypes,
  user,
  isSystemAdminOrAsc,
}) => (
  <Column styles={sx.container}>
    <Row>
      <FormTextField
        {...FORMFIELD_PROPS}
        id="e2e-userProfileFormFirstNameForm"
        label="First Name"
        name="first_name"
        placeholder="First name"
      />
      <FormTextField
        {...FORMFIELD_PROPS}
        id="e2e-userProfileFormLastNameForm"
        label="Last Name"
        name="last_name"
        placeholder="Last name"
      />
      {!isCurrentUser && isSystemAdminOrAsc ? (
        <FormSelectField
          {...FORMFIELD_PROPS}
          id="e2e-userProfileFormTitleSelectField"
          items={titleTypes}
          label="Title"
          name="title"
          placeholder="Select title..."
        />
      ) : (
        <div>
          <Row style={{ marginBottom: 8 }}>
            <Text style={{ color: '#5009B5', fontSize: 13 }}>Title</Text>
          </Row>
          <Row>
            <Text style={{ padding: 10 }}>{user.title}</Text>
          </Row>
        </div>
      )}
    </Row>
    <Row>
      <div>
        <Row style={{ marginBottom: 8 }}>
          <Text style={{ color: '#5009B5', fontSize: 13 }}>Email address</Text>
        </Row>
        <Row>
          <Text style={{ padding: 10 }}>{user.email}</Text>
        </Row>
      </div>
      {!isCurrentUser && isSystemAdminOrAsc ? (
        <>
          <FormSelectField
            {...FORMFIELD_PROPS}
            id="e2e-userProfileFormUserTypeSelectField"
            items={userRoleTypes}
            label="User Type"
            name="user_type"
          />
          <FormTextField
            {...FORMFIELD_PROPS}
            label="ZipDrug Pharmacy User Group Access Ticket Number"
            name="ticket_number"
            placeholder="REQ..."
            styles={sx.formField}
          />
        </>
      ) : (
        <div>
          <Row style={{ marginBottom: 8 }}>
            <Text style={{ color: '#5009B5', fontSize: 13 }}>User Type</Text>
          </Row>
          <Row>
            <Text style={{ padding: 10 }}>{UserTypesMapping[user.roles[0]?.type]}</Text>
          </Row>
        </div>
      )}
    </Row>
    <ButtonRow styles={sx.buttonRow}>
      <Button
        {...BUTTON_PROPS}
        id="e2e-userProfileFormUpdateButton"
        loading={submitting}
        onClick={handleSubmit}
        text={`Update${isCurrentUser ? ' profile' : ''}`}
        type="primary"
      />
      {!isCurrentUser && (
        <Button {...BUTTON_PROPS} onClick={onClickCancel} outline text="Cancel" type="secondary" />
      )}
      {isCurrentUser && submitSucceeded && !submitting && (
        <Div styles={sx.successMessage}>Success! Your profile has been updated.</Div>
      )}
    </ButtonRow>
  </Column>
);

UserProfileForm.defaultProps = {
  isCurrentUser: false,
};

UserProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  isCurrentUser: PropTypes.bool,
  onClickCancel: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  submitting: PropTypes.bool,
  titleTypes: PropTypes.arrayOf(PropTypes.object),
  userRoleTypes: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  isSystemAdminOrAsc: PropTypes.bool,
};

export default withRoles(UserProfileForm);
