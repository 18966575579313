import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

export const PharmacyContext = React.createContext();

const PharmacyContextProvider = ({ children, pharmacyId }) => {
  return (
    <PharmacyContext.Provider
      value={{
        pharmacyId,
      }}
    >
      {children}
    </PharmacyContext.Provider>
  );
};

const mapStateToProps = state => {
  return {
    pharmacyId: get(state, 'query.all.pharmacy_id'),
  };
};

PharmacyContextProvider.propTypes = {
  children: PropTypes.node,
  pharmacyId: PropTypes.number,
};

export default connect(mapStateToProps)(React.memo(PharmacyContextProvider));
