import { groupBy, padStart } from 'lodash';

const groupClaimsByActiveStatus = claims =>
  groupBy(claims, ({ is_active }) => (is_active ? 'active' : 'inactive'));

const groupClaimsByNdcCode = claims => groupBy(claims, ({ ndc_code }) => ndc_code);

const padNdcCodes = claims =>
  claims.map(({ ndc_code, ...claim }) => ({
    ...claim,
    ndc_code: padStart(ndc_code, 10, '0'),
  }));

export default function buildRxListsFromClaims(claims) {
  const claimsByActiveStatus = groupClaimsByActiveStatus(padNdcCodes(claims));

  const activeClaimsByNdcCode = groupClaimsByNdcCode(claimsByActiveStatus.active);
  const inactiveClaimsByNdcCode = groupClaimsByNdcCode(claimsByActiveStatus.inactive);

  return {
    active: Object.keys(activeClaimsByNdcCode).length ? activeClaimsByNdcCode : null,
    inactive: Object.keys(inactiveClaimsByNdcCode).length ? inactiveClaimsByNdcCode : null,
  };
}
