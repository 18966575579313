import { fields, patients } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { pick } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, withHandlers } from 'recompose';
import ContactList from './ContactList';

const sanitizePhones = phones => phones.slice(0).map(phone => pick(phone, ...fields.PhoneModifier));

export default compose(
  graphql(patients.mutateUpdatePatient, { name: 'mutateUpdatePatient' }),
  withHandlers({
    onUpdateContactOrder: props => async ({ direction, order }) => {
      const { mutateUpdatePatient, contactItem } = props;

      const newOrder = order - direction;

      const phones = sanitizePhones(contactItem.phones);
      if (newOrder < 0 || newOrder >= phones.length) return;

      phones[order].order = newOrder;
      phones[newOrder].order = order;

      await alertOnError(
        mutateUpdatePatient({
          variables: {
            id: contactItem.id,
            modifier: { phones },
          },
        }),
      );
    },
  }),
)(ContactList);
