/* eslint-disable react/prop-types */
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { LeadListContext, ActionTypes } from 'contexts/LeadListProvider';
import { StyleSheet, css } from 'aphrodite';
import { SearchOutlined, FilterFilled } from '@ant-design/icons';
import SearchBox from './SearchBox';

const sx = StyleSheet.create({
  searchButton: { color: '#5009B5' },
});

const FilterSearchBox = (
  dataIndex,
  placeholder,
  defaultFilterValue,
  onFilterDropdownCallBack,
  options,
  loading,
) => {
  const { state, dispatch } = useContext(LeadListContext);
  const { tableActions } = state;

  const onSearch = useCallback((searchValue, setSelectedKeys) => {
    setSelectedKeys(searchValue);
  });

  const onFilterSelection = (confirm, option, setSelectedKeys) => {
    if (option?.value) {
      setSelectedKeys(option.value);
    }
    confirm();
  };

  const onReset = (clearFilters, setSelectedKeys) => {
    setSelectedKeys('');
    clearFilters();
    dispatch({
      type: ActionTypes.UPDATE_LEADLIST_TABLE_SETTINGS,
      payload: {
        ...tableActions,
        filters: {
          ...tableActions?.filters,
          [dataIndex]: null,
        },
      },
    });
  };

  const SearchIcon = filtered => {
    return <SearchOutlined className={filtered ? css(sx.searchButton) : undefined} />;
  };

  const FilterIcon = () => {
    return <FilterFilled />;
  };

  return {
    filterDropdown: ({ selectedKeys, setSelectedKeys, confirm, clearFilters }) => {
      return (
        <SearchBox
          dataIndex={dataIndex}
          placeholder={placeholder}
          options={options}
          loading={loading}
          onSearch={searchValue => onSearch(searchValue, setSelectedKeys)}
          onFilterSelection={(value, option) => {
            onFilterSelection(confirm, option, setSelectedKeys);
          }}
          onReset={() => onReset(clearFilters, setSelectedKeys)}
          value={selectedKeys?.length ? selectedKeys : ''}
          defaultValue={defaultFilterValue || ''}
        />
      );
    },
    filterIcon: filtered => {
      return onFilterDropdownCallBack ? FilterIcon() : SearchIcon(filtered);
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible && onFilterDropdownCallBack) {
        onFilterDropdownCallBack();
      }
    },
  };
};

SearchBox.propTypes = {
  dataIndex: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FilterSearchBox;
