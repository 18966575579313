import gql from 'graphql-tag';

const UPDATE_MEMBER_STATUS = gql`
  mutation updateLeadChurns($ids: [Int]!, $modifiers: LeadChurnModifiers!) {
    updateLeadChurns(ids: $ids, modifiers: $modifiers) {
      data {
        id
        assigned_user_id
        filtered_out
        filtered_by_user_id
      }
    }
  }
`;

export default UPDATE_MEMBER_STATUS;
