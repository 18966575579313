import PropTypes from 'prop-types';
import React from 'react';
import { Bold } from '@zipdrug/ui';
import SiteDownContainer from './SiteDownContainer';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';

const SiteDownPage = ({ history }) => (
  <StandaloneFormPageTemplate title={<Bold>The site is currently down</Bold>}>
    <SiteDownContainer history={history} withSiteBypass />
  </StandaloneFormPageTemplate>
);

SiteDownPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default SiteDownPage;
