import PropTypes from 'prop-types';
import { createElement, Component } from 'react';
import ConfirmDeleteActivityItemModalContainer from '../patients/activity-feed/ConfirmDeleteActivityItemModalContainer';

export default class ModalConductor extends Component {
  static propTypes = {
    modalName: PropTypes.string,
    modalProps: PropTypes.object,
    onClose: PropTypes.func,
  };

  componentDidMount = () => this.handleToggleDocumentScroll(!!this.props.modalName);

  componentWillReceiveProps = ({ modalName }) => this.handleToggleDocumentScroll(!!modalName);

  componentWillUnmount = () => this.handleToggleDocumentScroll(false);

  handleToggleDocumentScroll = disableScroll =>
    document.body.classList.toggle('disableScroll', disableScroll);

  renderModal = template =>
    createElement(template, {
      ...this.props.modalProps,
      onClose: this.props.onClose,
    });

  render() {
    switch (this.props.modalName) {
      case 'CONFIRM_DELETE_ACTIVITY_ITEM':
        return this.renderModal(ConfirmDeleteActivityItemModalContainer);

      default:
        return null;
    }
  }
}
