import { border, colors, font, Icon, Row, space, Span, TimelineTooltip, unit } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import { startCase, upperFirst } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';

const sx = StyleSheet.create({
  container: {
    width: '100%',
  },
  label: {
    color: colors.gray52,
    cursor: 'default',
    flex: 'none',
    fontSize: font.size.xSmall,
    fontWeight: font.weight.medium,
    lineHeight: font.lineHeight.tight,
    textTransform: 'uppercase',
  },
  labelExpanded: {
    maxWidth: '50%',
    whiteSpace: 'nowrap',
    ':last-of-type': {
      textAlign: 'right',
    },
  },
  labelMinimized: {
    ...border.build('right'),
    marginRight: space.half,
    paddingRight: space.half,
  },
  star: {
    color: colors.yellow,
  },
});

const LABEL_TOOLTIP_DISTANCE = unit(font.size.xSmall) * font.ROOT_FONT_SIZE_PX;

const TIMELINE_LABEL_TOOLTIP_CONTENT = {
  bestOutcome: {
    // This moment returns days until end of year
    text: `${moment(moment().endOf('year')).diff(moment().today, 'days')} days remaining`,
    title: 'PDC if fully adherent rest of year',
  },
  currentPDC: {
    text: 'Adherence score',
    title: 'Proportion of days covered',
  },
};

const AdherenceStarIcon = ({ marginDirection }) => (
  <Icon
    name="star"
    style={{ [`margin${upperFirst(marginDirection)}`]: space.fifth }}
    styles={sx.star}
  />
);

AdherenceStarIcon.propTypes = {
  marginDirection: PropTypes.oneOf(['left', 'right']),
};

const TimelineLabelToolTip = ({ children, name, ...props }) => (
  <TimelineTooltip
    {...props}
    {...TIMELINE_LABEL_TOOLTIP_CONTENT[name]}
    distance={LABEL_TOOLTIP_DISTANCE}
  >
    <Span>{children || startCase(name)}</Span>
  </TimelineTooltip>
);

TimelineLabelToolTip.propTypes = {
  children: PropTypes.node,
  name: PropTypes.oneOf(['bestOutcome', 'currentPDC']),
};

const AdherenceChartLabels = ({ bestOutcomePDC, currentPDC, isExpanded }) => {
  const isAdherent = currentPDC >= 80;

  return isExpanded ? (
    <Row align="center" justify="space-between" styles={sx.container}>
      <Row align="center" styles={[sx.label, sx.labelExpanded]}>
        {isAdherent && <AdherenceStarIcon marginDirection="right" />}
        <Span style={{ color: isAdherent ? colors.green : colors.red }}>
          {`${currentPDC}%`}&nbsp;
        </Span>
        <TimelineLabelToolTip name="currentPDC" />
      </Row>
      <Row align="center" styles={[sx.label, sx.labelExpanded]}>
        <TimelineLabelToolTip name="bestOutcome" />
        <Span>&nbsp;{`${bestOutcomePDC}%`}</Span>
        {!isAdherent && <AdherenceStarIcon marginDirection="left" />}
      </Row>
    </Row>
  ) : (
    <Row styles={[sx.label, sx.labelMinimized]}>
      <TimelineLabelToolTip name="currentPDC">
        <Span style={{ color: isAdherent ? colors.green : colors.red }}>
          {`${currentPDC}%`}&nbsp;
        </Span>
        <Span>PDC</Span>
      </TimelineLabelToolTip>
    </Row>
  );
};

AdherenceChartLabels.propTypes = {
  bestOutcomePDC: PropTypes.number,
  currentPDC: PropTypes.number,
  isExpanded: PropTypes.bool,
};

export default AdherenceChartLabels;
