import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';

const COLOR = {
  purple: 'white',
  purpleOutline: '#5009B5',
  green: 'white',
  red: '#FF4848',
  brightGreen: '#22CC0D',
};

const BACKGROUND = {
  purple: '#5009B5',
  purpleOutline: 'white',
  green: '#048802',
  red: '#FF4848',
  brightGreen: '#22CC0D',
};

const getStyles = props => {
  return StyleSheet.create({
    btn: {
      borderRadius: !props.shape ? '6px' : null,
      height: !props.shape ? '40px' : null,
    },
    color: {
      color: COLOR[props.type],
      background: BACKGROUND[props.type],
      borderColor: COLOR[props.type],
      ':hover': {
        color: COLOR[props.type],
        background: BACKGROUND[props.type],
        borderColor: COLOR[props.type],
        filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      },
      ':active': {
        color: COLOR[props.type],
        background: BACKGROUND[props.type],
        borderColor: COLOR[props.type],
        filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      },
      ':focus': {
        color: COLOR[props.type],
        background: BACKGROUND[props.type],
        borderColor: COLOR[props.type],
        filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      },
    },
    square: {
      width: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0px',
    },
  });
};

const CustomButton = ({ styles, square, ...props }) => {
  const sx = getStyles(props);
  let buttonStyles = [sx.btn, sx.color];

  if (square) {
    buttonStyles.push(sx.square);
  }

  if (styles) {
    if (Array.isArray(styles)) {
      buttonStyles = [...buttonStyles, ...styles];
    } else {
      buttonStyles.push(styles);
    }
  }

  return (
    <Button className={css(buttonStyles)} {...props}>
      {props.children}
    </Button>
  );
};

CustomButton.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  styles: PropTypes.any,
  square: PropTypes.bool,
};

export default CustomButton;
