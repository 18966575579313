const languageTypes = [
  {
    name: 'Arabic',
    key: 'arabic',
  },
  {
    name: 'Armenian',
    key: 'armenian',
  },
  {
    name: 'Cambodian',
    key: 'cambodian',
  },
  {
    name: 'Chinese',
    key: 'chinese',
  },
  {
    name: 'Croatian',
    key: 'croatian',
  },
  {
    name: 'English',
    key: 'english',
  },
  {
    name: 'Farsi',
    key: 'farsi',
  },
  {
    name: 'French',
    key: 'french',
  },
  {
    name: 'German',
    key: 'german',
  },
  {
    name: 'Gujarati',
    key: 'gujarati',
  },
  {
    name: 'Haitian Creole',
    key: 'haitiancreole',
  },
  {
    name: 'Hindi',
    key: 'hindi',
  },
  {
    name: 'Hmong',
    key: 'hmong',
  },
  {
    name: 'Italian',
    key: 'italian',
  },
  {
    name: 'Japanese',
    key: 'japanese',
  },
  {
    name: 'Korean',
    key: 'korean',
  },
  {
    name: 'Loatian',
    key: 'loatian',
  },
  {
    name: 'Polish',
    key: 'polish',
  },
  {
    name: 'Portuguese',
    key: 'portuguese',
  },
  {
    name: 'Punjabi',
    key: 'punjabi',
  },
  {
    name: 'Russian',
    key: 'russian',
  },
  {
    name: 'Samoan',
    key: 'samoan',
  },
  {
    name: 'Serbian',
    key: 'serbian',
  },
  {
    name: 'Spanish',
    key: 'spanish',
  },
  {
    name: 'Tagalog',
    key: 'tagalog',
  },
  {
    name: 'Telugu',
    key: 'telugu',
  },
  {
    name: 'Tongan',
    key: 'tongan',
  },
  {
    name: 'Vietnamese',
    key: 'vietnamese',
  },
  {
    name: 'Other',
    key: 'other',
  },
];

export default languageTypes;
