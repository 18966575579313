import { dateUtils, TimelineAxis } from '@zipdrug/ui';
import aphroditePropType from 'aphrodite-prop-type';
import React from 'react';

const buildSegmentsForCurrentYear = () =>
  dateUtils.daysInMonthsOfYear().map((month, index, array) => {
    const width = `${(month / dateUtils.daysInYear()) * 100}%`;
    const segment = { width };

    if (index === 0) segment.label = 'Jan';
    if (index === array.length - 1) segment.label = 'Dec';

    return segment;
  });

const FillTimelineAxis = ({ styles }) => (
  <TimelineAxis segments={buildSegmentsForCurrentYear()} styles={styles} />
);

FillTimelineAxis.propTypes = {
  styles: aphroditePropType,
};

export default FillTimelineAxis;
