import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import {
  Button,
  colors,
  Div,
  font,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  space,
} from '@zipdrug/ui';

const sx = StyleSheet.create({
  header: {
    paddingBottom: 0,
  },
  listItem: {
    color: colors.gray45,
    fontSize: font.size.medium,
    marginBottom: space.get(0.75),
  },
  wrapperStyles: {
    minWidth: '47rem',
  },
});

const BUTTON_PROPS = {
  focusable: true,
  small: true,
};

export default class ConfirmDeleteActivityItemModal extends Component {
  static propTypes = {
    error: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
  };

  // eslint-disable-next-line react/no-find-dom-node
  componentDidMount = () => this.closeButtonRef.current.focus();

  closeButtonRef = createRef(null);
  handleCloseButtonRef = ref => {
    this.closeButtonRef = ref;
  };

  render = () => (
    <Modal
      {...this.props}
      onClose={this.props.onClose}
      wrapperProps={{ type: 'warning' }}
      wrapperStyles={sx.wrapperStyles}
    >
      <ModalHeader title="Delete note" styles={sx.header} />
      <ModalSection>
        <Div styles={sx.listItem}>Are you sure you want to delete this note?</Div>
      </ModalSection>
      <ModalFooter error={this.props.error}>
        <Button
          {...BUTTON_PROPS}
          onClick={this.props.onClose}
          outline
          ref={this.handleCloseButtonRef}
          text="Go back"
          type="default"
        />
        <Button
          {...BUTTON_PROPS}
          id="e2e-ConfirmDeleteActivityItemModalDeleteButton"
          loading={this.props.submitting}
          onClick={this.props.onSubmit}
          text="Delete note"
          type="warning"
        />
      </ModalFooter>
    </Modal>
  );
}
