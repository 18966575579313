import update from 'immutability-helper';
import { createAction, handleActions } from 'redux-actions';

const REFETCH_PATIENTS = 'care-site/query/REFETCH_PATIENTS';
const SEARCH_PATIENTS = 'care-site/query/SEARCH_PATIENTS';
const SET_PATIENT_ID = 'care-site/query/SET_PATIENT_ID';
const SET_PHARMACY_ID = 'care-site/query/SET_PHARMACY_ID';
const SET_WORKSTATION_PHONE_ID = 'care-site/query/SET_WORKSTATION_PHONE_ID';
const SHOW_SNOOZED = 'care-site/query/SHOW_SNOOZED';
const SET_PASSWORD_EXPIRATION = 'care-site/passwordExpiration/SET_PASSWORD_EXPIRATION';
const SET_SITE_DOWN_BYPASS = 'care-site/query/SET_SITE_DOWN_BYPASS';

const initialState = {
  all: {
    pharmacy_id: null,
    workstationPhoneId: null,
    bypass: false,
  },
  patients: {
    refetchPatientsCount: 0,
    search: '',
    showSnoozed: false,
  },
  user: {
    passwordExpiration: null,
  },
};

export default handleActions(
  {
    [SEARCH_PATIENTS]: (state, action) => {
      const { search } = action.payload;

      return update(state, {
        patients: {
          search: { $set: search },
        },
      });
    },

    [SET_PATIENT_ID]: (state, action) => {
      const { patient_id } = action.payload;

      return update(state, {
        all: {
          patient_id: { $set: patient_id },
        },
      });
    },

    [SET_PHARMACY_ID]: (state, action) => {
      const { pharmacy_id } = action.payload;

      return update(state, {
        all: {
          pharmacy_id: { $set: pharmacy_id },
        },
      });
    },

    [SET_WORKSTATION_PHONE_ID]: (state, action) => {
      const { phoneId } = action.payload;

      return update(state, {
        all: {
          workstationPhoneId: { $set: phoneId },
        },
      });
    },

    [SHOW_SNOOZED]: (state, action) => {
      const { showSnoozed } = action.payload;

      return update(state, {
        patients: {
          showSnoozed: { $set: showSnoozed },
        },
      });
    },

    [REFETCH_PATIENTS]: (state, action) => {
      const { refetchPatientsCount } = action.payload;

      return update(state, {
        patients: {
          refetchPatientsCount: { $set: refetchPatientsCount },
        },
      });
    },
    [SET_PASSWORD_EXPIRATION]: (state, action) => {
      const { passwordExpiration } = action.payload;
      return update(state, {
        user: {
          passwordExpiration: { $set: passwordExpiration },
        },
      });
    },
    [SET_SITE_DOWN_BYPASS]: (state, action) => {
      const { bypass } = action.payload;

      return update(state, {
        all: {
          bypass: { $set: bypass },
        },
      });
    },
  },
  initialState,
);

// eslint-disable-next-line max-len
export const refetchPatients = createAction(REFETCH_PATIENTS, ({ refetchPatientsCount }) => ({
  refetchPatientsCount,
}));
export const searchPatients = createAction(SEARCH_PATIENTS, ({ search }) => ({ search }));
export const setPatientId = createAction(SET_PATIENT_ID, ({ patient_id }) => ({ patient_id }));
export const setPharmacyId = createAction(SET_PHARMACY_ID, ({ pharmacy_id }) => ({ pharmacy_id }));
export const setShowSnoozed = createAction(SHOW_SNOOZED, ({ showSnoozed }) => ({ showSnoozed }));
// eslint-disable-next-line max-len
export const setWorkstationPhoneId = createAction(SET_WORKSTATION_PHONE_ID, ({ phoneId }) => ({
  phoneId,
}));
export const setPasswordExpiration = createAction(
  SET_PASSWORD_EXPIRATION,
  ({ passwordExpiration }) => ({ passwordExpiration }),
);
export const setSiteDownBypass = createAction(SET_SITE_DOWN_BYPASS, ({ bypass }) => ({ bypass }));
