/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { get } from 'lodash';
import { Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { StyleSheet, css } from 'aphrodite/no-important';
import withRoles from '../../hoc/withRoles';
import FeatureFlags from '../../../src/constants/featureFlags';
import useFeatureFlags from '../../../src/hooks/useFeatureFlags';

const sx = StyleSheet.create({
  dropdownIcon: {
    marginLeft: '5px',
    marginRight: '5px',
    transform: 'translateY(5px)',
    stroke: '#231E33',
    strokeWidth: '30',
    alignItems: 'inherit',
  },
  OtherPgsDropdownContainer: {
    padding: '0.82rem !important',
    display: 'flex',
    fontWeight: '500',
  },
  dropdownItem: {
    margin: '2.5px 10px',
  },
  buttonContainer: {
    marginRight: '12px',
    padding: '0px !important',
  },
});

const getSettingsItems = (
  currentUser,
  isAscTeam,
  isBdTeam,
  isSystemAdmin,
  isPbsTeam,
  isPharmacyTeam,
  isPbsAdmin,
  hasRole,
  handlers,
) => {
  const callCenterFeatureFlag = get(currentUser, 'feature_flags.callCenter');
  const cohortFlag = get(currentUser, 'feature_flags.cohort');

  const churnedDisabled = useFeatureFlags(FeatureFlags.CHURNED);
  const items = [];

  if (isSystemAdmin) {
    items.push({
      key: '1',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.onLeadListClick()}>
          Lead List
        </div>
      ),
    });
  }
  if (isSystemAdmin || hasRole.pbsLead || hasRole.pbsAdmin) {
    items.push({
      key: '2',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickEnrollersList()}>
          PBS List
        </div>
      ),
    });
  }
  if (isSystemAdmin || isAscTeam) {
    items.push({
      key: '11',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickMemberList()}>
          Members List
        </div>
      ),
    });
  }
  if ((isSystemAdmin || isPbsTeam) && !churnedDisabled) {
    items.push({
      key: '3',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickChurnMembers()}>
          Churn Members
        </div>
      ),
    });
  }
  if (isSystemAdmin || isPbsTeam) {
    items.push({
      key: '4',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickPharmacies()}>
          Pharmacies
        </div>
      ),
    });
  }
  if (isSystemAdmin || isBdTeam || hasRole.projectManager) {
    items.push({
      key: '5',
      label: (
        <div
          className={css(sx.dropdownItem)}
          onClick={() => handlers.handleClickPharmacyNetworks()}
        >
          Pharmacy Networks
        </div>
      ),
    });
  }
  if (isSystemAdmin || isPharmacyTeam || isAscTeam || hasRole.pharmacyBusinessDevAdmin) {
    items.push({
      key: '6',
      label: (
        <div
          className={css(sx.dropdownItem)}
          onClick={() => handlers.handleClickZipcodeAssignment()}
        >
          Zipcode Assignment
        </div>
      ),
    });
  }
  if (isSystemAdmin) {
    items.push({
      key: '7',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickNotes()}>
          Notes
        </div>
      ),
    });
  }
  if (
    isSystemAdmin ||
    hasRole.pbsLead ||
    hasRole.pbsAdmin ||
    hasRole.pharmacyBusinessDevAdmin ||
    hasRole.pharmacyAscAdmin ||
    hasRole.projectManager
  ) {
    items.push({
      key: '7',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickCampaigns()}>
          Campaigns
        </div>
      ),
    });
  }
  if (callCenterFeatureFlag && isSystemAdmin) {
    items.push({
      key: '8',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickVoicemail()}>
          Voicemails & Missed Calls
        </div>
      ),
    });
  }
  if (callCenterFeatureFlag && isSystemAdmin) {
    items.push({
      key: '10',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.handleClickSupervisor()}>
          Supervise Calls
        </div>
      ),
    });
  }
  if (cohortFlag) {
    items.push({
      key: '9',
      label: (
        <div className={css(sx.dropdownItem)} onClick={() => handlers.onCohortListClick()}>
          Mailing Cohort
        </div>
      ),
    });
  }

  return items;
};

const SettingsTopBarDropdown = ({
  isAscTeam,
  isBdTeam,
  isSystemAdmin,
  isPbsTeam,
  isPharmacyTeam,
  isPharmacistTeam,
  isPbsAdmin,
  hasRole,
  currentUser,
  history,
}) => {
  const handlers = {
    handleClickSupervisor: () => history.push('/settings/supervisor'),
    handleClickVoicemail: () => history.push('/settings/voicemail'),
    handleClickPharmacies: () => history.push('/settings/pharmacylist'),
    handleClickMemberList: () => history.push('/settings/memberlist'),
    handleClickPharmacyNetworks: () => history.push('/settings/pharmacynetworkslist'),
    handleClickZipcodeAssignment: () => history.push('/settings/zipcodeassignment'),
    handleClickCampaigns: () => history.push('/settings/campaigns'),
    handleClickEnrollersList: () => history.push('/settings/pbslist'),
    handleClickNotes: () => history.push('/settings/noteslist'),
    onLeadListClick: () => history.push('/leadlist'),
    onCohortListClick: () => history.push('/cohortlist'),
    handleClickChurnMembers: () => history.push('/settings/churnlist'),
  };

  const items = getSettingsItems(
    currentUser,
    isAscTeam,
    isBdTeam,
    isSystemAdmin,
    isPbsTeam,
    isPharmacyTeam,
    isPbsAdmin,
    hasRole,
    handlers,
    isAscTeam,
  );

  return (
    <Button type="text" className={css(sx.buttonContainer)}>
      {items?.length > 0 && (
        <Space direction="vertical">
          {!isPharmacistTeam && (
            <Dropdown menu={{ items }} trigger={['click']}>
              <div className={css(sx.OtherPgsDropdownContainer)}>
                Other Pages
                {<DownOutlined className={css(sx.dropdownIcon)} />}
              </div>
            </Dropdown>
          )}
        </Space>
      )}
    </Button>
  );
};
SettingsTopBarDropdown.propTypes = {
  currentUser: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    feature_flags: PropTypes.object,
  }),
  history: PropTypes.object,
  hasRole: PropTypes.object,
  isAscTeam: PropTypes.bool,
  isBdTeam: PropTypes.bool,
  isSystemAdmin: PropTypes.bool,
  isPbsTeam: PropTypes.bool,
  isPharmacyTeam: PropTypes.bool,
  isPharmacistTeam: PropTypes.bool,
  isPbsAdmin: PropTypes.bool,
};

export default withRouter(withRoles(SettingsTopBarDropdown));
