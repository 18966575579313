import React, { useState, useEffect } from 'react';
import _get from 'lodash/get';
import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import { Button, Tooltip } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import ClickOutside from 'react-click-outside';
import { CloseIcon, SwitchPatientIcon } from './svg';
import { GET_PHONES_WITH_ASSOCIATIONS } from './queries';
import { getFormattedPhoneAssociations } from './util';
import commonSx from './styles';

const sx = StyleSheet.create({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  bold: {
    fontWeight: 'bold',
  },
  switchPatientContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  switchPatientOpen: {
    position: 'absolute',
    top: '45px',
    backgroundColor: 'white',
    zIndex: '1000',
    alignItems: 'center',
    width: '350px',
    border: '1px solid #DDDDDD',
    borderRadius: '10px',
  },
  innerBoxMargin: {
    margin: '0 30px 20px 30px',
  },
  submitBtn: {
    marginTop: '5px',
    width: '100%',
  },
  closeIcon: {
    alignSelf: 'flex-end',
    padding: '5px 5px 0px 0px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
});

function calculateAge(birthday) {
  return Math.floor(moment().diff(moment(birthday), 'years', true));
}

function getGender(sex) {
  return sex === 'female' ? 'F' : 'M';
}

const SwitchPatient = ({ participants, patientNumber, onChangeSelectedPatient }) => {
  const apolloClient = useApolloClient();
  const [switchPatientOpen, setSwitchPatientOpen] = useState(false);
  const [phones, setPhones] = useState([]);

  // find the current patient on the call to filter out of the switch
  let currentPatientOnCallId;
  Object.keys(participants).forEach(callSid => {
    const participant = participants[callSid];

    if (participant.type === 'patient') {
      currentPatientOnCallId = participant.typeId;
    }
  });

  useEffect(() => {
    async function getPhones() {
      const phoneRecords = await apolloClient.query({
        query: GET_PHONES_WITH_ASSOCIATIONS,
        variables: { query: { number: patientNumber } },
        fetchPolicy: 'network-only',
      });

      const { patients } = getFormattedPhoneAssociations(_get(phoneRecords, 'data.phones.data'));
      setPhones(patients);
    }

    if (switchPatientOpen) {
      getPhones();
    }
  }, [switchPatientOpen, setPhones, patientNumber]);

  const handleClickOutside = () => {
    setSwitchPatientOpen(false);
  };

  const filteredPatients = phones && phones.filter(phone => phone.patient_id !== null);
  return (
    <div className={css(sx.switchPatientContainer, commonSx.ml10)}>
      <Tooltip placement="bottom" title="Switch User">
        <Button
          type="primary"
          className={css(commonSx.square)}
          onClick={() => {
            setSwitchPatientOpen(!switchPatientOpen);
          }}
        >
          <SwitchPatientIcon />
        </Button>
      </Tooltip>

      <ClickOutside onClickOutside={handleClickOutside} style={{ alignSelf: 'flex-start' }}>
        <div className={css(switchPatientOpen ? [sx.switchPatientOpen, sx.flexCol] : sx.hidden)}>
          <button
            className={css(sx.closeIcon)}
            onClick={() => {
              setSwitchPatientOpen(!switchPatientOpen);
            }}
          >
            <CloseIcon />
          </button>

          <div className={css(sx.flexCol, sx.innerBoxMargin)}>
            {_get(filteredPatients, 'length') > 1 ? (
              <div>This number is also associated with:</div>
            ) : (
              <div>There are no associated patient with this number.</div>
            )}
            {_get(filteredPatients, 'length') > 0 &&
              filteredPatients.map((patient, index) => {
                // We're only displaying the list of candidate that is not currently on the call
                if (patient.patient_id === currentPatientOnCallId) {
                  return null;
                }

                return (
                  <div className="fs-hide" key={`${patient.first_name}${index + 1}`}>
                    <div
                      style={{ display: 'flex', margin: '20px 0', justifyContent: 'space-between' }}
                    >
                      <div
                        className={css([sx.bold, commonSx.mr12])}
                      >{`${patient.first_name} ${patient.last_name}`}</div>
                      <div className={css(commonSx.mr12)}>
                        {calculateAge(patient.birthday)}
                        {getGender(patient.sex)}
                      </div>
                      <div>{`DOB: ${moment(patient.birthday)
                        .utc()
                        .format('MM/DD/YYYY')}`}</div>
                    </div>

                    <Button
                      type="primary"
                      className={css(sx.submitBtn, sx.bold)}
                      onClick={() => onChangeSelectedPatient(patient.patient_id, patient.phone_id)}
                    >
                      Switch to this patient
                    </Button>
                  </div>
                );
              })}
          </div>
        </div>
      </ClickOutside>
    </div>
  );
};

SwitchPatient.propTypes = {
  participants: PropTypes.shape({
    type: PropTypes.string,
    typeId: PropTypes.number,
  }),
  patientNumber: PropTypes.string,
  onChangeSelectedPatient: PropTypes.func,
};

export default SwitchPatient;
