import gql from 'graphql-tag';

const GET_PHARM_NETWORK_TYPES = gql`
  query pharmacyNetworksGroupedByLob($limit: Int, $skip: Int) {
    pharmacyNetworksGroupedByLob(limit: $limit, skip: $skip) {
      data {
        name
        type
        groups {
          id
          name
          lob
        }
      }
    }
  }
`;

export default GET_PHARM_NETWORK_TYPES;
