import React from 'react';

const Unmute = () => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10.1719" y="1" width="6.34483" height="13.4828" rx="3.17241" fill="white" />
    <path
      d="M7 8.93066V11.31C7 14.8141 9.84068 17.6548 13.3448 17.6548V17.6548C16.849 17.6548 19.6897 14.8141 19.6897 11.31V8.93066"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <line
      x1="13.5508"
      y1="18.6553"
      x2="13.5508"
      y2="23.0001"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M4.13086 1.12695L23.9977 23.3101"
      stroke="#5009B5"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path d="M2 1L21.8669 23.1832" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default Unmute;
