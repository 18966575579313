import { pharmacies as pharmaciesSelection, withSession } from '@zipdrug/react-api-sdk';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRoles from './withRoles';

const withActivePharmaciesFiltered = Component =>
  graphql(pharmaciesSelection.queryPharmacies, {
    skip: ({ userId, hasRole: { pharmacistUser, pharmacistAdmin } }) =>
      !userId || (pharmacistUser && pharmacistAdmin),
    options: props => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          query: {
            payer_id: props.payer_id ? props.payer_id : null,
            status: 'active',
            pharmacyNetworkTypes: { ...props.pharmacyNetworkTypes },
            plan_id: props?.plan_id,
          },
          // TODO implement paging
          limit: 1000,
        },
      };
    },
    props: ({ data: { pharmacies: result } }) => ({
      activePharmacies: get(result, 'data'),
    }),
  })(Component);

export default Component =>
  compose(withSession, withRoles, withActivePharmaciesFiltered)(Component);
