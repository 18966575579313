const languages = [
  {
    text: 'English',
    value: 'english',
  },
  {
    text: 'Spanish',
    value: 'spanish',
  },
];

export default languages;
