import React from 'react';
import PropTypes from 'prop-types';

const AddUserIcon = ({ color }) => (
  <svg
    width="18px"
    height="21px"
    viewBox="0 0 18 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>adduser</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g
      id="Assigned-vs-Potential-Pharmacies"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Adding-Translation-Service-" transform="translate(-727.000000, -50.000000)">
        <g id="adduser" transform="translate(727.000000, 50.500000)" filter="url(#filter-1)">
          <g>
            <path
              d="M16.3370536,15.6160714 L14.3772321,15.6160714 L14.3772321,13.65625 C14.3772321,13.2120536 14.015625,12.8504464 13.5714286,12.8504464 C13.1272321,12.8504464 12.765625,13.2120536 12.765625,13.65625 L12.765625,15.6160714 L10.8058036,15.6160714 C10.3616071,15.6160714 10,15.9776786 10,16.421875 C10,16.8660714 10.3616071,17.2276786 10.8058036,17.2276786 L12.765625,17.2276786 L12.765625,19.1875 C12.765625,19.6316964 13.1272321,19.9933036 13.5714286,19.9933036 C14.015625,19.9933036 14.3772321,19.6316964 14.3772321,19.1875 L14.3772321,17.2276786 L16.3370536,17.2276786 C16.78125,17.2276786 17.1428571,16.8660714 17.1428571,16.421875 C17.1428571,15.9754464 16.78125,15.6160714 16.3370536,15.6160714 Z M13.5714286,5.01116071 C13.5714286,2.24330357 11.3325893,-8.62801893e-14 8.57142857,-8.62801893e-14 C5.81026786,-8.62801893e-14 3.57142857,2.24330357 3.57142857,5.01116071 C3.57142857,7.77901786 5.81026786,10.0223214 8.57142857,10.0223214 C11.3325893,10.0223214 13.5714286,7.77901786 13.5714286,5.01116071 Z M8.57142857,8.41071429 C6.69866071,8.41071429 5.17857143,6.88839286 5.17857143,5.01116071 C5.17857143,3.13392857 6.69866071,1.61160714 8.57142857,1.61160714 C10.4441964,1.61160714 11.9642857,3.13392857 11.9642857,5.01116071 C11.9642857,6.88839286 10.4441964,8.41071429 8.57142857,8.41071429 Z M13.2589286,11.4196429 C11.9107143,10.5357143 10.3013393,10.0223214 8.57142857,10.0223214 C3.83705357,10.0223214 0,13.8683036 0,18.6138393 L0.00223214286,18.6138393 C0.00223214286,18.6294643 0,18.6428571 0,18.6584821 C0,19.1026786 0.359375,19.4642857 0.803571429,19.4642857 C1.24776786,19.4642857 1.60714286,19.1026786 1.60714286,18.6584821 C1.60714286,18.6428571 1.60714286,18.6294643 1.60491071,18.6138393 L1.60714286,18.6138393 C1.60714286,14.7589286 4.72544643,11.6339286 8.57142857,11.6339286 C9.44642857,11.6339286 10.2834821,11.7946429 11.0535714,12.0915179 C11.7098214,11.7075893 12.4575893,11.46875 13.2589286,11.4196429 Z"
              fill={color}
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

AddUserIcon.propTypes = {
  color: PropTypes.string,
};

export default AddUserIcon;
