import getMeasure from './getMeasure';

export default function getTherapyFills(referenceFill, allFills = []) {
  const measure = getMeasure(referenceFill);
  if (!measure) return [];

  // Filter all the fills by the measure of the reference fill
  const measureFills = allFills.filter(f => getMeasure(f) === measure);

  // Mark fills with a different ndc code as an alternative
  const therapyFills = measureFills.map(fill => {
    const isAlternative = fill.ndc_code !== referenceFill.ndc_code;
    return {
      ...fill,
      isAlternative,
    };
  });

  return therapyFills;
}
