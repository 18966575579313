import { get } from 'lodash';

const sortListByOrderNumber = (array = []) =>
  array.slice(0).sort((a, b) => {
    const valueA = get(a, 'order');
    const valueB = get(b, 'order');

    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
  });

export default sortListByOrderNumber;
