/* eslint-disable react/prop-types */
import {
  border,
  colors,
  Column,
  FlexCentered,
  FlexItem,
  font,
  sortList,
  position,
  Spinner,
} from '@zipdrug/ui';
import { StyleSheet, css } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Typography, message } from 'antd';
import { get, groupBy, toPairs } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import withRoles from 'hoc/withRoles';
import { GET_CAREGAPS } from './graphql/careGaps';
import PageSection from '../../ui/PageSection';
import ColumnHeader from '../ColumnHeader';
import RxPrescriberGroupContainer from './RxPrescriberGroupContainer';
import CareGapModal from './CareGapModal';

const sx = StyleSheet.create({
  emptyState: {
    ...position.cover,
    color: colors.white,
    fontSize: font.size.medium,
  },
  list: {
    ...border.build('left'),
    overflowX: 'hidden',
    overflowY: 'scroll',
    maxHeight: '100vh',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'space-between',
    width: '550px',
    padding: '5px',
    border: '1px solid #FFA39E',
    borderRadius: '4px',
    backgroundColor: '#FFF1F0',
  },
  actionsList: {
    display: 'flex',
    alignItems: 'top',
  },
  actionItem: {
    listStyleType: 'none',
    margin: '4px',
    padding: '0',
  },
  errorIcon: {
    color: '#B50D0D',
    margin: '8px',
  },
  linkButton: {
    height: '16px',
    padding: '0',
    margin: '4px 8px',
  },
  linkText: {
    size: '16px',
    color: '#3A3BC9',
  },
});

const { Text } = Typography;

const groupClaimsByPrescriber = prescriberClaims =>
  toPairs(groupBy(prescriberClaims, ({ prescriber: { npi } }) => npi)).map(([npi, claims]) => ({
    claims,
    npi,
    prescriberName: get(claims, '[0].prescriber.name'),
  }));

const buildRxPrescriberGroupList = allClaims => {
  if (!allClaims) return null;
  const claimsByPrescriberNpi = groupClaimsByPrescriber(allClaims);
  return sortList(claimsByPrescriberNpi, 'prescriberName').map(({ claims, npi }) => (
    <RxPrescriberGroupContainer
      allClaims={allClaims}
      key={npi}
      prescriber={claims[0].prescriber}
      prescriberClaims={claims}
    />
  ));
};

const renderMedications = (error, isLoading, selectedPatientClaims) => {
  const hasClaims = !!(selectedPatientClaims && selectedPatientClaims.length);
  if (isLoading) {
    return <Spinner loading={isLoading} styles={sx.emptyState} />;
  }
  if (!isLoading && !hasClaims && !error) {
    return <FlexCentered styles={sx.emptyState}>No medications</FlexCentered>;
  }
  if (error) {
    return (
      <FlexCentered styles={sx.emptyState}>There was an error retrieving medications.</FlexCentered>
    );
  }
  return buildRxPrescriberGroupList(selectedPatientClaims);
};

const RxPrescriberGroupList = ({
  alertCount,
  error,
  isLoading,
  selectedPatientClaims,
  styles,
  selectedPatientId,
  hasRole,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [careGaps, setCareGaps] = useState([]);
  const [careGapsLoaded, setCareGapsLoaded] = useState(false);

  const [getSelectedPatientCareGaps, { refetch: refetchSelectedPatientCareGaps }] = useLazyQuery(
    GET_CAREGAPS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onError: error => {
        setCareGapsLoaded(false);
        message.error(`Error fetching caregaps: ${error.message}`);
      },
      onCompleted: ({ getCareGaps }) => {
        if (getCareGaps?.data.length) {
          const filterdCareGaps = getCareGaps.data.filter(data => data.care_gap_outcome === null);
          setCareGaps(filterdCareGaps);
          setCareGapsLoaded(true);
        } else {
          setCareGapsLoaded(false);
        }
      },
    },
  );

  useEffect(() => {
    if (selectedPatientId) {
      getSelectedPatientCareGaps({
        variables: {
          id: selectedPatientId,
        },
      });
    }
  }, [selectedPatientId]);

  const openModal = () => {
    setShowModal(true);
  };

  const onClose = () => {
    setShowModal(false);
    refetchSelectedPatientCareGaps();
  };

  return (
    <>
      <Column styles={styles}>
        <ColumnHeader alertCount={alertCount} text="Medications" />
        <PageSection component={FlexItem} styles={sx.list}>
          {careGapsLoaded && careGaps.length > 0 && (
            <div className={css(sx.actionsContainer)}>
              <div className={css(sx.actionsList)}>
                <ExclamationCircleFilled className={css(sx.errorIcon)} />
                {!hasRole.pharmacistUser && !hasRole.pharmacistAdmin ? (
                  <ul className={css(sx.actionItem)}>
                    <li className={css(sx.action)}>{`${careGaps.length} Care Gap${
                      careGaps.length > 1 ? 's' : ''
                    } for this member`}</li>
                  </ul>
                ) : (
                  <ul className={css(sx.actionItem)}>
                    {careGaps?.map(cg => {
                      return <li className={css(sx.action)}>{cg.provider_message_headline}</li>;
                    })}
                  </ul>
                )}
              </div>
              {(hasRole.pharmacistUser || hasRole.pharmacistAdmin) && (
                <Button type="link" onClick={() => openModal()} className={css(sx.linkButton)}>
                  <Text className={css(sx.linkText)} underline>
                    Take Action
                  </Text>
                </Button>
              )}
            </div>
          )}
          {renderMedications(error, isLoading, selectedPatientClaims)}
        </PageSection>
      </Column>
      {showModal && (
        <CareGapModal
          onClose={onClose}
          visible={showModal}
          careGaps={careGaps}
          selectedPatientId={selectedPatientId}
        />
      )}
    </>
  );
};

RxPrescriberGroupList.propTypes = {
  alertCount: PropTypes.number,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  selectedPatientClaims: PropTypes.arrayOf(PropTypes.object),
  styles: aphroditePropType,
  selectedPatientId: PropTypes.number,
  hasRole: PropTypes.object,
};

export default withRoles(RxPrescriberGroupList);
