import { Column, font, margin, padding, Row, Separator, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DropdownHeader from '../../../ui/DropdownHeader';
import SnoozeListItem from './SnoozeListItem';

const sx = StyleSheet.create({
  container: {
    ...padding.build(font.size.small, 0),
    width: '28rem',
  },
  separator: margin.build(space.half, font.size.small),
});

export default class SnoozeList extends Component {
  static propTypes = {
    onClickSnoozeValue: PropTypes.func,
    onToggleEditState: PropTypes.func,
  };

  handleItemClick = value => this.props.onClickSnoozeValue(value);

  render = () => (
    <Column styles={sx.container}>
      <DropdownHeader showBackButton={false} title="Snooze" />
      <Column>
        <SnoozeListItem
          format={value => value.format('h:mma')}
          icon="clock"
          label="1 hour"
          onClick={this.handleItemClick}
          value={moment().add(1, 'hour')}
        />
        <SnoozeListItem
          format={value => value.format('h:mma')}
          icon="clock"
          label="3 hours"
          onClick={this.handleItemClick}
          value={moment().add(3, 'hour')}
        />
      </Column>
      <Separator horizontal styles={sx.separator} />
      <Column>
        <SnoozeListItem
          format={value => value.format('dddd, h:mma')}
          icon="brightness"
          label="Tomorrow"
          onClick={this.handleItemClick}
          value={moment()
            .add(1, 'day')
            .startOf('day')
            .add(9, 'hours')}
        />
        <SnoozeListItem
          format={value => value.format('dddd, h:mma')}
          icon="nextWeek"
          label="Next week"
          onClick={this.handleItemClick}
          value={moment()
            .startOf('week')
            .add({ days: 8, hours: 9 })}
        />
      </Column>
      <Separator horizontal styles={sx.separator} />
      <Row>
        <SnoozeListItem
          icon="calendar"
          label="Pick a date & time"
          onClick={this.props.onToggleEditState}
        />
      </Row>
    </Column>
  );
}
