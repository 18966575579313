import React from 'react';

const PickupIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.1604 11.5737L13.0633 12.6708C12.0858 13.6483 10.5009 13.6483 9.52332 12.6708L7.32919 10.4766C6.35166 9.49912 6.35166 7.91425 7.32919 6.93673L8.42627 5.83965C8.7667 5.49922 8.7667 4.9481 8.42627 4.60855L4.07304 0.255317C3.73261 -0.0851057 3.18149 -0.0851057 2.84194 0.255317L0.708044 2.38922C0.238621 2.85864 -0.0331784 3.49672 0.00325266 4.15958C0.485398 12.9322 7.06638 19.5146 15.8404 19.9967C16.5033 20.0332 17.1414 19.7614 17.6108 19.2919L19.7447 17.1581C20.0851 16.8176 20.0851 16.2665 19.7447 15.927L15.3914 11.5737C15.0519 11.2342 14.4999 11.2342 14.1604 11.5737Z"
      fill="white"
    />
  </svg>
);

export default PickupIcon;
