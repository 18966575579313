import gql from 'graphql-tag';

const UPDATE_PHARMACY_NETWORK_TYPE = gql`
  mutation updatePharmacyNetworkType($id: Int!, $modifiers: PharmacyNetworkTypeUpdateModifiers!) {
    updatePharmacyNetworkType(id: $id, modifiers: $modifiers) {
      data {
        name
        id
        lob
      }
    }
  }
`;

export default UPDATE_PHARMACY_NETWORK_TYPE;
