import gql from 'graphql-tag';

const UPDATE_COHORT_CHILDREN = gql`
  mutation updateCohort($ids: [Int]!, $action: UpdateCohortAction!) {
    updateCohort(ids: $ids, action: $action) {
      data {
        id
        pharmacy_id
        include_in_cohort
      }
    }
  }
`;

export default UPDATE_COHORT_CHILDREN;
