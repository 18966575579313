import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setPatientId } from '../redux/queryReducer';
import withFilterPanelVisibility from './withFilterPanelVisibility';

const mapDispatchToProps = (dispatch, { isFilterPanelOpen, onToggleFilterPanelVisibility }) => ({
  onSelectPatient(patient_id) {
    dispatch(setPatientId({ patient_id }));

    if (isFilterPanelOpen) {
      onToggleFilterPanelVisibility();
    }
  },
});

export default Component =>
  compose(withFilterPanelVisibility, connect(null, mapDispatchToProps))(Component);
