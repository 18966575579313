import { colors, Div, font, padding, position, space } from '@zipdrug/ui';
import { StyleSheet, css } from 'aphrodite/no-important';
import React from 'react';
import PageHeading from '../../ui/PageHeading';
import SettingsPageTemplate from '../SettingsPageTemplate';
import EnrollersList from '../../dashboard/components/EnrollersList/EnrollersList';

const sx = StyleSheet.create({
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  boldDescription: {
    fontWeight: 'bold',
  },
  page: {
    ...position.size('100%'),
    maxWidth: 'initial',
    paddingBottom: 0,
  },
  headingPadding: {
    paddingBottom: 0,
  },
  enrollersList: padding.build(space.get(1.5), space.get(1.5), space.three),
});

const PharmaciesPage = () => (
  <SettingsPageTemplate styles={sx.page}>
    <PageHeading title="PBS List" className={css(sx.headingPadding)} />
    <Div styles={sx.enrollersList}>
      <EnrollersList />
    </Div>
  </SettingsPageTemplate>
);

export default PharmaciesPage;
