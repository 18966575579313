import React from 'react';
import { formatPhone } from '@zipdrug/ui';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';
import { InboundIcon, PickupIcon, HangupIcon, InsuranceIcon } from './svg';
import { COLORS } from './constants';
import commonSx, { getBtnColor } from './styles';

const sx = StyleSheet.create({
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  inboundText: {
    paddingRight: '10px',
    borderRight: `1px solid ${COLORS.GREEN}`,
  },
  phoneAreaContainer: {
    height: '40px',
    marginLeft: '20px',
    border: `1px solid ${COLORS.GREEN}`,
    borderRadius: '6px',
    padding: '6px',
    color: COLORS.GREEN,
  },
  patientContainer: {
    margin: '0px 20px',
    lineHeight: '14px',
  },
  ml10: {
    marginLeft: '10px',
  },
  insurance: {
    height: '40px',
    backgroundColor: '#CEE2FF',
    padding: '10px',
    borderRadius: '32px',
    color: '#2424C2',
    fontWeight: 'bold',
  },
  hangupBtn: {
    backgroundColor: '#E42E2E',
  },
  inboundName: {
    fontWeight: 'bold',
  },
});

const InboundCall = ({
  phone,
  label,
  name,
  insurance,
  patientId,
  acceptHandler,
  rejectHandler,
}) => {
  const isUnknownInbound = name === 'Unknown Caller';
  return (
    <div className={css(sx.flexCenter)}>
      <div className={css(sx.flexCenter, sx.phoneAreaContainer)}>
        <div className={css(sx.flexCenter, sx.inboundText)}>
          <InboundIcon />
          <div className={css(sx.ml10)}>Inbound Call</div>
        </div>
        <div className={css(sx.patientContainer)}>
          <div className={css(sx.inboundName)}>{name}</div>
          <div>{label === 'Pharmacy' ? label : formatPhone(phone)}</div>
        </div>
      </div>

      {/* Show insurance icon if inbound is not an enroller or unknown number */}
      {!isUnknownInbound && patientId && insurance && (
        <div className={css(sx.flexCenter, sx.insurance, commonSx.ml10)}>
          <InsuranceIcon />
          <div className={css(sx.ml10)}>{insurance}</div>
        </div>
      )}

      <Button
        className={css(getBtnColor('brightGreen'), commonSx.square, commonSx.ml10)}
        onClick={acceptHandler}
      >
        <PickupIcon />
      </Button>

      <Button
        className={css(getBtnColor('red'), commonSx.square, commonSx.ml15)}
        onClick={rejectHandler}
      >
        <HangupIcon color="white" />
      </Button>
    </div>
  );
};

InboundCall.propTypes = {
  phone: PropTypes.string,
  label: PropTypes.string,
  patientId: PropTypes.number,
  name: PropTypes.string,
  insurance: PropTypes.string,
  acceptHandler: PropTypes.func,
  rejectHandler: PropTypes.func,
};

export default InboundCall;
