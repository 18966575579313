import gql from 'graphql-tag';

const UPDATE_PHARMACY_HOURS = gql`
  mutation updatePharmacyInfo($pharmacyId: Int!, $modifiers: PharmacyModifiers) {
    updatePharmacyInfo(pharmacyId: $pharmacyId, modifiers: $modifiers) {
      data {
        hours
      }
    }
  }
`;

export default UPDATE_PHARMACY_HOURS;
