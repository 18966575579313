import { dateUtils } from '@zipdrug/ui';
import { get } from 'lodash';
import moment from 'moment';
import { compose, withHandlers, withProps, withState } from 'recompose';
import { reduxForm, SubmissionError } from 'redux-form';
import withCreateDelivery from '../../../hoc/withCreateDelivery';
import withCurrentPharmacy from '../../../hoc/withCurrentPharmacy';
import DeliveryFormConductor from './DeliveryFormConductor';

const OnfleetDashboardURL = 'https://onfleet.com/dashboard';

const buildDateTime = (date, time) => moment(`${date} ${time}`, 'YYYY-MM-DD h:mma').valueOf();

const validate = ({ date }) => {
  const errors = {};

  const momentDate = moment(date).utc();

  if (momentDate.isBefore(dateUtils.startOfDay())) {
    errors.date = "can't be before today";
  }

  if (
    momentDate.isAfter(
      moment()
        .utc()
        .add(100, 'years'),
    )
  ) {
    errors.date = 'must be sooner';
  }

  return errors;
};

const DeliveryFormContainer = reduxForm({
  enableReinitialize: true,
  form: 'orderForm',
  validate,
})(DeliveryFormConductor);

export default compose(
  withCurrentPharmacy,
  withCreateDelivery,
  withState('onfleetShortId', 'onUpdateShortId'),
  withProps(({ initialValues }) => ({
    initialValues: {
      ...initialValues,
      date: moment()
        .utc()
        .format('YYYY-MM-DD'),
      time: {
        max: '10:00pm',
        min: '8:00am',
      },
    },
  })),
  withHandlers({
    onClickOnfleet: () => () => window.open(OnfleetDashboardURL, '_blank'),
    onSubmit: props => ({ date, delivery_note, time }) =>
      props
        .onCreateDelivery({
          address_id: get(props.initialValues, 'id'),
          complete_after: buildDateTime(date, time.min),
          complete_before: buildDateTime(date, time.max),
          note: delivery_note,
        })
        .then(({ data }) => {
          const shortId = get(data, 'createDelivery.data.delivery.short_id');
          props.onUpdateShortId(shortId);
        })
        .catch(({ errors }) => {
          throw new SubmissionError(errors);
        }),
  }),
)(DeliveryFormContainer);
