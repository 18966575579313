// import { users } from '@zipdrug/react-api-sdk';
import { alertOnError } from '@zipdrug/ui';
import { mutateCreateUser, queryUsers, mutateUpdateUser } from 'graphql/users';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Component, createElement } from 'react';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import withRoles from './withRoles';
import { buildUserQueryVariables } from './withUsers';

const withUserMutation = ComponentToWrap =>
  class UserMutationComponent extends Component {
    static propTypes = {
      mutateCreateUser: PropTypes.func,
      mutateUpdateUser: PropTypes.func,
    };
    query = {
      query: queryUsers,
      variables: { query: buildUserQueryVariables(this.props) },
    };

    handleCreateUser = variables =>
      alertOnError(
        this.props.mutateCreateUser({
          update: (store, { data: { createUser } }) => {
            const data = store.readQuery(this.query);

            data.users.data.push(createUser.data);
            data.users.total += 1;

            store.writeQuery({ ...this.query, data });
          },
          variables,
        }),
      );

    handleDeleteUser = variables =>
      alertOnError(
        this.props.mutateUpdateUser({
          update: store => {
            const data = store.readQuery(this.query);
            const userId = get(variables, 'id');

            data.users.data = data.users.data.filter(u => u.id !== userId);
            data.users.total -= 1;

            store.writeQuery({ ...this.query, data });
          },
          variables,
        }),
      );

    handleUpdateUser = variables => alertOnError(this.props.mutateUpdateUser({ variables }));

    render = () =>
      createElement(ComponentToWrap, {
        ...this.props,
        mutateCreateUser: this.handleCreateUser,
        mutateDeleteUser: this.handleDeleteUser,
        mutateUpdateUser: this.handleUpdateUser,
      });
  };

export default ComponentToWrap =>
  compose(
    graphql(mutateCreateUser, { name: 'mutateCreateUser' }),
    graphql(mutateUpdateUser, { name: 'mutateUpdateUser' }),
    withRoles,
    withUserMutation,
  )(ComponentToWrap);
