import moment from 'moment';

const now = moment.utc().valueOf();

export function startOfDay(date = now) {
  return moment(date)
    .utc()
    .startOf('day')
    .valueOf();
}

export function startOfYear(date = now) {
  return moment(date)
    .utc()
    .startOf('year')
    .valueOf();
}

export function nextYear(date = now) {
  const yearStart = startOfYear(date);
  return moment(yearStart)
    .utc()
    .add(1, 'year')
    .valueOf();
}

export function daysDiff(until, from = now) {
  return moment(until).diff(from, 'days');
}

export function daysYearStartToNow() {
  return daysDiff(now, startOfYear());
}

export function daysRemainingInYear() {
  return daysDiff(nextYear());
}
