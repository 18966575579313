import gql from 'graphql-tag';

const getOutcomeTypes = gql`
  query outcomeReasonTypes($limit: Int, $skip: Int, $query: JSON, $patient_id: Int) {
    outcomeReasonTypes(limit: $limit, skip: $skip, query: $query, patient_id: $patient_id) {
      data {
        id
        enroll_status
        outcome
        outcome_reason
        pitched
      }
    }
  }
`;

export default getOutcomeTypes;
