import buildSegment from './buildSegment';
import { startOfDay } from './dateUtils';
import SegmentType from './SegmentType';

export default function appendGapSegments(segments, { firstClaimDate, fromDate, untilDate }) {
  /* eslint-disable no-param-reassign */
  // UNTRACKED....firstClaimDate....HOLE....now....UNTRACKED
  const now = startOfDay();

  if (fromDate > now || firstClaimDate > now) {
    // now....UNTRACKED
    segments.push(
      buildSegment({
        fromDate,
        type: SegmentType.Untracked,
        untilDate,
      }),
    );
    return;
  }

  const gapBeforeFirstClaim = fromDate < firstClaimDate;
  const gapCrossesFirstClaim = gapBeforeFirstClaim && untilDate > firstClaimDate;

  if (gapBeforeFirstClaim) {
    // UNTRACKED....firstClaimDate
    segments.push(
      buildSegment({
        fromDate,
        type: SegmentType.Untracked,
        untilDate: gapCrossesFirstClaim ? firstClaimDate : untilDate,
      }),
    );
    if (!gapCrossesFirstClaim) return;

    // Push forward the from date
    fromDate = firstClaimDate;
  }

  const gapAfterFirstClaim = fromDate >= firstClaimDate;
  const gapAfterNow = untilDate > now;
  const gapCrossesNow = gapAfterNow && fromDate < now;

  if (gapAfterFirstClaim) {
    // firstClaimDate....HOLE....now
    segments.push(
      buildSegment({
        fromDate,
        type: SegmentType.Hole,
        untilDate: gapCrossesNow ? now : untilDate,
      }),
    );

    if (!gapCrossesNow) return;

    // Push forward the from date
    fromDate = now;
  }

  if (gapAfterNow) {
    // now....UNTRACKED
    segments.push(
      buildSegment({
        fromDate: now,
        type: SegmentType.Untracked,
        untilDate,
      }),
    );
  }
}
