import {
  colors,
  Div,
  font,
  Icon,
  padding,
  position,
  Row,
  space,
  Tooltip,
  transition,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';

const TOPBARITEM_PADDING = {
  horizontal: '0.8rem',
  vertical: space.quarter,
};

const sx = StyleSheet.create({
  clickable: {
    ...transition.build(),
    borderRadius: 4,
    cursor: 'pointer',
    userSelect: 'none',
    color: colors.theme.primary.default,
    backgroundColor: colors.gray92,
    ':hover': { backgroundColor: colors.gray87 },
  },
  container: {
    position: 'relative',
  },
  content: padding.build(TOPBARITEM_PADDING.vertical, TOPBARITEM_PADDING.horizontal),
  icon: {
    ...position.size(space.half),
    color: colors.gray62,
    ':only-child': position.size(font.size.small),
  },
  text: {
    marginLeft: '0.6rem',
    ':only-child': { marginLeft: 0 },
    fontWeight: font.weight.medium,
    color: colors.bluePurple,
  },
  brandText: {
    marginLeft: '0.6rem',
    ':only-child': { marginLeft: 0 },
    fontWeight: font.weight.medium,
    color: '#231E33',
    fontSize: '16px',
  },
});

const TopBarItem = ({ icon, onClick, styles, text, tooltipText, isBrandName, ...props }) => {
  const content = (
    <Row align="center" styles={[sx.content, onClick ? sx.clickable : null]}>
      {icon && <Icon name={icon} styles={sx.icon} />}
      {text && <Div styles={isBrandName ? sx.brandText : sx.text}>{text}</Div>}
    </Row>
  );

  return (
    <Div {...props} onClick={onClick} styles={[sx.container, styles]}>
      {onClick && tooltipText ? (
        <Tooltip arrow={false} content={tooltipText} distance={10} size="big">
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </Div>
  );
};

TopBarItem.propTypes = {
  icon: Icon.propTypes.name,
  onClick: PropTypes.func,
  styles: aphroditePropType,
  text: PropTypes.node,
  tooltipText: PropTypes.string,
  isBrandName: PropTypes.bool,
};

TopBarItem.padding = TOPBARITEM_PADDING;

export default TopBarItem;
