import gql from 'graphql-tag';

const GET_PATIENT_CLAIMS = gql`
  query patients(
    $alert_type: [String]
    $assigned_pharmacy_id: [Int]
    $enroll_status: [String]
    $id: [Int]
    $language: [String]
    $limit: Int
    $no_snoozed: Boolean
    $only_snoozed: Boolean
    $pdc: [PDCSelector]
    $plan_id: [Int]
    $search: String
    $skip: Int
    $sort_by: String
  ) {
    patients(
      alert_type: $alert_type
      assigned_pharmacy_id: $assigned_pharmacy_id
      enroll_status: $enroll_status
      id: $id
      language: $language
      limit: $limit
      no_snoozed: $no_snoozed
      only_snoozed: $only_snoozed
      pdc: $pdc
      plan_id: $plan_id
      search: $search
      skip: $skip
      sort_by: $sort_by
    ) {
      data {
        claims {
          brand_name
          days_supply
          dispense_date
          dispense_fee_submitted
          fill_number
          id
          ingredient_cost_submitted
          is_active
          measures
          member_pay_amount
          ndc_code
          patient_id
          pharmacy {
            id
            name
            npi
            status
            pharmacy_services {
              pharmacy_id
              pharmacy_service_type_id
              pharmacy_service_type {
                pharmacy_service_type
              }
            }
            phones {
              extension
              can_sms
              id
              label
              number
              order
            }
            chain_pharmacies {
              id
              npi
            }
            parent_pharmacies {
              parent_pharmacy {
                name
                npi
                phones {
                  extension
                  can_sms
                  id
                  label
                  number
                  order
                }
              }
            }
          }
          prescriber {
            id
            name
            npi
            phones {
              extension
              can_sms
              id
              label
              number
              order
            }
          }
          quantity
          rx_number
          strength
          unit
        }
        first_name
        id
        last_name
      }
      limit
      skip
      total
    }
  }
`;

export default GET_PATIENT_CLAIMS;
