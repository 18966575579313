import React, { useState, useRef, useEffect, useContext } from 'react';
import _get from 'lodash/get';
import InputMask from 'react-input-mask';
import ClickOutside from 'react-click-outside';
import { Button, Tooltip } from 'antd';
import { StyleSheet, css } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { DialIcon, PickupIcon, CloseIcon } from './svg';
import commonSx, { getBtnColor } from './styles';

const sx = StyleSheet.create({
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialpadContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  dialpadOpen: {
    position: 'absolute',
    top: '45px',
    backgroundColor: 'white',
    zIndex: '1000',
    left: '0px',

    alignItems: 'center',
    width: '230px',
    border: '1px solid #DDDDDD',
    borderRadius: '10px',
    margin: '10px 0px',
  },
  dialpadHeight: {
    height: '355px',
  },
  dialpadHeightOnCall: {
    height: '315px',
  },
  inputContainer: {
    width: '180px',
    borderBottom: '1px solid purple',
    marginBottom: '10px',
  },
  input: {
    height: '40px',
    width: '100%',
    fontSize: '20px',
    textAlign: 'center',
    border: 'none',
    padding: '10px',
  },
  dialButtonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  dialButton: {
    height: '39px',
    outline: 'none',
    width: '39px',
    borderRadius: '50%',
    backgroundColor: 'white',
    margin: '8px 10px',
    border: 'none',
    boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    ':hover': {
      boxShadow: '1px 3px 8px rgba(0, 0, 0, 0.25)',
    },
    ':active': {
      background: '#EDE8FF',
    },
  },
  focus: {
    ':focus': {
      outline: 'none',
    },
  },
  submitBtn: {
    marginTop: '5px',
  },
  closeIcon: {
    alignSelf: 'flex-end',
    padding: '5px 5px 0px 0px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
});

const DialPad = ({ dialpadEntryHandler, onCallHandler, isOnCall }) => {
  const [isDialpadOpen, setDialpadNumberOpen] = useState(false);
  const [inputVal, setInputVal] = useState('');

  const dialKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'];
  const phoneInput = useRef(null);

  const { updateCallCenterStatusMutationContext, callCenterUserStatusContext } = useContext(
    CallCenterContext,
  );

  useEffect(() => {
    if (isDialpadOpen && phoneInput.current) {
      phoneInput.current.getInputDOMNode().focus();
    }
    if (!isDialpadOpen && callCenterUserStatusContext !== 'oncall') {
      updateCallCenterStatusMutationContext('available');
    }
  }, [isDialpadOpen]);

  const handleClickOutside = () => {
    setDialpadNumberOpen(false);
  };
  const prependEllipsis = newInputVal => {
    const inputLen = newInputVal.length;
    newInputVal = inputLen >= 12 ? `...${newInputVal.slice(inputLen - 12)}` : newInputVal;
    return newInputVal;
  };
  return (
    <div className={css(sx.dialpadContainer, commonSx.ml10)}>
      <Tooltip placement="bottom" title="Dialpad">
        <Button
          type="primary"
          className={css(commonSx.square)}
          onClick={() => {
            if (!isDialpadOpen && callCenterUserStatusContext !== 'oncall') {
              updateCallCenterStatusMutationContext('calldialing');
            }
            setDialpadNumberOpen(!isDialpadOpen);
          }}
        >
          <DialIcon />
        </Button>
      </Tooltip>

      <ClickOutside onClickOutside={handleClickOutside}>
        <div
          className={css(
            isDialpadOpen
              ? [sx.dialpadOpen, sx.flexCol, isOnCall ? sx.dialpadHeightOnCall : sx.dialpadHeight]
              : sx.hidden,
          )}
        >
          <button
            className={css(sx.closeIcon)}
            onClick={() => {
              if (!isDialpadOpen && callCenterUserStatusContext !== 'oncall') {
                updateCallCenterStatusMutationContext('calldialing');
              }
              setDialpadNumberOpen(!isDialpadOpen);
            }}
          >
            <CloseIcon />
          </button>

          <div className={css(sx.inputContainer)}>
            {isOnCall ? (
              <div className={css(sx.input)}>{prependEllipsis(inputVal)}</div>
            ) : (
              <InputMask
                ref={phoneInput}
                className={css(sx.input, sx.focus)}
                mask="(999) 999-9999"
                onChange={e => {
                  // removes all non digit characters
                  const val = _get(e, 'target.value').replace(/\D/g, '');
                  setInputVal(val);
                }}
                value={inputVal || ''}
              />
            )}
          </div>

          <div className={css(sx.dialButtonsContainer)}>
            {dialKeys.map(key => (
              <button
                key={key}
                className={css(sx.dialButton)}
                onClick={() => {
                  if (isOnCall) {
                    dialpadEntryHandler(key);
                  }
                  setInputVal(inputVal + key);
                }}
              >
                {key}
              </button>
            ))}
          </div>

          {!isOnCall && (
            <Tooltip placement="bottom" title="Outbound Call">
              <Button
                className={css(getBtnColor('brightGreen'), sx.submitBtn, commonSx.square)}
                onClick={() => {
                  if (inputVal.length === 10) {
                    onCallHandler(inputVal);
                  }
                }}
              >
                <PickupIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      </ClickOutside>
    </div>
  );
};

DialPad.propTypes = {
  dialpadEntryHandler: PropTypes.func,
  onCallHandler: PropTypes.func,
  isOnCall: PropTypes.bool,
};

export default DialPad;
