import { StyleSheet } from 'aphrodite/no-important';

const COLOR = {
  purple: 'white',
  purpleOutline: '#5009B5',
  green: 'white',
  red: '#FF4848',
  brightGreen: '#22CC0D',
};

const BACKGROUND = {
  purple: '#5009B5',
  purpleOutline: 'white',
  green: '#048802',
  red: '#FF4848',
  brightGreen: '#22CC0D',
};

export const getBtnColor = type => {
  const sx = StyleSheet.create({
    color: {
      color: COLOR[type],
      background: BACKGROUND[type],
      borderColor: COLOR[type],
      ':hover': {
        color: COLOR[type],
        background: BACKGROUND[type],
        borderColor: COLOR[type],
        filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      },
      ':active': {
        color: COLOR[type],
        background: BACKGROUND[type],
        borderColor: COLOR[type],
        filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      },
      ':focus': {
        color: COLOR[type],
        background: BACKGROUND[type],
        borderColor: COLOR[type],
        filter: 'drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.25))',
      },
    },
  });

  return sx.color;
};

const commonSx = StyleSheet.create({
  mr4: {
    marginRight: '4px',
  },
  mr12: {
    marginRight: '12px',
  },
  mr15: {
    marginRight: '15px',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  mlr10: {
    margin: '0 10px',
  },
  mlr15: {
    margin: '0 15px',
  },
  pb20: {
    paddingBottom: '20px',
  },
  ml10: {
    marginLeft: '10px',
  },
  ml15: {
    marginLeft: '15px',
  },
  bold: {
    fontWeight: 'bold',
  },
  square: {
    width: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px',
  },
});

export default commonSx;
