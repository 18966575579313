import { connect } from 'react-redux';
import { setCurrentlyPlayingCallId } from '../redux/audio';

const mapDispatchToProps = dispatch => ({
  setCurrentlyPlayingCallId(id) {
    dispatch(setCurrentlyPlayingCallId({ id }));
  },
});

const mapStateToProps = ({ audio: { id } }) => ({ currentlyPlayingCallId: id });

export default Component => connect(mapStateToProps, mapDispatchToProps)(Component);
