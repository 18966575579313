import {
  Button,
  ButtonRow,
  colors,
  Column,
  Div,
  font,
  FormEmailField,
  FormSelectField,
  FormTextField,
  padding,
  Row,
  space,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const sx = StyleSheet.create({
  buttonRow: {
    marginTop: space.half,
  },
  container: {
    ...padding.build(space.get(1.5)),
    backgroundColor: colors.lightYellow,
    color: 'inherit',
  },
  formField: {
    flex: 1,
    marginBottom: space.default,
    marginRight: font.size.xSmall,
    ':last-child': {
      marginRight: 0,
    },
  },
  header: {
    color: colors.gray57,
    marginBottom: space.get(1.5),
  },
  headerTitle: {
    color: colors.gray20,
    fontSize: font.size.medium,
    marginBottom: space.quarter,
  },
});

const BUTTON_PROPS = {
  focusable: true,
  size: 'large',
};

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
};

const TeamListInviteForm = ({
  handleSubmit,
  onClickCancel,
  submitting,
  titleTypes,
  userTypes,
  pharmTypes,
}) => (
  <Column styles={sx.container}>
    <Column styles={sx.header}>
      <Div styles={sx.headerTitle}>New invitation</Div>
      <Div>Invite a new user by email by completing the form below.</Div>
    </Column>
    <Row>
      <FormEmailField
        {...FORMFIELD_PROPS}
        autoFocus
        label="Email address"
        name="email"
        placeholder="example@email.com"
        styles={sx.formField}
      />
      <FormSelectField
        {...FORMFIELD_PROPS}
        label="User Type"
        name="user_type"
        placeholder="Select user type..."
        items={userTypes}
        styles={sx.formField}
      />
    </Row>
    <Row>
      <FormSelectField
        {...FORMFIELD_PROPS}
        label="Title"
        name="title"
        placeholder="Select title..."
        items={titleTypes}
        styles={sx.formField}
      />
      <FormSelectField
        {...FORMFIELD_PROPS}
        label="Pharmacy"
        name="pharmacy"
        placeholder="Select pharmacy..."
        items={pharmTypes}
        styles={sx.formField}
      />
    </Row>
    <Row>
      <FormTextField
        {...FORMFIELD_PROPS}
        label="ZipDrug Pharmacy User Group Access Ticket Number"
        name="ticket_number"
        placeholder="REQ..."
        styles={sx.formField}
      />
    </Row>
    <ButtonRow styles={sx.buttonRow}>
      <Button
        {...BUTTON_PROPS}
        loading={submitting}
        onClick={handleSubmit}
        text="Send invitation"
        type="primary"
      />
      <Button {...BUTTON_PROPS} onClick={onClickCancel} outline text="Cancel" type="secondary" />
    </ButtonRow>
  </Column>
);

TeamListInviteForm.propTypes = {
  handleSubmit: PropTypes.func,
  onClickCancel: PropTypes.func,
  submitting: PropTypes.bool,
  titleTypes: PropTypes.arrayOf(PropTypes.object),
  userTypes: PropTypes.arrayOf(PropTypes.object),
  pharmTypes: PropTypes.arrayOf(PropTypes.object),
};

export default TeamListInviteForm;
