import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatFullName, InitialsAvatar } from '@zipdrug/ui';
import { useLazyQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import { UserTypesMapping } from 'contexts/utils/models';
import SettingsAccordion from '../SettingsAccordion';
import SettingsAccordionListItemHeader from '../SettingsAccordionListItemHeader';
import UserProfileFormContainer from '../UserProfileFormContainer';
import TeamListItemActionsContainer from './TeamListItemActionsContainer';
import HAS_ENROLLERS from './graphql/hasEnrollers';

export const TeamListItemAvatar = ({ user, ...props }) =>
  user && user.joined_at ? (
    <InitialsAvatar {...props} firstName={user.first_name} lastName={user.last_name} />
  ) : null;

TeamListItemAvatar.propTypes = {
  user: PropTypes.object,
};

const TeamListItem = ({ isAdmin, item, isPharmacyTeam }) => {
  const [enrollersBoolean, setHasEnrollers] = useState();

  const [checkEnrollers, { loading: isCheckingEnrollers }] = useLazyQuery(HAS_ENROLLERS, {
    fetchPolicy: 'no-cache',
    onError: error => {
      message.error(`Error fetching PBS Agents: ${error.message}`);
    },

    onCompleted: data => {
      if (data?.enrollers?.data?.length > 0) {
        setHasEnrollers(true);
      } else {
        setHasEnrollers(false);
      }
    },
  });

  return (
    <SettingsAccordion
      actionsRenderer={
        <TeamListItemActionsContainer
          user={item}
          enrollerCheck={enrollersBoolean}
          isCheckingEnrollers={isCheckingEnrollers}
        />
      }
      editFormRenderer={<UserProfileFormContainer user={item} />}
      headerRenderer={
        <SettingsAccordionListItemHeader
          avatarRenderer={<TeamListItemAvatar user={item} />}
          editingMetaText={`Update ${item.first_name}'s profile by editing the information below.`}
          isPending={!item.joined_at}
          item={item}
          metaItems={[item.title, item.email, UserTypesMapping[item.roles[0]?.type]]}
          tag={isAdmin ? 'admin' : null}
          title={item.joined_at ? formatFullName(item) : 'Pending invitation'}
        />
      }
      onOpen={() =>
        !isPharmacyTeam && checkEnrollers({ variables: { query: { userId: item.id } } })
      }
    />
  );
};

TeamListItem.propTypes = {
  isAdmin: PropTypes.bool,
  isPharmacyTeam: PropTypes.bool,
  item: PropTypes.object,
};

export default TeamListItem;
