import PropTypes from 'prop-types';
import moment from 'moment';
import React, { Component } from 'react';
import { message } from 'antd';
import { reduxForm, SubmissionError } from 'redux-form';
import { validateForm } from '@zipdrug/ui';
import Cookies from 'js-cookie';
import { withSession } from '@zipdrug/react-api-sdk';
import withSsoLogin from 'hoc/withSsoLogin';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import PinLogin from './PinLogin';
import { setPasswordExpiration, setPharmacyId } from '../redux/queryReducer';
import { IS_NEW_BROWSER_SESSION } from '../routes/AuthenticatedComponent';
import { LAST_USER_ACTIVITY_COOKIE } from '../session-trackers/IdleTimerContainer';

const PIN_ERRORS = {
  invalidPin: 'Invalid PIN.',
  serverError: 'Something went wrong. Please contact support.',
};

const validate = values => {
  validateForm(values, {}, PIN_ERRORS);
};

const ReduxPinForm = reduxForm({
  form: 'pinLogin',
  validate,
})(PinLogin);

class PinLoginPage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    setUser: PropTypes.func,
    setPasswordExpiration: PropTypes.func,
    pinLogin: PropTypes.func,
    sendNewPin: PropTypes.func,
  };

  state = {
    resending: false,
    pinError: '',
  };

  handleLoginResponse = res => {
    if (res.expirationToken) {
      const expirationToken = res.expirationToken;
      this.props.history.push(`/reset-password?token=${expirationToken}&redirect=care`);
      return;
    }

    if (res.errors) {
      throw new SubmissionError(res.errors);
    }

    if (res.daysUntilPasswordExpires) {
      this.props.setPasswordExpiration({ passwordExpiration: res.daysUntilPasswordExpires });
    }

    Cookies.set(IS_NEW_BROWSER_SESSION, true);
    Cookies.set(LAST_USER_ACTIVITY_COOKIE, Date.now());

    this.props.setUser(res.user);

    this.props.history.push({
      pathname: '/members',
      state: { daysUntilPasswordExpires: 1 },
    });
  };

  handleResendPin = () => {
    this.setState({ resending: true });
    return this.props
      .sendNewPin({ email: this.props.history?.location?.state?.userId })
      .catch(e => {
        const _error = e?.graphQLErrors[0]?.message;
        this.setState({ resending: false, pinError: _error });
      })
      .then(props => {
        if (props?.data) {
          message.success('Check your email for a new PIN');
        }
        this.setState({ resending: false });
      });
  };

  handleSubmit = ({ pin }) =>
    this.props
      .pinLogin({ email: this.props.history?.location?.state?.userId, pin: +pin })
      .catch(e => {
        let _error = `${e?.graphQLErrors[0]?.message}`;
        if (_error.includes('ACCOUNT LOCKED')) {
          const newError = _error.replace('ACCOUNT LOCKED. PLEASE TRY AGAIN AFTER ', '');
          const lockDateTime = moment(new Date(newError)).format('MM/DD/YYYY h:mma');
          _error = `ACCOUNT LOCKED. PLEASE TRY AGAIN AFTER ${lockDateTime}`;
        }
        throw new SubmissionError({ _error });
      })
      .then(res => {
        if (res) {
          this.handleLoginResponse(res);
        }
      });

  render = () => (
    <ReduxPinForm
      onSubmit={this.handleSubmit}
      onResendPin={() => this.handleResendPin()}
      resending={this.state.resending}
      pinError={this.state.pinError}
    />
  );
}

const mapDispatchToProps = dispatch => ({
  setUser(user) {
    const roles = user.roles.filter(r => r.pharmacy_id);
    if (roles.length) {
      dispatch(setPharmacyId({ pharmacy_id: roles[0].pharmacy_id }));
    }
  },
  setPasswordExpiration: ({ passwordExpiration }) =>
    dispatch(setPasswordExpiration({ passwordExpiration })),
});

export default compose(withSsoLogin, withSession, connect(null, mapDispatchToProps))(PinLoginPage);
