/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { message } from 'antd';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation, useSubscription, useApolloClient } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Device } from 'twilio-client';
import { LoadingOutlined } from '@ant-design/icons';
import Push from 'push.js';
import { queryPatientAndPharmacy } from 'graphql/patients.js';
import { SessionContext } from 'contexts/SessionContextProvider';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { OutcomesContext } from 'contexts/OutcomesProvider';
import DialPad from './DialPad';
import CallCenterStatus from './CallCenterStatus';
import CallOutcomeModal from './CallOutcomeModal';
import CallPatientButton from './CallPatientButton';
import InboundCall from './InboundCall';
import MakeActive from './MakeActive';
import OnCallElsewhere from './OnCallElsewhere';
import OnCallContainer from './OnCallContainer';
import OnCallSupervising from './OnCallSupervising';
import SaveNumberModal from './SaveUnknownNumberModal';
import PATIENT_SUBSCRIPTION from "../../patients/info/graphql/patientSubscription";
import { GET_UPDATED_PHARMACY_SUBSCRIPTION } from "../../graphql/pharmacies"
import { COLORS } from './constants';
import {
  getFormattedPhoneAssociations,
  buildPatientInfo,
  getTwilioTokenQuery,
  getPhoneRecordsQuery,
  getCallRecordQuery,
} from './util';
import {
  CREATE_CALL_RECORD,
  GET_PHONES_WITH_ASSOCIATIONS,
  UPDATE_USER_ACTIVITY,
  UPDATE_CALL_RECORD,
  GET_USER_CALL_CENTER_STATUS,
} from './queries';
import SEARCH_MEMBERS from 'graphql/searchMembers';

const sx = StyleSheet.create({
  callCenterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '6rem',
    borderBottom: '1px solid #D9D9D9',
  },
  dialCont: {
    display: 'flex',
    height: '60px',
  },
  callCenterBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    margin: '10px',
    lineHeight: '14px',
  },
  inboundCall: {
    backgroundColor: COLORS.LIGHT_GREEN,
    margin: '0px',
    padding: '10px',
    height: '60px',
    width: '100vw',
  },
  green: {
    background: COLORS.LIGHT_GREEN,
  },
});

function registerTwilioDevice(
  token,
  setTwilioConnection,
  // setCallDetails,
  setInboundCall,
  deregisterTwilioRefContext,
  resetCallCenterState,
  updateCallCenterStatusMutationContext,
  currentCallIdRef,
  apolloClient,
  setIsCallCenterActivatedContext,
  twilioTokenExpiredRef,
  setOnCallDialingParticipant,
  setIsOnCall,
  setShowConnectingTwilio,
  setIsOutboundPatient,
  setUpdatedPharmacyContext,
  userId,
) {
  if (!token) {
    return;
  }

  Device.setup(token, {
    closeProtection:
      '"A call is currently in progress. Leaving or reloading this page will end the call."',
    enableRingingState: true,
    enableIceRestart: true,
  });

  Device.on('ready', () => {
    deregisterTwilioRefContext.current = false; // eslint-disable-line no-param-reassign
  });

  Device.on('reconnected', () => {
    // eslint-disable-next-line
    alert('Twilio connection has been restored');
  });

  Device.on('error', e => {
    // Token expired https://www.twilio.com/docs/api/errors/31205
    if (e.code === 31205) {
      if (!currentCallIdRef.current) {
        updateCallCenterStatusMutationContext('unavailable');
        setIsCallCenterActivatedContext(false);
        twilioTokenExpiredRef.current = false;
      } else {
        twilioTokenExpiredRef.current = true;
      }

      return;
    }

    // Mic access denied https://www.twilio.com/docs/api/errors/31208
    if (e.code === 31208) {
      // eslint-disable-next-line
      alert('Enable microphone access first inorder for call center to work');
    }

    if (e.code === 31003 || e.code === 31005) {
      // eslint-disable-next-line
      alert('Internet connection is unstable. Check your internet.');

      return;
    }

    message.error(`Twilio error occurred: ${e.code}: ${e.message}`);

    // eslint-disable-next-line
    FS.log('error', `Twilio error occurred: ${e.code}: ${e.message}`);

    resetCallCenterState();
  });

  // Offline event is also triggered when the token expires
  Device.on('offline', () => {
    if (!currentCallIdRef.current) {
      setIsCallCenterActivatedContext(false);
    }
  });

  Device.on('connect', async conn => {
    setShowConnectingTwilio(false);
    setTwilioConnection(conn);

    const callId = conn.customParameters.get('callId');
    const callerName = _get(conn, 'message.name');

    if (conn.direction === 'OUTGOING') {
      const callSid = _get(conn, 'parameters.CallSid');

      // if call initiate on dialpad, just check for unknown name
      // else get phoneRecords
      let isDialingPatient = false;
      if (callerName !== 'Unknown') {
        const phoneId = _get(conn, 'message.toPhoneId');
        if (phoneId) {
          const phoneRecordsOnConnect = await apolloClient.query({
            query: GET_PHONES_WITH_ASSOCIATIONS,
            variables: { query: { id: phoneId } },
            fetchPolicy: 'network-only',
          });
          const phoneRecordsData = _get(phoneRecordsOnConnect, 'data.phones.data');
          if (phoneRecordsData?.length > 0) {
            isDialingPatient = !!phoneRecordsData.find(p => p.patient_id);
            setUpdatedPharmacyContext(phoneRecordsData[0].patient?.assigned_pharmacy_id);
          }
        }
      }

      const onCallDialingData = {
        names: [_get(conn, 'message.name')],
        callSids: [callSid],
      };

      if (isDialingPatient) {
        onCallDialingData.participantType = 'patient';
        setIsOutboundPatient(true);
      }
      // We don't show the numbers if the outgoing call is a pharmacy
      if (!_get(conn, 'message.toPharmacyId')) {
        onCallDialingData.numbers = [_get(conn, 'message.to')];
      }

      setOnCallDialingParticipant(onCallDialingData);
    }

    updateCallCenterStatusMutationContext('oncall', parseInt(callId, 10));
    setIsOnCall(true);
  });

  Device.on('cancel', () => {
    resetCallCenterState();
  });

  Device.on('incoming', async conn => {
    const userInfoOnIncoming = await apolloClient.query({
      query: GET_USER_CALL_CENTER_STATUS,
      variables: { userId },
      fetchPolicy: 'network-only',
    });

    const userInfo = _get(userInfoOnIncoming, 'data.getCallCenterStatusByUserId.data');

    // We reject the call if there's already an incoming call happening
    if (currentCallIdRef.current || userInfo?.call_center_user_status !== 'available') {
      conn.reject();
      return;
    }

    const inboundCaller = _get(conn, 'customParameters').get('inCaller');
    const inboundCallerType = _get(conn, 'customParameters').get('inType');

    const continueSettingInboundCall = async () => {
      currentCallIdRef.current = parseInt(_get(conn, 'customParameters').get('callId'), 10);
      if (inboundCallerType === 'number') {
        // Make an api call to get the inbound caller information.
        const phoneRecords = await getPhoneRecordsQuery(apolloClient, inboundCaller);
        const formattedPhoneAssoc = getFormattedPhoneAssociations(phoneRecords);

        if (phoneRecords && phoneRecords.length > 0) {
          setUpdatedPharmacyContext(phoneRecords[0].patient?.assigned_pharmacy_id);
        }

        const { bestCandidate } = formattedPhoneAssoc;
        let name = bestCandidate?.phone_label.includes('CPCC')
          ? bestCandidate?.phone_label
          : bestCandidate.name;

        const inboundInfo = {
          name: name,
          patient_id: _get(formattedPhoneAssoc, 'bestCandidate.patient_id'),
          label: _get(formattedPhoneAssoc, 'bestCandidate.label'),
          phone: _get(formattedPhoneAssoc, 'number'),
          insurance: _get(formattedPhoneAssoc, 'bestCandidate.payer.name'),
        };
        // setCallDetails(inboundInfo);

        setInboundCall(inboundInfo);
      }
      // Inbound is from an enroller
      else {
        const inboundCallerDetails = JSON.parse(inboundCaller) || {};
        const toSetVal = {
          name: inboundCallerDetails.name || inboundCallerDetails.email || 'Unknown',
          label: inboundCallerType,
        }
        setInboundCall(toSetVal);
        // setCallDetails(toSetVal);
      }
    };

    // As long as there isn't a current call, continue setting the inbound call and call id
    continueSettingInboundCall();

    Push.create('You have a new inbound call.', {
      onClick() {
        window.focus();
        this.close();
      },
    });

    setTwilioConnection(conn);
  });
}

function makeOutboundCall({
  userId,
  toPhoneId,
  number,
  name,
  callId,
  toPharmacyId,
  fromCallerId,
  enrollerCallSid = null,
  currentCallId = null,
}) {
  const options = enrollerCallSid
    ? {
        confFriendlyNameToCoach: currentCallId,
        callSidToCoach: enrollerCallSid,
        callId: currentCallId,
        fromCallerId,
      }
    : {
        userId,
        to: number,
        toPhoneId,
        name,
        callId,
        fromCallerId,
      };

  if (toPharmacyId) {
    options.toPharmacyId = toPharmacyId;
  }
  Device.connect(options);
}

const CallCenterBar = ({ location, history }) => {
  const currentCallIdRef = useRef(null);
  const twilioTokenExpiredRef = useRef(null);
  const apolloClient = useApolloClient();
  const unknownNumberRef = useRef(null);
  const refreshActiveConfRef = useRef(false);
  const unknownNumberCallIdRef = useRef(null);
  const [cancelUnloadEvent, setCancelUnloadEvent] = useState(false);
  const [showOutcomeModal, setOutcomeModalVisible] = useState(false);
  const [isCallIdSet, setCallId] = useState(false);

  const {
    pharmacyStatus,
    pharmacyReassigned,
    outboundToPhoneIdContext,
    setOutboundToPhoneIdContext,
    isCallCenterActivatedContext,
    setIsCallCenterActivatedContext,
    selectedPatientId,
    setSelectedPatientId,
    updateCallCenterStatusMutationContext,
    callCenterUserStatusContext,
    deregisterTwilioRefContext,
    supervisingCallContext,
    setSupervisingCallContext,
  } = useContext(CallCenterContext);

  const { setUpdatedPharmacyContext } = useContext(OutcomesContext);

  const { userId } = useContext(SessionContext);
  // const [callDetails, setCallDetails] = useState({});

  const [inboundCall, setInboundCall] = useState(null);
  const [twilioConnection, setTwilioConnection] = useState(null);
  const [loadingTwilioToken, setLoadingTwilioToken] = useState(false);
  const [isOnCall, setIsOnCall] = useState(false);
  const [isSaveNumberModalOpen, setIsSaveNumberModalOpen] = useState(false);
  const [onCallDialingParticipant, setOnCallDialingParticipant] = useState(null);
  const [showConnectingTwilio, setShowConnectingTwilio] = useState(false);
  const [saveCallOnly, setSaveCallOnly] = useState(false);
  const [saveCallEnabled, setSaveCallEnabled] = useState(true);
  const [isPostCall, setIsPostCall] = useState(false);
  const [lastCallId, setLastCallId] = useState(null);
  const [isSaveUnknownSuccess, setIsSaveUnknownSuccess] = useState(false);
  const [isInboundPatient, setIsInboundPatient] = useState(false);
  const [isOutboundPatient, setIsOutboundPatient] = useState(false);
  const [callCenterPatientId, setCallCenterPatientId] = useState(null);
  // const [dataVersion, setDataVersion] = useState(0); //TECD-920

  // ================================= API CALL =================================== //
  const {
    loading: selectedPatInfoLoading,
    data: selectedPatInfoData,
    refetch: refetchPharmacy,
    // startPolling: startPollGetActiveConf,
    // stopPolling: stopPollGetActiveConf,
  } = useQuery(queryPatientAndPharmacy, {
    skip: !callCenterPatientId,
    fetchPolicy: 'network-only',
    variables: { id: callCenterPatientId },
  });
  //TECD-647 
  // useEffect(() => {
  //   const isUnknownCaller = twilioConnection?.message?.name?.includes('Unknown');
  //   const isIncomingUnknownCall = twilioConnection?._direction === 'INCOMING' && callDetails.name === 'Unknown';

  //   if (selectedPatientId && (isUnknownCaller || isIncomingUnknownCall)) {
  //     refetchPharmacy({ id: selectedPatientId })
  //       .then(() => setDataVersion(prevVersion => prevVersion + 1))
  //       .catch((error) => console.error("Refetch error:", error));
  //   }
  // }, [selectedPatientId, refetchPharmacy, callDetails]);

  // useEffect(() => {
  //   if (isOnCall) {
  //     startPollGetActiveConf(1000);
  //   } else {
  //     stopPollGetActiveConf();
  //   }
  // }, [isOnCall]);

   // console.log("selectedPatientId", selectedPatientId);
  const { data: patientSubs } = useSubscription(PATIENT_SUBSCRIPTION, {
    variables: { patientId: `${selectedPatientId}` },
    skip: !selectedPatientId,
    shouldResubscribe: true, // should be set to true when conference id changes
    fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      console.log(`Patient Data changed`, data);
      refetchPharmacy();
    },
    onError: error => {
      message.error(`Error in active conference subscription: ${error.message}`);
    },
  });

   // console.log(`selectedPatInfoData`, _get(selectedPatInfoData, 'patients.data[0].assigned_pharmacy.id'), selectedPatInfoData);
  const { data: pharmacySubs } = useSubscription(GET_UPDATED_PHARMACY_SUBSCRIPTION, {
    variables: { id: _get(selectedPatInfoData, 'patients.data[0].assigned_pharmacy.id') },
    skip: !selectedPatientId, //!_get(selectedPatInfoData, 'patients.data[0].assigned_pharmacy.id'),//TECD-920
    shouldResubscribe: true, // should be set to true when conference id changes
    fetchPolicy: 'network-only',
    onSubscriptionData: (data) => {
      console.log(`Pharmace Data changed`, data);
      refetchPharmacy();
    },
    onError: error => {
      message.error(`Error in active conference subscription: ${error.message}`);
    },
  });


  useEffect(() => {
    if ((!isOnCall && !showOutcomeModal) || !callCenterPatientId) {
      setCallCenterPatientId(selectedPatientId);
    }
  }, [selectedPatientId, isOnCall, showOutcomeModal]);

  useEffect(() => {
    if (pharmacyReassigned || pharmacyStatus || callCenterPatientId) {
      refetchPharmacy();
    }
  }, [pharmacyReassigned, pharmacyStatus, callCenterPatientId]);

  const [createCallRecordMutation] = useMutation(CREATE_CALL_RECORD, {
    onError: error => {
      message.error(`Error when creating call record: ${error.message}`);
    },
  });

  const [updateCallRecordMutation] = useMutation(UPDATE_CALL_RECORD, {
    onError: error => {
      message.error(`Error when updating call record: ${error.message}`);
    },
  });

  const [updateUserActivity] = useMutation(UPDATE_USER_ACTIVITY, {
    onError: error => {
      // eslint-disable-next-line
      console.error(`Error when updating user activity: ${error.message}`);
    },
  });

  // ================================ useEffect ======================================= //

  useEffect(() => {
    updateUserActivity();
    const intervalID = setInterval(updateUserActivity, 30000);

    return function cleanup() {
      clearInterval(intervalID);

      Device.destroy();

      setIsCallCenterActivatedContext(false);
      setOutboundToPhoneIdContext(null);
      setLastCallId(currentCallIdRef.current);
      currentCallIdRef.current = null;
      twilioTokenExpiredRef.current = null;
      unknownNumberRef.current = null;
      refreshActiveConfRef.current = false;
      unknownNumberCallIdRef.current = null;
    };
  }, []);

  useEffect(() => {
    function beforUnloadHandler(e) {
      e.preventDefault();
      updateCallCenterStatusMutationContext('unavailable');

      // Browser does not render the custom message anymore as a security feature but you still need to return a value to show the dialog box
      // https://developers.google.com/web/updates/2016/04/chrome-51-deprecations?hl=en#remove_custom_messages_in_onbeforeunload_dialogs
      // eslint-disable-next-line no-param-reassign
      e.returnValue = '';

      setCancelUnloadEvent(true);
    }

    if (isCallCenterActivatedContext) {
      window.addEventListener('beforeunload', beforUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforUnloadHandler);
    }
  }, [isCallCenterActivatedContext]);

  // This useEffect is used to determine whether user tried to leave the app or close the tab.
  // We switch the status to oncall if user is on a call since the status was set to unavailable when the beforeunload event happen
  // otherwise, we set the callcenterActivated to false
  useEffect(() => {
    if (cancelUnloadEvent) {
      if (isOnCall) {
        updateCallCenterStatusMutationContext('oncall');
      } else {
        setIsCallCenterActivatedContext(false);
      }

      setCancelUnloadEvent(false);
    }
  }, [cancelUnloadEvent]);

  // This useEffect is triggered by components outside the call center bar. i.e. pressing call button in the members profile
  useEffect(() => {
    async function initiateOutboundCall() {
      const phoneRecords = await apolloClient.query({
        query: GET_PHONES_WITH_ASSOCIATIONS,
        variables: { query: { id: outboundToPhoneIdContext } },
        fetchPolicy: 'network-only',
      });

      const formattedPhones = getFormattedPhoneAssociations(_get(phoneRecords, 'data.phones.data'));
      const { bestCandidate } = formattedPhones;
      const { number } = formattedPhones;

      const r = bestCandidate.pharmacy_id
        ? await createCallRecordMutation({
            variables: {
              toPhoneId: bestCandidate?.phone_id,
              toPharmacyId: bestCandidate?.pharmacy_id,
              direction: 'outbound',
            },
          })
        : await createCallRecordMutation({
            variables: {
              toPhoneId: outboundToPhoneIdContext,
              direction: 'outbound',
            },
          });

      const callId = _get(r, 'data.createCallRecord.data.callId');
      const fromCallerId = _get(r, 'data.createCallRecord.data.callerIdNumber');
      currentCallIdRef.current = parseInt(callId, 10);

      if (bestCandidate.pharmacy_id) {
        makeOutboundCall({
          userId,
          toPharmacyId: bestCandidate?.pharmacy_id,
          number,
          name: _get(bestCandidate, 'name'),
          callId,
          fromCallerId,
        });
      } else {
        makeOutboundCall({
          userId,
          toPhoneId: outboundToPhoneIdContext,
          number,
          name: _get(bestCandidate, 'name'),
          callId,
          fromCallerId,
        });
      }
      setOutboundToPhoneIdContext(null);
      if (r) {
        setCallId(true);
      }
    }

    // Initiate outbound only when your not on a call.
    if (outboundToPhoneIdContext && !isOnCall) {
      initiateOutboundCall();
    }
  }, [outboundToPhoneIdContext]);

  useEffect(() => {
    if (!_isEmpty(supervisingCallContext) && isCallCenterActivatedContext) {
      const enrollerCallSid = supervisingCallContext.enrollerCallSid;
      const currentCallId = supervisingCallContext.currentCallId;

      makeOutboundCall({
        enrollerCallSid,
        currentCallId,
      });
    }
  }, [supervisingCallContext]);

  async function resetCallCenterState() {
    setInboundCall(null);
    setOnCallDialingParticipant(null);
    setIsOnCall(false);

    if (twilioConnection) {
      twilioConnection.disconnect();
    }
    setTwilioConnection(null);

    setLastCallId(currentCallIdRef.current);
    currentCallIdRef.current = null;

    // Reset twilio device if the token expired.
    if (twilioTokenExpiredRef.current) {
      Device.destroy();
      twilioTokenExpiredRef.current = false;
    }
  }

  function onClickUnknownNumber(unknownNumber, saveCallOnly) {
    setSaveCallOnly(saveCallOnly);
    setIsSaveNumberModalOpen(true);
    unknownNumberRef.current = unknownNumber;
    unknownNumberCallIdRef.current = currentCallIdRef.current;
  }

  function resetStatesAfterPostCall() {
    setSaveCallEnabled(true);
    updateCallCenterStatusMutationContext('available');
    setIsPostCall(false);
    setIsSaveUnknownSuccess(false);
  }

  async function onSuccessSaveNumber(updatedPhoneId, updatedCallId) {
    await updateCallRecordMutation({
      variables: {
        call_id: parseInt(updatedCallId, 10),
        from_phone_id: parseInt(updatedPhoneId, 10),
      },
    });
    if (!isPostCall) {
      setIsSaveUnknownSuccess(true);
    }
    refreshActiveConfRef.current = true;
    setIsSaveNumberModalOpen(false);
    if (isPostCall) {
      // open outcome modal if unknown number was saved at the end of the call
      setOutcomeModalVisible(true);
    }
  }

  function setToPostCall() {
    updateCallCenterStatusMutationContext('postcall');
    setIsPostCall(true);
    resetCallCenterState();
  }

  function isNotPatientOrInboundBeforePickUp() {
    return (
      inboundCall ||
      (onCallDialingParticipant && onCallDialingParticipant?.participantType !== 'patient')
    );
  }

  function isPatientBeforePickUp() {
    return (
      (inboundCall && inboundCall?.label === 'Patient') ||
      (onCallDialingParticipant && onCallDialingParticipant?.participantType === 'patient')
    );
  }

  async function checkIfParticipantAfterCall(phoneId, participants) {
    // checks if patient is added in the beginning of the call or any time during the call
    const checkParticipant =
      (participants?.length > 0 && !!participants?.find(p => p.type === 'patient')) ||
      isInboundPatient ||
      isOutboundPatient ||
      isSaveUnknownSuccess;

    // if initial check fails, searches call record to see if patient is on the call record
    if (!checkParticipant) {
      const currentPhoneRecord = await apolloClient.query({
        query: GET_PHONES_WITH_ASSOCIATIONS,
        variables: { query: { id: phoneId } },
        fetchPolicy: 'network-only',
      });
      const phoneRecordsData = _get(currentPhoneRecord, 'data.phones.data');
      if (phoneRecordsData?.length > 0) {
        return !!phoneRecordsData?.find(p => p.patient_id);
      }
    } else {
      return checkParticipant;
    }
  }

  async function isEnrollerOnCallRecord(participants) {
    const callRecord = await getCallRecordQuery(apolloClient, currentCallIdRef.current);
    const fromUserOnCallRecord = _get(callRecord, 'from_user_id');
    const toUserOnCallRecord = _get(callRecord, 'to_user_id');
    const callDirection = _get(callRecord, 'direction');
    const isCallInbound = callDirection === 'inbound';
    const phoneId = isCallInbound
      ? _get(callRecord, 'from_phone_id')
      : _get(callRecord, 'to_phone_id');
    const isUserOnCallRecord = isCallInbound
      ? toUserOnCallRecord === userId
      : fromUserOnCallRecord === userId;
    const isPatientOnTheCall = await checkIfParticipantAfterCall(phoneId, participants);
    return { isUserOnCallRecord, isPatientOnTheCall };
  }

  async function endConference(unknownNumber, currentParticipants) {
    const { isUserOnCallRecord, isPatientOnTheCall } = await isEnrollerOnCallRecord(
      currentParticipants,
    );
    if (isNotPatientOrInboundBeforePickUp() || !isUserOnCallRecord) {
      // if dialing a non-patient, non-patient did not pick up
      resetCallCenterState();
      resetStatesAfterPostCall();
    } else if (isPatientBeforePickUp()) {
      // if dailing patient, patient did not pick up
      setOutcomeModalVisible(true);
      setToPostCall();
    } else {
      if (unknownNumber && onMembersPage) {
        if (saveCallEnabled) {
          // if unknown number & number was not saved during call
          setIsSaveNumberModalOpen(true);
          unknownNumberCallIdRef.current = currentCallIdRef.current;
          unknownNumberRef.current = unknownNumber;
          setToPostCall();
          return;
        }
        unknownNumberCallIdRef.current = currentCallIdRef.current;
        unknownNumberRef.current = unknownNumber;
      }
      if (isSaveUnknownSuccess) {
        // if unknown number was saved during call
        setSaveCallEnabled(true);
        setOutcomeModalVisible(true);
        setToPostCall();
        return;
      } else if (isPatientOnTheCall) {
        // if patient picked up
        setOutcomeModalVisible(true);
        setIsInboundPatient(false);
        setIsOutboundPatient(false);
        setToPostCall();
        return;
      }
      // if non-patient picked up
      resetCallCenterState();
      resetStatesAfterPostCall();
    }
  }

  function getPatientName(patientParticipant) {
    const patientName = patientParticipant?.name;
    if (
      onCallDialingParticipant?.participantType === 'patient' &&
      onCallDialingParticipant?.names?.length &&
      !onCallDialingParticipant.names.includes(patientName)
    ) {
      return onCallDialingParticipant.names[0];
    }
    return patientName;
  }

  async function onCallUpdatingParticipants(currentParticipants) {
    // if (callCenterPatientId) {
    //   return;
    // }
    // if (!!callCenterPatientId && !!onCallDialingParticipant) {
    //   refetchPharmacy();
    //   return;
    // }
    const patientParticipant = currentParticipants?.find(p => p.type === 'patient');
    if (!!patientParticipant) {
      async function fetchMemberByName(name) {
        const members = await apolloClient.query({
          query: SEARCH_MEMBERS,
          variables: {
            skip: 0,
            limit: 25,
            query: { search: name },
            searchCategory: 'name',
          },
          fetchPolicy: 'network-only',
        });
        if (members?.data?.searchMembers?.data?.length > 0) {
          const bestCandidate = members?.data?.searchMembers?.data[0];
          if (!!bestCandidate.id) {
            setCallCenterPatientId(bestCandidate.id);
            refetchPharmacy();
          }
        }
      }
      // fetchMemberByName(patientParticipant.name);
      // There is a patient in conference
      if (!!patientParticipant.number) {
        const phoneRecords = await apolloClient.query({
          query: GET_PHONES_WITH_ASSOCIATIONS,
          variables: { query: { number: patientParticipant.number } },
          fetchPolicy: 'network-only',
        });
        if (_get(phoneRecords, 'data.phones.data').length > 0) {
          const formattedPhones = getFormattedPhoneAssociations(
            _get(phoneRecords, 'data.phones.data'),
          );
          const { patients } = formattedPhones;
          const patientName = getPatientName(patientParticipant);
          const patient_id = !!patientName
            ? patients
                .sort((a, b) => !!b.assigned_pharmacy_id - !!a.assigned_pharmacy_id)
                .find(p => p.name === patientName).id
            : formattedPhones.bestCandidate?.patient_id;
          if (!!patient_id) {
            setCallCenterPatientId(patient_id);
            refetchPharmacy();
          } else if (!!patientName) {
            fetchMemberByName(patientName);
          }
        }
      } else if (!!patientParticipant.name) {
        fetchMemberByName(patientParticipant.name);
      }
    }
  }

  function onOutcomeCompleted() {
    setOutcomeModalVisible(false);
    resetStatesAfterPostCall();
  }

  function removeSupervisor() {
    if (twilioConnection) {
      twilioConnection.disconnect();
    }
    resetCallCenterState();
    setSupervisingCallContext(null);
    updateCallCenterStatusMutationContext('available');
  }

  function resetStatusAndCloseModal() {
    setIsSaveNumberModalOpen(false);
    resetStatesAfterPostCall();
  }

  async function onCallDialpadHandler(dialpadNumber) {
    const phoneRecords = await apolloClient.query({
      query: GET_PHONES_WITH_ASSOCIATIONS,
      variables: { query: { number: `+1${dialpadNumber}` } },
      fetchPolicy: 'network-only',
    });
    if (callCenterUserStatusContext !== 'oncall') {
      updateCallCenterStatusMutationContext('calldialing');
    }

    let callRecord;
    let name = 'Unknown';
    // Found a phone record. Let's find the phone id and use that to make a call record.
    if (_get(phoneRecords, 'data.phones.data').length > 0) {
      const formattedPhones = getFormattedPhoneAssociations(_get(phoneRecords, 'data.phones.data'));
      const { bestCandidate } = formattedPhones;
      name = bestCandidate?.phone_label.includes('CPCC')
        ? bestCandidate?.phone_label
        : bestCandidate.name;
      callRecord = await createCallRecordMutation({
        variables: { toPhoneId: _get(bestCandidate, 'phone_id'), direction: 'outbound' },
      });
    }
    // No phone record associated to number. Will create a phone record and a call record by passing a dialpadNumber.
    else {
      callRecord = await createCallRecordMutation({
        variables: { dialpadNumber },
        direction: 'outbound',
      });
    }

    const callId = _get(callRecord, 'data.createCallRecord.data.callId');
    const toPhoneId = _get(callRecord, 'data.createCallRecord.data.toPhoneId');
    const fromCallerId = _get(callRecord, 'data.createCallRecord.data.callerIdNumber');

    currentCallIdRef.current = callId;

    makeOutboundCall({
      userId,
      toPhoneId,
      number: `+1${dialpadNumber}`,
      name,
      callId,
      fromCallerId,
    });
  }

  const onMembersPage = _get(location, 'pathname').match('members');

  function renderPhoneArea() {
    const patientInfo =
      _get(selectedPatInfoData, 'patients.data[0]') &&
      buildPatientInfo(_get(selectedPatInfoData, 'patients.data[0]'));

    if ((selectedPatInfoLoading || showConnectingTwilio) && onMembersPage) {
      return <LoadingOutlined style={{ fontSize: '25px', marginLeft: '25px' }} />;
    }

    return (
      <>
        <div className={css(sx.callCenterBar)}>
          {onMembersPage && patientInfo && (
            <CallPatientButton
              callBtnHandler={async index => {
                setShowConnectingTwilio(true);

                const r = await createCallRecordMutation({
                  variables: { toPhoneId: patientInfo?.phoneIds[index], direction: 'outbound' },
                });
                const callId = _get(r, 'data.createCallRecord.data.callId');
                const fromCallerId = _get(r, 'data.createCallRecord.data.callerIdNumber');

                currentCallIdRef.current = callId;
                makeOutboundCall({
                  userId,
                  toPhoneId: patientInfo.phoneIds[index],
                  number: patientInfo.phones[index],
                  name: _get(patientInfo, 'patientName'),
                  callId,
                  fromCallerId,
                });
              }}
              // TODO: Don't spread
              {...patientInfo}
            />
          )}
          <div className={css(sx.dialCont)}>
            <DialPad onCallHandler={onCallDialpadHandler} />
          </div>
        </div>
      </>
    );
  }

  function renderMakeActive() {
    return (
      <MakeActive
        loadingTwilioToken={loadingTwilioToken}
        getTwilioTokenHandler={async () => {
          deregisterTwilioRefContext.current = true;
          setLoadingTwilioToken(true);
          const token = await getTwilioTokenQuery(apolloClient);

          // Adding a delay of 300ms  so user would see the loading state at least 300ms.
          // User might see the loading state flicker if the api response is too fast.
          const debouncedFn = _debounce(() => {
            setLoadingTwilioToken(false);
          }, 300);

          updateCallCenterStatusMutationContext('available');
          setIsCallCenterActivatedContext(true);
          registerTwilioDevice(
            token,
            setTwilioConnection,
            // setCallDetails,
            setInboundCall,
            deregisterTwilioRefContext,
            resetCallCenterState,
            updateCallCenterStatusMutationContext,
            currentCallIdRef,
            apolloClient,
            setIsCallCenterActivatedContext,
            twilioTokenExpiredRef,
            setOnCallDialingParticipant,
            setIsOnCall,
            setShowConnectingTwilio,
            setIsOutboundPatient,
            setUpdatedPharmacyContext,
            userId,
          );
          debouncedFn();
        }}
      />
    );
  }

  function renderInbound() {
    return (
      <div className={css(sx.inboundCall)}>
        <div>
          <InboundCall
            label={inboundCall.label} // posdsible values = ['Patient', 'Plan', 'Prescriber', 'Pharmacy', 'Unknown']
            patientId={inboundCall.patient_id}
            name={inboundCall.name}
            phone={inboundCall.phone}
            insurance={inboundCall.insurance}
            acceptHandler={async () => {
              twilioConnection.accept();

              // Redirect to member's page if user is not on member's page already.
              if (!onMembersPage && inboundCall.patient_id) {
                history.push('/members');
              }

              if (_get(inboundCall, 'patient_id')) {
                setSelectedPatientId(_get(inboundCall, 'patient_id'));
                setIsInboundPatient(true);
              }

              setInboundCall(null);
            }}
            rejectHandler={() => {
              twilioConnection.reject();
              endConference();
            }}
          />
        </div>
      </div>
    );
  }

  function renderCallCenterBar() {
    if (!isCallCenterActivatedContext && callCenterUserStatusContext === 'oncall') {
      return <OnCallElsewhere />;
    }

    if (!_isEmpty(supervisingCallContext) && isCallCenterActivatedContext) {
      return (
        <OnCallSupervising
          callId={supervisingCallContext.currentCallId}
          onHangup={removeSupervisor}
        />
      );
    } else if (isOnCall && !supervisingCallContext) {
      // let shouldRender = true;
      
      // //TECD-647 
      // const isUnknownCaller = twilioConnection?.message?.name?.includes('Unknown');
      // const isIncomingUnknownCall = twilioConnection?._direction === 'INCOMING' && callDetails.name === 'Unknown';

      // if (isUnknownCaller || isIncomingUnknownCall) {
      //   shouldRender = selectedPatientId === selectedPatInfoData?.patients?.data[0]?.id;
      // }
    
      // if (shouldRender) {
        const patientInfo =
          _get(selectedPatInfoData, 'patients.data[0]') &&
          buildPatientInfo(_get(selectedPatInfoData, 'patients.data[0]'));
    
        return (
          <OnCallContainer
            callId={currentCallIdRef.current}
            isCallIdSet={isCallIdSet}
            endConference={endConference}
            refreshActiveConfRef={refreshActiveConfRef}
            refreshActiveConf={refreshActiveConfRef.current}
            onCallDialingParticipant={onCallDialingParticipant}
            onCallUpdatingParticipants={onCallUpdatingParticipants}
            setOnCallDialingParticipant={setOnCallDialingParticipant}
            onSwitchPatient={patient_id => {
              setCallCenterPatientId(patient_id);
            }}
            onClickUnknownNumber={onClickUnknownNumber}
            twilioConnection={twilioConnection}
            selectedPatientData={patientInfo}
            isSaveCallEnabled={saveCallEnabled}
            isPostCall={isPostCall}
          />
        );
      // }
    } else if (inboundCall) {
      return renderInbound();
    } else if (isCallCenterActivatedContext) {
      return renderPhoneArea();
    }

    return renderMakeActive();
  }

  return (
    <div>
      <div className={css(sx.callCenterContainer, inboundCall ? sx.green : null)}>
        {renderCallCenterBar()}
        <CallCenterStatus />
      </div>

      <SaveNumberModal
        callId={unknownNumberCallIdRef.current}
        saveCallOnly={saveCallOnly}
        patient={
          _get(selectedPatInfoData, 'patients.data[0]') &&
          buildPatientInfo(_get(selectedPatInfoData, 'patients.data[0]'))
        }
        patientId={callCenterPatientId}
        number={unknownNumberRef.current}
        onCancel={() => resetStatusAndCloseModal()}
        afterClose={() => {
          setSaveCallOnly(false);
          unknownNumberCallIdRef.current = null;
          unknownNumberRef.current = null;

          setIsSaveNumberModalOpen(false);
        }}
        isModalOpen={isSaveNumberModalOpen}
        onSuccessSaveNumber={onSuccessSaveNumber}
        setSaveCallOnly={setSaveCallOnly}
        isPostCall={isPostCall}
        setSaveCallEnabled={setSaveCallEnabled}
        setIsPostCall={setIsPostCall}
      />
      <CallOutcomeModal
        callId={lastCallId || currentCallIdRef.current}
        isModalOpen={showOutcomeModal}
        onSuccessCallOutcome={onOutcomeCompleted}
        patient={
          _get(selectedPatInfoData, 'patients.data[0]') &&
          buildPatientInfo(_get(selectedPatInfoData, 'patients.data[0]'))
        }
        patientId={callCenterPatientId}
      />
    </div>
  );
};

CallCenterBar.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};
export default withRouter(CallCenterBar);
