import gql from 'graphql-tag';

// eslint-disable-next-line
export const queryPhones = gql`
  query phones($limit: Int, $query: JSON, $skip: Int) {
    phones(limit: $limit, query: $query, skip: $skip) {
      data {
        can_sms
        extension
        id
        is_zipdrug
        label
        number
        order
      }
      limit
      skip
      total
    }
  }
`;

export const mutateCreatePhone = gql`
  mutation createPhone(
    $extension: String
    $label: String!
    $number: String!
    $pharmacy_id: Int
    $user_id: Int
  ) {
    createPhone(
      extension: $extension
      label: $label
      number: $number
      pharmacy_id: $pharmacy_id
      user_id: $user_id
    ) {
      data {
        can_sms
        extension
        id
        is_zipdrug
        label
        number
        order
      }
      errors
    }
  }
`;
