import { getUrlParams } from '@zipdrug/ui';
import gql from 'graphql-tag';
import { apolloClient } from '../../App';

const VALIDATE_PW = gql`
  mutation validatePassword($password: String, $token: String) {
    validatePassword(password: $password, token: $token) {
      data
      errors
    }
  }
`;

const validatePwToUsername = async values => {
  const urlParams = getUrlParams();
  const { data } = await apolloClient.mutate({
    mutation: VALIDATE_PW,
    variables: {
      password: values.password,
      token: urlParams.token,
    },
  });
  return !data?.validatePassword?.data ? data?.validatePassword?.errors : '';
};

export default validatePwToUsername;
