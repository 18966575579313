import React, { useEffect, useState } from 'react';
import { Button, Form, message, Modal, Radio, Typography } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import { patients } from '@zipdrug/react-api-sdk';
import EmailPreferredFollowup from './EmailPreferredFollowup';
import PhonePreferredFollowup from './PhonePreferredFollowup';
import preferenceMap from './PreferenceMap';

// CSS styles
const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '583px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  otherStyles: {
    border: '1px solid #EAEAEA',
    borderRadius: '6px',
    padding: '1.3rem',
    fontSize: '1.5rem',
    outline: 'none',
    color: '#1E1E34',
  },
  radioButton: {
    borderRadius: '0 0 0 0 ',
  },
  radioGroup: {
    margin: '0 0 17px 0',
  },
  width100: {
    width: '100%',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '14px',
    color: '#ff4d4f',
    position: 'absolute',
    bottom: '20px',
    padding: '0px 5px',
    backgroundColor: 'white',
  },
});

const { Title, Text } = Typography;
const PreferredFollowupModal = ({
  onCloseModal, // use this to close the model and refresh the states.
  patient,
  visible,
  refetchSelectedPatientInfo,
  preferredPhone,
}) => {
  const [form] = Form.useForm();

  const [preferredSelection, setPreferredSelection] = useState(patient?.preferred_followup);
  const [preferredPhoneNumber, setPreferredPhoneNumber] = useState(null);
  const [emailId, setEmailId] = useState(null);
  const [phoneId, setPhoneId] = useState(null);
  const [changesSaved, setChangesSaved] = useState(true);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [updatePreferredFollowup] = useMutation(patients.mutateUpdatePatient, {
    onError: error => {
      setError(error.message);
    },
    onCompleted: () => {
      patient.preferred_followup = preferredSelection;
      if (preferredSelection === 'email') {
        patient.preferred_email_id = emailId;
      } else if (preferredSelection === 'phone') {
        patient.preferred_phone_id = preferredPhoneNumber;
      }
      message.success(`Preferred Follow-Up saved`);
      setEmailId(null);
      setPhoneId(null);
      form.resetFields();
      refetchSelectedPatientInfo();
      onCloseModal();
    },
  });

  const handleRadioButtonClick = e => {
    setPreferredSelection(e);
    setError(null);
  };

  const onCancel = () => {
    form.resetFields();
    setPreferredSelection(patient?.preferred_followup);
    setPhoneId(null);
    refetchSelectedPatientInfo();
    onCloseModal();
    setError(null);
  };

  const updatePreference = (preferred_followup_type, id = null) => {
    const modifier = {};
    modifier.preferred_followup = preferred_followup_type;
    if (preferred_followup_type === 'phone') {
      modifier.preferred_phone_id = id;
    } else if (preferred_followup_type === 'email') {
      modifier.preferred_email_id = id;
    }
    updatePreferredFollowup({
      variables: {
        id: patient?.id,
        modifier,
      },
    });
  };

  const validateForm = () => {
    if (preferredSelection === 'email' && emailId) {
      return { validated: true, selection: preferredSelection, id: emailId, saved: changesSaved };
    } else if (preferredSelection === 'phone' && phoneId) {
      return { validated: true, selection: preferredSelection, id: phoneId, saved: !isEditing };
    } else if (preferredSelection === 'do_not_followup') {
      return { validated: true, selection: preferredSelection, id: null, saved: true };
    }
    return { validated: false };
  };

  const submitForm = () => {
    const { validated, selection, id, saved } = validateForm();
    if (validated && saved) {
      updatePreference(selection, id);
    } else if (preferredSelection) {
      setError(`PLEASE SAVE ${preferredSelection} BEFORE SETTING PREFERENCE`);
    } else {
      setError('PLEASE SET A PREFERENCE BEFORE SAVING');
    }
  };

  const updatePhoneArr = (update, p, n) => {
    patient.phones.push(p);
    setPhoneId(p.id);
    if (update) {
      patient.phones.find(x => x.id === p.id).number = n;
    }
    setPreferredPhoneNumber(p.id);
  };

  useEffect(() => {
    if (preferredPhone) {
      setPhoneId(preferredPhone.id);
    }
  }, [preferredPhone]);

  const renderMenu = () => {
    if (preferredSelection === 'phone') {
      return (
        <PhonePreferredFollowup
          patient={patient}
          phones={patient?.phones?.filter(p => p.can_sms)}
          phoneId={phoneId}
          preferredPhone={preferredPhone}
          setChangesSaved={setChangesSaved}
          setPhoneId={setPhoneId}
          setError={setError}
          updatePhoneArr={updatePhoneArr}
          setIsEditing={setIsEditing}
        />
      );
    } else if (preferredSelection === 'email') {
      return (
        <EmailPreferredFollowup
          email={patient?.emails[0]?.email}
          patient={patient}
          setEmailId={setEmailId}
          setError={setError}
        />
      );
    } else if (preferredSelection === 'do_not_followup') {
      return (
        <>
          <div className={css(sx.flexContainer)}>
            <div className={css(sx.inputBox)}>
              You have indicated this member requests no automated follow-ups
            </div>
          </div>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (patient?.preferred_email_id) {
      setEmailId(patient?.preferred_email_id);
    } else {
      setEmailId(patient?.emails[0]?.id);
    }
  }, [patient]);

  useEffect(() => {
    if (phoneId || emailId) {
      setError(null);
    }
  }, [phoneId, emailId]);

  return (
    <Modal
      className={css(sx.modalContainer)}
      bodyStyle={{ position: 'relative' }}
      footer={[
        <Button key="cancel" onClick={() => onCancel()} className={css(sx.button)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={() => submitForm()} className={css(sx.button)}>
          Set Preference
        </Button>,
      ]}
      getContainer={false}
      okText="Save"
      onCancel={onCancel}
      open={visible}
    >
      <Title className="modelHeaderText" level={3}>
        Select Preference
      </Title>
      <div>
        Note: Filling out this preference indicates this member gives consent for automated
        follow-up outreach
      </div>
      <br />
      <div>What is your preferred method of follow-up?</div>
      <Radio.Group
        buttonStyle="solid"
        defaultValue={preferredSelection}
        onChange={e => handleRadioButtonClick(e.target.value)}
        optionType="button"
        className={css(sx.radioGroup)}
      >
        {Object.keys(preferenceMap).map(preference => (
          <Radio.Button key={preference} className={css(sx.radioButton)} value={preference}>
            {preferenceMap[preference].label}
          </Radio.Button>
        ))}
      </Radio.Group>
      {renderMenu()}
      <p>{error && <Text className={css(sx.error)}>{error}</Text>}</p>
    </Modal>
  );
};

PreferredFollowupModal.propTypes = {
  onCloseModal: PropTypes.func,
  patient: PropTypes.object,
  visible: PropTypes.bool,
  refetchSelectedPatientInfo: PropTypes.func,
  preferredPhone: PropTypes.object,
};

export default PreferredFollowupModal;
