/* eslint-disable no-case-declarations */
import cloneDeep from 'lodash/cloneDeep';

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateVariables = actions => {
  const { pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  return {
    ...pageOptions,
  };
};

export default generateVariables;
