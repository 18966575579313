const formatPhoneNumber = (phoneNumberString, formatForInput = false) => {
  if (phoneNumberString) {
    const removeCountry = phoneNumberString?.replace('+1', '');
    const match = removeCountry.match(/(\d{1,3})?(\d{1,3})?(\d{1,4})?/);

    if (match && match[1]) {
      let formatted = `(${match[1]}`;

      if (match[2]) {
        formatted += `) ${match[2]}`;
      }

      if (match[3]) {
        formatted += `-${match[3]}`;
      }

      return formatted;
    }
  }

  // if can't format for display just return the original string
  return formatForInput ? '' : phoneNumberString;
};

// eslint-disable-next-line import/prefer-default-export
export default formatPhoneNumber;
