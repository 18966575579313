import gql from 'graphql-tag';

const GET_STATES = gql`
  query getUniquePatientStates($query: JSON) {
    getUniquePatientStates(query: $query) {
      data {
        states {
          name
          text
          value
        }
      }
    }
  }
`;

export default GET_STATES;
