import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite';
import { Modal, Select, Space, Typography } from 'antd';
import PharmacyInput from './PharmacyInput/PharmacyInput';
import states from '../../constants/states';

const sx = StyleSheet.create({
  label: {
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  button: {
    padding: '8px 40px',
    fontSize: '16px',
  },
  marginBottom10: {
    marginBottom: '10px',
  },
  modalContainer: {
    minWidth: '579px',
    minHeight: '403px',
    width: '579px',
    height: '403px',
  },
  error: {
    textTransform: 'uppercase',
    fontSize: '12px',
    color: '#B50D0D',
  },
  noGap: {
    gap: '0px !important',
  },
});

const { Text, Title } = Typography;
const { Option } = Select;

const pharmacySchema = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  npi: yup.string().required('NPI is a required field'),
  address: yup.object().shape({
    id: yup.string(),
    line1: yup.string().required('Street Address is a required field'),
    line2: yup.string().nullable(),
    city: yup.string().required('City is a required field'),
    state: yup.string().required('State is required'),
    postal_code: yup.string().required('Zip Code is a required field'),
  }),
});

const ModifyHubPharmacy = ({
  isEdit,
  visible,
  submitChildPharmacyInfo,
  onCancel,
  pharmacyInfo,
  parentNpi,
}) => {
  const [editedPharmacyInfo, setEditedPharmacyInfo] = useState(cloneDeep(pharmacyInfo));
  const [errors, setErrors] = useState({});

  const updatePharmacyInfo = (field, value, subfield) => {
    const payload = { ...editedPharmacyInfo };

    if (Object.keys(errors).length > 0) {
      validateForm(payload);
    }

    if (!payload[field]) {
      payload[field] = {};
    }
    if (subfield) {
      payload[field][subfield] = value;
    } else {
      payload[field] = value;
    }

    setEditedPharmacyInfo(payload);
  };

  const updateAddressInfo = (field, value) => {
    updatePharmacyInfo('address', value, field);
  };

  useEffect(() => {
    if (visible && pharmacyInfo) {
      setEditedPharmacyInfo(cloneDeep(pharmacyInfo));
    }
    setErrors({});
  }, [visible]);

  const validateForm = async pharmacyInfo => {
    let errorPayload = {};
    await pharmacySchema
      .validate(pharmacyInfo, { abortEarly: false })
      .then(() => {
        setErrors({});
      })
      .catch(error => {
        errorPayload = error?.inner?.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
      });
    const testPharmacyInfo = pharmacyInfo;
    if (testPharmacyInfo.address?.postal_code) {
      const zipcodeIsValid = RegExp(/^(?:\d{5})?$/).test(testPharmacyInfo.address.postal_code);

      if (!zipcodeIsValid) {
        errorPayload['address.postal_code'] = 'Please enter a valid 5-digit zipcode';
      }
    }
    if (testPharmacyInfo.npi === `${parentNpi}`) {
      errorPayload.npi = 'NPI cannot be the same as parent pharmacy npi';
    } else if (
      testPharmacyInfo.npi &&
      `${testPharmacyInfo.npi}` !== '' &&
      `${testPharmacyInfo.npi}`.length !== 10
    ) {
      errorPayload.npi = 'NPI must be 10 digits';
    }

    setErrors(errorPayload);
    return errorPayload;
  };

  const onSubmit = async () => {
    const errorPayload = await validateForm(editedPharmacyInfo);
    if (Object.keys(errorPayload).length === 0) {
      submitChildPharmacyInfo(editedPharmacyInfo);
    }
  };

  return (
    <Modal
      onOk={onSubmit}
      onCancel={onCancel}
      open={visible}
      okText={isEdit ? 'Save' : 'Add Child Pharmacy'}
      className={css(sx.modalContainer)}
      getContainer={false}
    >
      <Title className="modelHeaderText" level={3}>
        {!isEdit ? 'Add Child Pharmacy' : `Editing ${pharmacyInfo?.name}`}
      </Title>
      <Space size="middle" align="baseline" className={css(sx.marginBottom10)}>
        <PharmacyInput
          field="name"
          label="Pharmacy Location Name"
          defaultValue={editedPharmacyInfo?.name}
          value={editedPharmacyInfo?.name}
          onChange={updatePharmacyInfo}
          error={errors?.name}
          width="271px"
        />
        <PharmacyInput
          field="npi"
          label="npi"
          defaultValue={editedPharmacyInfo?.npi}
          value={editedPharmacyInfo?.npi}
          onChange={updatePharmacyInfo}
          error={errors?.npi}
          width="202px"
        />
      </Space>

      <Space size="middle" align="baseline" className={css(sx.marginBottom10)}>
        <PharmacyInput
          field="line1"
          label="Street Address"
          defaultValue={editedPharmacyInfo?.address?.line1}
          value={editedPharmacyInfo?.address?.line1}
          onChange={updateAddressInfo}
          error={errors?.['address.line1']}
          width="500px"
        />
      </Space>

      <Space size="middle" align="baseline" className={css(sx.marginBottom10)}>
        <PharmacyInput
          field="line2"
          label="Apt/Suite"
          defaultValue={editedPharmacyInfo?.address?.line2}
          value={editedPharmacyInfo?.address?.line2}
          onChange={updateAddressInfo}
          error={errors?.['address.line2']}
          width="250px"
        />
      </Space>

      <Space size="middle" align="baseline" className={css(sx.marginBottom10)}>
        <PharmacyInput
          field="city"
          label="City"
          defaultValue={editedPharmacyInfo?.address?.city}
          value={editedPharmacyInfo?.address?.city}
          onChange={updateAddressInfo}
          error={errors?.['address.city']}
          width="250px"
        />
        <div>
          <Text className={css(sx.label)}>State</Text>
          <br />
          <Select
            style={{ width: '160px' }}
            defaultValue={editedPharmacyInfo?.address?.state}
            value={editedPharmacyInfo?.address?.state}
            onChange={value => {
              updatePharmacyInfo('address', value, 'state');
            }}
            error={errors?.['address.state']}
          >
            {states.map(state => (
              <Option value={state.value} key={state.value}>
                {state.text}
              </Option>
            ))}
          </Select>
          <br />
          {errors?.['address.state'] && (
            <Text className={css(sx.error)}>{errors?.['address.state']}</Text>
          )}
        </div>
      </Space>
      <Space
        size="large"
        align="baseline"
        className={css(sx.marginBottom10, sx.noGap)}
        direction="vertical"
      >
        <PharmacyInput
          field="postal_code"
          label="Zip Code"
          defaultValue={editedPharmacyInfo?.address?.postal_code}
          value={editedPharmacyInfo?.address?.postal_code}
          onChange={updateAddressInfo}
          width="180px"
        />
        <div>
          {errors?.['address.postal_code'] && (
            <Text className={css(sx.error)}>{errors?.['address.postal_code']}</Text>
          )}
        </div>
      </Space>
      <br />
    </Modal>
  );
};

ModifyHubPharmacy.propTypes = {
  isEdit: PropTypes.bool,
  visible: PropTypes.bool,
  submitChildPharmacyInfo: PropTypes.func,
  onCancel: PropTypes.func,
  pharmacyInfo: PropTypes.object,
  parentNpi: PropTypes.number,
};

export default ModifyHubPharmacy;
