export const getStatusFiltersForMembers = isPharmacist => {
  const memberStatusFilters = [
    { text: 'Enrolled', value: 'interested' },
    { text: 'Active', value: 'active' },
  ];

  if (!isPharmacist) {
    memberStatusFilters.push({ text: 'Churned', value: 'churned' });
  }
  return memberStatusFilters;
};

export const statusTypes = {
  interested: 'Enrolled',
  active: 'Active',
  churned: 'Churned',
};
