import cloneDeepWith from 'lodash/cloneDeepWith';

const OmitDeep = (collection, excludeKeys) => {
  function omitFn(value) {
    if (value && typeof value === 'object') {
      excludeKeys.forEach(key => {
        delete value[key];
      });
    }
  }

  return cloneDeepWith(collection, omitFn);
};

export default OmitDeep;
