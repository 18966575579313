import { activities as activitiesSelection, withSession } from '@zipdrug/react-api-sdk';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { get } from 'lodash';
import withAlertsCountByType from '../../hoc/withAlertsCountByType';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import withSelectedPatientId from '../../hoc/withSelectedPatientId';
import ActivityFeed from './ActivityFeed';
import queryActivities from './graphql/queryActivities.js';

const ActivityFeedWithData = graphql(queryActivities, {
  skip: ({ selectedPatientId, userId }) => !selectedPatientId || !userId,
  options: ({ selectedPatientId }) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: 10,
      query: { patient_id: selectedPatientId },
      skip: 0,
    },
  }),
  props: ({
    data: { activities: result, fetchMore, loading, ...data } = {},
    ownProps: { selectedPatientId },
  }) => {
    let activities = [];
    let totalActivities = null;

    if (result && result.data) {
      activities = result.data;
      totalActivities = result.total;
    }

    activities = activities.filter(activity => !activity.deleted_at);

    if (activities.length) {
      const firstActivity = activities[0];
      const activityPatientID = get(firstActivity, 'patient.id');
      if (activityPatientID !== selectedPatientId) {
        activities = [];
      }
    }

    return {
      activities,
      isLoading: loading,
      loadMoreRows: (fromBeginning = false) => {
        const activitiesLength = fromBeginning ? 0 : activities.length;
        return fetchMore({
          query: queryActivities,
          updateQuery: (previousResult, { fetchMoreResult }) => {
            let previousData = [];
            if (previousResult) {
              previousData = [...previousResult?.activities?.data];
            }
            return {
              activities: {
                ...fetchMoreResult.activities,
                data: [...previousData, ...fetchMoreResult?.activities?.data],
              },
            };
          },
          variables: {
            limit: 10,
            query: { patient_id: selectedPatientId },
            skip: activitiesLength,
          },
        });
      },
      subscribeToNewActivities: ({ patient_id }) =>
        data.subscribeToMore({
          document: activitiesSelection.subscribeToActivities,
          updateQuery: (prev, { subscriptionData: { data: { activityOperation } = {} } }) => {
            if (!activityOperation) {
              return prev;
            }

            const nextActivities = [...prev.activities.data];
            if (activityOperation.operation === 'created') {
              nextActivities.unshift(activityOperation.data);
            } else if (activityOperation.operation === 'updated') {
              nextActivities.forEach((activity, index) => {
                if (activity.id === activityOperation.data.id) {
                  nextActivities[index] = activityOperation.data;
                }
              });
            }

            return {
              activities: {
                ...prev.activities,
                data: nextActivities,
              },
            };
          },
          variables: {
            patient_id,
          },
        }),
      totalActivities,
    };
  },
})(ActivityFeed);

export default compose(
  withCurrentPharmacy,
  withSelectedPatientId,
  withSession,
  withAlertsCountByType,
)(ActivityFeedWithData);
