import PropTypes from 'prop-types';
import React from 'react';
import ResetPasswordFormContainer from './ResetPasswordFormContainer';
import StandaloneFormPageTemplate from '../ui/StandaloneFormPageTemplate';

const ResetPasswordPage = ({ history }) => (
  <StandaloneFormPageTemplate title="Reset password">
    <ResetPasswordFormContainer history={history} />
  </StandaloneFormPageTemplate>
);

ResetPasswordPage.propTypes = {
  history: PropTypes.object.isRequired,
};

export default ResetPasswordPage;
