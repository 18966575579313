import React, { useState, useContext, useEffect } from 'react';
import { formatPhone } from '@zipdrug/ui';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Tooltip } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { COLORS } from './constants';

import PhonesDropdown from './PhonesDropdown';

const sx = StyleSheet.create({
  btnWrapper: {
    display: 'flex',
    border: '1px solid #048802',
    borderRadius: '6px',
    height: '40px',
    background: 'white',
  },
  phoneAreaBtn: {
    background: 'white',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: COLORS.GREEN,
    borderRadius: '6px',
    ':hover': {
      background: '#EDFFEA',
    },
  },
  callPatientButton: {
    marginLeft: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  dropdownBtn: {
    background: 'white',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',

    borderLeft: '1px solid #048802',
    borderRadius: '0px 6px 6px 0px',
    padding: '0px 10px',
    ':hover': {
      background: '#F4F4F4',
    },
  },
  patientContainer: {
    margin: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  boldFont: {
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '16px',
  },
  arrowUp: {
    transform: 'rotate(180deg)',
  },
  phoneNumber: {
    fontSize: '12px',
  },
});

function CallPatientButton({
  callBtnHandler,
  patientName,
  phones,
  labels,
  primaryPhoneIndex,
  lead_churn,
}) {
  const [showPhonesDropdown, setShowPhonesDropdown] = useState(false);
  const {
    updateCallCenterStatusMutationContext,
    callCenterUserStatusContext,
    isPatientChurnedAlreadyCalledContext,
  } = useContext(CallCenterContext);

  useEffect(() => {
    if (
      !showPhonesDropdown &&
      callCenterUserStatusContext !== 'oncall' &&
      !isPatientChurnedAlreadyCalledContext
    ) {
      updateCallCenterStatusMutationContext('available');
    }
  }, [showPhonesDropdown]);

  return (
    <div className={css(sx.callPatientButton)}>
      <div className={css(sx.btnWrapper)}>
        <Tooltip placement="bottom" title="Outbound Call">
          <button
            className={css(sx.phoneAreaBtn)}
            onClick={() => callBtnHandler(primaryPhoneIndex)}
            disabled={!!lead_churn?.churned_outreach_date || isPatientChurnedAlreadyCalledContext}
          >
            <div className={`${css(sx.patientContainer)} fs-hide`}>
              <div className={css(sx.boldFont)}>{patientName}</div>
              <div className={css(sx.phoneNumber)}>
                {phones && formatPhone(phones[primaryPhoneIndex])}
              </div>
            </div>
          </button>
        </Tooltip>

        <button
          className={css(sx.dropdownBtn)}
          disabled={!!lead_churn?.churned_outreach_date || isPatientChurnedAlreadyCalledContext}
          onClick={() => {
            if (!showPhonesDropdown && callCenterUserStatusContext !== 'oncall') {
              updateCallCenterStatusMutationContext('calldialing');
            }
            setShowPhonesDropdown(!showPhonesDropdown);
          }}
        >
          <div>
            {showPhonesDropdown ? (
              <UpOutlined style={{ color: '#048802' }} />
            ) : (
              <DownOutlined style={{ color: '#048802' }} />
            )}
          </div>
        </button>
      </div>

      <PhonesDropdown
        isVisible={showPhonesDropdown}
        callBtnHandler={callBtnHandler}
        primaryTexts={labels}
        secondaryTexts={phones}
        setShowPhonesDropdown={setShowPhonesDropdown}
      />
    </div>
  );
}

CallPatientButton.propTypes = {
  callBtnHandler: PropTypes.func,
  patientName: PropTypes.string,
  phones: PropTypes.array,
  labels: PropTypes.array,
  primaryPhoneIndex: PropTypes.number,
  lead_churn: PropTypes.object,
};

export default CallPatientButton;
