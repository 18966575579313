import {
  animation,
  colors,
  Column,
  dateUtils,
  Div,
  Dot,
  EnDash,
  font,
  Icon,
  MetaRow,
  Nbsp,
  Row,
  space,
  Span,
  transition,
} from '@zipdrug/ui';
import { Tag } from 'antd';
import { css, StyleSheet } from 'aphrodite';
import { get } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AdherenceChart from '../adherence-chart/AdherenceChart';
import { SessionContext } from '../../../contexts/SessionContextProvider';
import { isPharmacistTeam } from '../../../contexts/utils/models';

const preventSelection = event => {
  event.stopPropagation();
  event.preventDefault();
};

const INACTIVE_BUTTON_ANIMATION = {
  from: { transform: `translateX(-${space.quarter})` },
  to: { transform: 'translateX(0)' },
};

const sx = StyleSheet.create({
  activeStatusButton: {
    ...animation.build({ name: [INACTIVE_BUTTON_ANIMATION, animation.fadeIn] }),
    color: colors.theme.primary.default,
    cursor: 'pointer',
    opacity: 0,
    transform: 'translateX(-50%)',
    userSelect: 'none',
    ':hover': { color: colors.theme.primary.hover },
    ':active': { color: colors.theme.primary.active },
  },
  adherenceChart: {
    marginTop: font.size.medium,
  },
  adherenceChartExpanded: {
    marginBottom: space.quarter,
    marginTop: space.default,
  },
  title: {
    fontWeight: font.weight.bold,
  },
  titleInactive: {
    color: colors.gray62,
  },
  toggleExpandButton: {
    ...transition.build({
      duration: 100,
      ease: animation.ease.outExpo,
      property: 'transform',
    }),
    color: colors.theme.primary.default,
    cursor: 'pointer',
    flex: 'none',
    height: space.default,
    transform: 'scale(1) translateZ(0)',
    transformOrigin: '75% 25%',
    width: '2rem',
    ':hover': {
      color: colors.theme.primary.hover,
      transform: 'scale(0.9) translateZ(0)',
    },
    ':active': {
      color: colors.theme.primary.active,
      transform: 'scale(0.7) translateZ(0)',
    },
  },
  toggleExpandIcon: {
    height: 17,
    position: 'absolute',
    right: 4,
    top: 0,
    width: 8,
  },
  zdPharmTag: {
    border: '1px solid #D9D9D9',
    height: '24px',
  },
});

export default class RxItemHeader extends Component {
  static propTypes = {
    allClaims: PropTypes.arrayOf(PropTypes.object).isRequired,
    isActive: PropTypes.bool,
    isExpanded: PropTypes.bool,
    onToggleActiveStatus: PropTypes.func,
    onToggleExpand: PropTypes.func,
    // Eslint does not see it is used because it is referenced as a string
    // eslint-disable-next-line react/no-unused-prop-types
    rxClaims: PropTypes.arrayOf(PropTypes.object).isRequired,
    postalCodesByPharmacies: PropTypes.arrayOf(PropTypes.object),
  };

  get referenceClaim() {
    return get(this.props, 'rxClaims[0]');
  }

  getTags = (pharmacy, pharmacyId, user, id, dispenseDate) => {
    let serviceTypeCheck = false;
    const currentDate = new Date();
    const getLastAcceptableDate = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
    const isDispDateWiSixMonths = new Date(dispenseDate) > getLastAcceptableDate;

    const getPharmacyServicesType = pharmacy?.pharmacy_services;
    if (getPharmacyServicesType) {
      serviceTypeCheck = getPharmacyServicesType.some(
        pharmacy_service =>
          pharmacy_service?.pharmacy_service_type?.pharmacy_service_type === 'Zipdrug',
      );
    }

    const isPharmacyInactive = pharmacy.status === 'inactive' || pharmacy.status === null;

    if (!isPharmacyInactive && isDispDateWiSixMonths && serviceTypeCheck) {
      // select which tag to display
      if (isPharmacistTeam(user) && pharmacy.id === pharmacyId) {
        return (
          <Tag className={css(sx.zdPharmTag)} key={`${pharmacy}-${id}`} closable={false}>
            {`Filled at Your Pharamcy`}
          </Tag>
        );
      } else if (!isPharmacistTeam(user)) {
        return (
          <Tag className={css(sx.zdPharmTag)} key={`${pharmacy}-${id}`} closable={false}>
            {`Filled at Zipdrug Pharamcy`}
          </Tag>
        );
      }
      return null;
    }

    return null;
  };

  static contextType = SessionContext;

  buildFillDateLabel = () => {
    const { isActive } = this.props;
    const { days_supply, dispense_date } = this.referenceClaim;

    let date = moment.utc(dateUtils.startOfDay(dispense_date));
    let label = 'Last fill';

    if (isActive) {
      date = date.add(days_supply, 'days');
      label = moment.utc(date).isAfter(dateUtils.startOfDay()) ? 'Next fill' : 'Past due';
    }

    return (
      <Span>
        {label}
        <Nbsp />
        <EnDash />
        <Nbsp />
        {date.format('M/DD/YYYY')}
      </Span>
    );
  };

  renderActiveStatusToggle = () => {
    const label = this.props.isActive ? 'inactive' : 'active';

    return (
      <Div
        className="e2e-rxItemStatusToggle"
        onClick={this.props.onToggleActiveStatus}
        styles={sx.activeStatusButton}
        title={`Mark ${this.referenceClaim.brand_name} as an ${label} medication`}
      >
        {`Mark as ${label}`}
      </Div>
    );
  };

  renderHeaderContent = () => {
    const { isActive, isExpanded } = this.props;
    const { brand_name, quantity, strength, unit } = this.referenceClaim;

    const rxMetadata = [
      `${strength}${unit.toLowerCase()}, qty ${quantity}`,
      this.buildFillDateLabel(),
    ];

    return (
      <Div>
        <Row align="center">
          <Div
            className="e2e-rxItemBrandName"
            styles={[sx.title, isActive ? null : sx.titleInactive]}
          >
            {brand_name}
          </Div>
          <Dot style={{ opacity: isExpanded ? 1 : 0 }} />
          {isExpanded && this.renderActiveStatusToggle()}
        </Row>
        <MetaRow items={rxMetadata} />
      </Div>
    );
  };

  renderFilledAtZipdrugTag = () => {
    const { pharmacy, id, is_active, dispense_date } = this.referenceClaim;
    const { postalCodesByPharmacies } = this.props;

    let pharmacyId = null;
    let user = null;
    if (this.context) {
      const role = this.context.state?.currentUser?.roles?.find(r => r.pharmacy_id !== null);
      user = this.context.state?.currentUser;
      pharmacyId = role?.pharmacy_id;
    }

    if (postalCodesByPharmacies?.length > 0) {
      pharmacy.hasPostalCodes = postalCodesByPharmacies.find(
        p => p.id === pharmacy.id,
      ).hasPostalCodes;
    }

    return (
      pharmacy?.hasPostalCodes &&
      is_active &&
      this.getTags(pharmacy, pharmacyId, user, id, dispense_date)
    );
  };

  renderToggleExpandButton = () => (
    <Div
      className="e2e-rxItemToggleExpandButton"
      onClick={this.props.onToggleExpand}
      onMouseDown={preventSelection}
      onMouseUp={preventSelection}
      styles={sx.toggleExpandButton}
      title={`${this.props.isExpanded ? 'Collapse' : 'Expand'} item`}
    >
      <Icon
        direction={this.props.isExpanded ? 'up' : 'down'}
        name="caret"
        styles={sx.toggleExpandIcon}
      />
    </Div>
  );

  render = () =>
    this.props.allClaims.length ? (
      <Column>
        <Row justify="space-between">
          {this.renderHeaderContent()}
          <Row>
            {this.renderFilledAtZipdrugTag()}
            {this.renderToggleExpandButton()}
          </Row>
        </Row>
        {!!this.referenceClaim.measures.length && (
          <AdherenceChart
            allClaims={this.props.allClaims}
            isExpanded={this.props.isExpanded}
            referenceClaim={this.referenceClaim}
            styles={[sx.adherenceChart, this.props.isExpanded ? sx.adherenceChartExpanded : null]}
          />
        )}
      </Column>
    ) : null;
}
