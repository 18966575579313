import {
  ArrowButton,
  Button,
  ErrorMessage,
  font,
  FormEmailField,
  FormPasswordField,
  Row,
  space,
} from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { compose, withHandlers } from 'recompose';

const sx = StyleSheet.create({
  arrowButton: {
    flex: 'none',
    // marginTop: space.half,
    // marginBottom: space.half,
    width: '100%',
    backgroundColor: '#5009B5',
    fontWeight: font.weight.medium,
  },
  formField: {
    marginBottom: space.default,
  },
  secondaryActionRow: {
    marginTop: space.oneAndTwoTenths,
  },
  elevanceFontFamily: {
    color: '#5009B5',
    marginBottom: space.oneAndTwoTenths,
  },
  forgotButton: {
    color: '#5009B5',
    fontWeight: font.weight.medium,
    marginBottom: space.oneAndTwoTenths,
  },
});

const FORMFIELD_PROPS = {
  preserveDimensions: false,
  shouldReplaceLabelWithError: true,
  styles: sx.formField,
};

const LoginForm = ({
  error,
  handleSubmit,
  onRequestResetPassword,
  onFormFieldKeyUp,
  submitFailed,
  submitting,
  ipError,
  isAnthemEmployee,
}) => (
  <Fragment>
    <FormEmailField
      {...FORMFIELD_PROPS}
      autoFocus
      id="e2e-loginFormEmailField"
      label="Email address"
      name="email"
      onKeyUp={onFormFieldKeyUp}
      placeholder="name@email.com"
      styles={sx.elevanceFontFamily}
    />
    <FormPasswordField
      {...FORMFIELD_PROPS}
      id="e2e-loginFormPasswordField"
      label="Password"
      name="password"
      onKeyUp={onFormFieldKeyUp}
      type="password"
      placeholder="••••••••"
      styles={sx.elevanceFontFamily}
    />
    {error && submitFailed && <ErrorMessage styles={sx.formField}>{error}</ErrorMessage>}
    {ipError && (
      <ErrorMessage styles={sx.formField}>
        {isAnthemEmployee
          ? 'Please connect to VPN to log into Care Manager'
          : 'Please connect to VPN to log into Care Control'}
      </ErrorMessage>
    )}
    <ArrowButton
      id="e2e-loginFormSubmitButton"
      loading={submitting}
      onClick={handleSubmit}
      styles={sx.arrowButton}
      text="Sign In"
    />
    <Row styles={sx.secondaryActionRow}>
      <Button
        onClick={onRequestResetPassword}
        outline
        size="micro"
        text="Forgot password"
        type="primary"
        styles={sx.forgotButton}
      />
    </Row>
  </Fragment>
);

LoginForm.propTypes = {
  error: PropTypes.string,
  ipError: PropTypes.any,
  isAnthemEmployee: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onFormFieldKeyUp: PropTypes.func,
  onRequestResetPassword: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool,
  submitting: PropTypes.bool,
};

export default compose(
  withHandlers({
    onFormFieldKeyUp: ({ handleSubmit }) => event => {
      if (event.which === 13) {
        // Submit form if user presses "Enter" key
        event.preventDefault();
        handleSubmit();
      }
    },
  }),
)(LoginForm);
