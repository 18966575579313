import gql from 'graphql-tag';

const GET_CHILD_PHARMACIES = gql`
  query pharmacies($limit: Int, $query: JSON, $skip: Int) {
    pharmacies(limit: $limit, query: $query, skip: $skip) {
      data {
        id
        name
        npi
        status
        chain_pharmacies {
          id
          npi
        }
        parent_pharmacy {
          id
          name
          npi
        }
        address {
          id
          line1
          line2
          city
          state
          postal_code
        }
      }
      limit
      skip
      total
    }
  }
`;

export default GET_CHILD_PHARMACIES;
