/* eslint-disable no-case-declarations */
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';

const sortDirection = {
  ascend: 'ASC',
  descend: 'DESC',
};

const generateOrderByUtil = (key, sortKey, direction) => {
  const payload = {};
  payload[key] = {
    sortKey,
    direction: sortDirection[direction],
  };
  return payload;
};

// Adding format here so it can be changed on the fly.
const dateFormatter = (inputDate, format) => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  format = format.replace('MM', month.toString().padStart(2, '0'));

  if (format.indexOf('YYYY') > -1) {
    format = format.replace('YYYY', year.toString());
  }

  format = format.replace('DD', day.toString().padStart(2, '0'));

  return format;
};

const generateOrderBy = sortOrder => {
  const sortPayload = {
    order_by: {},
  };

  switch (sortOrder?.columnKey) {
    case 'status':
      sortPayload.order_by = generateOrderByUtil('status', 'enroll_status', sortOrder.order);
      return sortPayload;
    case 'language':
      sortPayload.order_by = generateOrderByUtil('language', 'language', sortOrder.order);
      return sortPayload;
    case 'pharmacy':
      sortPayload.order_by = generateOrderByUtil('pharmacy', 'name', sortOrder.order);
      return sortPayload;
    case 'enrolled_date':
      sortPayload.order_by = generateOrderByUtil('enrolled_date', 'enrolled_date', sortOrder.order);
      return sortPayload;
    default:
      if (sortOrder?.model) {
        sortPayload.order_by = generateOrderByUtil(
          sortOrder.model,
          sortOrder.columnKey,
          sortOrder.order,
        );
      }
      return sortPayload;
  }
};

const defaultQueryVariables = {
  query: {},
};

const generateQuery = filters => {
  if (!filters) {
    return { ...defaultQueryVariables };
  }

  return {
    query: {
      ...Object.entries(filters).reduce(
        (acc, curr) => {
          if (curr[1]) {
            switch (curr[0]) {
              case 'name':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  $or: _flatten(
                    _map(['first_name', 'last_name'], item => {
                      return _map(curr[1].split(' '), q => {
                        return { [item]: { $ilike: `%${q}%` } };
                      });
                    }),
                  ),
                };
                return acc;
              case 'pharmacy':
                acc.pharmacyQuery = {
                  ...acc?.pharmacyQuery,
                  $or: _flatten(
                    _map(['name'], item => {
                      return _map(curr[1].split(' '), q => {
                        if (/^\d+$/.test(q)) {
                          return { npi: q };
                        }
                        return { [item]: { $ilike: `%${q}%` } };
                      });
                    }),
                  ),
                };
                return acc;
              case 'language':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  language: curr[1],
                };
                return acc;
              case 'outreach_type':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  event_source: curr[1],
                };
                return acc;
              case 'status':
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  enroll_status: curr[1],
                };
                return acc;
              case 'DOB':
                const formatDate = dateFormatter(curr[1], 'YYYY-MM-DD');
                acc.patientQuery = {
                  ...acc?.patientQuery,
                  birthday: formatDate,
                };
                return acc;
              case 'plan_name':
                acc.planQuery = {
                  name: {
                    $ilike: `%${curr[1]}%`,
                  },
                };
                return acc;
              default:
                return acc;
            }
          }
          return acc;
        },
        {
          ...defaultQueryVariables.query,
        },
      ),
    },
  };
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateVariables = actions => {
  const { sorter, filters, pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  // Generate Order_By
  const order_by = sorter ? generateOrderBy(sorter) : {};
  // Generate Query Variable
  const query = filters ? generateQuery(filters) : { ...defaultQueryVariables };

  return {
    ...pageOptions,
    ...query,
    ...order_by,
  };
};

export default generateVariables;
