import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import { css } from 'aphrodite';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import {
  border,
  Button,
  colors,
  Column,
  Div,
  font,
  formatAddress,
  padding,
  Row,
  space,
  Tag,
  transition,
  TruncateText,
} from '@zipdrug/ui';

const CONTENT_TRANSITION_DELAY = 25;
const CONTENT_TRANSITION_DURATION = 200;
const CONTENT_TRANSITION = transition.build({
  delay: CONTENT_TRANSITION_DELAY,
  duration: CONTENT_TRANSITION_DURATION,
});

const sx = StyleSheet.create({
  button: {
    alignSelf: 'flex-start',
    flex: 'none',
    marginTop: font.size.small,
  },
  container: {
    ...border.build('bottom'),
    overflow: 'hidden',
    paddingRight: font.size.small,
    ':last-of-type': {
      borderBottomWidth: 0,
    },
  },
  content: {
    ...padding.build(font.size.small),
    flex: 1,
    maxWidth: '21rem',
    position: 'relative',
    width: '100%',
  },
  editButton: {
    ...CONTENT_TRANSITION,
    color: colors.theme.primary.default,
    cursor: 'pointer',
    userSelect: 'none',
    fontSize: '14px',
    marginTop: '10px',
  },
  defaultTagColumn: {
    width: '64px',
    height: '22px',
    TextColor: '#231E33',
    marginTop: '10px',
    marginLeft: '14px',
  },
  header: {
    marginBottom: 4,
  },
  addressNote: {
    fontStyle: 'italic',
    marginTop: space.half,
  },
  addressText: {
    color: colors.gray62,
    fontSize: font.size.xSmall,
    lineHeight: font.lineHeight.loose,
    marginLeft: '10px',
  },
  addressLine: {
    whiteSpace: 'nowrap',
  },
  defaultTag: {
    backgroundColor: '#F7F7F7',
    border: '1px solid #D9D9D9 !important',
    color: 'rgba(0,0,0,0.65) !important',
    fontSize: '12px',
  },
});

export default class AddressListItem extends Component {
  static propTypes = {
    address: PropTypes.object,
    index: PropTypes.number,
    label: PropTypes.string,
    onClickEdit: PropTypes.func,
    onClickOrder: PropTypes.func,
    onUpdateAddressOrder: PropTypes.func,
    styles: aphroditePropType,
  };

  state = { isHover: false };

  handleArrowDownClick = () => this.handleUpdateAddressOrder(-1);
  handleArrowUpClick = () => this.handleUpdateAddressOrder(1);

  handleUpdateAddressOrder = direction =>
    this.props.onUpdateAddressOrder({
      direction,
      order: get(this.props, 'address.order'),
    });

  handleClickEdit = () => {
    this.props.onClickEdit({
      ...this.props.address,
      index: this.props.index,
    });
  };

  handleMouseEnter = () => this.setState({ isHover: true });
  handleMouseLeave = () => this.setState({ isHover: false });
  handeMouseMove = () => {
    if (!this.state.isHover) {
      this.setState({ isHover: true });
    }
  };

  calcHoverOpacity = () => ({ opacity: this.state.isHover ? 1 : 0 });

  renderContent = () => {
    const { city, label, line1, line2, note, postal_code, state, order } = this.props.address;
    return (
      <Div styles={sx.content}>
        <Row align="center" styles={sx.header}>
          <TruncateText styles={sx.label}>
            {order + 1} {label}
          </TruncateText>
        </Row>
        <Div styles={sx.addressText}>
          <Div className="fs-hide" styles={sx.addressLine} data-id="e2e-addressContent">
            {formatAddress({ line1, line2 })}
          </Div>
          <Div className="fs-hide" styles={sx.addressLine}>
            {formatAddress({ city, state, postal_code })}
          </Div>
          {note && (
            <Div styles={sx.addressNote}>
              <Dotdotdot clamp={6}>{note}</Dotdotdot>
            </Div>
          )}
          <Row align="center">
            <Column>{this.renderEditButton()}</Column>
            {order === 0 && (
              <Column styles={sx.defaultTagColumn}>
                <Tag className={css(sx.defaultTag)}>Default</Tag>
              </Column>
            )}
          </Row>
        </Div>
      </Div>
    );
  };

  renderEditButton = () => (
    <>
      <Div
        id="e2e-addressListItemEdit"
        onClick={this.handleClickEdit}
        styles={sx.editButton}
        title="Edit address"
      >
        Edit
      </Div>
    </>
  );

  render = () => (
    <Row
      align="center"
      onMouseEnter={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      onMouseMove={this.handeMouseMove}
      styles={[sx.container, this.props.styles]}
    >
      {this.renderContent()}
      <Button
        icon="shoppingCart"
        id="e2e-addressListItemDeliverButton"
        onClick={this.props.onClickOrder}
        outline
        styles={sx.button}
        title={`Deliver to ${this.props.label}`}
        type="violet"
      />
    </Row>
  );
}
