import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

export const LeadListContext = React.createContext();

export const ActionTypes = {
  UPDATE_LEADLIST_TABLE_SETTINGS: 'UPDATE_LEADLIST_TABLE_SETTINGS',
};

const defaultSortOrderDSM = {
  model: 'lead',
  columnKey: 'cadence',
  field: 'cadence',
  order: 'ascend',
};

const defaultSortOrderDSA = {
  model: 'lead',
  columnKey: 'lead_status',
  field: 'lead_status',
  order: 'descend',
};

const defaultPagination = {
  current: 0,
  defaultPageSize: 100,
};

const initialState = {
  tableActions: {
    pagination: defaultPagination,
    filters: null,
    sorter: null,
  },
  defaultTableActions: {
    sorter: {
      dsm: defaultSortOrderDSM,
      dsa: defaultSortOrderDSA,
    },
    pagination: defaultPagination,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_LEADLIST_TABLE_SETTINGS: {
      const { payload } = action;
      return {
        ...state,
        tableActions: payload,
      };
    }
    default:
      throw new Error();
  }
};

const LeadListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <LeadListContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </LeadListContext.Provider>
  );
};

LeadListProvider.propTypes = {
  children: PropTypes.node,
};

export default React.memo(LeadListProvider);
