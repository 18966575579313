import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setFilterPanelVisibility } from '../redux/filterPanel';

const mapDispatchToProps = (dispatch, { isFilterPanelOpen }) => ({
  onToggleFilterPanelVisibility() {
    dispatch(setFilterPanelVisibility({ isFilterPanelOpen: !isFilterPanelOpen }));
  },
});

const mapStateToProps = ({ filterPanel: { isFilterPanelOpen } }) => ({ isFilterPanelOpen });

export default Component =>
  compose(connect(mapStateToProps), connect(null, mapDispatchToProps))(Component);
