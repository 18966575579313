import gql from 'graphql-tag';

const GET_CAMPAIGNS = gql`
  query campaigns($limit: Int, $skip: Int) {
    campaigns(limit: $limit, skip: $skip) {
      data {
        id
        status
        name
        description
        payer_ids
        priority
        pharmacy_names
        pharmacy_ids
        plan_ids
        special_notes
      }
      total
      limit
      skip
    }
  }
`;

export default GET_CAMPAIGNS;
