import gql from 'graphql-tag';

const GET_CAMPAIGN_WEIGHTS = gql`
  query campaignWeights {
    campaignWeights {
      data {
        weight
        percent_allocation
      }
    }
  }
`;

export default GET_CAMPAIGN_WEIGHTS;
