import gql from 'graphql-tag';

const UPDATE_NOTES = gql`
  mutation dismissNotes($ids: [Int]!, $dismissed_at: Date) {
    dismissNotes(ids: $ids, dismissed_at: $dismissed_at) {
      data {
        text
        id
        dismissed_at
      }
    }
  }
`;

export default UPDATE_NOTES;
