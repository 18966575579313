import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography, Collapse, Form, Input, Select, Button, message } from 'antd';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite';
import { GET_CAREGAPS, RESOLVE_CAREGAP } from './graphql/careGaps';
import './CareGapStyles.css';

const sx = StyleSheet.create({
  modalContainer: {
    minWidth: '579px',
    minHeight: '252px',
  },
  message: {
    fontSize: '12px',
    color: '#212121',
    lineHeight: '14px',
    textAlign: 'justify',
    textJustify: 'inter-word',
    marginBottom: '15px',
  },
  label: {
    color: '#212121',
    margin: '0 0 2px 0 !important',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  line: {
    height: '1px',
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  button: {
    width: '120px',
    height: '40px',
  },
});

const { Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

const CareGapModal = ({ onClose, visible, careGaps, selectedPatientId }) => {
  const [data, setData] = useState(careGaps);
  const [form] = Form.useForm();
  const [isOther, setIsOther] = useState(false);
  const activeKey = data[0].id;
  const [activePanel, setActivePanel] = useState(activeKey);

  // reset form fields on panel change.
  const panelChange = key => {
    form.resetFields();
    setActivePanel(key);
  };

  const [getSelectedPatientCareGaps] = useLazyQuery(GET_CAREGAPS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching caregaps: ${error.message}`);
    },
    onCompleted: ({ getCareGaps }) => {
      if (getCareGaps?.data.length) {
        const filterdCareGaps = getCareGaps.data.filter(data => data.care_gap_outcome === null);
        if (filterdCareGaps.length) {
          setData(filterdCareGaps);
          panelChange(filterdCareGaps[0].id);
        } else {
          onClose();
          message.success(`All CareGaps Resolved`);
        }
      }
    },
  });

  const fetchNewCareGaps = () => {
    getSelectedPatientCareGaps({
      variables: {
        id: selectedPatientId,
      },
    });
  };

  // query to resove careGap.
  const [resolveCareGap, { loading: isResolvingCareGap }] = useMutation(RESOLVE_CAREGAP, {
    onError: e => {
      message.error(`Error Resolving CareGap: ${e.message}`);
    },
    onCompleted: () => {
      message.success(`CareGap Resolved Successfully`);
      fetchNewCareGaps();
    },
  });

  const submitForm = (type, careGapId, messageId, outcomeMsgRuleId) => {
    const { outcome, message } = form.getFieldsValue();
    if (outcome === 'other') {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
    form
      .validateFields()
      .then(() => {
        if (type === 'submit') {
          resolveCareGap({
            variables: {
              careGapId,
              messageId,
              outcomeMsgRuleId,
              patientId: selectedPatientId,
              modifiers: {
                outcome_reason: outcome,
                outcome_message: message || '',
              },
            },
          });
        }
      })
      .catch(info => {
        return info;
      });
  };

  return (
    <Modal onCancel={onClose} open={visible} className={css(sx.modalContainer)} footer={null}>
      <Title className="modelHeaderText" level={3}>
        Care Gaps
      </Title>
      <Collapse activeKey={activePanel} accordion onChange={panelChange} ghost>
        {data.map(cg => (
          <>
            <hr className={css(sx.line)} />
            <Panel header={`Care Alert: ${cg.provider_message_headline}`} key={cg.id}>
              <div className={css(sx.message)}>{cg.provider_message}</div>
              <Form name="modify-form-ref" form={form} layout="vertical">
                <div>
                  <p className={css(sx.label)}>Outcome</p>
                  <Form.Item
                    name="outcome"
                    rules={[{ required: true, message: 'Outcome is required.' }]}
                  >
                    <Select
                      placeholder="<Select>"
                      showArrow
                      size="medium"
                      key="outcome-select"
                      onChange={() => {
                        submitForm('change');
                      }}
                    >
                      <Option value="medication_filled">Medication Filled</Option>
                      <Option value="change_in_therapy">Change in Therapy</Option>
                      <Option value="patient_not_compliant">Patient not compliant</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <p className={css(sx.label)}>Message</p>
                  <Form.Item
                    name="message"
                    rules={
                      isOther
                        ? [{ required: true, message: 'Message is required.' }]
                        : [{ required: false }]
                    }
                  >
                    <Input key="message-input" />
                  </Form.Item>
                </div>
                <div className={css(sx.buttonsContainer)}>
                  <Button
                    className={css(sx.button)}
                    type="primary"
                    loading={isResolvingCareGap}
                    onClick={() => submitForm('submit', cg.id, cg.message_id, cg.rule_id)}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Panel>
          </>
        ))}
      </Collapse>
    </Modal>
  );
};

CareGapModal.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  careGaps: PropTypes.array,
  selectedPatientId: PropTypes.number,
};

export default CareGapModal;
