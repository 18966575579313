/* eslint-disable react/prop-types */
import { colors } from '@zipdrug/ui';
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import cloneDeep from 'lodash/cloneDeep';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Table, Typography, Button, message, Space } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { LeadChurnListContext, ActionTypes } from 'contexts/LeadChurnListProvider';
import filterSearchBox from '../Filters/Search/Search';
import withSelectPatient from '../../../../../hoc/withSelectPatient';
import withRoles from '../../../../../hoc/withRoles';
import ReAssignDSA from '../ReAssignDSA/ReAssignDSA';
import GET_LEAD_CHURN_LIST from '../../graphql/getLeadChurnList';
import UPDATE_MEMBER_STATUS from '../../graphql/updateMemberStatus';
import REASSIGN_DSA from '../../graphql/reAssignDSA';
import generateVariables from '../../utils/GenerateVariables.utils';

const { Link, Text } = Typography;

const sx = StyleSheet.create({
  statusSelect: { width: '133px' },
  tableContainer: { width: 'auto', minWidth: 'unset !important' },
  leadChurnListTableOptionsContainer: {
    marginBottom: '20px',
  },
  leadChurnListTableOptionsButtonMargin: {
    marginRight: '16px',
  },
  dsaReassign: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  disabledRow: {
    background: '#f5f5f5',
    opacity: '0.5',
  },
  leadsTextContainer: {
    display: 'flex !important',
    justifyContent: 'space-between',
  },
  leadsText: {
    color: colors.gray52,
  },
});

const defaultSelectedMembers = {
  memberIds: [],
  memberRecords: [],
};

const defaultSelectedDSA = {
  first_name: null,
  last_name: null,
  id: null,
};

const ChurnMemberListTable = ({ onSelectPatient, history, isAdmin, hasRole, user }) => {
  const [data, setData] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [selectedMembers, setSelectedMembers] = useState(defaultSelectedMembers);
  const [selectedDSA, setSelectedDSA] = useState(defaultSelectedDSA);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [lastUpdateDate, setLastUpdateDate] = useState(null);
  const { state, dispatch } = useContext(LeadChurnListContext);
  const { tableActions, defaultTableActions } = state;
  const [
    getLeadList,
    { loading: isFetchingLeadList, data: leadsData, refetch: refetchLeadList },
  ] = useLazyQuery(GET_LEAD_CHURN_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onError: error => {
      message.error(`Error fetching lead list: ${error.message}`);
    },
    onCompleted: ({ leadChurns }) => {
      setTotalLeads(leadChurns?.total);
      setData(leadChurns?.data);
      setLastUpdateDate(leadChurns?.last_updated_at);
    },
  });

  const [reAssignDSA, { loading: isReassigningDSA }] = useMutation(REASSIGN_DSA, {
    onError: error => {
      message.error(`Error re-assigning PBS: ${error.message}`);
    },
    onCompleted: ({ updateLeadChurns }) => {
      const clonedData = cloneDeep(data);
      const memberIds = updateLeadChurns?.data?.map(lead => lead.id);

      const resultData = clonedData.map(member => {
        if (memberIds.includes(member.id)) {
          member.assigned_user = { ...selectedDSA };
        }
        return member;
      });
      setData(resultData);
      setSelectedDSA(defaultSelectedDSA);
      setSelectedMembers(defaultSelectedMembers);
      setShowReassignModal(false);
      message.success('Successfully re-assigned PBS');
    },
  });

  const [updateMemberStatus, { loading: isUpdatingMemberStatus }] = useMutation(
    UPDATE_MEMBER_STATUS,
    {
      onError: error => {
        message.error(`Error updating Status: ${error.message}`);
      },
      onCompleted: ({ updateLeadChurns }) => {
        const clonedData = cloneDeep(data);
        const memberIds = updateLeadChurns?.data?.map(lead => lead.id);
        const resultData = clonedData.map(member => {
          if (memberIds.includes(member.id)) {
            member.filtered_out = updateLeadChurns?.data[0]?.filtered_out;
          }
          return member;
        });

        setData(resultData);
        setSelectedMembers(defaultSelectedMembers);

        message.success('Successfully Updated Status');
      },
    },
  );

  const onMemberClick = patientId => {
    onSelectPatient(patientId);
    history.push('/members');
  };

  const onStatusChange = async (leadId, status) => {
    if (leadId?.memberIds) {
      updateMemberStatus({
        variables: {
          ids: leadId?.memberIds,
          modifiers: {
            filtered_by_user_id: user.id,
            filtered_out: status === 'filter_out' ? Date.now() : null,
          },
        },
      });
    }
  };

  const submitReassignment = selectedOption => {
    if (!selectedOption?.id) {
      message.error('Please enter a valid PBS.');
    } else {
      setSelectedDSA(selectedOption);
      reAssignDSA({
        variables: {
          ids: selectedMembers?.memberIds,
          modifiers: {
            assigned_user_id: selectedOption.id,
          },
        },
      });
    }
  };

  const generateColumns = () => {
    const headers = [
      {
        title: 'Name',
        dataIndex: 'member',
        key: 'member',
        width: 250,
        ...filterSearchBox('member', 'Name', tableActions?.filters?.patient),
        filteredValue: tableActions?.filters?.member || null,
        render: (text, record) => (
          <Link underline onClick={() => onMemberClick(record?.patient_id)}>
            {`${record?.first_name} ${record?.last_name}`}
          </Link>
        ),
      },
    ];

    if (isAdmin || hasRole.pbsLead) {
      const dsaColumns = [
        {
          title: 'PBS',
          key: 'assigned_user',
          width: 250,
          dataIndex: 'assigned_user',
          ...filterSearchBox('assigned_user', 'PBS'),
          filteredValue: tableActions?.filters?.assigned_user || null,
          sorter: true,
          sortDirections: ['ascend', 'descend', 'ascend'],
          sortOrder:
            tableActions?.sorter?.columnKey === 'assigned_user' && tableActions?.sorter?.order,
          render: text => {
            return `${text?.first_name} ${text?.last_name}`;
          },
        },
      ];

      dsaColumns.forEach(col => headers.push(col));
    }
    return headers;
  };

  const fetchLeadList = (payload = cloneDeep(tableActions), refetch = false) => {
    // If no headxers are present in some scenarios, ANTD does some pagic and assign the sorter to the last column in the headers on any action...
    // So if Sorter is present check the order and if its false that means its ANTD Magic, if anything else means its actually sort.
    if (payload?.sorter && !payload?.sorter?.order) {
      payload.sorter = null;
    }

    const variables = generateVariables(payload);

    if (refetch) {
      refetchLeadList({
        ...variables,
      });
    } else {
      getLeadList({
        variables: {
          ...variables,
        },
      });
    }
  };

  useEffect(() => {
    fetchLeadList();
  }, []);

  const onTableChange = (pagination, filters, sorter, { action }) => {
    dispatch({
      type: ActionTypes.UPDATE_LEADCHURNLIST_TABLE_SETTINGS,
      payload: {
        pagination: action === 'paginate' ? pagination : { ...pagination, current: 1 },
        filters,
        sorter,
      },
    });

    // To stay consistent always re-direct user to page 1 on filter/sort.
    if (action === 'paginate') {
      fetchLeadList({
        pagination,
        filters,
        sorter,
      });
    } else {
      fetchLeadList({
        filters,
        sorter,
      });
    }
  };

  // Useful when you update an value and the filter/sort is not applied.
  const refreshList = () => {
    fetchLeadList(tableActions, true);
  };

  const clearAllFilters = () => {
    const payload = {
      pagination: defaultTableActions.pagination,
      filters: null,
      sorter: null,
    };

    dispatch({
      type: ActionTypes.UPDATE_LEADCHURNLIST_TABLE_SETTINGS,
      payload,
    });

    fetchLeadList(payload);
  };

  const getTableOptions = () => {
    const tableOptions = {
      columns: generateColumns(),
      dataSource: data,
      size: 'middle',
      onChange: onTableChange,
      rowKey: record => record.id,
      rowClassName: record => record.filtered_out !== null && css(sx.disabledRow),
      loading: isFetchingLeadList || isReassigningDSA || isUpdatingMemberStatus,
      pagination: {
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: [25, 50, 100],
        total: leadsData?.leadChurns?.total || 0,
        current: tableActions?.pagination?.current || 1,
        pageSize: tableActions?.pagination?.pageSize,
      },
      onRow: record => {
        return {
          className: record.lead_status === 'filter_out' ? css(sx.disabledRow) : '',
        };
      },
    };
    if (isAdmin || hasRole.pbsLead) {
      tableOptions.rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedMembers({ memberIds: selectedRowKeys, memberRecords: selectedRows });
        },
        selectedRowKeys: selectedMembers.memberIds,
      };
    }

    return tableOptions;
  };

  return (
    <>
      <div className={css(sx.leadChurnListTableOptionsContainer)}>
        <Button
          type="secondary"
          className={css(sx.leadChurnListTableOptionsButtonMargin)}
          onClick={refreshList}
        >
          Refresh List
        </Button>
        <Button
          type="secondary"
          onClick={clearAllFilters}
          className={css(sx.leadChurnListTableOptionsButtonMargin)}
        >
          Clear All Filters
        </Button>
        {selectedMembers?.memberIds?.length > 0 && (
          <>
            <Button
              type="secondary"
              onClick={() => setShowReassignModal(true)}
              className={css(sx.leadChurnListTableOptionsButtonMargin)}
            >
              Reassign
            </Button>
            <Button
              type="secondary"
              onClick={() => onStatusChange(selectedMembers, 'filter_out')}
              className={css(sx.leadChurnListTableOptionsButtonMargin)}
            >
              Filter Out Leads
            </Button>
            <Button
              type="secondary"
              onClick={() => onStatusChange(selectedMembers, 'notCalled')}
              className={css(sx.leadChurnListTableOptionsButtonMargin)}
            >
              Filter In Leads
            </Button>
          </>
        )}
      </div>
      <Space className={css(sx.leadsTextContainer)}>
        <Text className={css(sx.leadsText)}>Total Churned Members: {totalLeads}</Text>
        {data?.length > 0 && (
          <Text className={css(sx.leadsText)}>
            Last Updated: {lastUpdateDate && moment(lastUpdateDate).format('MMMM Do YYYY')}
          </Text>
        )}
      </Space>
      <Table {...getTableOptions()} />

      {showReassignModal && (
        <ReAssignDSA
          visible={showReassignModal}
          submitReassignment={submitReassignment}
          onReassignCancel={() => setShowReassignModal(false)}
          reassignCount={selectedMembers?.memberIds?.length}
        />
      )}
    </>
  );
};

export default withSelectPatient(withRouter(withRoles(ChurnMemberListTable)));
