import { fields, setSession, users } from '@zipdrug/react-api-sdk';
import { getUrlParams } from '@zipdrug/ui';
import { pick } from 'lodash';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { setPharmacyId } from '../redux/queryReducer';

const mapDispatchToProps = (dispatch, { mutateUpdateUserAndLogin }) => ({
  async updateUserAndLogin(modifier) {
    const urlParams = getUrlParams();
    const modifiers = pick(modifier, ...fields.UserModifier);
    if (modifier.currentPassword) {
      modifiers.current_password = modifier.currentPassword;
    }
    return mutateUpdateUserAndLogin({
      variables: {
        modifier: modifiers,
        token: urlParams.token,
      },
    }).then(
      ({
        data: {
          updateUserAndLogin: { data },
        },
        errors,
      }) => {
        if (errors) {
          return { errors };
        }

        const { token, user } = data;

        const roles = user.roles.filter(r => r.pharmacy_id);

        if (roles.length) {
          dispatch(setPharmacyId({ pharmacy_id: roles[0].pharmacy_id }));
        }

        if (token) {
          dispatch(setSession({ token, user }));
        }

        return { token, user };
      },
    );
  },
});

export default Component =>
  compose(
    graphql(users.mutateUpdateUserAndLogin, { name: 'mutateUpdateUserAndLogin' }),
    connect(null, mapDispatchToProps),
  )(Component);
