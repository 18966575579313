import { colors, Div, FlexItem, Icon, media, padding, Row, space } from '@zipdrug/ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

const sx = StyleSheet.create({
  container: {
    ...padding.build(0),
    borderBottom: 'none',
  },
  content: {
    paddingRight: space.default,
  },
  icon: {
    height: 'auto',
    width: '11.6rem',
  },
  link: {
    outline: 'none',
    ':focus': { outline: 'none' },
  },
  logoContainer: {
    ...padding.build(space.default),
    color: colors.theme.primary.default,

    [media.desktop.small]: {
      ':hover': {
        color: colors.theme.primary.hover,
        opacity: 0.75,
      },
    },

    ':active': {
      color: colors.theme.primary.active,
      opacity: 0.75,
    },
  },
});

const AppNav = ({ children, styles, ...props }) => (
  <Header {...props} styles={[sx.container, styles]}>
    <Link className={css(sx.link)} to="/">
      <Div styles={sx.logoContainer} tabIndex={-1} title="Home">
        <Icon
          iconColor={colors.theme.primary.default}
          name={'branding'}
          styles={sx.icon}
          textColor={colors.gray20}
        />
      </Div>
    </Link>
    <Row align="center" component={FlexItem} justify="end" styles={sx.content}>
      {children}
    </Row>
  </Header>
);

AppNav.propTypes = {
  children: PropTypes.node,
  styles: aphroditePropType,
};

export default AppNav;
