import { StyleSheet } from 'aphrodite/no-important';
import React, { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { Accordion, border, colors, padding, space } from '@zipdrug/ui';

const HOVER_STYLES = {
  backgroundColor: colors.lightYellow,
};

const sx = StyleSheet.create({
  container: {
    ...border.build('bottom', { color: colors.gray97 }),
    ...padding.build(space.get(1.5)),
    color: colors.theme.primary.default,
    flex: 1,

    ':hover': {
      ...HOVER_STYLES,
      color: colors.theme.primary.hover,
    },
    ':active': {
      color: colors.theme.primary.active,
    },
  },
  containerEdit: HOVER_STYLES,
});

export default class SettingsAccordion extends Component {
  static propTypes = {
    actionsRenderer: PropTypes.node,
    editFormRenderer: PropTypes.node,
    headerRenderer: PropTypes.node,
    onOpen: PropTypes.func,
  };

  state = { editing: false };

  handleDisableEdit = () => this.setState({ editing: false });
  handleEnableEdit = () => this.setState({ editing: true });

  renderActions = () =>
    cloneElement(this.props.actionsRenderer, {
      onClickEdit: this.handleEnableEdit,
    });

  renderEditForm = () =>
    cloneElement(this.props.editFormRenderer, {
      onClickCancel: this.handleDisableEdit,
      onSubmit: this.handleDisableEdit,
    });

  renderHeader = () =>
    cloneElement(this.props.headerRenderer, {
      isEditing: this.state.editing,
    });

  render = () => (
    <Accordion
      headerRenderer={this.renderHeader()}
      onOpen={this.props.onOpen}
      preventToggle={this.state.editing}
      shouldToggleOnContentClick={false}
      styles={[sx.container, this.state.editing ? sx.containerEdit : null]}
    >
      {this.state.editing ? this.renderEditForm() : this.renderActions()}
    </Accordion>
  );
}
