import React from 'react';

const DigitalEnrollmentLarge = () => (
  <svg width="21px" height="18px" viewBox="0 0 21 18" version="1.1">
    <title>Style / Icons / desktop-light</title>
    <g
      id="Style-/-Icons-/-desktop-light"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M19.59375,-2.30926389e-14 L1.340625,-2.30926389e-14 C0.60234375,-2.30926389e-14 -9.05941988e-14,0.60234375 -9.05941988e-14,1.340625 L-9.05941988e-14,12.5859375 C-9.05941988e-14,13.3242187 0.60234375,13.9265625 1.340625,13.9265625 L9.703125,13.9265625 L9.703125,16.4554687 L6.25078125,16.4554687 C5.84296875,16.4554687 5.51015625,16.7882812 5.51015625,17.1960937 C5.51015625,17.6039062 5.84296875,17.9367187 6.25078125,17.9367187 L14.6507812,17.9367187 C15.0585937,17.9367187 15.3914062,17.6039062 15.3914062,17.1960937 C15.3914062,16.7882812 15.0585937,16.4554687 14.6507812,16.4554687 L11.184375,16.4554687 L11.184375,13.9265625 L19.59375,13.9265625 C20.3320312,13.9265625 20.934375,13.3242187 20.934375,12.5859375 L20.934375,1.340625 C20.934375,0.60234375 20.3320312,-2.30926389e-14 19.59375,-2.30926389e-14 Z M19.453125,1.48125 L19.453125,12.4453125 L1.48125,12.4453125 L1.48125,1.48125 L19.453125,1.48125 Z"
        id="desktop"
        fill="#9B9B9B"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

const DigitalEnrollmentSmall = () => (
  <svg width="18px" height="15px" viewBox="0 0 18 15" version="1.1">
    <title>Style / Icons / desktop</title>
    <g
      id="Style-/-Icons-/-desktop"
      stroke="none"
      strokeWidth="1"
      fill="currentColor"
      fillRule="evenodd"
    >
      <path
        d="M16.8554554,-2.30926389e-14 L1.153268,-2.30926389e-14 C0.518164119,-2.30926389e-14 -9.05941988e-14,0.518164119 -9.05941988e-14,1.153268 L-9.05941988e-14,10.827009 C-9.05941988e-14,11.4621129 0.518164119,11.980277 1.153268,11.980277 L8.34707958,11.980277 L8.34707958,14.1557598 L5.37721286,14.1557598 C5.02639357,14.1557598 4.74009277,14.4420606 4.74009277,14.7928799 C4.74009277,15.1436992 5.02639357,15.43 5.37721286,15.43 L12.6032837,15.43 C12.954103,15.43 13.2404038,15.1436992 13.2404038,14.7928799 C13.2404038,14.4420606 12.954103,14.1557598 12.6032837,14.1557598 L9.62131974,14.1557598 L9.62131974,11.980277 L16.8554554,11.980277 C17.4905593,11.980277 18.0087234,11.4621129 18.0087234,10.827009 L18.0087234,1.153268 C18.0087234,0.518164119 17.4905593,-2.30926389e-14 16.8554554,-2.30926389e-14 Z M16.7344832,1.27424017 L16.7344832,10.7060368 L1.27424017,10.7060368 L1.27424017,1.27424017 L16.7344832,1.27424017 Z"
        id="desktop"
        fill="#212121"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export { DigitalEnrollmentLarge, DigitalEnrollmentSmall };
