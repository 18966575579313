import { Div, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import aphroditePropType from 'aphrodite-prop-type';
import React, { Component } from 'react';
import TopBarItem from './topbar/TopBarItem';

const sx = StyleSheet.create({
  button: {
    minWidth: '9.8rem',
    textAlign: 'center',
  },
});

const togglePHIElementVisibility = isHidden => {
  // Select all elements containing PHI info using the
  // className we use for hiding PHI for Fullstory.com
  const elements = document.querySelectorAll('.fs-hide');

  for (let i = 0; i < elements.length; i += 1) {
    elements[i].style.visibility = isHidden ? 'hidden' : 'visible';
  }
};

export default class HidePHIButton extends Component {
  static propTypes = {
    styles: aphroditePropType,
  };

  state = { isHidden: false };

  togglePHIElements = () =>
    this.setState(prevState => {
      const isHidden = !prevState.isHidden;

      togglePHIElementVisibility(isHidden);
      return { isHidden };
    });

  renderText = () => (
    <Div>
      <span style={{ marginRight: space.half }}>{this.state.isHidden ? '🐵' : '🙈'}</span>
      <span>{`${this.state.isHidden ? 'Show' : 'Hide'} PHI`}</span>
    </Div>
  );

  render = () => (
    <TopBarItem
      onClick={this.togglePHIElements}
      styles={[sx.button, this.props.styles]}
      text={this.renderText()}
      tooltipText="Only Zipdrug employees see this button"
    />
  );
}
