import gql from 'graphql-tag';

const GET_PHARMACY_MEMBERS = gql`
  query getEnrolledMembersForPharmacy(
    $limit: Int
    $skip: Int
    $query: JSON
    $order_by: MemberListOrderByInput
  ) {
    getEnrolledMembersForPharmacy(limit: $limit, skip: $skip, query: $query, order_by: $order_by) {
      data {
        enrolled_date
        id
        name
        DOB
        status
        language
        pharmacy
        plan_name
        outreach_type
        click_redirect
        action_items
      }
      total
    }
  }
`;

export default GET_PHARMACY_MEMBERS;
