/* eslint-disable no-case-declarations */
import cloneDeep from 'lodash/cloneDeep';

const sortDirection = {
  ascend: 'ASC',
  descend: 'DESC',
};

const generateOrderByUtil = (key, sortKey, direction) => {
  const payload = {};
  payload[key] = {
    sortKey,
    direction: sortDirection[direction],
  };
  return payload;
};

const generateOrderBy = sortOrder => {
  const sortPayload = {
    order_by: {},
  };

  switch (sortOrder?.columnKey) {
    case 'pharmacy_name':
    case 'state':
    case 'plan_name':
    case 'group_name':
      sortPayload.order_by = generateOrderByUtil(
        sortOrder.columnKey,
        sortOrder.columnKey,
        sortOrder.order,
      );
      return sortPayload;
    default:
      return sortPayload;
  }
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 100,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateQuery = filters => {
  if (!filters) {
    return {
      query: {},
    };
  }

  return {
    query: {
      ...Object.entries(filters).reduce((acc, curr) => {
        if (curr[1]) {
          switch (curr[0]) {
            case 'pharmacy_name':
            case 'state':
            case 'plan_name':
            case 'group_name':
              acc[curr[0]] = {
                $or: curr[1],
              };
              return acc;
            case 'pharmacy_npi':
            case 'template_envelope_id':
            case 'lob_envelope_id':
            case 'lob_return_envelope_id':
            case 'lob_perforated_page_index':
              acc[curr[0]] = {
                $ilike: `%${curr[1]}%`,
              };
              return acc;
            default:
              return acc;
          }
        }
        return acc;
      }, {}),
    },
  };
};

const generateVariables = actions => {
  const { sorter, filters, pagination } = cloneDeep(actions);

  // Generate Pagination
  const pageOptions = generatePagination(pagination);

  // Generate Order_By
  const order_by = sorter ? generateOrderBy(sorter) : {};

  // Generate Query
  const query = generateQuery(filters);

  return {
    ...pageOptions,
    ...order_by,
    ...query,
  };
};

export default generateVariables;
