import gql from 'graphql-tag';

const getCallOutcomes = gql`
  query getCallOutcomes($patientId: Int!) {
    getCallOutcomes(patientId: $patientId) {
      data {
        id
        call_outcome_user {
          first_name
          id
          last_name
        }
        notes
        pharmacy_id
        call_outcome_reason {
          id
          outcome_reason_type_id
          outcome_type {
            id
            enroll_status
            outcome
            outcome_reason
            pitched
          }
        }
      }
      total
      __typename
    }
  }
`;

export default getCallOutcomes;
