import { withSession } from '@zipdrug/react-api-sdk';
import pharmacyTypes from 'dashboard/components/LeadList/constants/pharmacyServiceTypes';
import GET_PHARMACY_SERVICE_TYPES from 'dashboard/components/LeadList/graphql/getPharmacyServiceTypes';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import { compose, mapProps } from 'recompose';
import fetchOnceOptions from './utils/fetchOnceOptions';
import withSelectedPatientId from './withSelectedPatientId';

const withPharmacyServiceTypes = Component =>
  graphql(GET_PHARMACY_SERVICE_TYPES, {
    skip: ({ selectedPatientId }) => !selectedPatientId,
    options: () =>
      fetchOnceOptions('pharmacyServiceType', {
        variables: {
          query: {
            pharmacy_service_type: Object.values(pharmacyTypes),
          },
        },
      }),
    props: ({ data: { pharmacyServiceType: result } }) => ({
      serviceTypes: get(result, 'data'),
    }),
  })(Component);

export default Component =>
  compose(
    withSession,
    withSelectedPatientId,
    withPharmacyServiceTypes,
    mapProps(({ serviceTypes, ...props }) => ({
      serviceTypes,
      ...props,
    })),
  )(Component);
