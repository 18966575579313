import cloneDeep from 'lodash/cloneDeep';
import _flatten from 'lodash/flatten';

const defaultPayload = {
  text: {
    $ilike: '%#%',
  },
};

const generatePagination = pagination => {
  return {
    limit: pagination?.pageSize || 25,
    skip: pagination?.current > 0 ? (pagination?.current - 1) * pagination?.pageSize : 0,
  };
};

const generateQuery = filters => {
  if (!filters) {
    return {
      query: { ...defaultPayload },
    };
  }

  return {
    query: {
      ...defaultPayload,
      ...Object.entries(filters).reduce((acc, curr) => {
        if (curr[1]) {
          switch (curr[0]) {
            case 'status':
              acc.text = {
                ...acc.text,
                $or: _flatten(
                  curr[1].map(entry => {
                    return { $ilike: `%${entry}%` };
                  }),
                ),
              };
              return acc;
            case 'note': {
              const doesTextStartWithHashTag = curr[1].startsWith('#');
              acc.text = {
                ...acc.text,
                $ilike: doesTextStartWithHashTag ? `%${curr[1]}%` : `%#%${curr[1]}%`,
              };
              return acc;
            }
            case 'pharmacy_name':
              acc.pharmacyQuery = {
                pharmacyNameQuery: {
                  name: {
                    $ilike: `%${curr[1]}%`,
                  },
                },
              };
              return acc;
            case 'pharmacy_state':
              acc.pharmacyQuery = {
                pharmacyStateQuery: {
                  state: {
                    $in: curr[1],
                  },
                },
              };
              return acc;

            default:
              return acc;
          }
        }
        return acc;
      }, {}),
    },
  };
};

const generateVariables = actions => {
  const { filters, pagination } = cloneDeep(actions);

  const pageOptions = generatePagination(pagination);
  const query = generateQuery(filters);

  return {
    ...pageOptions,
    ...query,
  };
};

export default generateVariables;
