import { colors, Div, font, padding, space } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import withCurrentUser from '../../hoc/withCurrentUser';
import PageHeading from '../../ui/PageHeading';
import PageSectionTitle from '../../ui/PageSectionTitle';
import SettingsPageTemplate from '../SettingsPageTemplate';
import UserProfileFormContainer from '../UserProfileFormContainer';
import ResetPasswordButtonContainer from './ResetPasswordButtonContainer';
import { version } from '../../../package.json';

const sx = StyleSheet.create({
  description: {
    color: colors.gray57,
    lineHeight: font.lineHeight.looser,
  },
  profileFormContainer: {
    ...padding.build(space.get(1.5), space.get(1.5), space.three),
  },
});

const MyAccountPage = ({ currentUser }) => {
  const [buttonVisible, setButtonVisible] = useState('hidden');
  useEffect(() => {
    const result = currentUser.roles.map(role => {
      return role.type.includes('pharmacist_admin') || role.type.includes('pharmacist_user');
    });
    if (result.includes(true)) setButtonVisible('visible');
  }, []);

  return (
    <SettingsPageTemplate>
      <PageHeading title="My account" />
      <PageSectionTitle>Version</PageSectionTitle>
      <Div styles={sx.profileFormContainer}>
        <Div styles={sx.description}>{version}</Div>
      </Div>
      <PageSectionTitle>Profile</PageSectionTitle>
      <Div styles={sx.profileFormContainer}>
        <Div styles={sx.description}>Update your profile by editing the information below.</Div>
        <UserProfileFormContainer isCurrentUser user={currentUser} />
      </Div>
      <PageSectionTitle style={{ visibility: buttonVisible }}>Password</PageSectionTitle>
      <Div styles={sx.profileFormContainer} style={{ visibility: buttonVisible }}>
        <Div styles={sx.description}>
          Reset your password by clicking the button below. We’ll
          <br />
          email you a link with instructions.
        </Div>
        <ResetPasswordButtonContainer user={currentUser} />
      </Div>
    </SettingsPageTemplate>
  );
};

MyAccountPage.propTypes = {
  currentUser: PropTypes.object,
};

export default withCurrentUser(MyAccountPage);
