import { colors, Icon, LinkButton, margin, padding, space, TruncateText } from '@zipdrug/ui';
import { StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import React from 'react';

const ContainerOpenStyles = {
  backgroundColor: colors.gray92,
};

const sx = StyleSheet.create({
  caret: {
    color: colors.gray62,
  },
  container: {
    ...padding.build(space.quarter, '0.8rem'),
    marginRight: space.quarter,
  },
  containerOpen: ContainerOpenStyles,
  text: margin.build(0, space.quarter),
});

const PatientInfoDropdownButton = ({ icon, id, isOpen, text, title }) => (
  <LinkButton id={id} styles={[sx.container, isOpen ? sx.containerOpen : null]} title={title}>
    <Icon name={icon} />
    <TruncateText styles={sx.text}>{text}</TruncateText>
    <Icon direction="down" name="caret" styles={sx.caret} />
  </LinkButton>
);

PatientInfoDropdownButton.propTypes = {
  icon: Icon.propTypes.name,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default PatientInfoDropdownButton;
