/* eslint-disable */
import { css, StyleSheet } from 'aphrodite/no-important';
import { get, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  border,
  colors,
  Column,
  Div,
  FlexItem,
  formatPhone,
  font,
  MetaRow,
  padding,
  Row,
  space,
} from '@zipdrug/ui';
import { Tag } from 'antd';
import CallButtonContainer from '../../../ui/CallButtonContainer';
import RxItemFillListItem from './RxItemFillListItem';

const sx = StyleSheet.create({
  container: {
    marginBottom: space.get(0.75),
    ':last-of-type': { marginBottom: 0 },
  },
  fillList: {
    marginBottom: space.quarter,
    color: colors.gray62,
    ':last-of-type': { marginBottom: 0 },
  },
  header: {
    marginBottom: space.half,
  },
  headerTitle: {
    color: colors.gray62,
  },
  rxNumHeader: {
    ...border.build('bottom', { color: colors.gray97 }),
    ...padding.build(space.quarter, 0),
    fontSize: font.size.xSmall,
  },
  textStyle: {
    color: colors.gray62,
  },
  hubPharmacyTag: {
    backgroundColor: '#F7F7F7',
    border: '1px solid #D9D9D9 !important',
    color: '#231E33 !important',
    fontSize: '12px',
    margin: '0px 0px 0px 3px',
    border: '1px solid #D9D9D9 !important',
  },
});

export default class RxItemPharmacySection extends Component {
  static propTypes = {
    rxClaims: PropTypes.arrayOf(PropTypes.object),
  };

  renderHeader = () => {
    const pharmacy = get(this.props, 'rxClaims[0].pharmacy', {});
    let phone = get(pharmacy, 'phones[0]');

    if (pharmacy?.parent_pharmacies.length > 0) {
      const parentPharm = pharmacy.parent_pharmacies[0].parent_pharmacy;
      pharmacy.name = parentPharm.name;
      pharmacy.npi = parentPharm.npi;
      phone = parentPharm.phones[0];
    }
    let pharmacyName;
    if (pharmacy?.name) {
      pharmacyName = pharmacy?.name?.replace('(Hub)', '');
    }

    return (
      <Row justify="space-between" styles={sx.header}>
        <Div styles={sx.headerContent}>
          <Div styles={sx.headerTitle}>
            <span styles={sx.textStyle}>{pharmacyName} </span>
            {pharmacy?.parent_pharmacies?.length > 0 || pharmacy?.chain_pharmacies?.length > 0 ? (
              <Tag className={css(sx.hubPharmacyTag)} type="secondary">
                Hub Pharmacy
              </Tag>
            ) : (
              ''
            )}
          </Div>
          {pharmacy.npi && (
            <MetaRow
              styles={sx.textStyle}
              items={[`NPI: ${pharmacy.npi}`, `${formatPhone(phone?.number)}`]}
            />
          )}
        </Div>
        {phone && (
          <FlexItem flex="none">
            <CallButtonContainer phone={phone} title={`Call ${pharmacy.name}`} />
          </FlexItem>
        )}
      </Row>
    );
  };

  renderFillLists = () => {
    const rxClaimsByNDCCode = groupBy(this.props.rxClaims, ({ rx_number }) => rx_number);

    return Object.keys(rxClaimsByNDCCode).map(rx_number => (
      <Column key={rx_number} styles={sx.fillList}>
        <Div className="fs-hide" styles={sx.textStyle}>
          {`RX# ${rx_number}`}
        </Div>
        {rxClaimsByNDCCode[rx_number].map(fill => (
          <RxItemFillListItem {...fill} key={fill.id} styles={sx.textStyle} />
        ))}
      </Column>
    ));
  };

  render = () =>
    this.props.rxClaims ? (
      <FlexItem styles={sx.container}>
        {this.renderHeader()}
        {this.renderFillLists()}
      </FlexItem>
    ) : null;
}
