import React from 'react';

const HandIcon = () => (
  <svg width="28" height="39" viewBox="0 0 28 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5884 29.007C12.5884 24.6887 8.86097 23.0093 6.99723 22.7094C6.65837 20.9098 6.69287 16.9507 5.06634 15.5113C3.4398 14.0718 1 15.0623 1 16.4109C1.50978 20.9101 1.50978 22.2562 2.52637 27.2077C3.54295 32.1592 5.78068 38 15.8442 38C27 38 27 26.0045 27 20.0068V8.76102C27 5.16237 22.046 5.1604 22.046 8.76102M11.3719 17.31V6.96056C11.3719 2.9135 5.78068 2.90904 5.78068 6.50966V15.6092M16.8342 17.3101V4.26228C16.8342 -0.235316 11.2429 0.210762 11.2429 3.81138V6.06203M22.046 18.6617V6.22847C22.046 1.7291 16.8342 2.17695 16.8342 5.77757"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default HandIcon;
